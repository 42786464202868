import React, { useEffect, useRef, useState } from 'react';
import videojs from 'video.js';

const AudioVideoPlayer = ({ videoDetailsData, audioTracks, options }) => {
    const videoRef = useRef(null);
    const playerRef = useRef(null);
    const [isUserInteracted, setIsUserInteracted] = useState(false);

    useEffect(() => {
        if (videoRef.current && videoDetailsData && isUserInteracted) {
            const player = videojs(videoRef.current, options, () => {
                playerRef.current = player;

                // Add audio tracks to the player after initialization
                if (audioTracks.length) {
                    audioTracks.forEach((track) => {
                        player.audioTracks().addTrack(
                            new videojs.AudioTrack({
                                id: track.id,
                                kind: 'alternative',
                                label: track.label,
                                language: track.language,
                                enabled: false, // Disable all tracks initially
                            })
                        );
                    });
                }
            });

            return () => {
                if (playerRef.current) {
                    playerRef.current.dispose();
                }
            };
        }
    }, [videoDetailsData, options, isUserInteracted, audioTracks]);

    const switchAudioTrack = (trackId) => {
        if (playerRef.current) {
            const player = playerRef.current;
            const tracks = player.audioTracks();
            for (let i = 0; i < tracks.length; i++) {
                if (tracks[i].id === trackId) {
                    tracks[i].enabled = true; // Enable the selected track
                } else {
                    tracks[i].enabled = false; // Disable other tracks
                }
            }
        } else {
            console.error('Player not initialized or available.');
        }
    };

    const handlePlayClick = () => {
        setIsUserInteracted(true);
        if (playerRef.current) {
            playerRef.current.play().catch((error) => {
                console.error('Error playing video:', error.message);
            });
        }
    };

    return (
        <div>
            <video ref={videoRef} className="video-js vjs-default-skin" controls={false} />
            {!isUserInteracted && (
                <button onClick={handlePlayClick}>Play Video</button>
            )}
            
            {audioTracks.map((track) => (
                <button key={track.id} onClick={() => switchAudioTrack(track.id)}>
                    Switch to {track.label} Audio
                </button>
            ))}
        </div>
    );
};

export default AudioVideoPlayer;
