import React, { useState } from 'react'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { IconFlim, IconMyBell, IconMyGear, IconMyHeart, IconMyNextArrow, IconMyPrevArrow, IconMySave, IconMySearch, IconMyUser } from '../../../assets/icons/MyIcons'
import LazyLoadImage from '../../HomePage/HomeSwiperComponents/LazyLoadImage'
import { useMyContext } from '../../../components/Layout/CommonProvider'
import { getItem } from '../../../Utils/localStorageUtils'
import MusicLogo from "../../../assets/images/music-icon.webp"
import MessageBox from '../../../components/MessageBox'

const MusicHeader = ({ languageDetailsData, profileDetails, toggleSideBar, authAction }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()

    const { musicSearchValue, handleMusicSearch, navigateTranslateChecker } = useMyContext()
    const goBack = () => {
        navigate(-1);
    };
    const goForward = () => {
        navigate(1);
    };

    const userRoleLocal = getItem('role')


    return (
        <div className="navbarWrapper w-100 align-items-center justify-content-between py-3 px-md-4 px-2 mb-md-2 d-flex ">
            <div className='d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center justify-content-between  d-none d-md-block '>
                    <button type='button' className='bg-transparent border-0 p-0 rounded-circle musicGoBackBtn navigationPrev theme-text-color' onClick={goBack} disabled={location.pathname === navigateTranslateChecker`/`}><IconMyPrevArrow styled={{ width: "30px", height: "30px", className: "" }} /></button>
                    <button type='button' className='bg-transparent border-0 p-0 rounded-circle theme-text-color navigationNext' onClick={goForward}><IconMyNextArrow styled={{ width: "30px", height: "30px", className: "" }} /></button>
                </div>
                <Link to={"/"} className="d-md-none d-block ">
                    <LazyLoadImage key={'logo'} src={MusicLogo} alt={'logo'} title={'M'} imageSize="rounded-2  overflow-hidden musicLogoImage object-fit-contain " classNameOptional="rounded-circle musicLogoImageHeight  d-flex align-items-center  justify-content-center mx-auto " />
                </Link>
                <div className='position-relative d-none d-sm-block'>
                    <input type="text" onChange={(e) => handleMusicSearch(e)} value={musicSearchValue} className="musicSearch py-2 pe-2  theme-border-color rounded-4 bg-transparent  theme-text-color" placeholder='Search for songs, artist, etc' style={{ borderWidth: "2px", borderStyle: "solid" }} />
                    <IconMySearch styled={{ width: "20px", height: "20px", className: "position-absolute searchIconHeader", left: "26px", top: "13px" }} />
                </div>
            </div>
            <div className='d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center justify-content-between me-5 gap-4'>
                    {/* <IconMyBell styled={{ width: "20px", height: "20px", className: "" }} /> */}
                    <NavLink to={navigateTranslateChecker(`/music/wishlist`)} className={``}><IconMySave styled={{ width: "20px", height: "20px", className: "" }} /></NavLink>
                    <NavLink to={navigateTranslateChecker(`/music/user/settings`)} className={``}><IconMyGear styled={{ width: "20px", height: "20px", className: "" }} /></NavLink>
                    <NavLink to={"/"} className={``}><IconFlim styled={{ width: "20px", height: "20px", className: "" }} /></NavLink>
                </div>
                <Link to={navigateTranslateChecker(`/plan`)} className='btn ms-5 theme-button-bg-color d-none d-lg-block  me-4 px-4'><MessageBox text="Purchase" classname='theme-button-text-color' /></Link>
                {userRoleLocal ?
                    <div className='position-relative' >
                        <button onClick={toggleSideBar} className='profileToggle bg-transparent'></button>
                        <Link to={navigateTranslateChecker(`/myprofile`)} className={`profileNavigation `}> </Link>
                        <LazyLoadImage key={'logo'} src={profileDetails?.Profile_details?.user_avatar} alt={'logo'} title={profileDetails?.Profile_details?.username.slice(0, 1)} imageSize="rounded-2 overflow-hidden musicLogoImageUser object-fit-cover" classNameOptional="rounded-circle musicLogoImageUser" />
                    </div>
                    : <button onClick={() => authAction()} className='bg-transparent p-0'><IconMyUser styled={{ width: "20px", height: "20px", className: "theme-text-color" }} /></button>}
            </div>
        </div>
    )
}
const mapStateToProps = state => ({
    languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData,
    profileDetails: state?.get_profileDetails_Reducer?.profileDetails,
})


export default connect(mapStateToProps)(MusicHeader)