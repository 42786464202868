// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rtl .musicSideBar {
    right: 0;
    left: auto;
}

.rtl .rightMusicContainer {
    margin-right: auto;
    margin-left: unset;
}

.rtl .playlistHeading::before {
    right: 11px;
    left: auto;
}

.rtl .trackPlaylist {
    padding-right: 10px;
    padding-left: unset;
}

.rtl .purchaseClose {
    left: 17px;
    right: auto;
}

@media screen and (max-width: 991px) {
    .rtl .musicSideBar {
        right: -60% !important;
        left: auto !important;
        transition: right 0.6s linear !important;
    }

    .rtl .musicSideBar.activeSideBar {
        right: 0% !important;
        left: auto !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/Styles/rtl-music.css"],"names":[],"mappings":"AAAA;IACI,QAAQ;IACR,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,UAAU;AACd;;AAEA;IACI,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,WAAW;AACf;;AAEA;IACI;QACI,sBAAsB;QACtB,qBAAqB;QACrB,wCAAwC;IAC5C;;IAEA;QACI,oBAAoB;QACpB,qBAAqB;IACzB;AACJ","sourcesContent":[".rtl .musicSideBar {\n    right: 0;\n    left: auto;\n}\n\n.rtl .rightMusicContainer {\n    margin-right: auto;\n    margin-left: unset;\n}\n\n.rtl .playlistHeading::before {\n    right: 11px;\n    left: auto;\n}\n\n.rtl .trackPlaylist {\n    padding-right: 10px;\n    padding-left: unset;\n}\n\n.rtl .purchaseClose {\n    left: 17px;\n    right: auto;\n}\n\n@media screen and (max-width: 991px) {\n    .rtl .musicSideBar {\n        right: -60% !important;\n        left: auto !important;\n        transition: right 0.6s linear !important;\n    }\n\n    .rtl .musicSideBar.activeSideBar {\n        right: 0% !important;\n        left: auto !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
