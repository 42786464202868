import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { getItem, getItemToken } from '../../Utils/localStorageUtils'
import { Link } from 'react-router-dom'
import PaginationViewAll from '../../components/ViewAll/Source/PaginationViewAll'
import { connect } from 'react-redux'
import { useMyContext } from '../../components/Layout/CommonProvider'
import MessageBox from '../../components/MessageBox'
import CardWithHover from '../HomePage/HomeSwiperComponents/Theme/CardWithHover'
import CardWithoutHover from '../HomePage/HomeSwiperComponents/Theme/CardWithoutHover'
import { IoSearch } from 'react-icons/io5'
import { useTranslation } from 'react-i18next'
import { URLGenerater } from '../CommonMethods/CommonMethods'
import { IconMyClose } from '../../assets/icons/MyIcons'
import { allHomePageAction } from '../../Redux/Actions/apiActions'
import { useDispatch } from 'react-redux'

const SearchPage = ({ settings, themeMode, homepage }) => {
    const [searchData, setSearchData] = useState(null)
    const [currentPage, setCurrentPage] = useState(1);
    const [prevView, setPrevView] = useState(null);
    const [view, setView] = useState(0);
    const [render, setRender] = useState('suggestion')
    const { navigateTranslateChecker, searchValue, handleSearch, handleSearchClear } = useMyContext()
    const { t } = useTranslation()
    const dispatch = useDispatch()


    const sourceTypeMap = {
        "videos": "videos",
        "episodes": "series",
        "series": "shows",
        "livestreams": "live",
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    const fetchData = async () => {
        setRender("loading")
        const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/Search`, {
            Login_user_type: parseInt(getItem('role'), 10) || 9,
            Login_device_type: "web",
            Login_user_id: parseInt(getItem('user_id'), 10) || null,
            search_value: searchValue?.trim()
        }, { headers: getItemToken('access_token') || null })

        if (response?.data?.status) {
            setRender("result")
            setSearchData(response?.data?.data)
        }
    }

    useEffect(() => {
        if (searchValue?.length > 0 && searchValue) {
            fetchData();
        } else {
            setRender("suggestion");
            setSearchData(null);
        }
    }, [searchValue]);

    useEffect(() => {
        const interval = setInterval(() => {
            setPrevView(view);
            const lengthOfData = homepage && homepage?.filter((item) => item?.source == "latest_videos")?.[0]?.data?.length
            setView((prevView) => (prevView + 1) % lengthOfData);
        }, 2000);

        return () => clearInterval(interval);
    }, [view, searchData, homepage]);

    useEffect(() => {
        if (settings && Object.keys(settings).length) {
            dispatch(allHomePageAction());
        }
    }, [])

    return (

        <div className="px-md-5 px-2 paddingTop ">
            <div className="placeholderWrapper col-md-10 col-lg-8 col-xl-6 col-12 mx-auto my-md-5 mt-3 mb-2">
                <IoSearch className="searchPageIcon" />
                <input type="text" className="scrollingPlaceholder border-0 rounded-5 focus-out-none  theme-bg-color-secondary theme-text-color outline-0 pe-3 ps-5" placeholder='Search ' name="value" onChange={(e) => handleSearch(e)} value={searchValue} />
                {searchValue?.length > 0 && <div className='position-absolute closeBar cursor-pointer' onClick={handleSearchClear}>
                    <IconMyClose styled={{ className: "theme-text-color", width: "20px", height: "20px" }} />
                </div>}
                {!searchValue?.length > 0  && (
                    <div className="placeholderTextContainer">
                        {homepage?.filter((item) => item?.source == "latest_videos")[0]?.data?.map((text, index) => (
                            <MessageBox key={index} classname={`placeholderText ${index === view ? 'active' : ''} ${index === prevView ? 'prev' : ''}`} text={`"${t(`${text?.title}`)}"`} > </MessageBox>
                        ))}
                    </div>
                )}
            </div>

            {render === "suggestion" && (
                <>
                    <MessageBox classname="small-md-heading text-center pb-5 pt-3 d-block" text="You can search for Movies, TV Shows, Live Streams, Episodes" />
                    <div className="d-flex flex-wrap align-items-center col-md-10 col-12 mx-auto">
                        {homepage?.filter((item) => item?.source).map((item, index) => (
                            <div className="d-block mb-2 p-1" key={index}>
                                <Link className="border border-2 hoverEffectSecondary d-block px-md-3 p-2 py-md-2 rounded-5 theme-border-color w-100" to={navigateTranslateChecker(`/${URLGenerater(item.source == "Videos_based_on_Categories" ? "video-categories" : item.source == "Audios_Genre" ? "audios-categories" : item.source == "AllLanguage" ? "alllanguage" : item.source == "Series_based_on_Genres" ? "series-categories" : item.source == "videocategories" ? "video-categories" : item.source == "Audio_based_on_Genre" ? "audios-categories" : item?.source)}`)}>
                                    <MessageBox text={item.header_name} classname="hoverEffectText" />
                                </Link>
                            </div>
                        ))}
                    </div>
                </>
            )}

            {render === "loading" && (
                <div className="gridContainer pt-5">
                    {Array.from({ length: 14 }).map((_, index) => (
                        <div className={`viewAllCardTwo position-relative theme-bg-color-secondary rounded-3 mb-4`} key={index}>
                            <div className="square w-100 h-100 rounded-3"></div>
                        </div>
                    ))}
                </div>
            )}

            {render === "result" && searchData?.length > 0 && (
                <>
                    <div className="gridContainer pt-5">
                        {searchData.slice((currentPage - 1) * settings?.videos_per_page, currentPage * settings?.videos_per_page)
                            .map((data, index) => (
                                <div className={`viewAllCardTwo position-relative theme-bg-color-secondary rounded-3 mb-4`} key={index}>
                                    {themeMode?.hoverCard ? (
                                        <CardWithHover hoverClass="hoverCardContainer" value={data} segMent={sourceTypeMap[data?.source] !== "series" ? sourceTypeMap[data?.source] : `series/${data?.series_slug}`} />
                                    ) : (
                                        <CardWithoutHover value={data} segMent={sourceTypeMap[data?.source] !== "series" ? sourceTypeMap[data?.source] : `series/${data?.series_slug}`} />
                                    )}
                                </div>
                            ))}
                    </div>
                    <PaginationViewAll data={searchData} handlePage={handlePageChange} currentPage={currentPage} visibleLength={settings?.videos_per_page} />
                </>
            )}

            {render === "result" && searchData?.length === 0 && (
                <MessageBox text="No Data found !!!" classname="small-md-heading text-center py-5 d-block" />
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    settings: state.get_setting_Reducer.settings,
    themeMode: state.get_headerIndex_Reducer.themeMode,
    languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData,
    homepage: state.get_allHomePage_Reducer.homepage,
});
export default connect(mapStateToProps)(SearchPage)