import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import "./Content_AddPages.css";
import JoditEditor from "jodit-react";
import { toast } from "react-toastify";
 
import loadings from "../../../assets/gif/loading.gif";
import ResponseLoader from "../../../components/Loader/ResponseLoader";

import Creatable from "react-select/creatable";
import { color } from "@mui/system";

function Content_AddPages() {
  const [body, setBody] = useState("");
  const [active, setActive] = useState("");
  const [footer_active, setFooter_active] = useState("");
  const [editUser, setEditUser] = useState({
    title: "",
  });

  const navigate = useNavigate();

  // let access_token, access_token_cpp;

  // if (localStorage.getItem("username")) {
  //   access_token = localStorage.getItem("access_token");
  // } else {
  //   access_token_cpp = localStorage.getItem("access_token_cpp");
  // }

  // const headers = {
  //   "Content-Type": "application/json",
  //   // Authorization: "Bearer " + access_token_cpp,
  //   Accept: "application/json",
  //   "Access-Control-Allow-Origin": "*",
  // };

  // if (access_token) {
  //   headers.Authorization = "Bearer " + access_token;
  // } else if (access_token_cpp) {
  //   headers.Authorization = "Bearer " + access_token_cpp;
  // }

  const access_token_cpp = localStorage.getItem("access_token_cpp");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token_cpp,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const getFirstNElements = (arr, n) => {
    return arr.slice(0, n);
  };

  // console.log('artist_id',artist_id)
  const [data, setData] = useState([]);

  const [autoFillValuetitle, setAutoFillValuetitle] = useState("");

  const handleInputKeyDowntitle = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      generateSlug(editUser?.title);
    }
  };
  const handleBlurtitle = () => {
    generateSlug(editUser?.title);
  };

  const handleAutoFillChangetitle = (event) => {
    setAutoFillValuetitle(event.target.value);
  };

  const generateSlug = (title) => {
    title = title.trim();
    const slug = title.toLowerCase().replace(/\s+/g, "-");
    setAutoFillValuetitle(slug);
  };

  const contentFieldChanaged = (data) => {
    setBody(data);
  };
  const fileInputRef1 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);

  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = (setSelectedFile1) => {
    setSelectedFile1(null);
    if (fileInputRef1.current) {
      fileInputRef1.current.value = null;
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessagetitle("");
    setIsValidationHiddentitle(true);

    setEditUser({ ...editUser, title: e.target.value });
    setEditUser({ ...editUser, [e.target.name]: e.target.value });

    if (e.target.checked === true) {
      setActive({ ...active, [e.target.name]: 1 });
      setFooter_active({ ...active, [e.target.name]: 1 });
    } else {
      setActive({ ...active, [e.target.name]: 0 });
      setFooter_active({ ...active, [e.target.name]: 0 });
    }
  };

  const [validationMessagetitle, setValidationMessagetitle] = useState("");
  const [isValidationHiddentitle, setIsValidationHiddentitle] = useState(true);

  const titleInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  async function savePage() {
    let focusInputRef = null;

    if (editUser?.title === "") {
      setValidationMessagetitle("Title cannot be empty*.");
      setIsValidationHiddentitle(false);
      focusInputRef = titleInputRef;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    const editInputvalue = active.active;
    var data = JSON.stringify(editInputvalue);
    var aactivedata = data;
    const editInputvaluefooter_active = active.footer_active;
    var data = JSON.stringify(editInputvaluefooter_active);
    var aactivedatafooter_active = data;

    const formData = new FormData();

    formData.append("title", editUser?.title);
    formData.append("slug", autoFillValuetitle);
    formData.append("body", body);
    formData.append("active", aactivedata);
    formData.append("footer_active", aactivedatafooter_active);

    let firstApiStatus = false;
    let secondApiStatus = false;
    let resultapi = null;
    let resultapifalse = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/page/store`,
        formData,
        { headers: headers }
      );

      if (response?.data?.status === true) {
        firstApiStatus = true;

        resultapi = response?.data;

        var page_id = response?.data?.Page_id;

        const imageFormData = new FormData();
        imageFormData.append("page_id", page_id);

        if (selectedFile1) {
          imageFormData.append("image", selectedFile1);
        }

        const imageResponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/admin/Image-upload-pages`,
          imageFormData,
          { headers: headers }
        );

        if (imageResponse?.data?.status === true) {
          secondApiStatus = true;
        }
      } else if (response?.data?.status === false) {
        // Handle the case when the first API response status is false
        resultapifalse = response?.data;
      }
    } catch (error) { }

    if (firstApiStatus && secondApiStatus) {
      setValidationMessagetitle("");
      // props.setApiresponsealert(resultapi);
      // props.setShowalert(true);
      toast.success(resultapi?.message);
      setTimeout(() => {
        navigate("/pages");
      }, 3000);
    } else if (resultapifalse) {
      // props.setApiresponsealert(resultapifalse);
      // props.setShowalert(true);
      toast.error(resultapifalse?.message);
      setProcessing(false);
      setShowOverlay(false);
      setIsValidationHiddentitle(false);
    }
  }

  return (
    <>
      <div className=" m-0 p-0">
        <section className="container-fluid  theme-bg-color-secondary ">
          <div className="col-lg-12 row py-3">
            <div className="col-6 col-sm-6 col-lg-6">
              <h3 className=" theme-text-color">Add Page</h3>
            </div>
            <div className="col-6 col-sm-6 col-lg-6">
              <div className="text-end">
                <button onClick={savePage} className="btn btn-primary">
                  Save Page
                </button>
              </div>
            </div>
          </div>
          <div>
            {showOverlay && (<ResponseLoader />)}
          </div>

          <div className="row col-lg-12 ">
            <div className=" col-sm-6 col-md-6 col-lg-6 ">
              <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                <h5 className=" theme-text-color">Create Page</h5>
                <hr className=" theme-text-color"></hr>
                <div className=" text-start">
                  <label className="m-0 theme-text-color">
                    Title<span className="mandatory">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      id="title"
                      name="title"
                      className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                      onChange={handleInput}
                      value={editUser?.title}
                      onBlur={handleBlurtitle}
                      onKeyDown={handleInputKeyDowntitle}
                      placeholder="Title"
                      ref={titleInputRef}
                    />
                  </div>
                  <span className="errorred">
                    {!isValidationHiddentitle && (
                      <p>{validationMessagetitle}</p>
                    )}
                  </span>
                </div>
                <div className="mt-2 text-start">
                  <label className="m-0 theme-text-color">SEO URL Slug</label>

                  <div className="mt-2">
                    <input
                      type="text"
                      id="slug"
                      name="slug"
                      className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                      value={autoFillValuetitle}
                      onChange={handleAutoFillChangetitle}
                      placeholder="slug"
                    />
                  </div>
                </div>

                <div className="row mt-2 text-start">
                  <div className="col-12">
                    <label className="m-0 theme-text-color">Page Content</label>

                    <div className="mt-2">
                      <JoditEditor
                        className="rs-input  theme-bg-color-secondary  theme-text-color border-0"
                        value={body}
                        onChange={(newContent) =>
                          contentFieldChanaged(newContent)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="text-start mb-3">
                  <button onClick={savePage} className="btn btn-primary">
                    Save Users
                  </button>
                </div> */}
            </div>

            {/* Second Part of Section  */}

            <div className=" col-sm-6 col-md-6 col-lg-6">
              <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                <h5 className=" theme-text-color">Thumbnails</h5>
                <hr className=" theme-text-color"></hr>

                <div className=" text-start mt-2">
                  <label className="m-0">Banner</label>

                  <div className="mt-2">
                    <div className="col-lg-12 row">
                      <div className=" col-sm-6 col-lg-6">
                        <div
                          className="imagedrop"
                          onClick={() => fileInputRef1.current.click()}
                        >
                          <input
                            type="file"
                            ref={fileInputRef1}
                            onChange={(event) =>
                              handleFileChange(
                                event,
                                fileInputRef1,
                                setSelectedFile1
                              )
                            }
                            accept="image/*"
                            style={{ display: "none" }}
                          />
                          <label>
                            <CameraRetroIcon />
                          </label>
                        </div>
                      </div>
                      <div className=" col-sm-6 col-lg-6">
                        <div>
                          {selectedFile1 && (
                            <div className="imagedropcopy text-end">
                              <div className="col-lg-12 row p-0 m-0">
                                <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                  <img
                                    src={URL.createObjectURL(selectedFile1)}
                                    alt="Uploaded"
                                    className="thumbnails-upload-images"
                                  />
                                </div>
                                <div
                                  className="col-sm-2 col-lg-2 p-0 m-0"
                                  style={{ alignSelf: "center" }}
                                >
                                  <button
                                    onClick={() =>
                                      handleDelete(setSelectedFile1)
                                    }
                                    type="button"
                                    className="btn-close theme-text-color"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                <h5 className=" theme-text-color">Status Settings</h5>
                <hr className=" theme-text-color"></hr>

                <div className="panel panel-primary" data-collapsed="0">
                  <div className="panel-body d-flex justify-content-between">
                    <label className=" theme-text-color">Is this page Active </label>
                    <label className="switch">
                      <input
                        name="active"
                        onChange={handleInput}
                        defaultChecked={active?.active === 1 ? true : false}
                        checked={active?.active === 1 ? true : false}
                        type="checkbox"
                      />
                      <span
                        className="slider round"
                        name="active"
                        onChange={handleInput}
                        value={active?.active === 1 ? "1" : "0"}
                      ></span>
                    </label>
                  </div>
                </div>
                <div className="panel panel-primary mt-2" data-collapsed="0">
                  <div className="panel-body d-flex justify-content-between">
                    <label className=" theme-text-color">Is this page Footer Active </label>
                    <label className="switch">
                      <input
                        name="footer_active"
                        onChange={handleInput}
                        defaultChecked={
                          footer_active?.footer_active === 1 ? true : false
                        }
                        checked={footer_active?.active === 1 ? true : false}
                        type="checkbox"
                      />
                      <span
                        className="slider round"
                        name="footer_active"
                        onChange={handleInput}
                        value={footer_active?.footer_active === 1 ? "1" : "0"}
                      ></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Content_AddPages;
