import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify"
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import ResponseLoader from "../../../components/Loader/ResponseLoader";
import AdminSaveHeader from "../../../components/adminCommonComponents/AdminSaveHeader";
import FormContainer from "../../CommonComponents/FormContainer";
import LeftWrapper from "../../CommonComponents/LeftWrapper";
import RightWrapper from "../../CommonComponents/RightWrapper";
import Wrapper from "../../CommonComponents/Wrapper";
import { IoClose } from "react-icons/io5";
import MessageHeading from "../../CommonComponents/MessageHeading";
import MessageLabel from "../../CommonComponents/MessageLabel";
import MessageBox from "../../../components/MessageBox";

const EditManageCategories = (props) => {
  const access_token = localStorage.getItem("access_token_channel");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const { id } = useParams();
  const [editUser, setEditUser] = useState({
    name: "",
    slug: "",
    in_menu: false,
    parent_id: "",
    image: "",
    image_url: "",
  });

  const [editUserError, setEditUserError] = useState({});
  const [coverImageFile, setCoverImageFile] = useState(null)
  const [category, setCategory] = useState([]);
  const [isChecked, setIsChecked] = useState(false)
  const [showOverlay, setShowOverlay] = useState(false);
  const [withoutRefresh, setWithoutRefresh] = useState(false)

  const coverImageRef = useRef(null);
  const slugInputRef = useRef(null)
  const titleInputRef = useRef(null);
  let defaultvalue = "";

  // Handle Auto slug generate function *start*
  const handleBlurtitle = () => {
    generateSlug(editUser?.name);
  };

  const handleBlurTitleEdit = () => {
    generateSlug(editUser?.slug);
  }

  const generateSlug = (title) => {
    title = title?.trim();
    const slug = title?.toLowerCase().replace(/\s+/g, "-");
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      slug: slug,
    }));
  };

  const handleAutoFillChangetitle = (event) => {
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      slug: event.target.value,
    }));
    setEditUserError((prevEditUser) => ({
      ...prevEditUser,
      [event.target.name]: '',
    }));
  };

  const handleInputKeyDowntitle = (event) => {
    if (event.key == "Enter" || event.key == "Tab") {
      generateSlug(editUser?.name);
    }
  };

  //Handle Image upload
  const handleCoverImageChange = (event) => {
    const selectedFile = event.target.files;
    if (selectedFile.length > 0) {
      const newFile = selectedFile[0];
      const reader = new FileReader();
      reader.onload = () => {
        setCoverImageFile(newFile);
      };
      reader.readAsDataURL(newFile);
    } else {
    }
  };

  //get data category
  const getData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_Baseurl}/Channel/Backend/live-stream-category/create`, { headers })
      setCategory(response?.data?.live_categories);
    }
    catch (err) {
      console.error(err)
    }
  };

  //get editdata 
  const getEditData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_Baseurl}/Channel/Backend/live-stream-category/edit/${id}`, { headers })
      setEditUser(response?.data?.LiveCategory[0]);
      setIsChecked(response?.data?.LiveCategory[0]?.in_menu == "0" ? false : true);
    }
    catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    getData();
    getEditData();
  }, [withoutRefresh]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));
    setEditUserError((prevEditUser) => ({
      ...prevEditUser,
      [name]: '',
    }));
    setEditUserError((prevEditUser) => ({
      ...prevEditUser,
      slug: '',
    }));
  };

  const handleChecked = (event) => {
    setEditUser({
      ...editUser,
      in_menu: event.value,
    });
    setIsChecked(!isChecked);
  };

  const handleCategoryUpdate = async (e) => {
    if (formValidation()) {
      console.log("data true go to api")
      editCategoryData()
    }
    else {
      console.log('Form validation failed ');
    }
  }

  const formValidation = () => {
    let formIsValid = true;
    if (!editUser?.name) {
      setEditUserError((prevEditUser) => ({ ...prevEditUser, name: 'Title cannot be empty.' }));
      titleInputRef.current.focus();
      formIsValid = false;
    }
    else if (!editUser?.slug) {
      setEditUserError((prevEditUser) => ({ ...prevEditUser, slug: 'Slug cannot be empty.' }));
      slugInputRef.current.focus();
      formIsValid = false;
    }
    return formIsValid;
  }

  const editCategoryData = async () => {
    setShowOverlay(true);

    const editInputvalue = {
      name: editUser.name,
      id: id,
      slug: editUser.slug,
      parent_id: editUser.parent_id,
      image: editUser.image,
      in_menu: isChecked ? 1 : 0,
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Channel/Backend/live-stream-category/update/${id}`, JSON.stringify(editInputvalue), { headers });
      const dataResponse = response.data;
      if (dataResponse.status && coverImageFile) {
        const imageFormData = new FormData();
        imageFormData.append('id', id);
        imageFormData.append('image', coverImageFile);
        const [imageResponse] = await Promise.all([
          axios.post(`${process.env.REACT_APP_Baseurl}/Channel/Backend/live-stream-category/image`, imageFormData, { headers }),
          setShowOverlay(false)
        ]);
        if (imageResponse && imageResponse.data) {
          setCoverImageFile(null)
          toast.success(imageResponse.data.message);
        } else {
          toast.error("Error uploading image");
        }
        setWithoutRefresh(!withoutRefresh);
      } else {
        toast.success(dataResponse.message);
        setShowOverlay(false);
        setWithoutRefresh(!withoutRefresh);
      }
    } catch (err) {
      const errorMessage = err.response?.data?.message || 'An error occurred';
      toast.error(errorMessage);
      setShowOverlay(false);
    }
  };

  return (
    <div>
      {showOverlay && (<ResponseLoader />)}
      <AdminSaveHeader heading="Edit live stream category" saveText="Update category" saveMethod={handleCategoryUpdate} />
      <FormContainer>
        <LeftWrapper>
          <Wrapper>
            <MessageHeading text="Create Category" />
            <MessageLabel text="Name" className="mandatorySimple" />
            <input
              type="text"
              className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder theme-text-color border-0 mb-3"
              id="name"
              name="name"
              placeholder="Enter Name"
              onChange={handleInput}
              value={editUser?.name}
              onBlur={handleBlurtitle}
              onKeyDown={handleInputKeyDowntitle}
              ref={titleInputRef}
            />
            {editUserError?.name && (<MessageBox text={`${editUserError?.name}`} classname='errorred mb-2 d-block' />)}
            <MessageLabel text="Slug" />
            <input
              type="text"
              className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder theme-text-color border-0 mb-3"
              id="slug"
              name="slug"
              placeholder="Enter Slug"
              value={editUser?.slug}
              ref={slugInputRef}
              onBlur={handleBlurTitleEdit}
              onChange={handleAutoFillChangetitle}
            />
            {editUserError?.slug && (<MessageBox text={`${editUserError?.slug}`} classname='errorred mb-2 d-block' />)}
            <MessageLabel text="Category" />
            <select
              className="form-select theme-bg-color-secondary custom-placeholder theme-text-color border-0"
              name="parent_id"
              id="parent_id"
              onChange={handleInput}
            >
              {category?.map((item, index) => {
                if (editUser?.parent_id) {
                  defaultvalue = editUser?.parent_id == item?.id && item?.name;
                } else {
                  console.log("editUser.parent_id is not an array.");
                }
                return (
                  <>
                    <option
                      className="theme-bg-color theme-text-color"
                      key={"option" + index + 1}
                      value={item.id}
                      selected={defaultvalue}
                    >
                      {item?.name}
                    </option>
                  </>
                );
              })}
            </select>
          </Wrapper>
        </LeftWrapper>
        <RightWrapper>
          <Wrapper>
            <MessageHeading text="Thumbnails" />
            <MessageLabel text="Image" />
            <img
              src={editUser?.image_url}
              alt="Image"
              className="thumbnails-images d-block  rounded-2"
              style={{ width: '120px', height: "120px", objectFit: "cover" }}
            />
            <div className="mx-0 my-2 row">
              <div className="col-6 ps-0">
                <div className="imagedrop position-relative theme-border-color" >
                  <input
                    type="file"
                    ref={coverImageRef}
                    onChange={(event) => handleCoverImageChange(event)}
                    accept="image/*"
                    className="opacity-0"
                    key={coverImageFile ? coverImageFile : "inputKey"} />
                  <button type="button" className="bg-transparent position-absolute">
                    <CameraRetroIcon />
                  </button>
                </div>
              </div>
              <div className="col-6 pe-0">
                {coverImageFile && (
                  <div className="imagedropcopy  theme-border-color text-end">
                    <div className="d-flex align-items-center">
                      <img
                        src={URL.createObjectURL(coverImageFile)}
                        alt="Uploaded"
                        className="thumbnails-upload-images"
                      />
                      <button onClick={() => setCoverImageFile(null)} type="button" className="bg-transparent theme-text-color"><IoClose className="custom-close-btn" /></button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Wrapper>
          <Wrapper>
            <MessageHeading text="Status Settings" />
            <div className="mx-0 mb-2 row">
              <div className="col-9 ps-0">
                <MessageLabel text="Display in home page" />
              </div>
              <div className="col-3 pe-0 text-end">
                <label className="switch">
                  <input
                    className="rs-input"
                    type="checkbox"
                    name="in_menu"
                    value={editUser?.in_menu}
                    checked={isChecked}
                    onChange={handleChecked}
                  />
                  <span
                    className="slider round"
                    name="in_menu"
                    onChange={handleChecked}
                  ></span>
                </label>
              </div>
            </div>
          </Wrapper>
        </RightWrapper>
      </FormContainer >
    </div>
  );
};

export default EditManageCategories;