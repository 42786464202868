import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import "./Contenteditaudioalbum.css";
import { toast } from "react-toastify";
 
import loadings from "../../../assets/gif/loading.gif";
import ResponseLoader from "../../../components/Loader/ResponseLoader";
import { useTranslation } from "react-i18next";

function Contenteditaudioalbum() {
  const { id } = useParams();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [audioalbums, setAudioalbums] = useState([]);
  const [editUser, setEditUser] = useState({
    albumname: "",
    parent_id: "",
    slug: "",
    albums: "",
    uploaded_by: "",
    image_url: "",
    Content_Partner_id: "",
  });
  const [editname, setEditname] = useState({
    albumname: "",
  });

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  // let access_token, access_token_cpp;

  // if (localStorage.getItem("username")) {
  //   access_token = localStorage.getItem("access_token");
  // } else {
  //   access_token_cpp = localStorage.getItem("access_token_cpp");
  // }

  // const headers = {
  //   "Content-Type": "application/json",
  //   // Authorization: "Bearer " + access_token_cpp,
  //   Accept: "application/json",
  //   "Access-Control-Allow-Origin": "*",
  // };

  // if (access_token) {
  //   headers.Authorization = "Bearer " + access_token;
  // } else if (access_token_cpp) {
  //   headers.Authorization = "Bearer " + access_token_cpp;
  // }

  const access_token_cpp = localStorage.getItem("access_token_cpp");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token_cpp,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const fileInputRef1 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);

  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = (setSelectedFile1) => {
    setSelectedFile1(null);
    if (fileInputRef1.current) {
      fileInputRef1.current.value = null;
    }
  };

  useEffect(() => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/Audio-Album/Edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.audio_album[0];

      setEditUser(res);
      setEditname(res);
      setLoading(false);
      // console.log(res)
    };
    getUser();

    axios
      .get(`${process.env.REACT_APP_Baseurl}/CPP/Backend/Audio-Album/create`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.AudioGenre;
        setAudioalbums(result);
      })
      .catch((error) => console.log(error));
  }, [id]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessageslug("");
    setIsValidationHiddenslug(true);
    setValidationMessagetitle("");
    setIsValidationHiddentitle(true);

    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };

  const [validationMessagetitle, setValidationMessagetitle] = useState("");
  const [isValidationHiddentitle, setIsValidationHiddentitle] = useState(true);

  const [validationMessageslug, setValidationMessageslug] = useState("");
  const [isValidationHiddenslug, setIsValidationHiddenslug] = useState(true);

  const titleInputRef = useRef(null);

  const handleUpdate = async (e) => {
    let focusInputRef = null;

    if (editUser?.name == "") {
      setValidationMessagetitle("Name cannot be empty*.");
      setIsValidationHiddentitle(false);
      focusInputRef = titleInputRef;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    e.preventDefault();

    const editInputvalue = {
      Content_Partner_id: editUser?.Content_Partner_id,
      uploaded_by: editUser?.uploaded_by,
      albumname: editUser?.albumname,
      parent_id: editUser?.parent_id,
      albums: editUser?.albums,
      slug: editUser?.slug,
    };

    let firstApiStatus = false;
    let secondApiStatus = false;
    let resultapi = null;
    let resjson = null;
    let resultapifalse = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/Audio-Album/update/${id}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res.json();

      if (resjson.status == true) {
        firstApiStatus = true;

        resultapi = resjson;

        var album_id = resjson.AudioAlbum_id;

        const formData = new FormData();
        formData.append("audio_album_id", album_id);
        if (selectedFile1) {
          formData.append("album_image", selectedFile1);
        }

        await axios.post(
          `${process.env.REACT_APP_Baseurl}/CPP/Backend/Audio-Album/Image-upload`,
          formData,
          { headers: headers }
        );
        secondApiStatus = true;
      } else if (resjson.status == false) {
        // Handle the case when the first API response status is false
        resultapifalse = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus && secondApiStatus) {
      setValidationMessagetitle("");
      setValidationMessageslug("");
      // props.setApiresponsealert(resultapi);
      // props.setShowalert(true);
      toast.success(t(resultapi?.message));
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else if (firstApiStatus && secondApiStatus) {
      // props.setApiresponsealert(resultapifalse);
      // props.setShowalert(true);
      toast.error(t(resultapifalse?.message));
      setProcessing(false);
      setShowOverlay(false);
      setIsValidationHiddentitle(false);
      setValidationMessageslug(false);
    }
  };

  return (
    <>
      {loading ? (
        <>
          <div className="overlay-container">
            <div className="overlay-icon">
              <img src={loadings} width={100} alt="Animated GIF" />
              Loading...
            </div>
          </div>
          <div className=" m-0 p-0">
            <section className="container-fluid  theme-bg-color-secondary ">
              <div className="col-lg-12 row py-3">
                <div className="col-6 col-sm-6 col-lg-6">
                  <h3 className=" theme-text-color">Edit Album</h3>
                </div>
                <div className="col-6 col-sm-6 col-lg-6">
                  <div className="text-end">
                    <button onClick={handleUpdate} className="btn btn-primary">
                      Update Audio Album
                    </button>
                  </div>
                </div>
              </div>

              <div>
              {showOverlay && (<ResponseLoader />)}
              </div>

              <div className="row col-lg-12 ">
                <div className="col-6 col-sm-6 col-md-6 col-lg-6 ">
                  <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                    <h5 className=" theme-text-color">{editname?.albumname}</h5>
                    <hr className=" theme-text-color"></hr>
                    <div className=" text-start">
                      <label className="m-0 theme-text-color">
                        Name<span className="mandatory">*</span>
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          id="albumname"
                          name="albumname"
                          className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                          onChange={handleInput}
                          value={editUser?.albumname}
                          placeholder="Enter Name"
                          ref={titleInputRef}
                        />
                      </div>
                      <span className="errorred">
                        {!isValidationHiddentitle && (
                          <p>{validationMessagetitle}</p>
                        )}
                      </span>
                    </div>
                    <div className="mt-2 text-start">
                      <label className="m-0 theme-text-color">Slug</label>

                      <div className="mt-2">
                        <input
                          type="text"
                          className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                          id="slug"
                          name="slug"
                          placeholder="Enter Slug"
                          onChange={handleInput}
                          value={editUser?.slug}
                        />
                      </div>
                    </div>

                    <div className="row mt-2 text-start">
                      <div className="col-12">
                        <label className="m-0 theme-text-color">Category</label>

                        <div className="mt-2">
                          <select
                            className="form-select  theme-bg-color-secondary  border-0 theme-text-color"
                            name="parent_id"
                            id="parent_id"
                            onChange={handleInput}
                            value={editUser?.parent_id}
                          >
                            <option value="0">Select </option>
                            {audioalbums?.map((item) => (
                              <option value={item?.id}>{item?.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="text-start mb-3">
                <button
                  onClick={submitcategory}
                  className="btn btn-primary"
                >
                 Save Audio Category
                </button>
              </div> */}
                </div>

                {/* Second Part of Section  */}

                <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                  <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                    <h5 className=" theme-text-color">Thumbnails</h5>
                    <hr className=" theme-text-color"></hr>

                    <div className=" text-start mt-2">
                      <label className="m-0 theme-text-color">
                        Cover Image
                      </label>

                      {/* <div>
                  <img src={editUser?.image_url} width="100" height="100" />
                </div> */}

                      <div>
                        {loading ? (
                          <img src={loadings} className="thumbnails-images" />
                        ) : (
                          <img
                            src={editUser?.image_url}
                            // alt="Image"
                            className="thumbnails-images"
                          />
                        )}
                      </div>

                      <div className="mt-2">
                        <div className="col-lg-12 row">
                          <div className=" col-sm-6 col-lg-6">
                            <div
                              className="imagedrop"
                              onClick={() => fileInputRef1.current.click()}
                            >
                              <input
                                type="file"
                                ref={fileInputRef1}
                                onChange={(event) =>
                                  handleFileChange(
                                    event,
                                    fileInputRef1,
                                    setSelectedFile1
                                  )
                                }
                                accept="image/*"
                                style={{ display: "none" }}
                              />
                              <label className=" theme-text-color">
                                <CameraRetroIcon className=" theme-text-color" />
                              </label>
                            </div>
                          </div>
                          <div className=" col-sm-6 col-lg-6">
                            <div>
                              {selectedFile1 && (
                                <div className="imagedropcopy text-end">
                                  <div className="col-lg-12 row p-0 m-0">
                                    <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                      <img
                                        src={URL.createObjectURL(selectedFile1)}
                                        alt="Uploaded"
                                        className="thumbnails-upload-images"
                                      />
                                    </div>
                                    <div
                                      className="col-sm-2 col-lg-2 p-0 m-0"
                                      style={{ alignSelf: "center" }}
                                    >
                                      <button
                                        onClick={() =>
                                          handleDelete(setSelectedFile1)
                                        }
                                        type="button"
                                        className="btn-close theme-text-color"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </>
      ) : (
        <div className=" m-0 p-0">
          <section className="container-fluid  theme-bg-color-secondary ">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3 className=" theme-text-color">Edit Album</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={handleUpdate} className="btn btn-primary">
                    Update Audio Album
                  </button>
                </div>
              </div>
            </div>

            <div>
            {showOverlay && (<ResponseLoader />)}
            </div>

            <div className="row col-lg-12 ">
              <div className="col-6 col-sm-6 col-md-6 col-lg-6 ">
                <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                  <h5 className=" theme-text-color">{editname?.albumname}</h5>
                  <hr className=" theme-text-color"></hr>
                  <div className=" text-start">
                    <label className="m-0 theme-text-color">
                      Name<span className="mandatory">*</span>
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="albumname"
                        name="albumname"
                        className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                        onChange={handleInput}
                        value={editUser?.albumname}
                        placeholder="Enter Name"
                        ref={titleInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddentitle && (
                        <p>{validationMessagetitle}</p>
                      )}
                    </span>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0 theme-text-color">Slug</label>

                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                        id="slug"
                        name="slug"
                        placeholder="Enter Slug"
                        onChange={handleInput}
                        value={editUser?.slug}
                      />
                    </div>
                  </div>

                  <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0 theme-text-color">Category</label>

                      <div className="mt-2">
                        <select
                          className="form-select  theme-bg-color-secondary  border-0 theme-text-color"
                          name="parent_id"
                          id="parent_id"
                          onChange={handleInput}
                          value={editUser?.parent_id}
                        >
                          <option value="0">Select </option>
                          {audioalbums?.map((item) => (
                            <option value={item?.id}>{item?.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="text-start mb-3">
                <button
                  onClick={submitcategory}
                  className="btn btn-primary"
                >
                 Save Audio Category
                </button>
              </div> */}
              </div>

              {/* Second Part of Section  */}

              <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                  <h5 className=" theme-text-color">Thumbnails</h5>
                  <hr className=" theme-text-color"></hr>

                  <div className=" text-start mt-2">
                    <label className="m-0 theme-text-color">Cover Image</label>

                    {/* <div>
                  <img src={editUser?.image_url} width="100" height="100" />
                </div> */}

                    <div>
                      {loading ? (
                        <img src={loadings} className="thumbnails-images" />
                      ) : (
                        <img
                          src={editUser?.image_url}
                          // alt="Image"
                          className="thumbnails-images"
                        />
                      )}
                    </div>

                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className=" col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef1.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef1}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef1,
                                  setSelectedFile1
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label className=" theme-text-color">
                              <CameraRetroIcon className=" theme-text-color" />
                            </label>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-lg-6">
                          <div>
                            {selectedFile1 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL.createObjectURL(selectedFile1)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() =>
                                        handleDelete(setSelectedFile1)
                                      }
                                      type="button"
                                      className="btn-close theme-text-color"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
}

export default Contenteditaudioalbum;
