import React, { useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { videoDetailsAction } from "../../Redux/Actions/apiActions";
import { useNavigate } from "react-router-dom";
import VideosJs from "./VideosJs";
import { useTranslation } from "react-i18next";
import AudioVideoplayer from "./AudioVideoplayer";
import videojs from "video.js";
import PageLoader from "../Loader/PageLoader";
const VideoPlayer = ({ videoDetailsData, isLoading, error, settings, slug, getWishListData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const user_role = localStorage.getItem("role");
  const playerType = videoDetailsData?.type;
  const embedSource = videoDetailsData?.embed_code;
  const videoJsOptions = {
    controlBar: {
      audioTrackButton: true, subtitlesButton: false, remainingTimeDisplay: true,
      fullscreenToggle: false, volumePanel: { inline: false, vertical: true }, pictureInPictureToggle: true
      // volumePanel: false, 
    },
    bigPlayButton: false,
    autoplay: false,
    controls: true,
    responsive: true,
    breakpoints: [{ tiny: 300, xsmall: 400, small: 500, medium: 600, large: 700, xlarge: 800, huge: 900 }],
    poster: videoDetailsData?.TV_Thumbnail,
    textTrackSettings: false,
    fluid: true,
    muted: true,
    sources: [{
      src: videoDetailsData?.videos_url,
      type: videoDetailsData?.video_player_type
    }],
    vhs: {
      withCredentials: false,
      enableLowInitialPlaylist: true,
    },
    playbackRates: [0.5, 1, 1.25, 1.5, 1.75, 2],
    html5: {
      vhs: {
        overrideNative: true
      }
    }
  };
  useEffect(() => {
    //dispatch(videoDetailsAction(slug, settings))
    dispatch(videoDetailsAction(slug, settings, true))
  }, [settings, user_role]);

  const playerRef = React.useRef(null);
  const handlePlayerReady = (player) => {
    playerRef.current = player;
    player.on("waiting", () => {
      // videojs.log("player is waiting");
    });
    player.on("dispose", () => {
      // videojs.log("player will dispose");
    });
  };

  const audioTracks = [
    {
      id: '1',
      kind: 'alternative',
      label: 'English',
      language: 'en',
      enabled: true,
      src: 'https://api.flicknexs.com/public/uploads/audios/Audios-1723808881.mp3',
    },
    {
      id: '2',
      kind: 'alternative',
      label: 'Spanish',
      language: 'es',
      enabled: false,
      src: 'https://api.flicknexs.com/public/uploads/audios/Audios-1723808881.mp3',
    },
    {
      id: '3',
      kind: 'alternative',
      label: 'French',
      language: 'fr',
      enabled: false,
      src: 'https://api.flicknexs.com/public/uploads/audios/Audios-1713869219.mp3',
    }];
  const wishListCheck = getWishListData?.videos?.some(item => item.id === videoDetailsData.id);
  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center paddingTop">
          <PageLoader />
        </div>
      ) : playerType == "embed_url|url" ? (
        <iframe src={embedSource} allowfullscreen width="1360" height="628" title="Video"></iframe>
      ) : videoDetailsData?.videos_url && videoDetailsData?.video_player_type
        ? <>
          <VideosJs options={videoJsOptions} onReady={handlePlayerReady} videoDetailsData={videoDetailsData}
            audioTracks={audioTracks} settings={settings} wishListCheckValue={wishListCheck} />
          {/* <AudioVideoplayer options={videoJsOptions} onReady={handlePlayerReady}
            videoDetailsData={videoDetailsData} audioTracks={audioTracks} settings={settings} /> */}
        </>
        : <div className='d-flex justify-content-center align-items-center paddingTop'>
          <h3>{t("Invalid Video src and type")}</h3>
        </div>
      }
    </>
  );
};
const mapStateToProps = (state) => ({
  videoDetailsData: state.get_videoDetails_Reducer.videoDetailsData,
  isLoading: state.get_videoDetails_Reducer.isLoading,
  error: state.get_videoDetails_Reducer.error,
  settings: state.get_setting_Reducer.settings,
  getWishListData: state?.get_wishList_Reducer?.getWishListData
});
export default connect(mapStateToProps)(VideoPlayer);