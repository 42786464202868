import React, { useEffect, useState } from "react";
import axios from "axios";
// import { Line } from "react-chartjs-2";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import BootstrapTable from "react-bootstrap-table-next";
import ResponseLoader from "../../../components/Loader/ResponseLoader";
import MessageBox from "../../../components/MessageBox";
import Wrapper from "../../CommonComponents/Wrapper";
import AdminHeader from "../../../components/adminCommonComponents/AdminHeader";
import NoRecordTable from "../../../components/adminCommonComponents/NoRecordTable";

const VideoAnalytics = () => {

  const [showOverlay, setShowOverlay] = useState(false);
  const [filteredLiveData, setFilteredLiveData] = useState([]);
  const [withOutRefresh, setWithOutRefresh] = useState(false);
  const [getLiveData, setLiveData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const access_token = localStorage.getItem("access_token_channel");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const optionstest = {
    type: "line",
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
    tooltips: {
      backgroundColor: 'rgba(255, 255, 255)', // Change background color of the tooltip/card
      titleFontColor: 'rgba(255, 255, 255)', // Change font color of the title (point label)
      bodyFontColor: 'rgba(255, 255, 255)', // Change font color of the body (data value)
      borderColor: 'rgba(255, 255, 255)', // Change border color
      borderWidth: 1, // Set border width
      titleAlign: 'center', // Align title to center
    },

  };

  const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    datasets: [
      {
        label: "Player Videos",
        data: [0, 40, 0, 10, 20, -20, 30, 0, 80, 0, 60, 0, 10, -20, 100],
        borderColor: "rgb(53, 162, 235)",
        lineTension: 0.4,
      },
    ],
  };

  const columns = [
    {
      dataField: "Email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "Uploader Name",
      text: "Uploader Name",
      sort: true,
    },
    {
      dataField: "Commission Paid",
      text: "Commission Paid",
    },
    {
      dataField: "Commission Pending",
      text: "Commission Pending",
    },
    {
      dataField: "Payment Type",
      text: "Payment Type",
    },
    {
      dataField: "Invoice",
      text: "Invoice",
    },
  ];
  useEffect(() => {
    fetchData();
  }, [withOutRefresh]);
  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_Baseurl}/*`, {
        headers: headers,
      });
      const liveData = response?.data;
      const filterHelp = liveData;
      setLiveData(liveData);
      setFilteredLiveData(filterHelp);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    nextPageText: "Next",
    prePageText: "Prev",
    firstPageText: <MdKeyboardDoubleArrowLeft />,
    lastPageText: <MdKeyboardDoubleArrowRight />,
    showTotal: true,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "All", value: filteredLiveData?.length },
    ],
  });

  const handleFilterChangeLive = (data) => {
    setFilteredLiveData(data);
  };

  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
    },
    selectionHeaderRenderer: ({ mode, checked, indeterminate }) => (
      <div>
        <input
          type="checkbox"
          checked={false}
          indeterminate={indeterminate}
        // onChange={}
        />
      </div>
    ),
  };

  return (
    <>
      {showOverlay && <ResponseLoader />}
      <h4 className="admin-title mb-0  pb-3 theme-text-color text-md-start"> <MessageBox text='Channel Partners Video Analytics' /></h4>
      <Wrapper>
        <div className="row mx-0 mb-5">
          <div className="col-10 ps-0">
            <div className="row mx-0">
              <div className="col-2 ps-0">
                <div  className="dropdown">
                  <button
                     className="btn button-color button-bg-color admin-button-text text-white rounded-2  py-2 px-3  dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Filter
                  </button>
                  <ul
                    className="dropdown-menu theme-bg-color"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li  className="dropdown-item">1 Day</li>
                    <li  className="dropdown-item">1 Week </li>
                    <li  className="dropdown-item"> 1 Month </li>
                  </ul>
                </div>
              </div>
              <div className="col-5">
                <div className="d-flex align-items-center">
                  <div className="me-3"><MessageBox text="Start Date:" classname="theme-text-color admin-input-title" /></div>
                  <div className=""><input type="date" className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder theme-text-color border-0" /></div>
                </div>
              </div>
              <div className="col-5 ">
                <div className="d-flex align-items-center">
                  <div className="me-3"><MessageBox text="End Date:" classname="theme-text-color admin-input-title" /></div>
                  <div className=""><input type="date" className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder theme-text-color border-0" /></div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-2 pe-0">
            <button type="button" className="btn button-color button-bg-color admin-button-text text-white rounded-2  py-2 px-3 float-end">
              Download CSV
            </button>
          </div>
        </div>
        <h4 className="admin-title mb-0  pb-3 theme-text-color text-md-start"> <MessageBox text='Video Analytics View Graph' /></h4>
        {/* <Line options={optionstest} data={data} /> */}
      </Wrapper>
      <div className="theme-bg-color p-3 rounded-2 bootstrapTable pb-5 multi-delete-width" >
        <AdminHeader  searchMethod={handleFilterChangeLive}   reduceData={filteredLiveData} originalData={getLiveData} fileName="ANALYTICS-LIST" />
        <BootstrapTable keyField="id"  pagination={pagination} columns={columns} data={filteredLiveData} className="no-border-table theme-text-color " noDataIndication={<NoRecordTable result={filteredLiveData?.length} loader={loading} />} hover headerClasses="videoWidth" selectRow={selectRow} selectAll={selectAll} />
      </div>
    </>
  );
};

export default VideoAnalytics;
