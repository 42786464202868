import React from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

const MessageBox = ({ text, classname, siteTheme }) => {
    const { t } = useTranslation();
    return (
        <span className={`${classname ? classname : ''} `}>{siteTheme?.translate_checkout ? t(text) : text}</span>
    )
}

const mapStateToProps = state => ({
    siteTheme: state.get_siteTheme_Reducer.siteTheme,
});
export default connect(mapStateToProps)(MessageBox)
