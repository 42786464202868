import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import { FaEllipsisVertical } from "react-icons/fa6";
import ResponseLoader from "../../../components/Loader/ResponseLoader";
import AdminDeleteModal from "../../../components/adminCommonComponents/AdminDeleteModal";
import AdminNavbar from "../../../components/adminCommonComponents/AdminNavbar";
import NoRecordTable from "../../../components/adminCommonComponents/NoRecordTable";
import MessageBox from "../../../components/MessageBox";
import { CiShare1 } from "react-icons/ci";
import { useTranslation } from "react-i18next";

function ChannelCategorylivestream() {
  const { t } = useTranslation();
  const access_token = localStorage.getItem("access_token_channel");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  const [getLiveCategory, setLiveCategory] = useState([]);
  const [itemToDeleteLiveCategory, setItemToDeleteLiveCategory] = useState([]);
  const [selectAllLiveCategory, setSelectAllLiveCategory] = useState(false);
  const [modalCloseLiveCategory, setModalCloseLiveCategory] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchDatalivevideo();
  }, []);

  const fetchDatalivevideo = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_Baseurl}/Channel/Backend/live-stream-category/index`, { headers });
      setLiveCategory(response?.data?.LiveCategory_list);
      setLoading(false)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedItems = Array.from(getLiveCategory);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);
    setLiveCategory(reorderedItems);
    saveShuffleOrder(reorderedItems);
  };

  const saveShuffleOrder = async (updatedItems) => {
    setShowOverlay(true);
    if (!updatedItems || updatedItems?.length == 0) {
      console.error("Menu items are empty");
      return;
    }
    const positions = updatedItems.map((menuItem) => menuItem.id);
    const ids = updatedItems.map((_, index) => index + 1);
    const requestData = {
      id: JSON.stringify(positions),
      position: JSON.stringify(ids),
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/Channel/live-stream-category/order-live-category`,
        requestData,
        { headers: headers }
      );

      const result = response.data;
      if (result.status == true) {
        toast.success(t(result));
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err.response.data.message;
      toast.error(t(errorMessage));
      setShowOverlay(false);
    }
  };

  const handleSelectAllLiveCategory = () => {
    setSelectAllLiveCategory(!selectAllLiveCategory);
    setItemToDeleteLiveCategory(
      selectAllLiveCategory ? [] : getLiveCategory.map((item) => item.id)
    );
  };

  const handleMultiSelectLiveCategory = (itemId) => {
    if (itemToDeleteLiveCategory.includes(itemId)) {
      setItemToDeleteLiveCategory(
        itemToDeleteLiveCategory.filter((id) => id !== itemId)
      );
    } else {
      setItemToDeleteLiveCategory([...itemToDeleteLiveCategory, itemId]);
    }
  };

  const singleDeleteLiveCategory = (id) => {
    setItemToDeleteLiveCategory([id]);
    setModalCloseLiveCategory(true);
    setSelectAllLiveCategory(false);
  };

  const closeLiveCategoryModal = () => {
    setModalCloseLiveCategory(!modalCloseLiveCategory);
  };

  const openModalMultiDeleteLiveCategory = () => {
    setModalCloseLiveCategory(true);
  };

  const handleDeleteLiveCategoryData = () => {
    closeLiveCategoryModal();
    liveCategoryDeleteOperation();
  };

  const liveCategoryDeleteOperation = async () => {
    setShowOverlay(true);
    const formData = new FormData();
    formData.append("id", "[" + itemToDeleteLiveCategory + "]");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/live-stream-category/multi-delete
        `,
        formData,
        {
          headers: headers,
        }
      );
      const result = response.data;
      if (result.status == true) {
        toast.success(t(result));
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err.response.data.message;
      toast.error(t(errorMessage));
      setShowOverlay(false);
    }
  };

  return (
    <>
      {showOverlay && <ResponseLoader />}
      {modalCloseLiveCategory && <AdminDeleteModal modelClose={modalCloseLiveCategory} deleteClose={closeLiveCategoryModal} deleteAction={handleDeleteLiveCategoryData} buttonText={itemToDeleteLiveCategory?.length} />}
      <AdminNavbar heading="Live categories" totalDataLength={getLiveCategory?.length} deleteCount={itemToDeleteLiveCategory?.length} navigation="/channel/add-manage-live-category" deleteMethod={openModalMultiDeleteLiveCategory} navText="Add categories" />
      <div className="bootstrapTable theme-bg-color p-3 rounded-2">
        <div className="">
          <div className="row m-0 theme-border-bottom-secondary pb-3">
            <div className="rowCheckboxDrag"> <label><input type="checkbox" checked={selectAllLiveCategory} onChange={handleSelectAllLiveCategory} /> </label></div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-4 data"> <label className="theme-text-color fw-bold fs-6">Category</label> </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-3 data"><label className="theme-text-color fw-bold fs-6">Uploaded by</label></div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-3 data"><label className="theme-text-color fw-bold fs-6">Actions</label> </div>
          </div>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="menu-items" className="theme-bg-color">
              {(provided) => (
                <ul
                  className="pb-5 px-0 theme-bg-color"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  <NoRecordTable result={getLiveCategory?.length} loader={loading} />
                  {getLiveCategory?.map((item, index) => (
                    <Draggable
                      key={item?.id.toString()}
                      draggableId={item?.id.toString()}
                      index={index}
                    >
                      {(provided) => (
                        <li
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`theme-bg-color orderborder ${item.order
                            } index ${index + 1} w-100 theme-border-bottom-secondary`}
                        >
                          <div className="row m-0 p-0 align-items-center">
                            <div className="rowCheckboxDrag  category-order-title">
                              <input
                                type="checkbox"
                                checked={itemToDeleteLiveCategory.includes(
                                  item.id
                                )}
                                onChange={() =>
                                  handleMultiSelectLiveCategory(item.id)
                                }
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4 col-4  category-order-title">
                              <div className="d-flex justify-content-between align-items-center">
                                <img src={item.image_url} className="profile" alt="image_all" />
                                <div className="rightVideoTitle">
                                  <Link className="theme-text-color table-heading d-flex  align-items-center" target="_blank" to={`/shows/${item?.slug}`}> <span title={`${item.slug}`}>{`${item?.name.slice(0, 15)}...`} </span><CiShare1 style={{ width: "15px", height: "15px", strokeWidth: '1px', marginLeft: "6px" }} /> </Link>
                                  <span className="dateText table-subheading "> {item?.upload_on}</span>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3 col-3 category-order-title">
                              <div className=" theme-text-color">{item?.uploaded_by == "1" ? "Admin" : item?.uploaded_by == "5" ? "Content Partner" : item?.uploaded_by == "4" ? "Channel Partner" : ""} </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3 col-3 category-order-title">
                              <div className="editdropdown">
                                <span className="editdropdown-button">
                                  <FaEllipsisVertical className="theme-text-color" />
                                </span>
                                <div className="editdropdown-menu  theme-bg-color">
                                  <Link to={"/channel/edit-live-category/" + item?.id} className="theme-text-color  theme-bg-color" >
                                    <span>
                                      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <g>
                                          <path  className="svgIcon" d="M10.3,18.5l-4.74.64A.49.49,0,0,1,5,18.59l.64-4.74a.42.42,0,0,1,.14-.28L17.31,2a.59.59,0,0,1,.85,0L22.11,6a.6.6,0,0,1,0,.84L10.58,18.36A.53.53,0,0,1,10.3,18.5Z" />
                                          <line  className="svgIcon" x1="1.01" y1="21.58" x2="18.89" y2="21.58" />
                                        </g>
                                      </svg>
                                      <MessageBox text="Edit" classname='ms-2 theme-text-color'></MessageBox>
                                    </span>
                                  </Link>
                                  <div onClick={() => singleDeleteLiveCategory(item?.id)} className="commonActionPadding theme-bg-color" >
                                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                      <g>
                                        <rect  className="svgIcon" x="4.38" y="5.54" width="14.49" height="17.71" />
                                        <line  className="svgIcon" x1="1.16" y1="5.54" x2="22.09" y2="5.54" />
                                        <g>
                                          <line  className="svgIcon" x1="9.21" y1="9.56" x2="9.21" y2="19.06" />
                                          <line  className="svgIcon" x1="14.04" y1="9.56" x2="14.04" y2="19.06" />
                                        </g>
                                        <path  className="svgIcon" d="M7.45,5.22a2.72,2.72,0,0,1,0-.28,4.19,4.19,0,1,1,8.37,0c0,.09,0,.19,0,.29" />
                                      </g>
                                    </svg>
                                    <MessageBox text="Delete" classname='ms-2 theme-text-color'></MessageBox>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      )}
                    </Draggable>
                  ))}
                  {/* {provided.placeholder} */}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    </ >
  );
}

export default ChannelCategorylivestream;
