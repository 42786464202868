import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import PageLoader from "../components/Loader/PageLoader";
import "../Styles/footer.css"

const DynamicPage = () => {
    const [page, setPage] = useState([]);
    const [loading, setLoading] = useState(true)
    const { type } = useParams()
    const { t } = useTranslation();

    const pageAction = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_Baseurl}/Front-End/Pages`);
            const ListRes = response?.data?.Page;
            setLoading(false)
            setPage(ListRes);
        } catch (error) {
            toast.error(error?.response?.data?.message || "An error occurred");
        }
    };

    useEffect(() => {
        pageAction();
    }, []);

    return (
        <div className="paddingTop themeTwoBannerWrappers">
            {loading ? <PageLoader /> : page && page.filter((item) => item.slug === type).map((item, index) => {
                return (
                    <div key={index}>
                        <h3 className='py-5 text-center theme-text-color priceTableBigHead'>{item?.title}</h3>
                        <div className='col-lg-8 col-md-10 col-12 mx-auto px-2 px-lg-0'>
                            {item?.active ? <div dangerouslySetInnerHTML={{ __html: t(item?.body) }}></div> : <h3 className="text-center">{t("No content available")}</h3>}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default DynamicPage;
