import React from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import HomeProtectedRoute from '../components/ProtectRoute/HomeProtectedRoute'
import HomeCommonLayout from '../components/Layout/HomeCommonLayout'
import HomePage from '../Pages/HomePage/HomePage'
import Player from '../components/Player/Player'
import TrailerPlayer from '../components/Player/TrailerPlayer'
import SeriesPlayer from '../components/Player/SeriesPlayer'
import Register_Payments from '../components/Payment_Registeration/Register_Payments'
import Success_Register_Payments from '../components/Payment_Registeration/Success_Register_Payments'
import Failed_Register_Payments from '../components/Payment_Registeration/Failed_Register_Payments'
import ViewAllPage from '../components/ViewAll/Source/ViewAllPage'
import CategoryBasedLanguageViewAll from '../components/ViewAll/CategoryBasedLanguageViewAll'
import CategoryBasedLanguageMoreViewAll from '../components/ViewAll/CategoryBasedLanguageMoreViewAll'
import CategoryBasedLiveViewAll from '../components/ViewAll/CategoryBasedLiveViewAll'
import CategoryBasedVideoViewAll from '../components/ViewAll/CategoryBasedVideoViewAll'
import CategoryBasedSeriesViewAll from '../components/ViewAll/CategoryBasedSeriesViewAll'
import CategoryBasedAudioViewAll from '../components/ViewAll/CategoryBasedAudioViewAll'
import WishListViewall from '../components/ViewAll/Source/WishListViewall'
import ProtectedRoute from '../components/ProtectRoute/ProtectedRoute'
import Videos from '../components/ViewAll/Slug/Videos'
import Livestreams from '../components/ViewAll/Slug/Livestreams'
import Series from '../components/ViewAll/Slug/Series'
import Episodes from '../components/ViewAll/Slug/Episodes'
import ArtistDetails from '../components/ViewAll/Slug/ArtistDetails'
import ChannelDetails from '../components/ViewAll/Slug/ChannelDetails'
import PartnerDetails from '../components/ViewAll/Slug/PartnerDetails'
import MusicAllRoutes from './MusicAllRoutes'
import MoviePage from '../Pages/MoviePage/MoviePage'
import ShowPage from '../Pages/ShowPage/ShowPage'
import AdminRoute from '../Pages/AdminRoute'
import AccountMain from '../components/AccountSection/AccountMain'
import ProfileAllRoutes from './ProfileAllRoutes'
import Multiprofile from '../components/Multiprofile/Multiprofile'
import EditMultiprofile from '../components/Multiprofile/EditMultiprofile'
import Iyzico_Sucesspage from '../components/Payment_Registeration/Iyzico_Sucesspage'
import Iyzico_Failerpage from '../components/Payment_Registeration/Iyzico_Failerpage'
import SearchPage from '../Pages/Search/SearchPage'
import FaqHelp from '../Pages/FaqHelp'
import Contact from '../Pages/Contact'
import ForgetPasswordThemeTwo from '../Pages/Auth/ForgetPasswordThemeTwo'
import VerifyOtp from '../Pages/Auth/VerifyOtp'
import DynamicPage from '../Pages/DynamicPage'
import LivePurchaseModal from '../components/Player/livePurchaseModal'
import PurchaseModal from '../components/Player/PurchaseModal'
import Purchase_Media_Register_Payments from '../components/Payment_Registeration/Purchase_Media_Register_Payments'
import Transcation_List_Register_Payments from '../components/Payment_Registeration/Transcation_List_Register_Payments'
import Wishlists from '../Pages/Wishlists'
import Watchlater from '../Pages/Watchlater'
import PaymentCallback from '../components/Payment_Registeration/PaymentCallback'
import PlanThemeTwo from '../Pages/Plan/PlanThemeTwo'
import Error404 from '../components/Error404'
import UserPreference from '../components/UserPreference/UserPreference'
import OwnSchedule from '../Players/OwnSchedule'


const UserAllRoutes = ({ props }) => {
    const { currentTheme } = props
    const location = useLocation();
    return (
        <Routes>
            <Route path="/" element={<HomeProtectedRoute><HomeCommonLayout /></HomeProtectedRoute>}>
                <Route index element={<HomePage />} />
                <Route path="player/:sourcetype/:slug" element={<Player />} ></Route>
                <Route path="trailer/:sourcetype/:slug" element={<TrailerPlayer />} ></Route>
                <Route path="episode/:series_slug/:episode_slug" element={<SeriesPlayer />} ></Route>
                <Route path="plan" element={currentTheme == 1 ? <Register_Payments /> : currentTheme == 2 ? <PlanThemeTwo /> : null} />
                <Route path="payment-callback" component={<PaymentCallback />} />
                <Route path="plan/payment/success" element={<Success_Register_Payments />} ></Route>
                <Route path="plan/payment/failed" element={<Failed_Register_Payments />} ></Route>
                <Route path="schedule" element={<OwnSchedule />}></Route>
                <Route path="home" element={<HomePage />}  ></Route>

                {/* Dynamic ViewAll Router Start */}
                <Route path=":source" element={<ViewAllPage />}></Route>
                <Route path="languages/:slug" element={<CategoryBasedLanguageViewAll />} ></Route>
                <Route path="languages/:slug/:type" element={<CategoryBasedLanguageMoreViewAll />} ></Route>
                <Route path="live-categories/:slug" element={<CategoryBasedLiveViewAll />} ></Route>
                <Route path="video-categories/:slug" element={<CategoryBasedVideoViewAll />} ></Route>
                <Route path="series-categories/:slug" element={<CategoryBasedSeriesViewAll />} ></Route>
                <Route path="audios-categories/:slug" element={<CategoryBasedAudioViewAll />} ></Route>
                <Route path="mywishlists/:type" element={<ProtectedRoute> <WishListViewall /> </ProtectedRoute>} ></Route>
                {/* Dynamic ViewAll Router  End*/}

                {/* Dynamic ViewDetails Slug Router Start */}
                <Route path="videos/:slug" element={<Videos />} ></Route>
                <Route path="live/:slug" element={<Livestreams />} ></Route>
                <Route path="shows/:slug" element={<Series />} ></Route>
                <Route path="series/:series_slug/:episode_slug" element={<Episodes />} ></Route>
                <Route path="artists/:slug" element={<ArtistDetails />} ></Route>
                <Route path="channels/:slug" element={<ChannelDetails />}  ></Route>
                <Route path="partners/:slug" element={<PartnerDetails />} ></Route>
                <Route path="shows/:slug/:episode" element={<PartnerDetails />} ></Route>

                {/* Dynamic ViewDetails Slug Router End */}

                {/* profile  router start */}
                <Route path="myprofile" element={<ProtectedRoute> <AccountMain /> </ProtectedRoute>} ></Route>
                <Route path="myprofile/*" element={<ProtectedRoute> <ProfileAllRoutes /> </ProtectedRoute>} ></Route>
                {/* profile redirect router start */}

                {/* Multiprofile  router start */}
                <Route path="choose-profile" element={<ProtectedRoute> <Multiprofile />  </ProtectedRoute>} ></Route>
                <Route path="multi-profile/create" element={<ProtectedRoute> <EditMultiprofile /></ProtectedRoute>} ></Route>
                <Route path="multi-profile/edit/:id" element={<ProtectedRoute> <EditMultiprofile /></ProtectedRoute>} ></Route>
                {/* Multiprofile  router end */}

                <Route path="watchlater" element={<ProtectedRoute> <Watchlater /> </ProtectedRoute>} ></Route>
                <Route path="mywishlists" element={<ProtectedRoute> <Wishlists /> </ProtectedRoute>} ></Route>
                <Route path="transcation-list" element={<ProtectedRoute> <Transcation_List_Register_Payments /> </ProtectedRoute>} ></Route>
                <Route path="purchase-media" element={<ProtectedRoute>  <Purchase_Media_Register_Payments /> </ProtectedRoute>}></Route>
                <Route path="purchase/:slug" element={<PurchaseModal />} />
                <Route path="purchase-live/:slug" element={<LivePurchaseModal />} />

                <Route path="successpage" element={<Iyzico_Sucesspage />}></Route>
                <Route path="failerpage" element={<Iyzico_Failerpage />}></Route>
                <Route path="search" element={<SearchPage />}></Route>
                <Route path="help" element={<FaqHelp />}></Route>
                <Route path="contact" element={<Contact />}></Route>
                <Route path="forget" element={<ForgetPasswordThemeTwo />}></Route>
                <Route path="verify/:code" element={<VerifyOtp />}></Route>
                <Route path="page/:type" element={<DynamicPage />} />
                <Route path="preference" element={<ProtectedRoute><UserPreference /></ProtectedRoute>} />
                {/* Music router start */}
                <Route path="music/*" element={<MusicAllRoutes />} />
                {/* Music router end */}

                {/* Movie router start */}
                <Route path="videos" element={<MoviePage />} />
                {/* Movie router end */}

                {/* Show router start */}
                <Route path="show" element={<ShowPage />} />
                {/* Show router end */}

                {/* Admin redirect router start */}
                <Route path="admin" element={<AdminRoute />} ></Route>
                {/* Admin redirect router start */}

                <Route path="*" element={<Error404></Error404>}></Route>

                {/*
                <Route path="category/:slug" element={<ProtectedRoute> <CategoryVideoDetails /> </ProtectedRoute>}></Route> 
                <Route path="/video-play/:slug" element={<ProtectedRoute><VideoPlayer /> </ProtectedRoute>} ></Route>
                <Route path="/Subscription-Plans" element={ <ProtectedRoute> <SubscriptionPlans /> </ProtectedRoute> } ></Route>
                <Route  path="/Subscriber-section" element={ <ProtectedRoute><SubscriberPage /></ProtectedRoute>}></Route> 
                <Route path="/:lang/video/:slug" element={<PlayerVisibility />} ></Route>
                <Route path="/:lang/livestream/:slug" element={<LivestreamVisibility />} ></Route>
                <Route path="/:lang/series/:series_slug/:episode_slug" element={<SeriesPlayerVisibility />} ></Route>
                <Route path="/shows/:slug" element={<SeriesDetails />} ></Route> 
                <Route path="/videos/:slug" element={<VideoDetails />} ></Route>
                <Route path="/live/:slug" element=<LiveDetails />} ></Route> 
            */}
            </Route>
        </Routes>
    )
}

export default UserAllRoutes