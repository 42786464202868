import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import "./Edit_album.css";
import { toast } from "react-toastify";
 
import loadings from "../../../assets/gif/loading.gif";
import ResponseLoader from "../../../components/Loader/ResponseLoader";
import AdminSaveHeader from "../../../components/adminCommonComponents/AdminSaveHeader";
import { IoClose } from "react-icons/io5";
import { useTranslation } from "react-i18next";

function Edit_album() {
  const { id } = useParams();
  const { t } =useTranslation();
  const [loading, setLoading] = useState(true);
  const [audioalbums, setAudioalbums] = useState([]);
  const [editUser, setEditUser] = useState({
    albumname: "",
    parent_id: "",
    slug: "",
    albums: "",
    uploaded_by: "",
    image_url: "",
  });
  const [editname, setEditname] = useState({
    albumname: "",
  });
  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const access_token_channel = localStorage.getItem("access_token_channel");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token_channel,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const fileInputRef1 = useRef(null);
  const [selectedFile1, setSelectedFile1] = useState(null);
  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = (setSelectedFile1) => {
    setSelectedFile1(null);
    if (fileInputRef1.current) {
      fileInputRef1.current.value = null;
    }
  };

  useEffect(() => {
    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/Audio-Album/Edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.audio_album[0];
      setEditUser(res);
      setEditname(res);
      setLoading(false);
    };
    getUser();
    axios.get(`${process.env.REACT_APP_Baseurl}/Channel/Backend/Audio-Album/create`, { headers: headers, }).then((response) => {
      var result = response?.data?.AudioGenre;
      setAudioalbums(result);
    })
      .catch((error) => console.log(error));
  }, [id]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessageslug("");
    setIsValidationHiddenslug(true);
    setValidationMessagetitle("");
    setIsValidationHiddentitle(true);
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };
  const [validationMessagetitle, setValidationMessagetitle] = useState("");
  const [isValidationHiddentitle, setIsValidationHiddentitle] = useState(true);
  const [validationMessageslug, setValidationMessageslug] = useState("");
  const [isValidationHiddenslug, setIsValidationHiddenslug] = useState(true);
  const titleInputRef = useRef(null);
  const handleUpdate = async (e) => {
    let focusInputRef = null;
    if (editUser?.name == "") {
      setValidationMessagetitle("Name cannot be empty.");
      setIsValidationHiddentitle(false);
      focusInputRef = titleInputRef;
    }
    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }
    e.preventDefault();
    const editInputvalue = {
      uploaded_by: editUser?.uploaded_by,
      albumname: editUser?.albumname,
      parent_id: editUser?.parent_id,
      albums: editUser?.albums,
      slug: editUser?.slug,
    };

    let firstApiStatus = false;
    let secondApiStatus = false;
    let resultapi = null;
    let resjson = null;
    let resultapifalse = null;
    setProcessing(true);
    setShowOverlay(true);
    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/Audio-Album/update/${id}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );
      resjson = await res.json();
      if (resjson.status == true) {
        firstApiStatus = true;
        resultapi = resjson;
        var album_id = resjson.AudioAlbum_id;
        const formData = new FormData();
        formData.append("audio_album_id", album_id);
        if (selectedFile1) {
          formData.append("album", selectedFile1);
        }
        await axios.post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/Audio-Album/Image-upload`,
          formData,
          { headers: headers }
        );
        secondApiStatus = true;
      } else if (resjson.status == false) {
        resultapifalse = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
    }
    if (firstApiStatus && secondApiStatus) {
      setValidationMessagetitle("");
      setValidationMessageslug("");
      toast.success(t(resultapi?.message));
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else if (firstApiStatus && secondApiStatus) {
      toast.error(t(resultapifalse?.message));
      setProcessing(false);
      setShowOverlay(false);
      setIsValidationHiddentitle(false);
      setValidationMessageslug(false);
    }
  };
  return (
    <>
      {showOverlay && (<ResponseLoader />)}
      <AdminSaveHeader heading="Edit Album" saveText="Update Audio Album" saveMethod={handleUpdate} />
      <div className="row m-0">
        <div className="col-12 col-md-6 ps-md-0">
          <div className="rounded-3 mb-4 theme-bg-color p-3">
            <h5 className="theme-text-color theme-border-bottom-secondary pb-3">{editname?.albumname}</h5>
            <div className="mt-3 text-start">
              <label className="mt-2 d-block mandatorySimple position-relative theme-text-color admin-input-title">
                Name
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  id="albumname"
                  name="albumname"
                  className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder theme-text-color border-0"
                  onChange={handleInput}
                  value={editUser?.albumname}
                  placeholder="Enter Name"
                  ref={titleInputRef}
                />
              </div>
              <span className="errorred d-block mt-2">
                {!isValidationHiddentitle && (
                  <p>{validationMessagetitle}</p>
                )}
              </span>
            </div>
            <div className="mt-2 text-start">
              <label className="mt-2 admin-input-title theme-text-color">Slug</label>
              <div className="mt-2">
                <input
                  type="text"
                  className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder theme-text-color border-0"
                  id="slug"
                  name="slug"
                  placeholder="Enter Slug"
                  onChange={handleInput}
                  value={editUser?.slug}
                />
              </div>
            </div>
            <div className="mt-2 text-start">
              <label className="mt-2 admin-input-title theme-text-color">Category</label>
              <div className="mt-2">
                <select
                  className="form-select theme-bg-color-secondary custom-placeholder border-0 theme-text-color"
                  name="parent_id"
                  id="parent_id"
                  onChange={handleInput}
                  value={editUser?.parent_id}
                >
                  <option value="0">Select </option>
                  {audioalbums?.map((item) => (
                    <option value={item?.id} className="theme-text-color theme-bg-color">{item?.name}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="rounded-3 mb-4 theme-bg-color p-3">
            <h5 className="theme-text-color pb-3 theme-border-bottom-secondary">Thumbnails</h5>
            <div className=" text-start mt-2">
              <label className="my-2 admin-input-title theme-text-color">Cover Image</label>
              {loading ? (<img src={loadings} className="thumbnails-images d-block" />) : (<img src={editUser?.image_url} alt="Image" className="thumbnails-images d-block" />)}
              <div className="mt-2">
                <div className="m-0 row">
                  <div className=" col-6 ps-0">
                    <div
                      className="imagedrop theme-border-color"
                      onClick={() => fileInputRef1.current.click()}
                    >
                      <input
                        type="file"
                        ref={fileInputRef1}
                        onChange={(event) =>
                          handleFileChange(
                            event,
                            fileInputRef1,
                            setSelectedFile1
                          )
                        }
                        accept="image/*"
                        style={{ display: "none" }}
                      />
                      <label className=" theme-text-color">
                        <CameraRetroIcon className=" theme-text-color" />
                      </label>
                    </div>
                  </div>
                  <div className="col-6 ">
                    <div>
                      {selectedFile1 && (
                        <div className="imagedropcopy theme-border-color text-end">
                          <div className="row  m-0">
                            <div className="col-10 p-0 ">
                              <img
                                src={URL.createObjectURL(selectedFile1)}
                                alt="Uploaded"
                                className="thumbnails-upload-images"
                              />
                            </div>
                            <div
                              className="col-2 p-0 "
                              style={{ alignSelf: "center" }}
                            >
                              <button
                                onClick={() =>
                                  handleDelete(setSelectedFile1)
                                }
                                type="button"
                                className="theme-text-color bg-transparent "
                              >  <IoClose className="custom-close-btn" /></button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Edit_album;
