import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./ChannelHeader.css";
import { RxHamburgerMenu } from "react-icons/rx";
import { FaRegUser } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { LogOut } from "../../Pages/CommonMethods/CommonMethods";
import axios from "axios";
import MessageBox from "../../components/MessageBox";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useMyContext } from "../../components/Layout/CommonProvider";

function ChannelHeader({ toggleAction, headerindexall }) {

  const { t } = useTranslation();
  const { navigateTranslateChecker } = useMyContext()
  const channel_name = localStorage.getItem("channel_name");


  const access_token_channel = localStorage.getItem("access_token_channel");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token_channel,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };


  const capitalizeFirstLetter = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };

  const capitalizedUsername = capitalizeFirstLetter(channel_name);

  const toggleChannelNavbar = () => {
    toggleAction()
  }

  const [aboutheader, setAboutheader] = useState();
  const fetchOptions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/About`,
        { headers: headers }
      );
      setAboutheader(response?.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  async function logincontent() {
    const formData = new FormData();

    formData.append("email", aboutheader?.content_partner_exists?.email);
    formData.append("password", aboutheader?.content_partner_exists?.password);

    let firstApiStatus = false;
    let secondApiStatus = false;
    let resultapi = null;
    let resultapifalse = null;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/CPP-auth/sign-in`,
        formData,
      );

      if (response?.data?.status === true) {
        firstApiStatus = true;
        resultapi = response?.data;
        let access_token = resultapi.access_token;
        let expires_in = resultapi.expires_in;
        let username = resultapi.CPP_user.username;
        let user_id = resultapi.CPP_user.id;
        let role = resultapi.CPP_user.role;
        let cpp_image = resultapi.CPP_user.cpp_image;
        localStorage.setItem("access_token_cpp", access_token);
        localStorage.setItem("cpp_Partner_id", user_id);
        localStorage.setItem("cpp_role", role);
        localStorage.setItem("cpp_name", username);
        localStorage.setItem("cpp_image", cpp_image);
        localStorage.setItem("cpp_expires_in", expires_in);
        toast.success(t(resultapi?.message));
      } else if (response?.data?.status === false) {
        resultapifalse = response?.data;
        toast.error(t("These credentials do not match our records"));
      }
    } catch (error) { }

    if (firstApiStatus) {
      setTimeout(() => {
        window.location.href = navigateTranslateChecker(`/cpp/home`)
      }, 3000);
    } else {
      setTimeout(() => {
        window.location.href = navigateTranslateChecker(`/cpp/login`)
      }, 3000);
    }
  }


  async function loginwebsite() {
    const formData = new FormData();

    formData.append("email", aboutheader?.User_exists?.email);
    formData.append("password", aboutheader?.User_exists?.password);

    let firstApiStatus = false;
    let secondApiStatus = false;
    let resultapi = null;
    let resultapifalse = null;
  

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/users/signin`,
        formData,
      );

      if (response?.data?.status === true) {
        firstApiStatus = true;
        const result = response?.data;
        const resultUser = result?.user;
        // let access_token = resultapi.access_token;
        // let expires_in = resultapi.expires_in;
        // let channel_name = resultapi.Channel_user.channel_name;
        // let user_id = resultapi.Channel_user.id;
        // let role = resultapi.Channel_user.role;
        // let channel_image = resultapi.Channel_user.channel_image;
        localStorage.setItem("access_token", result?.access_token);
        localStorage.setItem("user_id", resultUser?.id);
        console.log("user_id", resultUser?.id);
        localStorage.setItem("role", Number(resultUser?.role));
        localStorage.setItem("username", resultUser?.username);
        localStorage.setItem("last_name", resultUser?.last_name);
        localStorage.setItem("image_url", resultUser?.avatar_url);
        localStorage.setItem("email", resultUser?.email);
        localStorage.setItem("expires_in", result?.expires_in);

        toast.success(t(resultapi?.message));
      } else if (response?.data?.status === false) {
        // Handle the case when the first API response status is false
        resultapifalse = response?.data;
        toast.error(t("These credentials do not match our records"));
      }
    } catch (error) { }

    if (firstApiStatus) {
      setTimeout(() => {
        window.location.href = `/`
      }, 3000);
    } else {
      setTimeout(() => {
        window.location.href = navigateTranslateChecker(`/login`)
      }, 3000);
      // setProcessing(false);
      // setShowOverlay(false);
    }
  }


  return (
    <nav className="theme-bg-color  px-2 px-sm-3 channelHeader">
      <ul className="d-flex align-items-center justify-content-between p-0 m-0 list-unstyled">
        <li>
          <button type="button" onClick={toggleChannelNavbar} className="bg-transparent d-inline-flex p-2 toggleAdminButton">
            <RxHamburgerMenu className="adminIcon" />
          </button>
        </li>
        <li>
          <div className="d-flex align-items-center justify-content-center gap-3">
            {aboutheader?.User_exists ? (
              <button className="theme-button-bg-color accessButton"
                onClick={loginwebsite}
              >
                <span>{t("View Website")}</span>
              </button>
            ) : null}
            {aboutheader?.content_partner_exists ? (
              <button className="theme-button-bg-color accessButton" onClick={logincontent}>
                <span>{t("CPP Portal")}</span>
              </button>
            ) : null}
            {/* <Link className="btn btn-primary rounded-2 border-0 d-flex px-4 px-sm-3  py-1 py-sm-2" to="/"> <BiWorld className="adminIcon me-2" /> <span className="d-none d-sm-block">Website</span></Link> */}
          </div>
        </li>
        <li className="position-relative adminDropDownContainer py-3 theme-bg-color ">
          <button className="d-flex align-items-center px-3 py-2 bg-transparent shadow rightSideButton">
            <FaRegUser style={{ fill: "currentColor", width: "20px", height: "20px" }} />
            <p className="mb-0 userText">
              <MessageBox text={`${capitalizedUsername}`} classname="theme-text-color" /></p>
          </button>
          <ul className="adminSideDropDown list-unstyled theme-bg-color">
            <li>
              <Link className="d-flex align-items-center px-3 py-3 border-bottom theme-text-color" to="/channel/my-profile" >
                <CgProfile className="exportIcon me-2 theme-text-color" />My Profile
              </Link>
            </li>
            <li>
              <button type="button" className="bg-transparent border-0  theme-text-color d-flex align-items-center px-3 py-3" to="/" onClick={() => LogOut('/channel/login')}>
                <RiLogoutCircleRLine className="exportIcon me-2 theme-text-color" /> Logout
              </button>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
}


const mapStateToProps = (state, ownProps) => ({
  props: ownProps,
  headerindexall: state.get_headerIndex_Reducer.headerindexall,
});
export default connect(mapStateToProps)(ChannelHeader);
