import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from "react-icons/md";
import { FaEllipsisVertical } from "react-icons/fa6";
import { toast } from 'react-toastify'
import defaultImage from "../../../assets/images/default_vertical_image.webp";
import ResponseLoader from "../../../components/Loader/ResponseLoader";
import AdminDeleteModal from "../../../components/adminCommonComponents/AdminDeleteModal";
import AdminNavbar from "../../../components/adminCommonComponents/AdminNavbar";
import AdminHeader from "../../../components/adminCommonComponents/AdminHeader";
import NoRecordTable from "../../../components/adminCommonComponents/NoRecordTable";
import MessageBox from "../../../components/MessageBox";
import { CiShare1 } from "react-icons/ci";
import { useTranslation } from "react-i18next";
import { useMyContext } from "../../../components/Layout/CommonProvider";


const ChanneListArtists = () => {
    const { navigateTranslateChecker } = useMyContext()
    const { t } = useTranslation();
    const [getArtist, setArtist] = useState([]);
    const [filteredArtistData, setFilteredArtistData] = useState([]);
    const [itemToDeleteArtist, setItemToDeleteArtist] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [modalCloseArtist, setModalCloseArtist] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showOverlay, setShowOverlay] = useState(false);
    const access_token_channel = localStorage.getItem("access_token_channel");
    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token_channel,
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
    };

    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_Baseurl}/Channel/Backend/artist/index`,
                { headers: headers }
            );
            const artistData = response?.data?.artists;
            setArtist(artistData);
            setFilteredArtistData(artistData);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false);
        }
    };

    const pagination = paginationFactory({
        page: 1,
        sizePerPage: 10,
        nextPageText: "Next",
        prePageText: "Prev",
        firstPageText: <MdKeyboardDoubleArrowLeft />,
        lastPageText: <MdKeyboardDoubleArrowRight />,
        showTotal: true,
        alwaysShowAllBtns: true,
        sizePerPageList: [
            { text: "5", value: 5 },
            { text: "10", value: 10 },
            { text: "All", value: filteredArtistData?.length },
        ],
    });

    const selectRow = {
        mode: "checkbox",
        onSelect: (row, isSelect) => {
            if (row) {
                if (isSelect) {
                    setItemToDeleteArtist([...itemToDeleteArtist, row?.id]);
                } else {
                    setItemToDeleteArtist(
                        itemToDeleteArtist.filter((id) => id !== row?.id)
                    );
                }
            }
        },
        selected: itemToDeleteArtist,
        selectionHeaderRenderer: ({ mode, checked, indeterminate }) => (
            <div>
                <input
                    type="checkbox"
                    checked={checked}
                    indeterminate={indeterminate}
                    onChange={handleAll}
                />
            </div>
        ),
    };
    const actionFormatter = (data, row) => {
        return (
            <div className="editdropdown ">
                <span className="editdropdown-button">
                    <FaEllipsisVertical className="theme-text-color" />
                </span>
                <div className="editdropdown-menu theme-bg-color">
                    <Link to={navigateTranslateChecker(`/channel/update-artist/${row?.id}`)} className="theme-text-color  theme-bg-color">
                        <span className="theme-text-color">
                            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <g>
                                    <path className="svgIcon" d="M10.3,18.5l-4.74.64A.49.49,0,0,1,5,18.59l.64-4.74a.42.42,0,0,1,.14-.28L17.31,2a.59.59,0,0,1,.85,0L22.11,6a.6.6,0,0,1,0,.84L10.58,18.36A.53.53,0,0,1,10.3,18.5Z" />
                                    <line className="svgIcon" x1="1.01" y1="21.58" x2="18.89" y2="21.58" />
                                </g>
                            </svg>
                            <MessageBox text="Edit" classname='ms-2 theme-text-color'></MessageBox>
                        </span>
                    </Link>
                    <div
                        onClick={() => openModalSingleDeleteArtist(row?.id)}
                        className="commonActionPadding theme-bg-color"
                    >
                        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <g>
                                <rect className="svgIcon" x="4.38" y="5.54" width="14.49" height="17.71" />
                                <line className="svgIcon" x1="1.16" y1="5.54" x2="22.09" y2="5.54" />
                                <g>
                                    <line className="svgIcon" x1="9.21" y1="9.56" x2="9.21" y2="19.06" />
                                    <line className="svgIcon" x1="14.04" y1="9.56" x2="14.04" y2="19.06" />
                                </g>
                                <path className="svgIcon" d="M7.45,5.22a2.72,2.72,0,0,1,0-.28,4.19,4.19,0,1,1,8.37,0c0,.09,0,.19,0,.29" />
                            </g>
                        </svg>
                        <MessageBox text="Delete" classname='ms-2 theme-text-color'></MessageBox>
                    </div>
                </div>
            </div>
        );
    };


    const imageFormatter = (data, row) => {
        return (
            <div className="d-flex justify-content-between align-items-center">
                <img src={row.Thumbnail ? row.Thumbnail : defaultImage} className="profile" />
                <div className="rightVideoTitle">
                    <Link className="theme-text-color table-heading d-flex  align-items-center" target="_blank" to={`/videos/${data}`}> <span title={`${row.slug}`}>{`${data.slice(0, 15)}...`} </span><CiShare1 style={{ width: "15px", height: "15px", strokeWidth: '1px', marginLeft: "6px" }} /> </Link>
                    <span className="me-1 text-dark dateText theme-text-color table-subheading"> {row?.duration} </span>
                    <span className="dateText table-subheading theme-text-color">{row?.upload_on}</span>
                </div>
            </div>
        );
    };

    const columns = [
        {
            dataField: "artist_name",
            text: "Artists",
            formatter: imageFormatter,
            sort: true,
        },
        {
            dataField: "artist_type",
            text: "Artists Type ",
            sort: true,
        },
        {
            dataField: "ACTIONS",
            text: "Actions",
            formatter: actionFormatter,
        },
    ];

    const handleFilterChangeArtist = (data) => {
        setFilteredArtistData(data);
    };

    const handleAll = () => {
        setSelectAll(!selectAll);
        setItemToDeleteArtist(
            selectAll ? [] : filteredArtistData?.map((item) => item?.id)
        );
    };

    const closeArtistModal = () => {
        setModalCloseArtist(!modalCloseArtist);
    };
    const openModalSingleDeleteArtist = (id) => {
        setItemToDeleteArtist([id]);
        setModalCloseArtist(true);
    };
    const openModalMultiDeleteArtist = () => {
        setModalCloseArtist(true);
    };
    const handleDeleteArtistData = () => {
        closeArtistModal();
        artistDeleteOperation();
    };
    const artistDeleteOperation = async () => {
        setShowOverlay(true);
        const formData = new FormData();
        formData.append("id", "[" + itemToDeleteArtist + "]");

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_Baseurl}/Channel/Backend/artist/multi-delete`,
                formData,
                {
                    headers: headers,
                }
            );
            const result = response?.data;
            if (result?.status) {
                toast.success(t(result?.message))
                setShowOverlay(false);
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            }
        } catch (err) {
            console.error(err);
            var errorMessage = err?.response?.data?.message;
            toast.success(t(errorMessage))
            setShowOverlay(false);
        }
    };

    return (
        <>
            {showOverlay && (<ResponseLoader />)}
            {modalCloseArtist && <AdminDeleteModal modelClose={modalCloseArtist} deleteClose={closeArtistModal} deleteAction={handleDeleteArtistData} buttonText={itemToDeleteArtist?.length} />}
            <AdminNavbar heading="All artists" totalDataLength={getArtist?.length} deleteCount={itemToDeleteArtist?.length} navigation="/channel/add-artist" deleteMethod={openModalMultiDeleteArtist} navText="Add artists" />
            <div className="theme-bg-color p-3 rounded-2 bootstrapTable  multi-delete-width">
                <AdminHeader searchMethod={handleFilterChangeArtist} reduceData={filteredArtistData} originalData={getArtist} fileName="ARTISTS-LIST" />
                <BootstrapTable keyField="id" columns={columns} data={filteredArtistData} pagination={pagination} className="no-border-table theme-text-color" noDataIndication={<NoRecordTable result={filteredArtistData?.length} loader={loading} />} hover headerClasses="videoWidth" selectRow={selectRow} selectAll={selectAll} />
            </div>
        </>
    )
}

export default ChanneListArtists