import "./ContentSidebar.css";
import React from "react";
import { NavLink } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { PiMicrophoneLight } from "react-icons/pi";
import logo from "../../assets/images/site-dark-logo.webp";
import MessageBox from "../../components/MessageBox";
import { analyticsContentURL, artistContentURL, audioContentURL, contentPagesURL, liveStreamContentURL, seriesContentURL, settingsContentURL, videoContentURL } from "./ContentNavigation";

function ContentSidebar({ toggleClassName }) {
  return (
    <section className="Sidebar">
      <div className={` ${toggleClassName ? "channel-active" : ""}  adminSideBarLeft theme-bg-color `}>
        <NavLink className="adminImageLink theme-bg-color d-block" to="/cpp">
          <img src={logo} alt="flogo" className="mx-auto d-block py-3 " style={{ width: "200px", height: "80px", objectFit: "contain" }} />
        </NavLink>
        <ul className="p-0 list-unstyled px-2">

          <li className="nav-item mb-2">
            <NavLink className="d-flex align-items-center sideMenu position-relative" to="/cpp">
              <div>
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                  <g>
                    <rect  className="cls-1" style={{ fill: "none", stroke: "currentcolor", strokeMiterlimit: "10", strokeWidth: "0.75pxa" }} x="1" y="1.16" width="10.11" height="7.14" rx="0.89" />
                    <rect  className="cls-1" style={{ fill: "none", stroke: "currentcolor", strokeMiterlimit: "10", strokeWidth: "0.75pxa" }} x="12.89" y="16.02" width="10.11" height="7.14" rx="0.89" />
                    <rect  className="cls-1" style={{ fill: "none", stroke: "currentcolor", strokeMiterlimit: "10", strokeWidth: "0.75pxa" }} x="1" y="10.67" width="10.11" height="12.49" rx="1.18" />
                    <rect  className="cls-1" style={{ fill: "none", stroke: "currentcolor", strokeMiterlimit: "10", strokeWidth: "0.75pxa" }} x="12.89" y="1.16" width="10.11" height="12.49" rx="1.18" />
                  </g>
                </svg>
              </div>
              <MessageBox text={`Dashboard`} classname="ms-2 text-break theme-text-color" />
            </NavLink>
          </li>

          <li className="nav-item mb-2">
            <div className={`nav-link  d-flex align-items-center justify-content-between sideMenu position-relative ${videoContentURL.some((path) => window.location.pathname.startsWith(path.url)) && 'headingActive theme-bg-color-secondary'}`} data-bs-target="#Video-Management" data-bs-toggle="collapse" aria-expanded="false" aria-controls="Video-Management"  >
              <div>
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                  <g>
                    <rect  className="cls-1" style={{ fill: "none", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} x="1" y="3.16" width="22.01" height="12.89" rx="0.94" /> <g>
                      <line  className="cls-1" style={{ fill: "currentcolor", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} x1="22.78" y1="20.82" x2="13.33" y2="20.82" />
                      <line  className="cls-1" style={{ fill: "currentcolor", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} x1="10.67" y1="20.82" x2="1.22" y2="20.82" />
                      <path  className="cls-1" style={{ fill: "currentcolor", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} d="M13.33,20.82a1.33,1.33,0,1,0-2.66,0" />
                      <path  className="cls-1" style={{ fill: "currentcolor", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} d="M10.67,20.82a1.33,1.33,0,1,0,2.66,0" />
                    </g>
                    <polygon  className="cls-1" style={{ fill: "currentcolor", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} points="9.9 12.2 9.9 6.54 14.1 9.37 9.9 12.2" />
                  </g>
                </svg>
                <MessageBox text={`Video Management`} classname="ms-2 text-break theme-text-color" />
              </div>
              <IoIosArrowDown />
            </div>
            <ul id="Video-Management" className={`collapse p-0 mt-2 list-unstyled ${videoContentURL.some((path) => window.location.pathname.startsWith(path.url)) && 'show'}`}>
              {videoContentURL?.map((data, index) => <li className=" mb-2" >
                <NavLink to={`${data?.url}`} className="d-flex align-items-center sideMenu position-relative ps-4  pe-3">
                  <MessageBox text={`${data?.message}`} classname="ms-2 text-break theme-text-color" />
                </NavLink>
              </li>)}
            </ul>
          </li>

          <li className="nav-item mb-2">
            <div className={`nav-link  d-flex align-items-center  justify-content-between  sideMenu position-relative ${seriesContentURL.some((path) => window.location.pathname.startsWith(path.url)) && 'headingActive theme-bg-color-secondary'}`} data-bs-target="#Series-Episode" data-bs-toggle="collapse" aria-expanded="false" aria-controls="Series-Episode" >
              <div>
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                  <g>
                    <g>
                      <rect  className="cls-1" style={{ fill: "none", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} x="0.81" y="1" width="22.38" height="19.02" rx="4.08" />
                      <line  className="cls-1" style={{ fill: "none", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} x1="1.12" y1="15.38" x2="23.17" y2="15.38" />
                      <line  className="cls-1" style={{ fill: "none", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} x1="9.63" y1="20.16" x2="8.98" y2="22.8" />
                      <line  className="cls-1" style={{ fill: "none", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} x1="14.44" y1="20.16" x2="14.92" y2="22.8" />
                      <line  className="cls-1" style={{ fill: "none", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} x1="6.71" y1="23.31" x2="17.36" y2="23.31" />
                    </g>
                    <polygon  className="cls-1" style={{ fill: "none", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} points="10.2 11.5 10.2 5.49 15.02 8.43 10.2 11.5" />
                  </g>
                </svg>
                <MessageBox text={`Series & Episode`} classname="ms-2 text-break theme-text-color" />
              </div>
              <IoIosArrowDown />
            </div>
            <ul id="Series-Episode" className={`collapse p-0 mt-2 list-unstyled ${seriesContentURL.some((path) => window.location.pathname.startsWith(path.url)) && 'show'}`} >
              {seriesContentURL?.map((data, index) => <li className=" mb-2" >
                <NavLink to={`${data?.url}`} className="d-flex align-items-center sideMenu position-relative ps-4  pe-3">
                  <MessageBox text={`${data?.message}`} classname="ms-2 text-break theme-text-color" />
                </NavLink>
              </li>)}
            </ul>
          </li>

          <li className="nav-item mb-2">
            <div className={`nav-link  d-flex align-items-center  justify-content-between  sideMenu position-relative ${audioContentURL.some((path) => window.location.pathname.startsWith(path.url)) && 'headingActive theme-bg-color-secondary'}`} data-bs-target="#Audio-Management" data-bs-toggle="collapse" aria-expanded="false" aria-controls="Audio-Management" >
              <div>
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                  <g>
                    <g>
                      <path  className="cls-1" style={{ fill: "none", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} d="M19.38,3.15a10.37,10.37,0,0,1,3.53,6,7.83,7.83,0,0,1,.16,1.17.42.42,0,0,0,0,.15v1.36l-.12.84a10.16,10.16,0,0,1-1.54,4.12,10.31,10.31,0,0,1-2.09,2.38" />
                      <path  className="cls-1" style={{ fill: "none", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} d="M17.54,5.4A7.55,7.55,0,0,1,20,9.59a7.37,7.37,0,0,1,.16,1.56,7.51,7.51,0,0,1-2.64,5.72" />
                      <path  className="cls-1" style={{ fill: "none", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} d="M15.7,7.65a4.63,4.63,0,0,1,0,7" />
                    </g>
                    <g>
                      <rect  className="cls-1" style={{ fill: "none", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} x="1.55" y="6.27" width="3.33" height="9.77" />
                      <polyline  className="cls-1" style={{ fill: "none", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} points="5.09 6.23 11.79 1 11.79 21.31 4.87 15.91" />
                    </g>
                  </g>
                </svg>
                <MessageBox text={`Audio Management`} classname="ms-2 text-break theme-text-color" />
              </div>
              <IoIosArrowDown />
            </div>
            <ul id="Audio-Management" className={`collapse p-0 mt-2 list-unstyled ${audioContentURL.some((path) => window.location.pathname.startsWith(path.url)) && 'show'}`}  >
              {audioContentURL?.map((data, index) => <li className=" mb-2" >
                <NavLink to={`${data?.url}`} className="d-flex align-items-center sideMenu position-relative ps-4  pe-3">
                  <MessageBox text={`${data?.message}`} classname="ms-2 text-break theme-text-color" />
                </NavLink>
              </li>)}
            </ul>
          </li>

          <li className="nav-item mb-2">
            <div className={`nav-link  d-flex align-items-center  justify-content-between  sideMenu position-relative ${artistContentURL.some((path) => window.location.pathname.startsWith(path.url)) && 'headingActive theme-bg-color-secondary'}`} data-bs-target="#Artist-Management" data-bs-toggle="collapse" aria-expanded="false" aria-controls="Artist-Management" >
              <div>
                <PiMicrophoneLight fill="currentColor" style={{ width: '28px', height: '30px', opacity: '0.8', marginLeft: '-4px' }} />
                <MessageBox text={`Caste and Crew`} classname="ms-2 text-break theme-text-color" />
              </div>
              <IoIosArrowDown />
            </div>
            <ul id="Artist-Management" className={`collapse p-0 mt-2 list-unstyled ${artistContentURL.some((path) => window.location.pathname.startsWith(path.url)) && 'show'}`} >
              {artistContentURL?.map((data, index) => <li className=" mb-2" >
                <NavLink to={`${data?.url}`} className="d-flex align-items-center sideMenu position-relative ps-4  pe-3">
                  <MessageBox text={`${data?.message}`} classname="ms-2 text-break theme-text-color" />
                </NavLink>
              </li>)}
            </ul>
          </li>

          <li className="nav-item mb-2">
            <div className={`nav-link  d-flex align-items-center  justify-content-between  sideMenu position-relative ${liveStreamContentURL.some((path) => window.location.pathname.startsWith(path.url)) && 'headingActive theme-bg-color-secondary'}`} data-bs-target="#Live-Stream" data-bs-toggle="collapse" aria-expanded="false" aria-controls="Live-Stream">
              <div>
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                  <g>
                    <g>
                      <path  className="cls-1" style={{ fill: "none", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} d="M19.29,3.57A11.21,11.21,0,0,1,23,9.83a10.82,10.82,0,0,1,.25,2.34,11.24,11.24,0,0,1-4,8.57" />
                      <path  className="cls-1" style={{ fill: "none", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} d="M16.53,6.94a6.93,6.93,0,0,1,0,10.48" />
                    </g>
                    <g>
                      <path  className="cls-1" style={{ fill: "none", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} d="M4.71,3.57A11.21,11.21,0,0,0,1,9.83a10.82,10.82,0,0,0-.25,2.34,11.24,11.24,0,0,0,4,8.57" />
                      <path  className="cls-1" style={{ fill: "none", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} d="M7.47,6.94a6.93,6.93,0,0,0,0,10.48" />
                    </g>
                    <circle cx="12.13" cy="12.16" r="1.37" style={{ stroke: "currentcolor" }} />
                  </g>
                </svg>
                <MessageBox text={`Manage Live Stream`} classname="ms-2 text-break theme-text-color" />
              </div>
              <IoIosArrowDown />
            </div>
            <ul id="Live-Stream" className={`collapse p-0 mt-2 list-unstyled ${liveStreamContentURL.some((path) => window.location.pathname.startsWith(path.url)) && 'show'}`}  >
              {liveStreamContentURL?.map((data, index) => <li className=" mb-2" >
                <NavLink to={`${data?.url}`} className="d-flex align-items-center sideMenu position-relative ps-4  pe-3">
                  <MessageBox text={`${data?.message}`} classname="ms-2 text-break theme-text-color" />
                </NavLink>
              </li>)}
            </ul>
          </li>

          <li className="nav-item mb-2">
            <div className={`nav-link  d-flex align-items-center  justify-content-between  sideMenu position-relative ${settingsContentURL.some((path) => window.location.pathname.startsWith(path.url)) && 'headingActive theme-bg-color-secondary'}`} data-bs-target="#Content-Partner" data-bs-toggle="collapse" aria-expanded="false" aria-controls="Content-Partner" >
              <div className="d-flex">
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                  <g>
                    <g>
                      <path  className="cls-1" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "0.75px", stroke: "currentColor" }} d="M17.83,17.67a.48.48,0,0,1-.28-.13l-1.78-1.25a.26.26,0,0,0-.31,0,4.48,4.48,0,0,1-1,.42.25.25,0,0,0-.19.23c-.13.7-.25,1.39-.37,2.09-.06.36-.18.48-.54.48h-2c-.32,0-.46-.12-.52-.45-.12-.71-.24-1.43-.35-2.14a.21.21,0,0,0-.18-.2,5.29,5.29,0,0,1-1-.43.24.24,0,0,0-.28,0L7.25,17.5c-.34.24-.47.23-.77-.07L5.15,16.1c-.29-.3-.3-.43-.06-.77l1.2-1.68a.23.23,0,0,0,0-.31,5,5,0,0,1-.42-1,.25.25,0,0,0-.23-.2l-2.09-.37c-.34-.05-.45-.18-.45-.53v-2c0-.33.1-.46.42-.52l2-.32c.22,0,.36-.09.42-.34a3.67,3.67,0,0,1,.38-.89.26.26,0,0,0,0-.33c-.41-.57-.8-1.14-1.2-1.71-.22-.32-.21-.46.06-.73L6.54,3c.26-.26.4-.27.7-.06L9,4.19a.23.23,0,0,0,.27,0,5.43,5.43,0,0,1,1-.42c.14,0,.18-.12.2-.24.11-.67.22-1.34.32-2,.07-.44.18-.54.62-.54h2a.42.42,0,0,1,.48.41c.13.71.25,1.42.37,2.13a.27.27,0,0,0,.21.26,4,4,0,0,1,1,.42.24.24,0,0,0,.31,0L17.45,3c.34-.25.48-.24.79.07l1.33,1.33c.28.28.29.42.06.75-.39.56-.78,1.13-1.19,1.69a.33.33,0,0,0,0,.41,4.71,4.71,0,0,1,.39,1,.21.21,0,0,0,.19.17l2.08.34c.43.07.53.17.53.62v1.93a.44.44,0,0,1-.43.51L19,12.12a.26.26,0,0,0-.24.21,5,5,0,0,1-.42,1,.2.2,0,0,0,0,.25l1.26,1.75c.23.32.22.46-.07.75l-1.38,1.38A.52.52,0,0,1,17.83,17.67Z" />
                      <path  className="cls-1" style={{ fill: "none", strokeMiterlimit: "10", strokeWidth: "0.75px", stroke: "currentColor" }} d="M12.36,13.46a3.22,3.22,0,1,1,3.22-3.21A3.21,3.21,0,0,1,12.36,13.46Z" />
                    </g>
                    <path  className="cls-2" style={{ fill: "none", strokeMiterlimit: "10", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} d="M1.39,16.9v3.59A1.58,1.58,0,0,0,3,22.07h18.8a1.57,1.57,0,0,0,1.57-1.58V16.9" />
                  </g>
                </svg>
                <MessageBox text={`Manage Settings`} classname="ms-2 text-break theme-text-color" />
              </div>
              <IoIosArrowDown />
            </div>
            <ul id="Content-Partner" className={`collapse p-0 mt-2 list-unstyled ${settingsContentURL.some((path) => window.location.pathname.startsWith(path.url)) && 'show'}`}   >
              {settingsContentURL?.map((data, index) => <li className=" mb-2" >
                <NavLink to={`${data?.url}`} className="d-flex align-items-center sideMenu position-relative ps-4  pe-3">
                  <MessageBox text={`${data?.message}`} classname="ms-2 text-break theme-text-color" />
                </NavLink>
              </li>)}
            </ul>
          </li>

          <li className="nav-item mb-2">
            <div className={`nav-link  d-flex align-items-center  justify-content-between  sideMenu position-relative ${analyticsContentURL.some((path) => window.location.pathname.startsWith(path.url)) && 'headingActive theme-bg-color-secondary'}`} data-bs-target="#Analytics" data-bs-toggle="collapse" aria-expanded="false" aria-controls="Analytics" >
              <div>
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                  <g>
                    <path  className="cls-1" style={{ fill: "none", strokeMiterlimit: "10", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} d="M22.09,21.41H2.6a1.35,1.35,0,0,1-.92-.92V1" />
                    <g>
                      <polyline  className="cls-1" style={{ fill: "none", strokeMiterlimit: "10", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} points="4.53 18.38 9.25 10.51 12.4 12.87 14.76 8.15 18.69 9.72 22.1 5.63" />
                      <polygon points="22.87 9.5 22.12 9.54 21.92 5.85 18.25 6.32 18.16 5.57 22.63 5 22.87 9.5" />
                    </g>
                  </g>
                </svg>
                <MessageBox text={`Analytics`} classname="ms-2 text-break theme-text-color" />
              </div>
              <IoIosArrowDown />
            </div>
            <ul id="Analytics" className={`collapse p-0 mt-2 list-unstyled ${analyticsContentURL.some((path) => window.location.pathname.startsWith(path.url)) && 'show'}`}   >
              {analyticsContentURL?.map((data, index) => <li className=" mb-2" >
                <NavLink to={`${data?.url}`} className="d-flex align-items-center sideMenu position-relative ps-4  pe-3">
                  <MessageBox text={`${data?.message}`} classname="ms-2 text-break theme-text-color" />
                </NavLink>
              </li>)}
            </ul>
          </li>

          <li className="nav-item  mb-2">
            <div className={`nav-link  d-flex align-items-center  justify-content-between  sideMenu position-relative ${contentPagesURL.some((path) => window.location.pathname.startsWith(path.url)) && 'headingActive theme-bg-color-secondary'}`} data-bs-target="#Pages-Management" data-bs-toggle="collapse" aria-expanded="false" aria-controls="Pages-Management"   >
              <div>
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                  <g>
                    <g>
                      <path  className="cls-1" style={{ fill: "none", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} d="M19.38,3.15a10.37,10.37,0,0,1,3.53,6,7.83,7.83,0,0,1,.16,1.17.42.42,0,0,0,0,.15v1.36l-.12.84a10.16,10.16,0,0,1-1.54,4.12,10.31,10.31,0,0,1-2.09,2.38" />
                      <path  className="cls-1" style={{ fill: "none", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} d="M17.54,5.4A7.55,7.55,0,0,1,20,9.59a7.37,7.37,0,0,1,.16,1.56,7.51,7.51,0,0,1-2.64,5.72" />
                      <path  className="cls-1" style={{ fill: "none", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} d="M15.7,7.65a4.63,4.63,0,0,1,0,7" />
                    </g>
                    <g>
                      <rect  className="cls-1" style={{ fill: "none", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} x="1.55" y="6.27" width="3.33" height="9.77" />
                      <polyline  className="cls-1" style={{ fill: "none", strokeLinejoin: "round", strokeLinecap: "round", strokeWidth: "0.75px", stroke: "currentColor" }} points="5.09 6.23 11.79 1 11.79 21.31 4.87 15.91" />
                    </g>
                  </g>
                </svg>
                <MessageBox text={`Pages`} classname="ms-2 text-break theme-text-color" />
              </div>
              <IoIosArrowDown />
            </div>
            <ul id="Pages-Management" className={`collapse p-0 mt-2 list-unstyled ${contentPagesURL.some((path) => window.location.pathname.startsWith(path.url)) && 'show'}`} >
              {contentPagesURL?.map((data, index) => <li className=" mb-2" >
                <NavLink to={`${data?.url}`} className="d-flex align-items-center sideMenu position-relative ps-4  pe-3">
                  <MessageBox text={`${data?.message}`} classname="ms-2 text-break theme-text-color" />
                </NavLink>
              </li>)}
            </ul>
          </li>

        </ul>
      </div>
    </section>
  );
}

export default ContentSidebar;