import React from "react";
import { FaPlay } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import LazyLoadImage from "./LazyLoadImage";
import { connect } from "react-redux";
import MessageBox from "../../../components/MessageBox"
import { useMyContext } from "../../../components/Layout/CommonProvider";
const HoverCart = ({ thumbnailTitle, labelsettings, thumbnailAge, thumbnailPublishedYear, thumbnailDuration, thumbnailRating, thumbnailCategory, value, segMent, containerClass }) => {
  const { navigateTranslateChecker } = useMyContext()
  const formatDurationFormat = (duration) => {
    if (!duration) return '';
    const parts = duration.split(':');
    const hours = parseInt(parts[0], 10);
    const minutes = parseInt(parts[1], 10);
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}H ${formattedMinutes}M`;
  };
  return (
    <div className={`${containerClass ? containerClass : 'homeSwiperContainer'} hover-card-mobile-none bg-image-gradian global-shadow theme-bg-color-secondary`}>
      <div className="homeListImage bg-image-gradian">
        <LazyLoadImage
          key={value.id}
          src={value?.Player_image_url || value?.Episode_Player_thumbnail || value?.Player_thumbnail || value?.image_url}
          alt={value?.title || value?.name || value?.episode_title}
          title={value?.title || value?.name}
          className="radiusTarget object-fit-cover" imageSize='object-fit-cover'
        />
      </div>
      <div className="homeListContent px-2 pt-3 pb-2">
        <Link to={navigateTranslateChecker(`/${segMent}/${value?.slug ? value?.slug : value?.video_slug ? value?.video_slug : value?.series_slug ? value?.series_slug : value?.artist_slug ? value?.artist_slug : value?.channel_slug}`)}>
          <div className="homeListButton bgButton d-flex align-items-center justify-content-center gap-3"   >
            <FaPlay className="homeListPlayIcon" />
            <p className={`text-decoration-none float-end mb-0 homeListButtonText`}>
              <MessageBox text={`${labelsettings?.F_Slider[0]?.title}`} />
            </p>
          </div>
        </Link>

        <div className="d-flex align-items-center justify-content-between gap-3 mb-2">
          <div className="d-flex align-items-center gap-3 ">
            {thumbnailPublishedYear === 1 && value?.year ? (<span className="homePageCardSpecial d-block theme-text-color position-relative"> {value?.year}</span>)
              : value?.episode_year ? (<span className="homePageCardSpecial d-block theme-text-color position-relative"> {value?.episode_year}</span>)
                : value?.video_year ? (<span className="homePageCardSpecial d-block theme-text-color position-relative"> {value?.video_year}</span>) : ''}
            {thumbnailDuration === 1 && value?.duration ? formatDurationFormat(value?.duration) !== '' && (<span className="homePageCardSpecial d-block theme-text-color position-relative"> {formatDurationFormat(value?.duration)} </span>)
              : value?.episode_duration ? formatDurationFormat(value?.episode_duration) !== '' && (<span className="homePageCardSpecial d-block theme-text-color position-relative"> {formatDurationFormat(value?.episode_duration)} </span>)
                : value?.video_duration ? formatDurationFormat(value?.video_duration) !== '' && (<span className="homePageCardSpecial d-block theme-text-color position-relative"> {formatDurationFormat(value?.video_duration)} </span>) : ''}
            {thumbnailRating === 1 && value?.rating ? (<span className="homePageCardSpecial d-block theme-text-color position-relative">{value?.rating} </span>) : ''}
            {thumbnailCategory === 1 && value?.categories ? (<span className="homePageCardSpecial d-block theme-text-color position-relative"> {value?.categories}</span>) : ''}
            {thumbnailAge === 1 && value?.age_restrict ? (<span className="homePageCardSpecial d-block theme-text-color position-relative">{value?.age_restrict} +</span>) : ''}
          </div>
          <Link to={navigateTranslateChecker(`/${segMent}/${value?.slug ? value?.slug : value?.artist_slug ? value?.artist_slug : value?.channel_slug}`)} className="homePageCardSpecial d-block theme-text-color position-relative "><MessageBox text="More" /> </Link>
        </div>
        {thumbnailTitle === 1 ? (
          <>
            {value?.title && (<MessageBox classname="homePageCardHeading theme-text-color " text={value?.title}></MessageBox>)}
            {value?.name && (<MessageBox classname="homePageCardHeading theme-text-color " text={value?.name}></MessageBox>)}
            {value?.episode_title && (<MessageBox classname="homePageCardHeading theme-text-color " text={value?.episode_title}></MessageBox>)}
            {value?.video_title && (<MessageBox classname="homePageCardHeading theme-text-color " text={value?.video_title}></MessageBox>)}
          </>
        ) : (
          ""
        )}
        {value.description && <MessageBox classname="d-block homeListDesc theme-text-color  mb-0  mt-2 opacity-75" text={value.description} />}
        {value?.episode_description && <MessageBox classname="d-block homeListDesc theme-text-color mb-0  mt-2 opacity-75" text={value.episode_description} />}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  thumbnailTitle: state.get_allThumbnail_Reducer.thumbnailTitle,
  thumbnailAge: state.get_allThumbnail_Reducer.thumbnailAge,
  thumbnailPublishedYear: state.get_allThumbnail_Reducer.thumbnailPublishedYear,
  thumbnailDuration: state.get_allThumbnail_Reducer.thumbnailDuration,
  thumbnailRating: state.get_allThumbnail_Reducer.thumbnailRating,
  thumbnailCategory: state.get_allThumbnail_Reducer.thumbnailCategory,
  labelsettings: state.labelSettings.label
});
export default connect(mapStateToProps)(HoverCart);
