// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.musicHomePageSwiper .swiper-slide {
    height: 250px;
    border-radius: 0.375rem;
}

.musicCategorySwiper .swiper-slide {
    aspect-ratio: 16/9;
}

.musicAlbumSwiper .swiper-slide {
    height: 200px;
    border-radius: 0.375rem;
}

.musicHomePageSwiper .swiper-button-prev::after,
.musicHomePageSwiper .swiper-button-next::after,
.musicCategorySwiper .swiper-button-prev::after,
.musicCategorySwiper .swiper-button-next::after,
.musicAlbumSwiper .swiper-button-prev::after,
.musicAlbumSwiper .swiper-button-next::after {
    font-size: 22px;
    font-weight: bold;
}

.musicHomePageSwiper .swiper-button-prev,
.musicHomePageSwiper .swiper-button-next,
.musicCategorySwiper .swiper-button-prev,
.musicCategorySwiper .swiper-button-next,
.musicAlbumSwiper .swiper-button-prev,
.musicAlbumSwiper .swiper-button-next {
    top: 0;
    color: #fff;
    z-index: 3;
    height: 100%;
}


.musicImageDetailsHeight {
    height: 150px !important;
}

.musicDetailsImage {
    height: 150px !important;
}

.musicImageDetailsSmallHeight {
    height: 100px !important;
}

.musicDetailsSmallImage {
    height: 100px !important;
}

.musicDetailsSwiper .swiper-button-next:after,
.musicDetailsSwiper .swiper-button-prev:after {
    font-size: 23px;
    color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/Styles/musicSwiper.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;;;;;;IAMI,eAAe;IACf,iBAAiB;AACrB;;AAEA;;;;;;IAMI,MAAM;IACN,WAAW;IACX,UAAU;IACV,YAAY;AAChB;;;AAGA;IACI,wBAAwB;AAC5B;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;;IAEI,eAAe;IACf,WAAW;AACf","sourcesContent":[".musicHomePageSwiper .swiper-slide {\n    height: 250px;\n    border-radius: 0.375rem;\n}\n\n.musicCategorySwiper .swiper-slide {\n    aspect-ratio: 16/9;\n}\n\n.musicAlbumSwiper .swiper-slide {\n    height: 200px;\n    border-radius: 0.375rem;\n}\n\n.musicHomePageSwiper .swiper-button-prev::after,\n.musicHomePageSwiper .swiper-button-next::after,\n.musicCategorySwiper .swiper-button-prev::after,\n.musicCategorySwiper .swiper-button-next::after,\n.musicAlbumSwiper .swiper-button-prev::after,\n.musicAlbumSwiper .swiper-button-next::after {\n    font-size: 22px;\n    font-weight: bold;\n}\n\n.musicHomePageSwiper .swiper-button-prev,\n.musicHomePageSwiper .swiper-button-next,\n.musicCategorySwiper .swiper-button-prev,\n.musicCategorySwiper .swiper-button-next,\n.musicAlbumSwiper .swiper-button-prev,\n.musicAlbumSwiper .swiper-button-next {\n    top: 0;\n    color: #fff;\n    z-index: 3;\n    height: 100%;\n}\n\n\n.musicImageDetailsHeight {\n    height: 150px !important;\n}\n\n.musicDetailsImage {\n    height: 150px !important;\n}\n\n.musicImageDetailsSmallHeight {\n    height: 100px !important;\n}\n\n.musicDetailsSmallImage {\n    height: 100px !important;\n}\n\n.musicDetailsSwiper .swiper-button-next:after,\n.musicDetailsSwiper .swiper-button-prev:after {\n    font-size: 23px;\n    color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
