import React from "react";
import { FaCrown } from "react-icons/fa";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMyContext } from "./Layout/CommonProvider";

const PriceLabel = ({ item, thumbnailFreeORCostLabel, className }) => {
    const { t } = useTranslation();
    const { priceData } = useMyContext();

    const renderPriceLabel = () => {
        if (priceData?.loading) {
            return <p className="priceText d-flex align-items-center">{t("Loading...")}</p>;
        }
        const renderCurrencyPrice = (price) => {
            const symbol = priceData?.defaultCurrency?.enable_multi_currency === 1 ? priceData?.convertSymbol || '' : priceData?.defaultCurrency?.symbol;
            return <p className="priceText d-flex align-items-center">{symbol} {Number(price).toFixed(2)}</p>;
        };

        switch (item?.access) {
            case "guest":
                return <p className="priceText d-flex align-items-center">{t("Free")}</p>;
            case "subscriber":
                return <p className="priceText d-flex align-items-center"><FaCrown style={{ width: "18px", height: "18px", color: "gold" }} /></p>;
            case "registered":
                return <p className="priceText d-flex align-items-center">{t("Registered")}</p>;
            default:
                const price = item?.ppv_price || item?.global_ppv || item?.video_ppv_price;
                if (price !== null) {
                    const finalPrice = priceData?.defaultCurrency?.enable_multi_currency === 1 ? priceData?.ppvPrice * price : price;
                    return renderCurrencyPrice(finalPrice);
                }
                return null;
        }
    };
    return (
        thumbnailFreeORCostLabel ?
            <div className={`${className ? className : 'position-absolute priceLabelRadius theme-button-bg-color theme-button-text-color '} priceLabel`} >
                {renderPriceLabel()}
            </div> : ""
    )
};

const mapStateToProps = state => ({
    thumbnailFreeORCostLabel: state.get_allThumbnail_Reducer.thumbnailFreeORCostLabel
});

export default connect(mapStateToProps)(PriceLabel);