import React, { useEffect, useState } from 'react'
import BannerPlayer from './BannerPlayer'
import { connect, useDispatch } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, EffectCoverflow } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-cube';
import "swiper/css/effect-coverflow";
import "../../Styles/Banner.css"
import BannerImage from './BannerImage';
import { Fragment } from 'react';
const BannerIndex = ({ sliderData, isLoading, error }) => {
    const [activeIndexOur, setActiveIndex] = useState(0);
    const handleSlideChange = (swiper) => {
        setActiveIndex(swiper.activeIndex)
    };
    const swiperConfig = {
        slidesPerView: 1,
        spaceBetween: 10,
        navigation: true,
        // lazy: true,
        centeredSlides: true,
        effect: 'coverflow',
        grabCursor: true,
        loop: false,
        // autoplay: false,
        autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        },
        coverflowEffect: {
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 2.5
        },
        breakpoints: {
            769: { slidesPerView: 2, spaceBetween: 10 },
        },
        modules: [Autoplay, Navigation, EffectCoverflow],
        className: 'homePageBannerSwiperMulti',
        // onSlideChange: { handleSlideChange }
    }



    return (
        <div className=''>
            {isLoading ? <Swiper Swiper {...swiperConfig} >
                {
                    Array.from({ length: 8 }).map((_, index) => (
                        <SwiperSlide key={index} className='' >
                            <div className={`${index} bannerContainer w-100 h-100 theme-bg-color-secondary`} key={index / 2}></div>
                        </SwiperSlide>))
                }
            </Swiper > :
                <Swiper {...swiperConfig}>
                    {sliderData?.length > 0 && sliderData.map((item, index) => (
                        <Fragment key={item.id}>
                            <SwiperSlide key={item.id + index}>
                                {item.trailer_videos_url || item.trailer_link
                                    ? <BannerPlayer data={item} sliderCurrentIndex={activeIndexOur} page="home" />
                                    : <BannerImage data={item} page="home" />}
                            </SwiperSlide>
                        </Fragment>
                    ))}
                </Swiper>
            }
        </div >
    )
}

const mapStateToProps = state => ({
    sliderData: state.get_HomeSlider_Reducer.sliderData,
    isLoading: state.get_HomeSlider_Reducer.isLoading,
    error: state.get_HomeSlider_Reducer.error,

});

export default connect(mapStateToProps)(BannerIndex)