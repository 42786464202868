// External Libraries and Packages
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { useTranslation } from 'react-i18next';
import live from "../../assets/images/live-indicater.webp"
// Internal Styles
import "swiper/css";
import "swiper/css/navigation";
import { URLGenerater } from '../CommonMethods/CommonMethods';
import PriceLabel from '../../components/PriceLabel';
import LazyLoadImage from '../HomePage/HomeSwiperComponents/LazyLoadImage';
import { useMyContext } from '../../components/Layout/CommonProvider';
// Internal Components

const PartnerLiveStream = ({ item }) => {

    const { source } = useParams();
    const { t } = useTranslation();
    const { navigateTranslateChecker } = useMyContext()
    return (
        <div className=' pt-1 zIndex'>
            {
                item?.data.length > 0 && (<>
                    <Link to={navigateTranslateChecker(`/${URLGenerater(item?.source)}`)} className="blocklinetitle text-decoration-none d-inline-block swiperHeading theme-text-color" aria-label={`View all ${item?.header_name}`}>{t(item?.header_name)} </Link>
                    <Link to={navigateTranslateChecker(`/${URLGenerater(item?.source)}`)} className="blocklinetitle text-decoration-none float-end swiperHeading theme-text-color viewall-homepage" aria-label={`View all ${item?.header_name}`}>{t('View All')}</Link>
                </>)
            }
            <div className="">
                <Swiper
                    slidesPerView={1}
                    spaceBetween={30}
                    navigation={true}
                    lazy={true}
                    slidesPerGroup={1}
                    breakpoints={{
                        576: {
                            slidesPerView: 2,
                            spaceBetween: 30,
                            slidesPerGroup: 2,
                        },
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 30,
                            slidesPerGroup: 3,
                        },

                        1200: {
                            slidesPerView: 4,
                            spaceBetween: 30,
                            slidesPerGroup: 4,
                        },
                        1440: {
                            slidesPerView: 4,
                            spaceBetween: 30,
                            slidesPerGroup: 4,
                        }

                    }}
                    modules={[Navigation]}
                    className={`homePageSwiper livestream`}
                >
                    {item?.data.map((value, index) => (
                        <SwiperSlide key={value?.id} className='theme-bg-color-secondary' >
                            <PriceLabel item={value} />
                            <Link to={navigateTranslateChecker(`/live/${value?.slug}`)} className={`text-decoration-none  swiperHeading theme-text-color  d-block w-100 h-100 categoryHover `}>
                                <LazyLoadImage key={index} src={value?.image_url} alt={value?.title} title={value?.title} classNameOptional="loaderMain " imageSize="object-fit-cover rounded-2" />
                                <span className="live_Indicator"> <img src={live} className="w-100" alt="Live_stream" /> </span>
                                {/* <LazyLoadImage key={index} src={value?.image_url} alt={value?.title} title={value?.title} classNameOptional="rounded-3" imageSize="object-fit-cover loaderMain " /> */}
                                {/* <div className="homeSwiperContainer">
                          <img
                            src={value.image_url}
                            className="homeListImage"
                            loading="lazy"
                          />
                          <div className="homeListContent p-3">
                            <button className="homeListButton d-flex align-items-center justify-content-center">
                              <FaPlay className="homeListPlayIcon" />
                              <p className="ms-2 mb-0 homeListButtonText">
                                Watch now
                              </p>
                            </button>
                            <p className="homeListText text-start">
                              {" "}
                              {value.title}{" "}
                            </p>
                            <p className="homeListDesc text-start">
                              In publishing and graphic design, Lorem ipsum is a
                              placeholder text commonly used.{" "}
                            </p>
                          </div>
                        </div> */}
                            </Link>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    )
}

export default PartnerLiveStream