// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profileThemeTwo.active::before {
    position: absolute;
    content: "";
    bottom: 14px;
    left: 55px;
    height: 3px;
    border-radius: 20px;
    width: 25px;
}

.profileThemeTwo.active {
    opacity: 1;
}

.profileThemeTwo.active .popupDetailLink {
    font-weight: bold;
}

.profileThemeTwo {
    padding: 17px 20px;
}`, "",{"version":3,"sources":["webpack://./src/Styles/AccountPageThemeTwo.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,UAAU;IACV,WAAW;IACX,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".profileThemeTwo.active::before {\n    position: absolute;\n    content: \"\";\n    bottom: 14px;\n    left: 55px;\n    height: 3px;\n    border-radius: 20px;\n    width: 25px;\n}\n\n.profileThemeTwo.active {\n    opacity: 1;\n}\n\n.profileThemeTwo.active .popupDetailLink {\n    font-weight: bold;\n}\n\n.profileThemeTwo {\n    padding: 17px 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
