import React from 'react';
import AdvertiserPanel from './AdvertiserPanel';


const AdvertiserDashBoard = () => {

    const labelOne = ['AdMixer 2 Pos', 'AdMixer 1 Pre'];
    const labelTwo = ['AdMixer 2 Pos', 'AdMixer 1 Pre',];

    const optionOne = {
        responsive: true,
        plugins: {
            legend:
            {
                position: 'top',
            },
            title:
            {
                display: true,
                text: 'Advertisements'
            }
        },
    };

    const optionsTwo = {
        responsive: true,
        plugins: {
            legend:
            {
                position: 'top',
            },
            title:
            {
                display: true,
                text: 'Advertisements'
            }
        },
    };

    const dataOne = {
        labelOne,
        datasets: [
            {
                label: 'Cost Per Click Analysis',
                // data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'blue',
            },

        ],
    };

    const dataTwo = {
        labelTwo,
        datasets: [
            {
                label: 'Cost Per View Analysis',
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
        ],
    };


    return (
        <AdvertiserPanel>
            <div className='theme-bg-color rounded-3 '>
                <div className="d-flex flex-wrap">
                    <div className="col-12 col-sm-12 col-md-6 ">

                    </div>
                    <div className="col-12 col-sm-12 col-md-6 ">

                    </div>
                </div>
            </div>
        </AdvertiserPanel>
    )
}

export default AdvertiserDashBoard