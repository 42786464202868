import React, { useEffect } from "react";
import BannerIndex from "../../components/Banner/BannerIndex";
import BannerPagination from "../../components/Banner/BannerPagination";
import { connect } from "react-redux";
import AllHomePage from "./AllHomePage";
import { homeBannerAction } from "../../Redux/Actions/apiActions";
import { useDispatch } from "react-redux";
const HomePage = ({ currentTheme, settings }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (settings && Object.keys(settings).length > 0) {
      dispatch(homeBannerAction());
    }
  }, [settings, dispatch]);

  return (
    <div className="heightForChild">
      {currentTheme == 1 && <BannerIndex />}
      {currentTheme == 2 && <BannerPagination />}
      <AllHomePage />
    </div>
  );
};
const mapStateToProps = (state) => ({
  currentTheme: state.get_siteTheme_Reducer.currentTheme,
  settings: state.get_setting_Reducer.settings,
});
export default connect(mapStateToProps)(HomePage);

