import React, { useState, useEffect, useRef } from 'react';
import { epg } from "../JSON/epg"
import "../Styles/OwnSchedule.css"
import { IconMyNextArrow, IconMyPrevArrow } from '../assets/icons/MyIcons';
import { connect } from 'react-redux';
const EpgComponent = ({ languageDetailsData }) => {
  const [startDate, setStartDate] = useState(new Date());
  const elementRef = useRef(null);
  const [timelineLengthInHours, setTimelineLengthInHours] = useState(24);


  const [userStartDate, setUserStartDate] = useState(new Date());
  const [userEndDate, setUserEndDate] = useState(new Date(new Date().setDate(new Date().getDate() + 1))); //end date plus 1 data increment
  const [filteredTimeline, setFilteredTimeline] = useState();
  const currentIndicate = startDate.getHours() * 250 + startDate.getMinutes() * (250 / 60) + 138;

  useEffect(() => {
    userEndDate.setHours(23, 59, 59, 999)
  }, [timelineLengthInHours]);
  const currenttime = new Date()
  useEffect(() => {

    const setDefaultScroll = () => {
      if (elementRef.current) {
        elementRef.current.scrollLeft = currenttime.getHours() * 215;
      }
    };
    setDefaultScroll();
  }, [currentIndicate, currenttime]);

  useEffect(() => {
    filterEvents(userStartDate, userEndDate)
  }, [userStartDate, userEndDate])



  const handleHorizantalScroll = (element, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }

    },);
  };

  const calculatePosition = (time, totalHoursNext) => {
    calculateTimelineLength()
    const pixelsPerHour = 250
    const positionInPixels = (totalHoursNext * pixelsPerHour)
    return positionInPixels;
  };

  const calculateWidth = (startTime, endTime) => {
    calculateTimelineLength()
    const durationInHours = (endTime - startTime) / (60 * 60 * 1000);
    const pixelsPerHour = 250;
    const widthInPixels = durationInHours * pixelsPerHour;
    return widthInPixels;
  };

  const ScheduleItem = ({ startTime, endTime, title, image, totalHoursNext }) => {
    const startPosition = calculatePosition(startTime, totalHoursNext)
    const width = calculateWidth(startTime, endTime);
    const currentTime = new Date();
    const isActive = currentTime >= startTime && currentTime <= endTime;
    const positionStyle = languageDetailsData?.Language_Code === 'ar' ? "right" : "left";
    return (

      <div
        className={`flickNexsListItem ${isActive ? 'active' : ''}`}
        id={`${startPosition}`}
        style={{ [positionStyle]: `${startPosition}px`, width: `${width}px` }}
      >
        <img src={image} alt="Preview" className="trackImage" />
        <span className="">
          <p className="flickNexsTitle">{title}</p>
          <span className="flickNexsTitle">{startTime.getHours()} - {endTime.getHours()}</span>
          <span className="flickNexsTitle d-block">{startTime.toLocaleDateString()} - {endTime.toLocaleDateString()}</span>
        </span>
      </div>
    )
  }


  const filterEvents = (userStartDate, userEndDate) => {
    const filteredData = epg.timeline.map((channel) => {
      const filteredChannelData = channel.data.filter((event) => {
        const eventStartDate = new Date(event.since);
        const eventEndDate = new Date(event.till);
        const eventStartDateWithoutTime = new Date(eventStartDate.getFullYear(), eventStartDate.getMonth(), eventStartDate.getDate());
        const eventEndDateWithoutTime = new Date(eventEndDate.getFullYear(), eventEndDate.getMonth(), eventEndDate.getDate());
        const startDateWithoutTime = new Date(userStartDate.getFullYear(), userStartDate.getMonth(), userStartDate.getDate());
        const endDateWithoutTime = new Date(userEndDate.getFullYear(), userEndDate.getMonth(), userEndDate.getDate());
        return (
          eventStartDateWithoutTime >= startDateWithoutTime &&
          eventStartDateWithoutTime <= endDateWithoutTime &&
          eventEndDateWithoutTime >= startDateWithoutTime &&
          eventEndDateWithoutTime <= endDateWithoutTime
        );
      });

      return { ...channel, data: filteredChannelData };
    });

    setFilteredTimeline(filteredData);
  };

  console.log("filerddatatafds", filteredTimeline)

  const formatHour = (hourIndex) => {
    const startHour = 0;
    const formattedHour = (startHour + hourIndex) % 24;
    const amPm = formattedHour >= 12 ? 'pm' : 'am';
    const displayHour = formattedHour % 12 || 12;
    return `${displayHour}:00 ${amPm}`;
  };

  const calculateTimelineLength = () => {
    const startDateTime = new Date(userStartDate);
    startDateTime.setHours(0, 0, 0, 0);
    const endDateTime = new Date(userEndDate);
    endDateTime.setHours(23, 59, 59, 999);
    const timeDifference = Math.abs(endDateTime - startDateTime);
    const hoursDiff = timeDifference / (1000 * 60 * 60);
    const timelineLengthInHours = Math.ceil(hoursDiff);
    setTimelineLengthInHours(timelineLengthInHours)
  }

  const calculateDayIndex = (eventStartDate) => {
    const startDateTime = new Date(userStartDate)
    startDateTime.setHours(0, 0, 0, 0);
    const timeDifference = eventStartDate - startDateTime
    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
    return hoursDifference;
  };

  function DateRangeSelector({ onSelectDateRange }) {
    const handleDateRangeChange = () => {
      onSelectDateRange(userStartDate, userEndDate);
    };
    return (
      <>
        <input type="date" className='dayPicker picker' min={(new Date()).toISOString().split('T')[0]} value={userStartDate.toISOString().split('T')[0]} max={(new Date(userEndDate)).toISOString().split('T')[0]} onChange={(e) => setUserStartDate(new Date(e.target.value))} />
        <input type="date" className='dayPicker picker' min={(new Date(userStartDate.getTime() + 24 * 60 * 60 * 1000)).toISOString().split('T')[0]} value={userEndDate.toISOString().split('T')[0]} onChange={(e) => setUserEndDate(new Date(e.target.value))} />
        <button onClick={handleDateRangeChange} className="dayPicker">Apply</button>
      </>
    );
  }
  return (
    <div  className="px-3 px-lg-5 paddingTop">
      <div className='d-flex align-items-center  flex-wrap justify-content-between gap-3 my-3 '>
        <div className='d-flex align-items-center gap-2  flex-wrap'>
          <DateRangeSelector onSelectDateRange={filterEvents} />
        </div>
        <div>
          <div className="d-flex align-items-center gap-2 justify-content-end flex-reverse ">
            <button onClick={() => { handleHorizantalScroll(elementRef.current, 300, -300); }} className="rounded-circle p-1 border-0 prev" style={{ width: "33px", height: "33px" }}  >
              <IconMyPrevArrow />
            </button>
            <button onClick={() => { handleHorizantalScroll(elementRef.current, 300, 300); }} className="rounded-circle  p-1 border-0 next " style={{ width: "33px", height: "33px" }}
            >
              <IconMyNextArrow />
            </button>
          </div>
        </div>
      </div>

      <div className='mainTrack' ref={elementRef}>
        <div className='flickNexsIndicater' style={{ left: `${currentIndicate}px` }}> </div>

        <div className='flickNexsSideBar'>
          <h4 className='timeLineChannelNameHide'></h4>
          {epg.timeline?.map((eventGroup, index) => (
            <h4 className='timeLineChannelName theme-text-color' style={{ top: `${index * 95 + 108}px` }} >{eventGroup.ChannelName}</h4>
          ))}
        </div>

        <div>
          <div className='flickNexsTimeLine'>
            {Array.from({ length: timelineLengthInHours / 24 }).map((_, dayIndex) => {
              const currentDate = new Date(userStartDate);
              currentDate.setDate(userStartDate.getDate() + dayIndex);
              currentDate.setHours(0, 0, 0, 0);
              const hoursSinceStart = dayIndex * 24;
              return (
                <div key={dayIndex} className={`flicknexsDayMaker ${dayIndex} ${timelineLengthInHours}`}>
                  <div className="flickNexsTimeLineMins">
                    <span className='flickNexsDateShow'>{currentDate.toLocaleDateString()}</span>
                    {Array.from({ length: 24 }).map((_, hourIndex) => (
                      <div key={hourIndex} className={` flickNexs24Hours ${hourIndex + hoursSinceStart}`}>
                        <span className='timeValues'>{formatHour(hourIndex + hoursSinceStart)}</span>
                        <div className='flickNexs15MinsIndicater'>
                          <div className='timeLineBreakPoint'></div>
                          <div className='timeLineBreakPoint'></div>
                          <div className='timeLineBreakPoint'></div>
                          <div className='timeLineBreakPoint'></div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

              );
            })}
          </div>
          <div className='flicknexsListBox'>
            {filteredTimeline?.map((eventGroup, index) => (
              <>
                <div key={eventGroup.id} className={`position-absolute`} style={{ top: `${index * 84}px` }} >
                  {eventGroup.data.map((event) => (
                    <ScheduleItem
                      key={event.id}
                      startTime={new Date(event.since)}
                      endTime={new Date(event.till)}
                      title={event.title}
                      image={event.image}
                      totalHoursNext={calculateDayIndex(new Date(event.since))}
                    />
                  ))}
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = state => ({
  languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData
});
export default connect(mapStateToProps)(EpgComponent)

