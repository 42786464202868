// External Libraries and Packages
import React from 'react'
import { Link } from 'react-router-dom'
// Internal Styles
import "swiper/css";
import "swiper/css/navigation";
// Internal Components
import { useTranslation } from 'react-i18next';
import LazyLoadImage from '../../HomePage/HomeSwiperComponents/LazyLoadImage';
import { URLGenerater } from '../../CommonMethods/CommonMethods';
import { useMyContext } from '../../../components/Layout/CommonProvider';


const ShowCategoryAll = ({ item }) => {
    const { t } = useTranslation();
    const { navigateTranslateChecker } = useMyContext()
    return (
        <div className='pt-1 zIndex'>
            {item?.data?.length > 0 && (<>
                <Link to={navigateTranslateChecker(`/${item.source == "liveCategories" ? 'live-categories' : item.source == 'Series_Genre' ? 'series-categories' : item.source == 'videoCategories' ? 'video-categories' : item.source === "Audio_Genre" ? "audios-categories" : URLGenerater(item?.source)}`)} className="blocklinetitle text-decoration-none  d-inline-block  swiperHeading theme-text-color" aria-label={`View all ${item?.header_name}`}>{t(item?.header_name)}</Link>
                <Link to={navigateTranslateChecker(`/${item.source == "liveCategories" ? 'live-categories' : item.source == 'Series_Genre' ? 'series-categories' : item.source == 'videoCategories' ? 'video-categories' : item.source === "Audio_Genre" ? "audios-categories" : URLGenerater(item?.source)}`)} className="blocklinetitle text-decoration-none  float-end  swiperHeading theme-text-color viewall-homepage" aria-label={`View all ${item?.header_name}`}>{t('View All')}</Link>
            </>)
            }
            <div className='flex-wrap gridCategory '>
                {item?.data.map((value, index) => (
                    <div className='mb-3 gridCategoryItems w-100 theme-bg-color-secondary rounded-3' key={value?.id}>
                        <Link to={navigateTranslateChecker(`/${item.source == "liveCategories" ? 'live-categories' : item.source == 'Series_Genre' ? 'series-categories' : item.source == 'videoCategories' ? 'video-categories' : item.source === "Audio_Genre" ? "audios-categories" : URLGenerater(item?.source)}/${value?.slug}`)} className="text-decoration-none  swiperHeading theme-text-color  d-block w-100 h-100 categoryHover">
                            <LazyLoadImage key={index} src={value?.image_url} alt={value?.title} title={value.title} classNameOptional="rounded-3 w-100" imageSize={`${item.header_name == "Videos Categories" ? 'object-fit-contain' : "object-fit-cover"} gridCategoryImage mx-auto loaderMain `} />
                            <span className='languageText theme-text-color text-center '>{t(value?.name)} </span>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ShowCategoryAll