// External Libraries and Packages
import { connect, useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import { MdClose } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom"

// Internal Styles
import "../Styles/musicPlayer.css";
import "../Styles/progressBar.css";
// Internal Components
import defaultAudioImage from "../assets/images/default_vertical_image.webp";
import ToolTip from "../components/ToolTip";
import { getItemToken, getItem } from "../Utils/localStorageUtils"
import { musicDetailsUpdate } from "../Redux/Actions/MusicAction"
import { musicViewsCount } from "../components/Api/MusicApi"
import { LikeAction, disLikeAction, wishListAction } from "../Pages/CommonMethods/CommonMethods";
import MiniPrograssBar from "../Pages/Music/MiniPrograssBar";
import { useMyContext } from "../components/Layout/CommonProvider";
import axios from "axios";
import MessageBox from "../components/MessageBox";
import { IconMyAddPlaylist, IconMyDisLike, IconMyDisLikeFill, IconMyHeart, IconMyHeartFill, IconMyLike, IconMyLikeFill, IconMyLoop, IconMyMic, IconMyMini, IconMyPause, IconMyPlay, IconMyPlayPrev, IconMyShuffle, IconMyVolumn, IconMyVolumnMute } from "../assets/icons/MyIcons";
import LazyLoadImage from "../Pages/HomePage/HomeSwiperComponents/LazyLoadImage";


const FloatAudioPlayer = ({ playlistAudio, currentAudio, currentToggle, currentPlaying, currentIndex, currentRepeat, getMusicData, initialModal, currentUserInteract, languageDetailsData, playlistShow }) => {

  const { navigateTranslateChecker, shufflePlaylist, handlePlayPause, handleVolume, handlePlayNext, handlePlayPrev, handleSeek, handleEnableLoop, minimizePlayer, prograssBarPercentage, duration, currentTime, readyState, volume, audioReference } = useMyContext();

  const userRoleLocal = getItem('role');
  const userIdLocal = getItem('user_id');
  const userAccessToken = getItemToken('access_token');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [volumns, setVolume] = useState(0)
  const [audioCalled, setAudioCalled] = useState(false);
  const [playerMin, setPlayerMin] = useState(false)

  const excludedPathsCloseIcon = ["/music"];

  const isPathExcluded = (excludedPaths) =>
    excludedPaths.some((path) => {
      // Check if the path is in the format "/:lang/music"
      const regex = new RegExp(`^/\\w+/music`);
      return regex.test(window.location.pathname);
    });

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handleClickWishList = async () => {
    if (currentAudio) {
      try {
        await wishListAction(userIdLocal || null, userRoleLocal || 9, 'web', currentAudio?.id, 5, userAccessToken || null);
        await dispatch(musicDetailsUpdate('AUDIO', currentUserInteract?.slug))
      } catch (error) {
        console.error(error);
      }
    }
    else {
      return null
    }
  }

  const handleLike = async () => {
    if (currentAudio?.id) {
      try {
        const likeStatus = currentUserInteract?.like ? 0 : 1;
        await LikeAction(userIdLocal || null, userRoleLocal || 9, 'web', currentUserInteract?.id, 5, likeStatus, userAccessToken || null);
        await dispatch(musicDetailsUpdate('AUDIO', currentUserInteract?.slug))
      } catch (error) {
        console.error(error);
      }
    }
    else {
      return null
    }
  };

  const handleDisLike = async () => {
    if (currentAudio?.id) {
      try {
        const likeStatus = currentUserInteract?.like ? 1 : 0;
        await disLikeAction(userIdLocal || null, userRoleLocal || 9, 'web', currentUserInteract?.id, 5, likeStatus, userAccessToken || null);
        await dispatch(musicDetailsUpdate('AUDIO', currentUserInteract?.slug))

      } catch (error) {
        console.error(error);
      }
    }
    else {
      return null
    }
  };

  const handleLyrics = () => {
    if (currentAudio?.id) {
      dispatch({
        type: "GET_TOGGLE_SUCCESS",
        toggle: !currentToggle,
      });
      if (currentToggle && window.location.pathname !== navigateTranslateChecker(`/music`)) {
        navigate(-1);
      } else {
        navigate(navigateTranslateChecker(`/music/lyrics`));
      }
    }
    else {
      return null
    }
  };

  const handlePlaylistModal = () => {
    if (currentAudio?.id) {
      dispatch({ type: "OPEN_INITIAL_MODAL", payload: true })
      dispatch({ type: "GET_PLAYLIST_STORE_ID", payload: null })
    }
    else {
      return null
    }
  };

  const handleShowPlaylist = () => {
    if (currentAudio?.id) {
      dispatch({ type: "OPEN_PLAYLIST", payload: !playlistShow })
    }
    else {
      return null
    }
  }

  useEffect(() => {
    const roundedTime = Math.floor(currentTime);
    if (roundedTime >= 30000) {
      setAudioCalled(true);
    }
    else {
      setAudioCalled(false);
    }
    if (roundedTime >= 30000 && !audioCalled) {
      const data = {
        Login_user_type: userRoleLocal || 9,
        Login_device_type: "web",
        Login_user_id: userIdLocal || null,
        source_type: 5,
        source_id: currentAudio?.id
      };
      axios.post(musicViewsCount, data)
        .then(response => {
          // console.log('API call success:', response);
          setAudioCalled(true);
        })
        .catch(error => {
          console.error('API call error:', error);
        });
    } else if (roundedTime < 30000) {
      // console.log("error audio content");
    }
  }, [currentTime, audioCalled]);

  const handleVolumeChange = (event) => {
    const newVolume = parseFloat(event.target.value);
    setVolume(newVolume);
    audioReference.current.volume = newVolume;
  };

  const handleClose = () => {
    dispatch({ type: "GET_CLOSE_SUCCESS", isClose: false });
    handlePlayPause()
  };


  return (
    <div className={`music-player theme-bg-color slide-bottom ${currentAudio?.id}`}>
      {/* {initialModal && <PlaylistModal />} */}
      <div className={`w-100`}> <MiniPrograssBar handleTime={handleSeek} progressValue={prograssBarPercentage} duration={duration} readyState={readyState} /> </div>
      <div className="d-flex flex-wrap align-items-center justify-content-between px-2 px-lg-4 py-2 w-100">
        <div className="col-6 col-sm-5 col-md-3 ">
          <div className={`d-flex align-items-center justify-content-between gap-3`}>
            {getMusicData?.enable_track_image ? <LazyLoadImage key={'logo'} src={currentAudio?.image_url ? currentAudio?.image_url : currentAudio?.Thumbnail ? currentAudio?.Thumbnail : defaultAudioImage} alt={'logo'} title={currentAudio?.title?.slice(0, 1)} imageSize="rounded-2  albumArtImage overflow-hidden  object-fit-cover" classNameOptional="rounded-2 albumArtImage" /> : null}
            <div className="col-4 col-lg-4 overflow-hidden flex-fill">
              {getMusicData?.enable_track_title ? <MessageBox classname={`${currentAudio?.title ? '' : 'theme-bg-color-secondary rounded-2'}  playerTrackTitle theme-text-color mb-0 mt-0 fw-bold`} text={currentAudio?.title ? currentAudio?.title : ""}> </MessageBox> : null}
              {getMusicData?.enable_track_artists ? <span className={`${currentAudio?.id ? 'miniPlayerText' : 'theme-bg-color-secondary miniPlayerTextNone rounded-2'} `}>{currentAudio?.artists && currentAudio?.artists.map((item, index) => <Link to={navigateTranslateChecker(`/music/artist/${item.artist_slug}`)} className="audioLink theme-text-color audioListNameTrack" >{item.name}</Link>)}</span> : null}
            </div>
            <div className={` d-sm-block d-none theme-text-color p-0 ${currentUserInteract ? "" : 'opacity-75'}`}>
              <button type="button" onClick={handleClickWishList} className="bg-transparent theme-text-color">
                {currentUserInteract?.wishlist ? <IconMyHeartFill styled={{ width: "16px", height: "16px", className: "musicSideBarIcons" }} /> : <IconMyHeart styled={{ width: "16px", height: "16px", className: "musicSideBarIcons" }} />}
              </button>
            </div>
          </div>
        </div>

        <div className="col-6 col-sm-5 col-md-8 col-lg-6 ">
          <div className="d-flex align-items-center justify-content-center ">

            <button className={`bg-transparent theme-text-color  playButton border-0 p-0 marginER d-none d-md-flex  toolTipParent ${currentToggle ? "yes " : "no"}  ${currentAudio?.id ? "" : 'opacity-75'}`} onClick={handleLyrics} >
              <IconMyMic styled={{ width: "20px", height: "20px", className: "musicSideBarIcons " }} />
              <ToolTip bgColor="#000" text="Lyrics" top="-43px" left="-14px" className="px-3 py-2 rounded-2 position-absolute d-none" />
            </button>

            <button type="button" className={`playButton bg-transparent  d-none d-md-block theme-text-color p-0 marginER toolTipParent ${currentUserInteract?.id ? '' : 'opacity-75'}  `} onClick={handleLike}>
              {currentUserInteract?.like !== 1 ? <IconMyLike styled={{ width: "20px", height: "20px", className: "musicSideBarIcons" }} />
                : <IconMyLikeFill styled={{ width: "20px", height: "20px", className: "musicSideBarIcons" }} />}
            </button>

            <button type="button" className={` bg-transparent theme-text-color playButton d-none d-md-block p-0 marginER  toolTipParent  ${currentUserInteract?.id ? "" : 'opacity-75'}`} onClick={handleDisLike}>
              {currentUserInteract?.dislike !== 1 ? <IconMyDisLike styled={{ width: "20px", height: "20px", className: "musicSideBarIcons" }} />
                : <IconMyDisLikeFill styled={{ width: "20px", height: "20px", className: "musicSideBarIcons" }} />}
            </button>

            {getMusicData?.enable_shuffle ? <button className={`bg-transparent theme-text-color  marginER playButton p-0 d-none d-md-block toolTipParent  ${currentAudio?.id ? "" : 'opacity-75'} `} onClick={shufflePlaylist}   >
              <IconMyShuffle styled={{ width: "20px", height: "20px", className: "theme-text-color" }} />
              <ToolTip bgColor="#000" text="Shuffle" top="-43px" left="-14px" className="px-3 py-2 rounded-2 position-absolute d-none" />
            </button> : null}

            <button className={`${currentIndex == 0 ? 'opacity-75' : ''} bg-transparent theme-text-color playButton  p-0 marginER ${currentAudio?.id ? "" : 'opacity-75'}`} onClick={handlePlayPrev} disabled={currentIndex == 0 || !getMusicData?.enable_track_controls} >
              <IconMyPlayPrev styled={{ width: "20px", height: "20px", className: "musicSideBarIcons playerPrev" }} />
            </button>

            {!currentPlaying ? (
              <button className="playButtonMain theme-button-bg-color p-0 triangle d-flex align-items-center justify-content-center" onClick={handlePlayPause}><IconMyPlay styled={{ width: "20px", height: "20px", className: "" }} /></button>
            ) : (
              <button className="playButtonMain theme-button-bg-color p-0 " onClick={handlePlayPause}><IconMyPause styled={{ width: "20px", height: "20px", className: "" }} /></button>
            )}

            <button className={`${currentIndex + 1 >= playlistAudio?.length ? 'opacity-75' : ''} bg-transparent theme-text-color playButton p-0 marginER`} onClick={handlePlayNext} disabled={currentIndex + 1 >= playlistAudio?.length || !getMusicData?.enable_track_controls}>
              <IconMyPlayPrev styled={{ width: "20px", height: "20px", className: "musicSideBarIcons rotate playerNext" }} />
            </button>

            {getMusicData?.enable_loop ? <button className={`bg-transparent theme-text-color playButton marginER p-0 toolTipParent ${currentRepeat == "enabled" || currentRepeat == "currentloop" ? "yes " : "no"} ${currentRepeat == "currentloop" && 'one'}  ${currentAudio?.id ? "" : 'opacity-75'} d-none d-md-block`} onClick={handleEnableLoop}>
              <IconMyLoop styled={{ width: "20px", height: "20px", className: "musicSideBarIcon" }} />
              <ToolTip bgColor="#000" text="Loop" top="-43px" left="-14px" className="px-3 py-2 rounded-2 position-absolute d-none" />
            </button> : null}

            <button type="button" className={`bg-transparent theme-text-color playButton p-0 marginER  d-none d-md-block toolTipParent  ${currentAudio?.id ? "" : 'opacity-75'}`} onClick={handlePlaylistModal}>
              <IconMyAddPlaylist styled={{ width: "18px", height: "18px", className: "" }} />
            </button>

            {getMusicData?.enable_minimize_button ? <button type="button" className={`bg-transparent theme-text-color playButton marginER p-0 d-none d-md-block toolTipParent`} onClick={() => minimizePlayer()}>
              <IconMyMini styled={{ width: "15px", height: "15px", className: "" }} />
            </button> : null}

            {/* <div className={`dropup me-sm-1 me-md-2 moreMobileButton d-md-none ${playerMin ? "d-none" : ""} ${currentAudio?.id ? "" : 'opacity-75'}`} id="mobile-view-option-start">
              <button className="dropdown-toggle bg-transparent " type="button" id="dropdownMenuButton" aria-haspopup="true" data-bs-toggle="dropdown" aria-expanded="false">
                <MdMoreVert className={`likeIcon theme-text-color`} />
              </button>
              <ul  className="dropdown-menu py-3 theme-bg-color theme-text-color" aria-labelledby="dropdownMenuButton">
                <li className={`${currentUserInteract?.like == 1 && "theme-button-bg-color"}`}>
                  <button type="button" className={`px-3 d-flex dropdown-item bg-transparent ${currentAudio?.id ? "" : 'opacity-75'} `} onClick={handleLike}  >
                    <GoThumbsup className={`playerIcon mobile me-2 ${currentUserInteract?.like == 1 ? 'theme-text-color-body' : 'theme-text-color'} `} />
                    <MessageBox classname={`smallText d-block ${currentUserInteract?.like == 1 ? 'theme-text-color-body' : 'theme-text-color'}`} text={"Like"} />
                  </button>
                </li>
                <li className={`mt-2 ${currentUserInteract?.dislike == 1 && "theme-bg-color-reverse"}`}>
                  <button type="button" className={`px-3 d-flex dropdown-item bg-transparent ${currentAudio?.id ? "" : 'opacity-75'}`} onClick={handleDisLike}  >
                    <GoThumbsdown className={`playerIcon mobile me-2 ${currentUserInteract?.dislike == 1 ? 'theme-text-color-body' : 'theme-text-color'}`} />
                    <MessageBox classname={` smallText ${currentUserInteract?.dislike == 1 ? 'theme-text-color-body' : 'theme-text-color'}" `} text={"Dislike"} />
                  </button>
                </li>
                <li className="mt-2">
                  <button type="button" onClick={handleClickWishList} className={`px-3 d-flex dropdown-item bg-transparent ${currentAudio ? "" : 'opacity-75'}`}>
                    {currentUserInteract?.wishlist ? <><FaHeart className="likeIcon p-1 theme-text-color me-2" />  <MessageBox classname="theme-text-color smallText" text={"Favorite"} /> </> : <> <FaRegHeart className="likeIcon p-1 theme-text-color me-2" />  <MessageBox classname="theme-text-color smallText" text={"Favorite"} /></>}
                  </button>
                </li>
                <li className={`mt-2 ${currentRepeat && "theme-bg-color-reverse"} ${currentAudio?.id ? "" : 'opacity-75'}`}>
                  <button type="button" className={`px-3 d-flex dropdown-item bg-transparen`} onClick={handleEnableLoop}  >
                    <TfiLoop className={`playerIcon mobile me-2 ${currentRepeat ? 'theme-text-color-body' : 'theme-text-color'}  `} />
                    <MessageBox classname={`${currentRepeat ? 'theme-text-color-body ' : 'theme-text-color'} smallText`} text="Loop" />
                  </button>
                </li>
               
                <li className={`mt-2 ${currentToggle && "theme-bg-color-reverse"} ${currentAudio?.id ? "" : 'opacity-75'}`}>
                  <button type="button" className={`px-3 d-flex dropdown-item bg-transparent`} onClick={handleLyrics}  >
                    <MdMusicNote className={`playerIcon mobile me-2 ${currentToggle ? 'theme-text-color-body' : 'theme-text-color'} `} />
                    <MessageBox classname={`${currentToggle ? 'theme-text-color-body ' : 'theme-text-color'} smallText`} text="Lyrics" />
                  </button>
                </li>
                {!isPathExcluded(excludedPathsCloseIcon) && <li className={`mt-2`}>
                  <button type="button" className={`px-3 d-flex dropdown-item bg-transparent`} onClick={handleClose}>
                    <MdClose className={`playerIcon mobile me-2 theme-text-color`} />
                    <MessageBox classname="theme-text-color smallText" text={"Hide"} />
                  </button>
                </li>}
              </ul>
            </div> */}

          </div>
        </div>
        <div className={`col-2 col-sm-2 col-md-1 col-lg-3 d-none d-md-block`}>
          <div className=" d-flex gap-2 align-items-center justify-content-between">
            <div className="d-none d-lg-block ">
              <div className="d-flex align-items-center justify-content-between">
                <span className="audioTime theme-text-color" >{formatTime(currentTime / 1000)} </span>
                <span className="audioTime theme-text-color">/</span>
                <span className="audioTime theme-text-color">{formatTime(duration)}</span>
              </div>
            </div>
            {getMusicData?.enable_volume ? <div className="d-flex align-items-center">
              <button className="playButton toolTipParent bg-transparent p-0 theme-text-color marginER" onClick={handleVolume} >
                {volume ? <IconMyVolumn styled={{ width: "20px", height: "20px", className: "" }} />
                  : <IconMyVolumnMute styled={{ width: "20px", height: "20px", className: "" }} />}
                <ToolTip bgColor="#000" text="Volumn" top="-43px" left="-14px" className="px-3 py-2 rounded-2 position-absolute d-none" />
              </button>
              <input type="range" min="0" max="1" step="0.01" value={volume} className="playerRange d-none d-lg-block" onChange={handleVolume} />
            </div> : null}

            {!isPathExcluded(excludedPathsCloseIcon) &&
              <button className="bg-transparent theme-text-color playButton toolTipParent" onClick={() => handleClose()}>
                <MdClose className="playerIcon" />
                <ToolTip bgColor="#000" text="Close" top="-43px" left="-14px" className="px-3 py-2 rounded-2 position-absolute d-none" />
              </button>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  playlistAudio: state.get_playlist_Reducer.playlistAudio,
  isLoading: state.get_playlist_Reducer.isLoading,
  error: state.get_playlist_Reducer.error,
  currentIndex: state.get_current_Reducers.currentIndex,
  currentAudio: state.get_current_Reducers.currentAudio,
  currentTimes: state.get_current_Reducers.currentTime,
  currentToggle: state.get_current_Reducers.currentToggle,
  currentTrackId: state.get_current_Reducers.currentTrackId,
  currentDuration: state.get_current_Reducers.currentDuration,
  currentPlaying: state.get_current_Reducers.currentPlaying,
  currentRepeat: state.get_current_Reducers.currentRepeat,
  getMusicData: state.get_Music_Settings.getMusicData,
  initialModal: state.get_Playlist_Modal.initialModal,
  currentUserInteract: state.get_current_Reducers.currentUserInteract,
  playlistShow: state.get_Playlist_Modal.playlistShow,
  homepage: state.get_Playlist_Modal.homepage,
  languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData
});
export default connect(mapStateToProps)(FloatAudioPlayer)