import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { getItem } from "../../Utils/localStorageUtils";
import PageLoader from "../Loader/PageLoader";
import { useMyContext } from "../Layout/CommonProvider";

const HomeProtectedRoute = ({ settings, isLoading, children, siteTheme }) => {
  const { navigateTranslateChecker } = useMyContext()
  const userData = getItem("access_token");
  if (isLoading) {
    return <PageLoader />;
  }
  else {
    if (userData || Number(settings?.access_free)) {
      return children;
    } else {
      const loginPath = siteTheme?.translate_checkout ? navigateTranslateChecker(`/login`) : '/login';
      return <Navigate to={loginPath} />;
    }
  }
}
const mapStateToProps = state => ({
  settings: state.get_setting_Reducer.settings,
  isLoading: state.get_setting_Reducer.isLoading,
  siteTheme: state.get_siteTheme_Reducer.siteTheme,
});
export default connect(mapStateToProps)(HomeProtectedRoute);;