import React from "react";
import { Route, Routes } from "react-router-dom";
import MusicLayout from '../components/Layout/MusicLayout';
import MusicHomePage from "../Pages/Music/HomePage/MusicHomePage";
import MusicViewAllIndex from "../Pages/Music/ViewAll/Source/MusicViewAllIndex";
import MusicBasedWriterViewAll from "../Pages/Music/ViewAll/Source/MusicBasedWriterViewAll";
import MusicBasedMusicianViewAll from "../Pages/Music/ViewAll/Source/MusicBasedMusicianViewAll";
import MusicBasedAlbumViewAll from "../Pages/Music/ViewAll/Source/MusicBasedAlbumViewAll";
import MusicBasedCategoryViewAll from "../Pages/Music/ViewAll/Source/MusicBasedCategoryViewAll";
import MusicBasedLanguageViewAll from "../Pages/Music/ViewAll/Source/MusicBasedLanguageViewAll";
import MusicDetails from "../Pages/Music/MusicDetails";
import MusicPlaylistDetails from "../Pages/Music/MusicPlaylistDetails";
import ArtistsDetails from "../Pages/Music/ArtistsDetails";
import AlbumDetails from "../Pages/Music/AlbumDetails";
import Error404 from "../components/Error404";
import StationDetails from "../Pages/Music/StationDetails";
import MusicSearchPage from "../Pages/Music/Page/Search/MusicSearchPage";
import MusicWishListPage from "../Pages/Music/Page/WishList/MusicWishListPage";
import MusicSettingPage from "../Pages/Music/Page/Setting/MusicSettingPage";
import MusicProfilePage from "../Pages/Music/Page/Profile/MusicProfilePage";
import GenreDetails from "../Pages/Music/ViewAll/DetailsPage/GenreDetails";
import HomeProtectedRoute from "../components/ProtectRoute/HomeProtectedRoute";
import OwnLyrics from "../Players/OwnLyrics";
const MusicAllRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<MusicLayout />}>
                <Route index element={<HomeProtectedRoute><MusicHomePage /></HomeProtectedRoute>} />
                <Route path="/:source" element={<MusicViewAllIndex />}></Route>
                <Route path="/song-writer/:slug" element={<MusicBasedWriterViewAll />}></Route>
                <Route path="/musician/:slug" element={<MusicBasedMusicianViewAll />}></Route>
                <Route path="/audio-genre/:slug" element={<MusicBasedCategoryViewAll />}></Route>
                <Route path="/audio-albums/:slug" element={<MusicBasedAlbumViewAll />}></Route>
                <Route path="/language/:slug" element={<MusicBasedLanguageViewAll />}></Route>
                <Route path="/playlist/:songname" element={<MusicPlaylistDetails />} />
                <Route path="/track/:songname" element={<MusicDetails />} />
                <Route path="/artist/:artistname" element={<ArtistsDetails />} />
                <Route path="/album/:albumname" element={<AlbumDetails />} />
                <Route path="/genre/:genrename" element={<GenreDetails />} />
                <Route path="/station/:stationslug" element={<StationDetails />} />
                <Route path="/lyrics" element={<OwnLyrics />} />
                <Route path="search" element={<MusicSearchPage />}></Route>
                <Route path="wishlist" element={<MusicWishListPage />}></Route>
                <Route path="user/settings" element={<MusicSettingPage />} />
                {/* <Route path="user/profile" element={<MusicProfilePage />} /> */}
                <Route path="plan" element={<h3 className='text-center py-4'>Upcoming Plan </h3>}></Route>
                <Route path="*" element={<Error404 />} />
            </Route>
        </Routes>
    )
}

export default MusicAllRoutes