import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { MdOutlineInfo } from "react-icons/md";
import { getCurrentAction, musicDetailsUpdate, playQueueAction } from '../../Redux/Actions/MusicAction';
import { IoClose } from "react-icons/io5";
import MessageBox from '../../components/MessageBox';
import defaultImage from '../../assets/images/default_vertical_image.webp'
import { Link, useParams } from 'react-router-dom';
import PriceLabel from '../../components/PriceLabel';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../CommonMethods/CommonMethods';
import usePpvGlobalImageConfig from "../../components/Api/Rentppv_global_change";
import axios from 'axios';
import { toast } from "react-toastify";
import EmptyPlaylistLoader from './CommonComponents/EmptyPlaylistLoader'
import { IoPlay } from "react-icons/io5";
import { useMyContext } from '../../components/Layout/CommonProvider';


const PurchaseModal = ({ playlistAudio, upComingTitle, currentAudio, settings, profileDetails, getMusicData, minimize, isLoading, currentUserInteract, playlistData }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { lang } = useParams()
    const { navigateTranslateChecker } = useMyContext()
    const user_role = localStorage.getItem("role");
    const user_id = localStorage.getItem("user_id");
    const access_token = localStorage.getItem("access_token");
    const headers = { "Content-Type": "application/json", Authorization: "Bearer " + access_token, Accept: "application/json" };
    const playNext = () => {
        const currentIndex = playlistAudio.findIndex(item => item.id == upComingTitle?.id);
        const nextIndex = (currentIndex + 1) % playlistAudio?.length;
        let nextAudio = playlistAudio?.[nextIndex];
        if (nextAudio?.audio_access) {
            if (nextIndex >= 0) {
                dispatch({ type: "PURCHASE_MODAL", payload: false, upComingTitle: playlistAudio?.[nextIndex + 1] })
                dispatch(getCurrentAction(nextIndex, playlistAudio, 0));
                dispatch(musicDetailsUpdate('AUDIO', playlistAudio?.[nextIndex]?.slug))
            }
        }
        else {
            dispatch({ type: "PURCHASE_MODAL", payload: true, upComingTitle: nextAudio })
        }
    };
    const handleClose = () => {
        dispatch({ type: "PURCHASE_MODAL", payload: false })
    }
    const [convertedPrice, setConvertedPrice] = useState(null);

    const renderButton = () => {
        if (user_role === 1) {
            // If user_role is 1, play all the audio
            if (["Guest", "registered", "subscriber", "ppv"].includes(upComingTitle?.access)) {
                return isLoading
                    ? Array.from({ length: 5 }).map((data, index) => <EmptyPlaylistLoader key={index} />)
                    : playlistToggle && playlistAudio?.map((audio, index) => (
                        <button
                            key={index}
                            className="buttonList bg-transparent p-0"
                            onClick={() => handleCurrentAudioPlay(audio, index)}
                        >
                            <IoPlay className='theme-text-color-button' />
                        </button>
                    ));
            }
        } else if (user_role === 2) {
            // If user_role is 2, show the register button
            return (
                <button className='theme-button-bg-color px-3 py-2 rounded-2 d-inline-block'>
                    <Link className='theme-button-text-color' to={navigateTranslateChecker(`/signup`)}>
                        <span>{t("Register Now")}</span>
                    </Link>
                </button>
            );
        } else if (user_role === 3) {
            // If user_role is 3, show the subscription button
            return (
                <button className='theme-button-bg-color px-3 py-2 rounded-2 d-inline-block'>
                    <Link className='theme-button-text-color' to={navigateTranslateChecker(`/plan`)}>
                        <span>{t("Become a Member")}</span>
                    </Link>
                </button>
            );
        }

        // Handle Guest access audio play
        if (upComingTitle?.access === "Guest") {
            return <button className='theme-button-bg-color d-flex align-items-center justify-content-between px-3 py-2 rounded-2'><span>{t("Watch Now")}</span></button>;
        }

        // Handle registered access audio button
        if (upComingTitle?.access === "registered") {
            return <button className='theme-button-bg-color d-flex align-items-center justify-content-between px-3 py-2 rounded-2'><Link className='theme-button-text-color' to={navigateTranslateChecker(`/signup`)}>
                <span>{t("Register Now")}</span>
            </Link></button >;
        }

        // Handle subscriber access showing the subscription button
        if (upComingTitle?.access === "subscriber") {
            return (
                <button className='theme-button-bg-color px-3 py-2 rounded-2 d-inline-block'>
                    <Link className='theme-button-text-color' to={navigateTranslateChecker(`/plan`)}>
                        <span>{t("Become a Member")}</span>
                    </Link>
                </button>
            );
        }
        if (upComingTitle?.access === "ppv") {
            return (
                <>
                    {currency?.enable_multi_currency === 1 || currency?.enable_multi_currency === "1" ? (
                        <button
                            className="theme-button-bg-color px-3 py-2 rounded-2 d-inline-block"
                            type="button"
                            data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                        >
                            {convertedPrice !== null
                                ? `Rent Now ${currencySymbol} ${convertedPrice.toFixed(2)}`
                                : `Rent Now ${upComingTitle?.ContentPriceRate?.Symbol} ${parseFloat(upComingTitle?.ContentPriceRate?.ChangedPrice).toFixed(2)}`}
                        </button>
                    ) : (
                        <button
                            className="theme-button-bg-color px-3 py-2 rounded-2 d-inline-block"
                            type="button"
                            data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                        >
                            {t(`Rent Now ${currency?.symbol} ${parseFloat(upComingTitle?.ppv_price).toFixed(2)}`)}
                        </button>

                    )}
                </>
                // <button className='theme-button-bg-color px-3 py-2 rounded-2 d-inline-block' type='button' data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                //     {currency?.code === null || currency?.code === ""
                //         ? `Rent Now ${upComingTitle?.ppv_price !== null ? upComingTitle?.ppv_price : 'Loading...'}`
                //         : (settings?.PPV_Individual_Contents === "0" || settings?.PPV_Individual_Contents === 0
                //             ? `Rent Now ${currencySymbol} ${convertedPrice !== null ? convertedPrice : 'Loading...'}`
                //             : (upComingTitle?.ContentPriceRate !== null
                //                 ? `Rent Now ${upComingTitle?.ContentPriceRate?.Symbol} ${upComingTitle?.ContentPriceRate?.ChangedPrice}`
                //                 : `Rent Now ${currencySymbol} ${convertedPrice !== null ? convertedPrice : 'Loading...'}`
                //             )
                //         )}
                // </button>
            );
        }

        return null;
    };





    //   geo ip location integration 
    const { currency, currencySymbol, userCurrency, locationResponselocationResponse } = usePpvGlobalImageConfig();
    // const [convertedPrice, setConvertedPrice] = useState(null);

    useEffect(() => {
        const fetchConversionRate = async () => {
            if (!userCurrency || !currency?.code || currency?.enable_multi_currency !== 1) return;
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_Curreny_generate}/${currency?.code}`);
                const data = response.data;
                const conversionRate = data.rates[userCurrency];
                if (conversionRate) {
                    const convertedPPVPrice = upComingTitle?.ppv_price * conversionRate;
                    setConvertedPrice(convertedPPVPrice);
                } else {
                    console.error('Conversion rate not found for currency:', userCurrency);
                }
            } catch (error) {
                console.error('Error fetching conversion rate:', error);
            } finally {
            }
        };

        fetchConversionRate();
    }, [userCurrency, currency?.code, currency?.enable_multi_currency, upComingTitle?.ppv_price]);

    //   geo ip location integration 
    //   geo ip location integration 

    //    Iyzico payment integration 
    //    Iyzico payment integration 
    const successpage = window.location.href;
    const ppv_priceppv_price = upComingTitle?.ppv_price
    const [formDataiyzicopayment, setFormDataiyzicopayment] = useState({
        locale: '',
        conversationId: '',
        price: ppv_priceppv_price, // Initialize with an empty value
        paidPrice: ppv_priceppv_price, // Initialize with an empty value
        successpage: successpage, // Initialize with an empty value
        currency: '',
        basketId: '',
        paymentGroup: 'PRODUCT',
        callbackUrl: '',
        enabledInstallments: [2, 3, 6, 9],
        buyer: {
            id: '',
            name: '',
            surname: '',
            gsmNumber: '',
            email: '',
            identityNumber: '',
            lastLoginDate: '',
            registrationDate: '',
            registrationAddress: '',
            ip: '',
            city: '',
            country: '',
            zipCode: ''
        },
        shippingAddress: {
            contactName: 'Jane Doe',
            city: 'Istanbul',
            country: 'Turkey',
            address: 'Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1',
            zipCode: '34742'
        },
        billingAddress: {
            contactName: 'Jane Doe',
            city: 'Istanbul',
            country: 'Turkey',
            address: 'Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1',
            zipCode: '34742'
        },
        basketItems: [
            {
                id: 'BI101',
                name: 'Binocular',
                category1: 'Collectibles',
                category2: 'Accessories',
                itemType: 'PHYSICAL',
                price: ppv_priceppv_price,
            }
        ]
    });


    useEffect(() => {
        let value;
        if (currency?.code === null || currency?.code === "") {
            value = upComingTitle?.ppv_price !== null ? upComingTitle?.ppv_price : '';
        } else {
            if (settings?.PPV_Individual_Contents === "0" || settings?.PPV_Individual_Contents === 0) {
                value = convertedPrice !== null ? convertedPrice : '';
            } else {
                value = upComingTitle?.ContentPriceRate !== null
                    ? upComingTitle?.ContentPriceRate?.ChangedPrice
                    : convertedPrice !== null
                        ? convertedPrice
                        : '';
            }
        }

        const getCurrentDateTime = () => {
            const now = new Date();
            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, '0');
            const day = String(now.getDate()).padStart(2, '0');
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            const seconds = String(now.getSeconds()).padStart(2, '0');
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        };

        const currentDateTime = getCurrentDateTime();
        const locale = lang === "tr" ? "tr" : "en";
        const conversationId = user_id;
        const currencyfinal = currency?.code;
        const basketId = profileDetails?.Profile_details?.id;
        const gsmNumber = "+" + profileDetails?.Profile_details?.payment_mobile_number;
        // const successpage = window.location.href;
        const failerpage = `${process.env.REACT_APP_API_PATHss}/` + lang + "/failerpage";
        const successpage = `${process.env.REACT_APP_API_PATHss}/` + lang + "/successpage";
        const pageredirectionslug = `${process.env.REACT_APP_API_PATHss}/` + lang + "/" + "music";
        const callbackUrl = `${process.env.REACT_APP_API_IYZICO_PAYMENT}/callback`;
        const usernames = profileDetails?.Profile_details?.username;
        const last_name = profileDetails?.Profile_details?.last_name;
        const payment_mobile_number = "+" + profileDetails?.Profile_details?.payment_mobile_number;
        const user_ids = user_id;
        const emailemail = profileDetails?.Profile_details?.email;
        const city = locationResponselocationResponse?.city;
        const country = locationResponselocationResponse?.country;
        const zipCode = locationResponselocationResponse?.asn;
        const ip = locationResponselocationResponse?.ip;
        const basketItemsname = upComingTitle?.title || upComingTitle?.name;
        setFormDataiyzicopayment(prevState => ({
            ...prevState,
            locale: locale,
            price: ppv_priceppv_price,
            conversationId: conversationId,
            Login_user_id: user_id,
            Login_device_type: "web",
            source_type: 5,
            source_id: upComingTitle?.id,
            regionname: country,
            countryname: country,
            cityname: city,
            successpage: successpage,
            pageredirectionslug: pageredirectionslug,
            failerpage: failerpage,
            paidPrice: ppv_priceppv_price,
            currency: currencyfinal,
            basketId: basketId,
            callbackUrl: callbackUrl,
            buyer: {
                id: user_ids,
                name: usernames,
                surname: last_name,
                gsmNumber: payment_mobile_number,
                email: emailemail,
                identityNumber: gsmNumber,
                lastLoginDate: currentDateTime,
                registrationDate: currentDateTime,
                registrationAddress: country,
                ip: ip,
                city: city,
                country: country,
                zipCode: zipCode
            },
            shippingAddress: {
                contactName: usernames,
                city: city,
                country: country,
                district: city,
                address: country,
                zipCode: zipCode
            },
            billingAddress: {
                contactName: usernames,
                city: city,
                country: country,
                address: country,
                zipCode: zipCode
            },
            basketItems: [
                {
                    id: user_ids,
                    name: basketItemsname,
                    category1: 'Videos',
                    itemType: 'PHYSICAL',
                    price: ppv_priceppv_price
                }
            ]
        }));

    }, [upComingTitle, currency, settings, convertedPrice]);

    const initializeCheckout = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_IYZICO_PAYMENT}/initialize-checkout`, formDataiyzicopayment);
            if (response.data.status === "success") {
                dispatch(playQueueAction("AUDIO", upComingTitle.slug));
                const paymentPageUrl = response?.data?.paymentPageUrl;
                window.location.href = paymentPageUrl;
            }
            else if (response.data.status === "failure") {
                toast.error(`${response.data.errorMessage}`)
            }
        } catch (error) {
            console.error('Error initializing checkout:', error);
        }
    };




    // useEffect(() => {
    //     let showAlert = true;

    //     const retrievePaymentDetails = async () => {
    //         try {
    //             const response = await axios.get(`http://localhost:7004/retrieve-payment-details`);

    //             if (response.data.status === 'success' && showAlert) {
    //                 try {
    //                     const locationResponse = await axios.get(`${process.env.REACT_APP_API_IP_Location}`);
    //                     const country = locationResponse?.data?.country;
    //                     const country_code = locationResponse?.data?.country_code;
    //                     const city = locationResponse?.data?.city; // Assuming you need city here

    //                     if (city && country) { // Ensure both city and country are defined
    //                         showAlert = false;

    //                         const result = response.data;
    //                         const user_id = localStorage.getItem("user_id");
    //                         const web = "web";
    //                         const upComingTitleid = upComingTitle?.id;

    //                         const formDataiyzico = new FormData();
    //                         formDataiyzico.append("Login_user_id", user_id);
    //                         formDataiyzico.append("Login_device_type", web);
    //                         formDataiyzico.append("source_type", 1);
    //                         formDataiyzico.append("source_id", upComingTitleid);
    //                         // formDataiyzico.append("tokenid ", result.token);
    //                         formDataiyzico.append("payment_id", result.paymentId);
    //                         formDataiyzico.append("regionname", country);
    //                         formDataiyzico.append("countryname", country);
    //                         formDataiyzico.append("cityname", city);

    //                         console.log("FormDataiyzico:", Array.from(formDataiyzico.entries())); // Log the FormData content

    //                         const postResponse = await axios.post(
    //                             `${process.env.REACT_APP_Baseurl}/Front-End/iyzico-ppv-verify-store`,
    //                             formDataiyzico
    //                         );

    //                         if (postResponse.data.status_code === 200) {
    //                             toast.success(`Purchased video was Created Successfully`);
    //                         } else {
    //                             toast.error(`Purchased video was Created Failed`);
    //                         }
    //                     } else {
    //                         console.error('City or country is undefined');
    //                     }
    //                 } catch (locationError) {
    //                     console.error('Error retrieving location data:', locationError);
    //                 }
    //             }
    //         } catch (paymentError) {
    //             console.error('Error retrieving payment details:', paymentError);
    //         }
    //     };

    //     retrievePaymentDetails();
    // }, [upComingTitle]); // Add `upComingTitle` as a dependency if it's used in the effect


    // Iyzico payment integration 
    // Iyzico payment integration 



    const [paymentGateways, setPaymentGateways] = useState([]);

    const [activeGateway, setActiveGateway] = useState(null); // Initialize activeGateway as null

    const fetchPaymentGateways = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_Baseurl}/Front-End/Payment-Gateway-list`,
                { headers: headers }
            );
            const paymentGateways = response?.data?.Payment_Gateways;
            setPaymentGateways(paymentGateways);
            // Set the active gateway to the first one by default
            setActiveGateway(paymentGateways[0]?.Payment_Gateway_name || null);
        } catch (error) {
            console.error(error);
        }
    };

    const handleGatewayClick = (gatewayName) => {
        setActiveGateway(gatewayName);
    };


    useEffect(() => {
        // fetchOptions();
        fetchPaymentGateways();
    }, []);


    const staticContent = {
        Stripe: (
            <>
                <div>
                    <button className='btn w-100'>Stripe Pay to the {upComingTitle?.title ? upComingTitle.title : upComingTitle?.name}</button>
                </div>
            </>
        ),
        PayPal: (
            <>
                <div>
                    <button className='btn w-100'>PayPal Pay to the {upComingTitle?.title ? upComingTitle.title : upComingTitle?.name}</button>
                </div>
            </>
        ),
        Razorpay: (
            <>
                <div>
                    <button className='btn w-100'>Razorpay Pay to the {upComingTitle?.title ? upComingTitle.title : upComingTitle?.name}</button>
                </div>
            </>
        ),
        Iyzico: (
            <>
                <div>
                    <button className='btn w-100' onClick={() => initializeCheckout()}>Iyzico Pay to the {upComingTitle?.title ? upComingTitle.title : upComingTitle?.name}</button>
                </div>
            </>
        ),
    };


    //    Iyzico payment integration 
    //    Iyzico payment integration 



    const [playlistToggle, setPlaylistToggle] = useState(true)

    const handleCurrentAudioPlay = async (audiolist, index) => {
        if (audiolist?.audio_access) {
            await dispatch(musicDetailsUpdate('AUDIO', audiolist?.slug))
            await dispatch(getCurrentAction(index, playlistAudio, 0))
        }
        else {
            dispatch({ type: "PURCHASE_MODAL", payload: true, upComingTitle: audiolist })
        }
    };
    const handlePlaylistToggle = () => {
        setPlaylistToggle(!playlistToggle)
    }

    const handlePlaylistModal = (id) => {
        if (currentAudio?.id) {
            dispatch({ type: "OPEN_INITIAL_MODAL", payload: true })
            dispatch({ type: "GET_PLAYLIST_STORE_ID", payload: id })
        }
        else {
            toast.info(t("Please log in after adding audio to the playlist."));
        }
    };

    console.log(upComingTitle?.Player_thumbnail, "upComingTitle")
    return (
        <>

            {/* { PPV Payment Modal} */}
            {/* { PPV Payment Modal} */}
            <div className="modal fade paymentppvmodalcode" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog  modal-lg modal-dialog-centered modal-dialog-scrollable ">

                    <div className="modal-content theme-bg-color">
                        <div className="modal-header">
                            <h5 className="modal-title theme-text-color" id="staticBackdropLabel">Upgrade to <span>{upComingTitle?.title ? upComingTitle.title : upComingTitle?.name}</span> video pack by just paying the difference </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body rent_video_page_ul_li">
                            <div className="row">
                                {/* <div className="col-3 col-md-3 col-lg-3"></div> */}

                                <div className='row col-lg-12'>
                                    <div className='col-lg-5 col-md-5 col-sm-5 col-5'>
                                        <img alt={upComingTitle?.title ? upComingTitle.title : upComingTitle?.name} src={upComingTitle?.Thumbnail ? upComingTitle?.Thumbnail : upComingTitle?.image_url} className='w-100 h-100' />
                                    </div>
                                    <div className='col-lg-7 col-md-7 col-sm-7 col-7 rounded-3 border-3'>
                                        <div className='p-2'>
                                            <p className='fw-bold fs-5  theme-text-color'>BUY {upComingTitle?.title ? upComingTitle?.title : upComingTitle?.name}</p>

                                            <hr></hr>

                                            <div className='mt-4'>
                                                <div>
                                                    <img alt={upComingTitle?.title ? upComingTitle.title : upComingTitle?.name} src={upComingTitle?.Player_thumbnail} className='w-50' />
                                                </div>
                                                <div className='mt-3'>
                                                    <ul>
                                                        <li className=' theme-text-color'>
                                                            Available to watch for <span className='fw-bold'>30 Days</span> after renting
                                                        </li>
                                                        <li className=' theme-text-color'>
                                                            Finish within <span className='fw-bold'>48 Hours</span> once started streaming
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <hr></hr>

                                            <div className='mt-2'>
                                                <ul className="nav nav-pills pt-3" id="pills-tab" role="tablist">
                                                    {paymentGateways?.map((gateway, index) => (
                                                        <li
                                                            className="nav-item d-flex align-items-center ms-2 paymenttabheading"
                                                            role="presentation"
                                                            key={index}
                                                        >
                                                            <input
                                                                type="radio"
                                                                className="nav-link"
                                                                id={`pills-${gateway?.Payment_Gateway_name}-tab`}
                                                                name="tab-radio"
                                                                value={gateway?.Payment_Gateway_name}
                                                                checked={activeGateway === gateway?.Payment_Gateway_name}
                                                                onChange={() =>
                                                                    handleGatewayClick(gateway?.Payment_Gateway_name)
                                                                }
                                                            />
                                                            <label
                                                                className="ms-2 theme-text-color"
                                                                htmlFor={`pills-${gateway?.Payment_Gateway_name}-tab`}
                                                            >
                                                                {gateway?.Payment_Gateway_name}
                                                            </label>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>

                                            <div className='mt-2'>
                                                <div className="row">
                                                    <div className="col-12 col-md-12 col-lg-12 offset-md-0">
                                                        <div className="tab-content text-start" id="pills-tabContent">
                                                            {paymentGateways?.map((gateway, index) => (
                                                                <div
                                                                    className={`tab-pane theme-text-color ${activeGateway === gateway?.Payment_Gateway_name
                                                                        ? "show active"
                                                                        : "fade"
                                                                        }`}
                                                                    id={`pills-${gateway?.Payment_Gateway_name}`}
                                                                    role="tabpanel"
                                                                    key={index}
                                                                >
                                                                    <p className="theme-text-color">{staticContent[gateway?.Payment_Gateway_name]}</p>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* <div className="modal-content theme-bg-color">
                        <div className="modal-header">
                            <h5 className="modal-title theme-text-color" id="staticBackdropLabel">Rent Video {upComingTitle?.title ? upComingTitle.title : upComingTitle?.name}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-6 col-md-6 col-lg-8 ">
                                    <ul className="nav nav-pills pt-3" id="pills-tab" role="tablist">
                                        {paymentGateways?.map((gateway, index) => (
                                            <li
                                                className="nav-item d-flex align-items-center ms-2 paymenttabheading"
                                                role="presentation"
                                                key={index}
                                            >
                                                <input
                                                    type="radio"
                                                    className="nav-link"
                                                    id={`pills-${gateway?.Payment_Gateway_name}-tab`}
                                                    name="tab-radio"
                                                    value={gateway?.Payment_Gateway_name}
                                                    checked={activeGateway == gateway?.Payment_Gateway_name}
                                                    onChange={() =>
                                                        handleGatewayClick(gateway?.Payment_Gateway_name)
                                                    }
                                                />
                                                <label
                                                    className="ms-2 theme-text-color"
                                                    htmlFor={`pills-${gateway?.Payment_Gateway_name}-tab`}
                                                >
                                                    {gateway?.Payment_Gateway_name}
                                                </label>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 offset-md-0">
                                    <div className="tab-content text-start" id="pills-tabContent">
                                        {paymentGateways?.map((gateway, index) => (
                                            <div
                                                className={`tab-pane theme-text-color ${activeGateway == gateway?.Payment_Gateway_name
                                                    ? "show active"
                                                    : "fade"
                                                    }`}
                                                id={`pills-${gateway?.Payment_Gateway_name}`}
                                                role="tabpanel"
                                                key={index}
                                            >
                                                <p className="theme-text-color">{staticContent[gateway?.Payment_Gateway_name]}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>

            {/* { PPV Payment Modal} */}
            {/* { PPV Payment Modal} */}

            <div className='playlistModal w-100 h-100 d-flex align-items-center justify-content-center position-fixed'>
                <div className='theme-bg-color playlistWrapper p-3 p-md-4 position-relative'>
                    <IoClose className='theme-text-color d-flex justify-content-end likeIcon ms-auto mb-2 purchaseClose position-absolute' onClick={handleClose} />
                    <div className='d-flex align-items-center gap-3 mt-4'>
                        <img src={upComingTitle?.image_url ? upComingTitle?.image_url : upComingTitle?.Thumbnail ? upComingTitle?.Thumbnail : defaultImage} className='purchaseImage object-fit-cover' />
                        <div className='d-flex flex-column'>
                            <Link to={navigateTranslateChecker(`/music/track/${upComingTitle?.slug}`)}><MessageBox classname='purchaseTitle text-start' text={upComingTitle?.title} /></Link>
                            <div className='audioListName  opacity-75 d-flex align-items-center  flex-wrap m-0'>{upComingTitle?.artists?.slice(0, 2).map((name, index) => <Link to={navigateTranslateChecker(`/music/artist/${name.artist_slug}`)} className=' d-block theme-text-color pe-1'> <MessageBox text={name?.name} />{upComingTitle?.artists?.length >= 2 && index + 1 !== 2 ? "," : ""} </Link>)}</div>
                        </div>
                        <PriceLabel item={upComingTitle} />
                    </div>
                    <div className='d-flex align-items-center justify-content-center mt-5'>
                        <MessageBox text="This audio is exclusively for purchase" classname='purchaseHeading text-center' />
                        {/* <MessageBox classname='purchaseHeading text-center ms-2' text={capitalizeFirstLetter(upComingTitle?.access)} /> */}
                    </div>
                    <MessageBox classname='text-start mt-3 px-md-4   d-block opacity-75 purchaseInfo' text=" Purchase a plan to enjoy endless music and exclusive content. " />
                    <div className='d-flex align-items-center justify-content-between mt-5 position-relative '>
                        <button className='purchaseBtn  bg-transparent btn btn-outline-light py-2 d-flex align-items-center justify-content-center' onClick={playNext}>
                            <MessageBox text="Skip" classname='d-block me-2 theme-text-color' />
                            <div className='infoBox d-inline-block position-relative '>
                                <MdOutlineInfo className='theme-text-color infoIcon' />
                                <p className='position-absolute infoContent p-2   text-start text-white rounded-2 bg-primary'> <MessageBox text="Click skip to move to the next track in active playlist" /></p>
                            </div>
                        </button>
                        {renderButton()}

                        {/* <Link to={navigateTranslateChecker(`/plan`)} className='purchaseBtn btn theme-button-bg-color  theme-button-text-color  py-2'> <MessageBox text="Subscribe" /></Link> */}
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => ({
    createModal: state.get_Playlist_Modal.createModal,
    currentAudio: state.get_current_Reducers.currentAudio,
    playlistMusicId: state.get_current_Reducers.playlistMusicId,
    playlistAudio: state.get_playlist_Reducer.playlistAudio,
    currentIndex: state.get_current_Reducers.currentIndex,
    upComingTitle: state.get_Playlist_Modal.upComingTitle,
    profileDetails: state.get_profileDetails_Reducer.profileDetails,
    settings: state.get_setting_Reducer.settings,
    isLoading: state.get_playlist_Reducer.isLoading,
    getMusicData: state.get_Music_Settings.getMusicData,
    minimize: state.get_Playlist_Modal.minimize,
    currentUserInteract: state.get_current_Reducers.currentUserInteract,
    playlistData: state.get_current_Reducers.playlistData,
});
export default connect(mapStateToProps)(PurchaseModal)

