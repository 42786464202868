import React from 'react';
import commentJson from "../JSON/comments.json"
const Comments = () => {
  return (
    <div className='m-2 pt-2'>
      <textarea className="theme-bg-color rs-input" name="comments" placeholder="Leave a comment here.." />
      <div className="d-flex gap-3 flex-column">
        {commentJson?.commentData.map(comment => (
          <div key={comment.id} className="p-2 rounded-2">
            <div className="d-flex align-items-center gap-2 mb-2">
              <img src={comment.avatar} alt={comment.name} className="object-fit-cover rounded-cirlce " />
              <h4 className="comment-name">{comment.name}</h4>
            </div>
            <p className="comment-text m-0">{comment.comments}</p>
          </div>
        ))}
      </div>
    </div>
  )
}
export default Comments