import React from 'react'
import MessageBox from '../components/MessageBox'

const Result = ({ text, className }) => {
    return (
        <div className={`${className ? className : "d-flex align-items-center justify-content-center flex-column theme-text-color min-vh-100"}`}>
            <MessageBox text={text} classname="small-lg-heading" />
        </div>
    )
}

export default Result