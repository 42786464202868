import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper/modules";
import { MdClose } from "react-icons/md";
import "./videoPlayer.css";

const RelatedVideosSwiper = ({ onClick }) => {
    const videoList = [
        {
            id: 1,
            title: 'Video 1',
            thumbnail: 'https://api.flicknexs.com/public/uploads/images/video-player-image-1715677596.webp'
        },
        {
            id: 2,
            title: 'Video 2',
            thumbnail: 'https://api.flicknexs.com/public/uploads/images/video-player-image-1715677596.webp'
        },
        {
            id: 3,
            title: 'Video 3',
            thumbnail: 'https://api.flicknexs.com/public/uploads/images/video-player-image-1715677596.webp'
        },
        {
            id: 4,
            title: 'Video 4',
            thumbnail: 'https://api.flicknexs.com/public/uploads/images/video-player-image-1715677596.webp'
        },
        {
            id: 5,
            title: 'Video 2',
            thumbnail: 'https://api.flicknexs.com/public/uploads/images/video-player-image-1715677596.webp'
        },
        {
            id: 6,
            title: 'Video 5',
            thumbnail: 'https://api.flicknexs.com/public/uploads/images/video-player-image-1715677596.webp'
        }];
    return (
        <div className="swiper-wrapper-container">
            <button className="close-button" onClick={onClick}><MdClose style={{ "fontSize": "30px" }} /></button>
            <Swiper
                navigation={true}
                spaceBetween={50}
                slidesPerView={5}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                modules={[Navigation]}
                breakpoints={{
                    206: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                        slidesPerGroup: 2,
                    },
                    346: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                        slidesPerGroup: 2,
                    },
                    576: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                        slidesPerGroup: 2,
                    },
                    768: {
                        slidesPerView: 4,
                        spaceBetween: 30,
                        slidesPerGroup: 3,
                    },

                    1200: {
                        slidesPerView: 5,
                        spaceBetween: 30,
                        slidesPerGroup: 4,
                    },
                    1440: {
                        slidesPerView: 5,
                        spaceBetween: 30,
                        slidesPerGroup: 4,
                    }
                }}>
                {videoList?.map((video) => (
                    <div className="next-videos-slider">
                        <div key={video.id} className="video-item">
                            <SwiperSlide>
                                <img src={video.thumbnail} alt={video.title} className="video-thumbnail" />
                                {/* <div className="video-title">{video.title}</div> */}
                            </SwiperSlide>
                        </div>
                    </div>
                ))}

            </Swiper>
        </div>
    );
};
export default RelatedVideosSwiper;