import React, { useState } from 'react'
import LazyLoadImage from "../HomePage/HomeSwiperComponents/LazyLoadImage"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { useMyContext } from "../../components/Layout/CommonProvider"
const ForgetPasswordThemeTwo = ({ themeMode, languageDetailsData }) => {
    const [inputField, setInputField] = useState(null)
    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputField(value)
    }
    const { navigateTranslateChecker } = useMyContext()


    const forgetEmailOtp = () => {

    }
    return (
        <div className='themeTwoBannerWrapper w-100 min-vh-100 '>
            <header className="theme-bg-color py-3 ">
                <LazyLoadImage src={themeMode?.logoUrl} alt={"logo"} classNameOptional="rounded-3 mx-auto signin-logo-images" imageSize="signin-logo-images loaderMain object-fit-cover mx-auto mb-0" />
            </header>
            <section className="d-flex align-items-center justify-content-center py-4">
                <div className='col-4 mx-auto theme-bg-color-secondary p-5 rounded-2'>
                    <p className='mb-5 text-center d-block themeTwoHead theme-text-color-button'>Forget Password</p>
                    <span className="d-block my-4 text-center theme-text-color opacity-75">Enter the email address with your account and we'll send an email with confirmation to reset your password.</span>
                    <span className="d-block mb-3 text-start theme-text-color opacity-75">Enter Email Address</span>
                    <input type="email" placeholder="Email" className="mb-4 d-block bg-transparent py-3 px-3 w-100 outline-0 border border-1" value={inputField} onChange={handleChange} />
                    <Link to={navigateTranslateChecker(`/login`)} className="d-block mb-3 text-center theme-text-color opacity-75">Back to Login</Link>
                    <button type="button" className="d-block p-3 rounded-2 w-100">Send Code</button>
                </div>
            </section>
        </div>
    )
}
const mapStateToProps = (state) => ({
    themeMode: state.get_headerIndex_Reducer.themeMode,
    languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData
});

export default connect(mapStateToProps)(ForgetPasswordThemeTwo);
