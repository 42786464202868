import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, EffectFade } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import { connect, useDispatch } from 'react-redux';
import StaticHomeSwiper from '../HomePage/HomeSwiperComponents/StaticHomeSwiper';
import { getItem, getItemToken } from '../../Utils/localStorageUtils';
import BannerPlayer from '../../components/Banner/BannerPlayer';
import BannerImage from '../../components/Banner/BannerImage';
import axios from 'axios';
import { showHomePageAction } from '../../Redux/Actions/apiActions';
import ShowAll from './Component/ShowAll';
import ShowCategoryAll from './Component/ShowCategoryAll';
import SingleSeriesAll from '../HomePage/HomeSwiperComponents/SingleSeriesAll';
import ShowBasedCategory from './Component/ShowBasedCategory';


const ShowPage = ({ thumbnailTitle, showPage, isLoading }) => {
    const dispatch = useDispatch()
    const [data, setData] = useState({ slider: [] })
    const [loading, setLoading] = useState({
        bannerLoader: false
    })
    const [activeIndexOur, setActiveIndex] = useState(0);
    const handleSlideChange = (swiper) => {
        setActiveIndex(swiper.activeIndex)
    };

    const fetchData = async () => {
        setLoading((prev) => ({
            ...prev,
            bannerLoader: true
        }))
        try {
            const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/tvshows-sliders`, {
                Login_user_type: parseInt(getItem('role'), 10) || 9,
                Login_device_type: "web",
                Login_user_id: parseInt(getItem('user_id'), 10) || null,
            }, { headers: getItemToken('access_token') || null })
            const ListRes = [...response?.data?.series_sliders, ...response?.data?.Episode_sliders]
            if (response?.data?.status) {
                setData((prevData) => ({
                    ...prevData,
                    slider: ListRes,
                }));
                setLoading((prev) => ({
                    ...prev,
                    bannerLoader: false
                }))
            }
        }
        catch (error) {
            var errorMessage = error?.response?.data?.message;
            // toast.error(errorMessage)
            setLoading((prev) => ({
                ...prev,
                bannerLoader: false
            }))
        };
    };

    useEffect(() => {

        fetchData();
        dispatch(showHomePageAction())
    }, []);

    const swiperConfig = {
        slidesPerView: 1,
        spaceBetween: 10,
        lazy: true,
        centeredSlides: true,
        grabCursor: true,
        loop: false,
        effect: 'fade',
        onSlideChange: { handleSlideChange },
        modules: [Autoplay, Pagination, EffectFade],
        className: 'movieSwiper w-100  pb-4 position-relative',
        autoplay: false,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        },
        pagination: {
            clickable: true,
            dynamicBullets: true,
            dynamicMainBullets: 1
        }
    }
    return (
        <div className='paddingTop'>
            {loading?.bannerLoader ? <Swiper {...swiperConfig} >
                {Array.from({ length: 8 }).map((value) => (
                    <SwiperSlide key={value} className='' >
                        <div className='bannerContainer w-100 h-100 theme-bg-color-secondary '></div>
                    </SwiperSlide>))}
            </Swiper> :
                <Swiper {...swiperConfig}  >
                    {data?.slider?.length >= 0 && data?.slider?.map((item, index) => (
                        <SwiperSlide key={index} >
                            {item?.trailer_videos_url || item?.trailer_link ? <BannerPlayer data={item} index={index} sliderCurrentIndex={activeIndexOur} page="movie" /> : <BannerImage data={item} page="movie" />}
                        </SwiperSlide>
                    ))
                    }
                </Swiper >}
            <div className="paddingBottom  px-2 px-md-4" style={{ overflow: "hidden" }}>
                {isLoading ?
                    Array.from({ length: 8 }).map((n) => <StaticHomeSwiper key={n} type="show" />)
                    : showPage?.map((item, index) => {
                        return (
                            item?.source == "Series_Genre" ? <ShowCategoryAll item={item} key={item.id} />
                                : item?.source == "single_series" ? item?.data?.map((value) => <SingleSeriesAll item={item} value={value} ThumbnailTitle={thumbnailTitle} key={item.id} className />)
                                    : item?.source == "latest_series" || item?.source == "latest_viewed_Episode" || item?.source == "continue_watching" ? <ShowAll item={item} ThumbnailTitle={thumbnailTitle} key={item.id} /> :
                                        item?.source == "Series_based_on_Genres" ? item?.data.map((value) => <ShowBasedCategory value={value} ThumbnailTitle={thumbnailTitle} key={item.id} />) : null
                        )
                    }
                    )}
            </div>
        </div>
    )
}
const mapStateToProps = state => ({
    showPage: state.showHomePage.showPage,
    isLoading: state.showHomePage.isLoading,
    thumbnailTitle: state.get_allThumbnail_Reducer.thumbnailTitle
});
export default connect(mapStateToProps)(ShowPage)
