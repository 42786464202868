import React from "react";
import "../Dashboard/Dashboard.css";
import { Link } from "react-router-dom";
import { Button } from "rsuite";
function Dashboard() {

  const channel_name = localStorage.getItem("channel_name");

  const capitalizeFirstLetter = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };

  const capitalizedUsername = capitalizeFirstLetter(channel_name);

  return (
    <section className="">
      <div className="section dashboard ">
        <div className="admin-list-table">
          <h4 className="admin-title mb-0  theme-text-color text-md-start ">Good Afternoon, {capitalizedUsername}!</h4>
          <p className="admin-sub-title theme-text-color mb-0">Welcome to Channel Partner</p>
        </div>
        <div className="row mt-4 mx-0">
          <div className="col-lg-4">
            <div className="card info-card user-Channel theme-bg-color">
              <div className="card-body">
                <h5 className="card-title fw-bold text-center theme-text-color">
                  User's Of Channel
                </h5>
                <div className="d-flex align-items-center p-3">
                  <div className="col-lg-2 text-center">
                    {" "}
                    <span className="small pt-1 fs-6 fw-bold theme-text-color">375</span>
                  </div>
                  <div className="col-lg-2 text-center">
                    {" "}
                    <span className="theme-text-color"> | </span>{" "}
                  </div>
                  <div className="col-lg-8">
                    {" "}
                    <span className="text-muted small fs-6 pt-2 ps-1 theme-text-color">
                      Total Subscriber's
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center p-3">
                  <div className="col-lg-2 text-center">
                    {" "}
                    <span className="small pt-1 fs-6 fw-bold theme-text-color">5</span>
                  </div>
                  <div className="col-lg-2 text-center">
                    {" "}
                    <span className="theme-text-color"> | </span>{" "}
                  </div>
                  <div className="col-lg-8">
                    {" "}
                    <span className="text-muted small fs-6 pt-2 ps-1 theme-text-color">
                      New Subscriber's
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center p-3">
                  <div className="col-lg-2 text-center">
                    {" "}
                    <span className="small pt-1 fs-6 fw-bold theme-text-color">75</span>
                  </div>
                  <div className="col-lg-2 text-center">
                    {" "}
                    <span className="theme-text-color"> | </span>{" "}
                  </div>
                  <div className="col-lg-8">
                    {" "}
                    <span className="text-muted small fs-6 pt-2 ps-1 theme-text-color">
                      Total Video's
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center p-3">
                  <div className="col-lg-2 text-center">
                    {" "}
                    <span className="small pt-1 fs-6 fw-bold theme-text-color">37</span>
                  </div>
                  <div className="col-lg-2 text-center">
                    {" "}
                    <span className="theme-text-color"> | </span>{" "}
                  </div>
                  <div className="col-lg-8">
                    {" "}
                    <span className="text-muted small fs-6 pt-2 ps-1 theme-text-color">
                      Total Audio's
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center p-3">
                  <div className="col-lg-2 text-center">
                    {" "}
                    <span className="small pt-1 fs-6 fw-bold theme-text-color">75</span>
                  </div>
                  <div className="col-lg-2 text-center">
                    {" "}
                    <span className="theme-text-color"> | </span>{" "}
                  </div>
                  <div className="col-lg-8">
                    {" "}
                    <span className="text-muted small fs-6 pt-2 ps-1 theme-text-color">
                      Total Visitor's
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="card p-4  theme-bg-color ">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-6">
                    <h5 className="card-title theme-text-color">Get Started</h5>
                    <p className=" theme-text-color">You Are 3 Steps Away From Completion</p>
                  </div>
                  <div className="col-lg-6">
                    <p className=" theme-text-color">Subscribed To Business</p>
                    <p className=" theme-text-color">Package Ends On 2023-Apr-21</p>
                  </div>
                </div>
                <hr className=" theme-text-color"></hr>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="d-flex align-items-start">
                      <div
                        className="nav flex-column nav-pills me-3"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        {/* <button
                              className="nav-link active"
                              id="v-pills-home-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#v-pills-home"
                              type="button"
                              role="tab"
                              aria-controls="v-pills-home"
                              aria-selected="true"
                            >
                              Upload your content
                            </button> */}

                        <Button
                          className="nav-link rounded-2 mb-2  theme-bg-color-secondary  mb-1 theme-text-color"
                          id="v-pills-home-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-home"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-home"
                          aria-selected="true"
                        >
                          Upload your content
                        </Button>
                        <Button
                          className="nav-link  rounded-2 mb-2  theme-bg-color-secondary   mb-1 theme-text-color"
                          id="v-pills-profile-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-profile"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-profile"
                          aria-selected="false"
                        >
                          Setup Website Logo,
                          <br /> Name, Social Links,
                          <br /> Payment Types, Etc
                        </Button>
                        {/* <button
                              className="nav-link"
                              id="v-pills-profile-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#v-pills-profile"
                              type="button"
                              role="tab"
                              aria-controls="v-pills-profile"
                              aria-selected="false"
                            >
                              Setup Website Logo, Name, Social Links, Payment
                              Types, Etc
                            </button> */}

                        <Button
                          className="nav-link rounded-2 mb-1  theme-bg-color-secondary  theme-text-color"
                          id="v-pills-messages-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-messages"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-messages"
                          aria-selected="false"
                        >
                          Payment Plans
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="tab-content" id="v-pills-tabContent">
                      <div
                        className="tab-pane fade show active upload-video  theme-bg-color-secondary "
                        id="v-pills-home"
                        role="tabpanel"
                        aria-labelledby="v-pills-home-tab"
                      >
                        <h5 className="theme-text-color mb-2">First things first, you need Video.</h5>
                        <div className="theme-text-color mb-2">Upload A Video To Get Started.</div>
                        <Link to="/admin/addnewvideo">
                          Upload Videos Now
                        </Link>
                      </div>
                      <div
                        className="tab-pane fade upload-video theme-bg-color  theme-text-color global-shadow"
                        id="v-pills-profile"
                        role="tabpanel"
                        aria-labelledby="v-pills-profile-tab"
                      >
                        <div className="theme-text-color ">
                          Setup Website Logo, Name, Social Links, Payment
                          Types, Etc.
                        </div>
                        <div className="mt-3 theme-text-color ">
                          <Link to="">Go to Storefront Settings</Link>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade upload-video  theme-bg-color  theme-text-color global-shadow"
                        id="v-pills-messages"
                        role="tabpanel"
                        aria-labelledby="v-pills-messages-tab"
                      >
                        <div className=" theme-text-color">Setup Subscription Plans</div>
                        <div className="mt-3 theme-text-color">
                          <Link to="">Add Subscription Plans</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row p-0 m-0">
          <div className="col-lg-3">
            <div className=" iq-card-block iq-card-stretch iq-card-height">
              <div className="iq-card-body1 p-3 theme-bg-color rounded-3 ">
                <div className="d-flex align-items-center justify-content-center">
                  <div className="iq-cart-text text-center text-capitalize">
                    <p className="mb-0 fw-bold theme-text-color">view</p>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-3">
                  <h4 className=" mb-0 theme-text-color">3.724k</h4>
                  <h6 className="mb-0 theme-text-color">
                    35%
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className=" iq-card-block iq-card-stretch iq-card-height">
              <div className="iq-card-body1 p-3  theme-bg-color  rounded-3 ">
                <div className="d-flex align-items-center justify-content-center">
                  <div className="iq-cart-text text-center text-capitalize">
                    <p className="mb-0 fw-bold theme-text-color">Visitors</p>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-3">
                  <h4 className=" mb-0 theme-text-color">10</h4>
                  <h6 className="mb-0 text-primary  theme-text-color">
                    100%
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className=" iq-card-block iq-card-stretch iq-card-height">
              <div className="iq-card-body1 p-3 theme-bg-color  rounded-3 ">
                <div className="d-flex align-items-center justify-content-center">
                  <div className="iq-cart-text text-center text-capitalize">
                    <p className="mb-0  fw-bold theme-text-color">view</p>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-3">
                  <h4 className=" mb-0 theme-text-color">3.724k</h4>
                  <h6 className="mb-0 text-primary theme-text-color">
                    45%
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="iq-card-block iq-card-stretch iq-card-height">
              <div className="iq-card-body1 p-3 theme-bg-color  rounded-3 ">
                <div className="d-flex align-items-center justify-content-center">
                  <div className="iq-cart-text text-center text-capitalize">
                    <p className="mb-0  fw-bold theme-text-color">view</p>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-3">
                  <h4 className=" mb-0 theme-text-color">3.724k</h4>
                  <h6 className="mb-0 text-primary theme-text-color">
                    95%
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row p-3 m-0">
              <div className="col-md-12">
                <div className="d-flex align-items-center mb-3">
                  <h3>Overview</h3>
                  <div className="days-cards ms-2">
                    <p className="mb-0">
                      <AiOutlineCalendar /> Last 7 Days
                    </p>
                  </div>
                </div>
              </div>
            </div> */}

        <div className="row p-0 m-0 pb-3">
          <div className="col-lg-6">
            <div className="row m-0">
              <div className="col-6 px-0 pe-3">
                <div className="p-3 iq-cards theme-bg-color rounded-2  mt-3">
                  <p className="theme-text-color">Revenue</p>
                  <h4 className="theme-text-color">USD 0</h4>
                  <div className="progress-bar-vertical">
                    <div className="progress-now-vertical"></div>
                  </div>
                </div>
              </div>
              <div className="col-6 px-0 ps-3">
                <div className="p-3 iq-cards theme-bg-color rounded-3  mt-3">
                  <p className=" theme-text-color">ARPU</p>
                  <h4 className=" theme-text-color">USD 0</h4>
                  <div className="progress-bar-vertical">
                    <div className="progress-now-vertical"></div>
                  </div>
                </div>
              </div>
              <div className="col-6 px-0 pe-3">
                <div className="p-3 iq-cards theme-bg-color rounded-3  mt-3">
                  <p className=" theme-text-color">Views</p>
                  <h4 className=" theme-text-color">0</h4>
                  <div className="progress-bar-vertical">
                    <div className="progress-now-vertical"></div>
                  </div>
                </div>
              </div>
              <div className="col-6 px-0 ps-3">
                <div className="p-3 iq-cards theme-bg-color rounded-3  mt-3">
                  <p className=" theme-text-color">Watch Duration</p>
                  <h4 className=" theme-text-color">00h 00m 00s</h4>
                  <div className="progress-bar-vertical">
                    <div className="progress-now-vertical"></div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="col-lg-6">
            <div className=" iq-card-block iq-card-stretch iq-card-height">
              <div className="section-con">
                <div className="iq-card-body1 p-3 theme-bg-color  rounded-3  mt-3">
                  <div className="row">
                    <div className="col-md-7">
                      <div>
                        <h5 className=" theme-text-color">Trending Content</h5>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="d-flex align-items-center justifiy-content-center">
                        <h5 className=" theme-text-color">By</h5>
                        <div>
                          <div className="dropdown ms-2">
                            <Link
                              className="btn btn-outline-secondary dropdown-toggle  theme-text-color"
                              to="#"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Views
                            </Link>

                            <ul className="dropdown-menu  theme-bg-color  global-shadow rounded-3  ">
                              <li>
                                <Link className="dropdown-item  theme-text-color" >
                                  Action
                                </Link>
                              </li>
                              <li>
                                <Link className="dropdown-item  theme-text-color" >
                                  Another action
                                </Link>
                              </li>
                              <li>
                                <Link className="dropdown-item  theme-text-color">
                                  Something else here
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Dashboard;
