// External Libraries and Packages
import React, { useEffect, useMemo } from "react";
import { connect, useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
// Internal Styles
// Internal Components
import { viewAllPageAction } from "../../../Redux/Actions/apiActions";
import { URLGenerater } from "../../../Pages/CommonMethods/CommonMethods";
import PageLoader from "../../Loader/PageLoader";
import PageError from "../../PageError";
import LayoutViewAll from "./LayoutViewAll";

const CategoryViewAll = ({ homepage, viewAllData, getSource, isLoading, error, settings }) => {
  const { source } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const user_role = localStorage.getItem("role");
  const user_id = localStorage.getItem("user_id");
  const access_token = localStorage.getItem("access_token");

  const headers = useMemo(() => ({
    "Content-Type": "application/json",
    headers: { "Access-Control-Allow-Origin": "*" },
    Authorization: "Bearer " + access_token,
  }), [access_token]);

  useEffect(() => {
    const sourceURLChanges = {
      "live-categories": "livecategories",
      "series-categories": "series-genre",
      "video-categories": "videocategories",
      "audios-categories": "audio-genre",
    };

    const mappedSource = sourceURLChanges[source] || "";
    const videoDataList = homepage.find(
      (item) => URLGenerater(item.source) === mappedSource
    );

    if (videoDataList) {
      const requestApi = videoDataList.Redirection_PageList_URL;
      if (settings && Object.keys(settings).length > 0) {
        if (!user_role) {
          dispatch(viewAllPageAction(null, 9, "web", headers, requestApi));
        } else {
          dispatch(
            viewAllPageAction(user_id, user_role, "web", headers, requestApi)
          );
        }
      }
    } else {
      console.log(`Object with source '${source}' not found.`);
    }
  }, [settings, user_role, homepage, user_id, location, dispatch, headers, source]);

  return isLoading ? <PageLoader /> : error ? <PageError /> : <LayoutViewAll mapData={viewAllData?.data} source={source} path={source} headingClass=' pb-4 text-center' />
};
const mapStateToProps = (state) => ({
  homepage: state.get_allHomePage_Reducer.homepage,
  viewAllData: state.get_viewall_Reducer.viewAllData,
  isLoading: state.get_viewall_Reducer.isLoading,
  error: state.get_viewall_Reducer.error,
  settings: state.get_setting_Reducer.settings,
});

export default connect(mapStateToProps)(CategoryViewAll);
