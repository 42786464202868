import "../Styles/CommonAdmin.css"
import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Signin from "../channelportal/Signin/Signin";
import Error404 from "../components/Error404";
import AddNewVideo from "../channelportal/Pages/VideoManagement/AddNewVideo";
import ManageVideoCategories from "../channelportal/Pages/VideoManagement/ManageVideoCategories";
import Payouts from "../channelportal/Pages/Analytics/Payouts";
import VideoAnalytics from "../channelportal/Pages/Analytics/VideoAnalytics";
import VideoViewRegion from "../channelportal/Pages/Analytics/VideoViewRegion";
import MyChannelSettings from "../channelportal/Pages/SettingManage/MyChannelSettings";
import AddManageCategories from "../channelportal/Pages/ManageLiveVideo/AddManageCategories";
import EditManageCategories from "../channelportal/Pages/ManageLiveVideo/EditManageCategories";
import ManageLiveEvent from "../channelportal/Pages/ManageLiveVideo/ManageLiveEvent";
import AddManageLiveEvent from "../channelportal/Pages/ManageLiveVideo/AddManageLiveEvent";
import EditManageLiveEvent from "../channelportal/Pages/ManageLiveVideo/EditManageLiveEvent";
import AudioLists from "../channelportal/Pages/AudioManagement/AudioLists";
import ManageAudioCategories from "../channelportal/Pages/AudioManagement/ManageAudioCategories";
import AddCategory from "../channelportal/Pages/AudioManagement/AddCategory";
import ManageAlbums from "../channelportal/Pages/AudioManagement/ManageAlbums";
import Addalbums from "../channelportal/Pages/AudioManagement/Addalbums";
import SeriesLists from "../channelportal/Pages/Series&Episode/SeriesLists";
import AddNewSeries from "../channelportal/Pages/Series&Episode/AddNewSeries";
import EditNewSeries from "../channelportal/Pages/Series&Episode/EditNewSeries";
import Edit_seriesseason from "../channelportal/Pages/Series&Episode/Edit_seriesseason";
import Manage_Episode from "../channelportal/Pages/Series&Episode/Manage_Episode";
import Edit_Episode_manage from "../channelportal/Pages/Series&Episode/Edit_Episode_manage";
import Edit_Episode_Video from "../channelportal/Pages/Series&Episode/Edit_Episode_Video";
import AddManageVideoCategory from "../channelportal/Pages/VideoManagement/AddManageVideoCategory";
import EditManageVideoCategory from "../channelportal/Pages/VideoManagement/EditManageVideoCategory";
import AddnewAudios from "../channelportal/Pages/AudioManagement/AddnewAudios";
import Edit_album from "../channelportal/Pages/AudioManagement/Edit_album";
import Edit_category from "../channelportal/Pages/AudioManagement/Edit_category";
import Edit_new_audio from "../channelportal/Pages/AudioManagement/Edit_new_audio";
import AboutMyChannel from "../channelportal/Pages/SettingManage/AboutMyChannel";
import Edit_SingleAudio from "../channelportal/Pages/AudioManagement/Edit_SingleAudio";
import ChannelAllVideos from "../channelportal/MainPage/ChannelVideo/ChannelAllVideos";
import Channelalllivestream from "../channelportal/MainPage/ChannelLiveStream/Channelalllivestream";
import ChannelAddlivestream from "../channelportal/MainPage/ChannelLiveStream/ChannelAddlivestream";
import ChannelEditlivestream from "../channelportal/MainPage/ChannelLiveStream/ChannelEditlivestream";
import ChannelCategorylivestream from "../channelportal/MainPage/ChannelLiveStream/ChannelCategorylivestream";
import ChannelCommonLayout from "../components/Layout/ChannelCommonLayout";
import Channel_Signup from "../channelportal/Signup/Channel_Signup";
import DashBoards from "../channelportal/Dashboard/Dashboard";
import Editvideo from "../channelportal/Pages/VideoManagement/EditVideo";
import SeriesCategory from "../channelportal/Pages/Series&Episode/SeriesCategory";
import AddSeriesCategory from "../channelportal/Pages/Series&Episode/AddSeriesCategory";
import EditSeriesCategory from "../channelportal/Pages/Series&Episode/EditSeriesCategory";
import ChanneListArtists from "../channelportal/Pages/ArtistManagement/ChanneListArtists";
import ChannelAddArtists from "../channelportal/Pages/ArtistManagement/ChannelAddArtists";
import ChannelUpdateArtists from "../channelportal/Pages/ArtistManagement/ChannelUpdateArtists";
import { useMyContext } from "../components/Layout/CommonProvider";
import PageLoader from "../components/Loader/PageLoader";


function ChannelAllRoutes() {
  const userData = localStorage.getItem("access_token_channel");
  const { navigateTranslateChecker } = useMyContext()
  return (
    <React.Fragment>
      <Suspense fallback={ <PageLoader /> }>
        <Routes>
          <Route path="/signup" element={<Channel_Signup />} />
          <Route path="/login" element={<Signin />} />
          <Route path="/" element={userData ? <ChannelCommonLayout /> : <Navigate to={navigateTranslateChecker(`/channel/login`)} />}>
            <Route index element={<DashBoards />} />
            <Route path="/home" element={<DashBoards />} />

            {/* Video route start */}
            <Route path="/video-lists" element={<ChannelAllVideos />} />
            <Route path="/add-video" element={<AddNewVideo />} />
            <Route path="/edit-video/:id" element={<Editvideo />} />

            <Route path="/video-categories-list" element={<ManageVideoCategories />} />
            <Route path="/add-video-category" element={<AddManageVideoCategory />} />
            <Route path="/edit-video-category/:id" element={<EditManageVideoCategory />} />
            {/* Video route end */}

            {/* Series route start */}
            <Route path="/series-lists" element={<SeriesLists />} />
            <Route path="/add-series" element={<AddNewSeries />} />
            <Route path="/edit-series/:id" element={<EditNewSeries />} />

            <Route path="/series-categories-list" element={<SeriesCategory />} />
            <Route path="/add-series-category" element={<AddSeriesCategory></AddSeriesCategory>} />
            <Route path="/edit-series-category/:id" element={<EditSeriesCategory />} />

            <Route path="/edit-season/:id" element={<Edit_seriesseason />} />
            <Route path="/manage-episode/:seriesid/:id" element={<Manage_Episode />} />
            <Route path="/edit-episode/manage/:id" element={<Edit_Episode_manage />} />
            <Route path="/edit-episode/video-manage/:id" element={<Edit_Episode_Video />} />
            {/* Series route end */}

            <Route path="/all-audio-lists" element={<AudioLists />} />
            <Route path="/add-new-audios" element={<AddnewAudios />} />
            <Route path="/manage-audio-category" element={<ManageAudioCategories />} />
            <Route path="/add-audio-category" element={<AddCategory />} />
            <Route path="/audio-manage-albums" element={<ManageAlbums />} />
            <Route path="/add-audio-albums" element={<Addalbums />} />
            <Route path="/edit-audio-albums/:id" element={<Edit_album />} />
            <Route path="/audio-edit-category/:id" element={<Edit_category />} />
            <Route path="/editaudio/:id" element={<Edit_new_audio />} />
            <Route path="/audio/edit/:id" element={<Edit_SingleAudio />} />
            <Route path="/all-livevideos" element={<Channelalllivestream />} />
            <Route path="/add-livestream" element={<ChannelAddlivestream />} />
            <Route path="/edit-livestream/:id" element={<ChannelEditlivestream />} />
            <Route path="/manage-live-category" element={<ChannelCategorylivestream />} />
            <Route path="/add-manage-live-category" element={<AddManageCategories />} />
            <Route path="/edit-live-category/:id" element={<EditManageCategories />} />
            <Route path="/manage-live-event" element={<ManageLiveEvent />} />
            <Route path="/add-manage-live-event" element={<AddManageLiveEvent />} />
            <Route path="/edit-live-event/:id" element={<EditManageLiveEvent />} />
            <Route path="/mychannel-setting" element={<MyChannelSettings />} />
            <Route path="/about-my-channel" element={<AboutMyChannel />} />
            <Route path="/video-analytics" element={<VideoAnalytics />} />
            <Route path="/video-region" element={<VideoViewRegion />} />
            <Route path="/payouts" element={<Payouts />} />
            {/* Artist Route Get Post Update Route Start */}
            <Route path="/all-artist" element={<ChanneListArtists />} />
            <Route path="/add-artist" element={<ChannelAddArtists />} />
            <Route path="/update-artist/:artistid" element={<ChannelUpdateArtists />} />
            {/* Artist Route Get Post Update Route End */}
            <Route path="/*" element={<Error404></Error404>} />
            {/* <Route path="/my-profile" element={<Myprofile></Myprofile>}></Route>
                  <Route path="/edit-livestream/:id"  element={  <EditNewLiveVideo  setApiresponsealert={setApiresponsealert}  setShowalert={setShowalert}></EditNewLiveVideo>  } ></Route>
                  <Route  path="/edit-audio/:id" element={ <Edit_new_audio ></Edit_new_audio> }> </Route>
                  <Route  path="/edit-category/:id" element={ <Edit_category ></Edit_category>} > </Route>
                  <Route path="/edit-albums/:id" element={<Edit_album setApiresponsealert={setApiresponsealert} setShowalert={setShowalert} ></Edit_album>  }  ></Route> */}
          </Route >
        </Routes >
      </Suspense >
    </React.Fragment >
  );
}

export default ChannelAllRoutes;