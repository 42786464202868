import React from 'react'
import LazyLoadImage from '../LazyLoadImage'
import PriceLabel from '../../../../components/PriceLabel'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import MessageBox from '../../../../components/MessageBox'
import { calculateTotalTime } from '../../../CommonMethods/CommonMethods'
import { useMyContext } from '../../../../components/Layout/CommonProvider'

const CardWithoutHover = ({ value, segMent, thumbnailCategory, thumbnailRating, thumbnailDuration, thumbnailPublishedYear, thumbnailAge, thumbnailTitle, thumbnail }) => {
    const { navigateTranslateChecker } = useMyContext()
    return (
        <>
            <Link to={navigateTranslateChecker(`/${segMent}/${value?.slug ? value?.slug : value?.video_slug ? value?.video_slug : value?.series_slug ? value?.series_slug : value?.artist_slug ? value?.artist_slug : value?.channel_slug}`)} className='w-100 h-100 position-relative rounded-3 overflow-hidden ' >
                <PriceLabel item={value} />
                <div className={`theme-bg-color-secondary h-100 d-flex flex-column justify-content-between rounded-2`}>
                    <div className="homeListImage active">
                        <LazyLoadImage key={value.id} src={value?.Player_image_url || value?.Episode_Player_thumbnail || value?.Player_thumbnail || value?.image_url} alt={value?.title || value?.name || value?.episode_title} title={value?.title || value?.name || value?.episode_title || value?.video_title} classNameOptional="radiusTarget object-fit-cover" imageSize='object-fit-cover' />
                    </div>
                    <div className={`homeListContent active px-2 pt-3 pb-2`}>
                        <div className="d-flex align-items-center justify-content-between gap-3 mb-3">
                            <div className="d-flex align-items-center gap-3 ">

                                {thumbnailPublishedYear === 1 && value?.year && value?.year !== null && value?.year !== "" ?
                                    (<span className="homePageCardSpecial d-block theme-text-color position-relative"> {value?.year?.slice(0, 4)}</span>)
                                    : value?.episode_year && value?.episode_year !== null && value?.episode_year !== "" ? (<span className="homePageCardSpecial d-block theme-text-color position-relative"> {value?.episode_year.slice(0, 4)}</span>)
                                        : value?.video_year && value?.video_year !== null && value?.video_year !== "" ? (<span className="homePageCardSpecial d-block theme-text-color position-relative"> {value?.video_year.slice(0, 4)}</span>)
                                            : ''}

                                {thumbnailDuration == 1 && value?.duration ?
                                    (<span className="homePageCardSpecial d-block theme-text-color position-relative"> {calculateTotalTime(value?.duration)} </span>)
                                    : value?.episode_duration ? calculateTotalTime(value?.episode_duration) !== '' && (<span className="homePageCardSpecial d-block theme-text-color position-relative"> {calculateTotalTime(value?.episode_duration)} </span>)
                                        : value?.video_duration ? calculateTotalTime(value?.video_duration) !== '' && (<span className="homePageCardSpecial d-block theme-text-color position-relative"> {calculateTotalTime(value?.video_duration)} </span>)
                                            : ''}
                                {thumbnailRating === 1 && value?.rating ? (<span className="homePageCardSpecial d-block theme-text-color position-relative">{value?.rating} </span>) : ''}
                                {thumbnailCategory === 1 && value?.categories ? (<span className="homePageCardSpecial d-block theme-text-color position-relative"> {value?.categories}</span>) : ''}
                                {thumbnailAge === 1 && value?.age_restrict ? (<span className="homePageCardSpecial d-block theme-text-color position-relative">{value?.age_restrict} +</span>) : ''}
                            </div>
                            <Link to={navigateTranslateChecker(`/${segMent}/${value?.slug ? value?.slug : value?.video_slug ? value?.video_slug : value?.series_slug ? value?.series_slug : value?.artist_slug ? value?.artist_slug : value?.channel_slug}`)} className="homePageCardSpecial d-block theme-text-color position-relative "><MessageBox text="More" /> </Link>
                        </div>
                        {thumbnailTitle === 1 ? (
                            <>
                                {value?.title && (<MessageBox classname="homePageCardHeading theme-text-color " text={value?.title}></MessageBox>)}
                                {value?.name && (<MessageBox classname="homePageCardHeading theme-text-color " text={value?.name}></MessageBox>)}
                                {value?.episode_title && (<MessageBox classname="homePageCardHeading theme-text-color " text={value?.episode_title}></MessageBox>)}
                                {value?.video_title && (<MessageBox classname="homePageCardHeading theme-text-color " text={value?.video_title}></MessageBox>)}
                            </>
                        ) : (
                            ""
                        )}
                        {(value.description || value?.episode_description) && <MessageBox classname="d-block homeListDesc active theme-text-color  mb-0  mt-2 opacity-75" text={value.description || value.episode_description} />}
                        {value.artist && <MessageBox classname="d-block homeListDesc active theme-text-color  mb-0  mt-2 opacity-75" text={value?.artist} />}
                    </div>
                </div>
            </Link>
        </>
    )
}
const mapStateToProps = state => ({
    thumbnailTitle: state.get_allThumbnail_Reducer.thumbnailTitle,
    thumbnailAge: state.get_allThumbnail_Reducer.thumbnailAge,
    thumbnailPublishedYear: state.get_allThumbnail_Reducer.thumbnailPublishedYear,
    thumbnailDuration: state.get_allThumbnail_Reducer.thumbnailDuration,
    thumbnailRating: state.get_allThumbnail_Reducer.thumbnailRating,
    thumbnailCategory: state.get_allThumbnail_Reducer.thumbnailCategory,
    thumbnail: state?.get_allThumbnail_Reducer?.thumbnail
});
export default connect(mapStateToProps)(CardWithoutHover);
