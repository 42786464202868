import React, { useState, useEffect, useRef } from "react";
import {  useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "./EditSeriesCategory.css";
import { toast } from "react-toastify";
 
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import loadings from "../../../assets/gif/loading.gif";
import ResponseLoader from "../../../components/Loader/ResponseLoader";
import AdminSaveHeader from "../../../components/adminCommonComponents/AdminSaveHeader";
import FormContainer from "../../CommonComponents/FormContainer";
import LeftWrapper from "../../CommonComponents/LeftWrapper";
import RightWrapper from "../../CommonComponents/RightWrapper";
import Wrapper from "../../CommonComponents/Wrapper";
import { IoClose } from "react-icons/io5";
import MessageHeading from "../../CommonComponents/MessageHeading";
import MessageLabel from "../../CommonComponents/MessageLabel";
import AdminCustomButton from "../../../components/adminCommonComponents/AdminCustomButton";
import MessageBox from "../../../components/MessageBox";

function EditSeriesCategory() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    id: "",
    user_id: "",
    order: "",
    parent_id: "",
    name: "",
    slug: "",
    in_home: "",
    footer: "",
    in_menu: "",
    uploaded_by: "",
  });
  const [editname, setEditname] = useState({
    name: "",
  });

  const [message, setMessage] = useState("");

  const [image, setImage] = useState("");
  const [data, setData] = useState([]);
  const [banner, setBanner] = useState("");
  const [loading, setLoading] = useState(true);
  const access_token_channel = localStorage.getItem("access_token_channel");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token_channel,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);

  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = (setSelectedFile1, setSelectedFile2) => {
    setSelectedFile1(null);
    if (fileInputRef1.current) {
      fileInputRef1.current.value = null;
    }
    setSelectedFile2(null);
    if (fileInputRef2.current) {
      fileInputRef2.current.value = null;
    }
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-category/create`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        var result = response?.data?.Series_Genre;
        setData(result);
      })
      .catch((error) => console.log(error));

    const getUser = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-category/edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.SeriesGenre[0];
      setEditUser(res);
      setEditname(res);
      setLoading(false);
    };
    getUser();
  }, [id]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));
    setValidationMessagetitle("");
    setIsValidationHiddentitle(true);
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };

  const handleInputenable = (e) => {
    if (e.target.checked == true) {
      setEditUser({ ...editUser, [e.target.name]: 1 });
    } else {
      setEditUser({ ...editUser, [e.target.name]: 0 });
    }
  };

  const [validationMessagetitle, setValidationMessagetitle] = useState("");
  const [isValidationHiddentitle, setIsValidationHiddentitle] = useState(true);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const titleInputRef = useRef(null);

  const handleUpdate = async (e) => {
    let focusInputRef = null;

    if (editUser?.name == "") {
      setValidationMessagetitle("Name cannot be empty*.");
      setIsValidationHiddentitle(false);
      focusInputRef = titleInputRef;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    e.preventDefault();

    const editInputvalue = {
      id: editUser?.id,
      user_id: editUser?.user_id,
      order: editUser?.order,
      parent_id: editUser?.parent_id,
      name: editUser?.name,
      image: editUser?.image,
      slug: editUser?.slug,
      in_home: editUser?.in_home,
      footer: editUser?.footer,
      banner: editUser?.banner,
      uploaded_by: editUser?.uploaded_by,
      in_menu: editUser?.in_menu,
      banner_image: editUser?.banner_image,
    };

    let firstApiStatus = false;
    let secondApiStatus = false;
    let resultapi = null;
    let resjson = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-category/update/${id}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res.json();

      if (resjson.status == true) {
        firstApiStatus = true;

        const SeriesGenre_id = resjson?.SeriesGenre_id;
        resultapi = resjson;

        const formData = new FormData();
        formData.append("series_genre_id", SeriesGenre_id);
        if (selectedFile1) {
          formData.append("image", selectedFile1);
        }
        if (selectedFile2) {
          formData.append("banner_image", selectedFile2);
        }

        await axios.post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-category/Image-upload`,
          formData,
          { headers: headers }
        );
        secondApiStatus = true;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus && secondApiStatus) {
      setValidationMessagetitle("");
      toast.success(resultapi?.message);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resjson.status == false) {
        var resultError = resjson;
        toast.error(resultError?.message);
        setIsValidationHiddentitle(false);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  };

  return (
    <>
      {showOverlay && (<ResponseLoader />)}
      <AdminSaveHeader heading="Edit series genre" saveText="Update series genre" saveMethod={handleUpdate} />
      <FormContainer >
        <LeftWrapper>
          <Wrapper>
            <MessageHeading text={editname.name} />
            <MessageLabel text="Name" className='mandatorySimple' />
            <input
              type="text"
              className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"
              id="name"
              name="name"
              placeholder="Enter Name"
              onChange={handleInput}
              value={editUser?.name}
              ref={titleInputRef}
            />
            {!isValidationHiddentitle && (<MessageBox text={`${validationMessagetitle}`} classname='errorred mb-2 d-block' />)}
            <MessageLabel text="Slug" />
            <input
              type="text"
              className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"
              id="slug"
              name="slug"
              placeholder="Enter Slug"
              onChange={handleInput}
              value={editUser?.slug}
            />
            <MessageLabel text="Category" />
            <select
              className="form-select theme-bg-color-secondary custom-placeholder mb-3 border-0 theme-text-color"
              name="parent_id"
              id="parent_id"
              onChange={handleInput}
              value={editUser?.parent_id}
            >
              <option value="" className="theme-bg-color theme-text-color">Choose Category</option>
              {data?.map((item) => (
                <option value={item?.order} className="theme-bg-color theme-text-color">{item?.name}</option>
              ))}
            </select>
          </Wrapper>
          <Wrapper >
            <MessageHeading text="Status Settings" />

            <div className="mx-0 mb-2 row">
              <div className="col-9 ps-0">
                <MessageLabel text="Display In Menu" />
              </div>
              <div className="col-3 pe-0 text-end">
                <label className="switch">
                  <input
                    name="in_menu"
                    onChange={handleInputenable}
                    defaultChecked={
                      editUser?.in_menu == 1 ? true : false
                    }
                    checked={editUser?.in_menu == 1 ? true : false}
                    className="rs-input"
                    type="checkbox"
                  />
                  <span
                    className="slider round"
                    name="in_menu"
                    onChange={handleInputenable}
                    value={editUser?.in_menu == 1 ? "1" : "0"}
                  ></span>
                </label>
              </div>
            </div>
            <div className="mx-0  mb-2 row ">
              <div className="col-9 ps-0">
                <MessageLabel text="Display In Home" />
              </div>
              <div className="col-3 pe-0 text-end">
                <label className="switch">
                  <input
                    name="in_home"
                    onChange={handleInputenable}
                    className="r-input"
                    defaultChecked={
                      editUser?.in_home == 1 ? true : false
                    }
                    checked={editUser?.in_home == 1 ? true : false}
                    type="checkbox"
                  />
                  <span
                    className="slider round"
                    name="in_home"
                    onChange={handleInputenable}
                    value={editUser?.in_home == 1 ? "1" : "0"}
                  ></span>
                </label>
              </div>
            </div>
            <div className="mx-0  row">
              <div className="col-9 ps-0">
                <MessageLabel text="Is this episode display in Footer" />
              </div>
              <div className="col-3 pe-0 text-end">
                <label className="switch">
                  <input
                    name="footer"
                    id="footer"
                    onChange={handleInputenable}
                    className="r-input"
                    defaultChecked={
                      editUser?.footer == 1 ? true : false
                    }
                    checked={editUser?.footer == 1 ? true : false}
                    type="checkbox"
                  />
                  <span
                    className="slider round"
                    name="footer"
                    id="footer"
                    onChange={handleInputenable}
                    value={editUser?.footer == 1 ? "1" : "0"}
                  ></span>
                </label>
              </div>
            </div>
          </Wrapper>
        </LeftWrapper >
        <RightWrapper>
          <Wrapper >
            <MessageHeading text="Thumbnails" />
            <MessageLabel text="Image" />
            {loading ? (
              <img src={loadings} className="thumbnails-images" />
            ) : (
              <img
                src={editUser?.image_url}
                alt="Image"
                className="thumbnails-images"
              />
            )}
            <div className="mx-0 my-2 row">
              <div className="col-6 ps-0">
                <div
                  className="imagedrop theme-border-color"
                  onClick={() => fileInputRef1.current.click()}
                >
                  <input
                    type="file"
                    ref={fileInputRef1}
                    onChange={(event) =>
                      handleFileChange(
                        event,
                        fileInputRef1,
                        setSelectedFile1
                      )
                    }
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                  <label className=" theme-text-color">
                    <CameraRetroIcon className=" theme-text-color" />
                  </label>
                </div>
              </div>
              <div className="col-6 pe-0">
                <div>
                  {selectedFile1 && (
                    <div className="imagedropcopy theme-border-color text-end">
                      <div className="mx-0 row">
                        <div className="col-10 ps-0">
                          <img
                            src={URL?.createObjectURL(
                              selectedFile1
                            )}
                            alt="Uploaded"
                            className="thumbnails-upload-images"
                          />
                        </div>
                        <div
                          className="col-2 ps-0"
                          style={{ alignSelf: "center" }}
                        >
                          <button
                            onClick={() =>
                              handleDelete(setSelectedFile1)
                            }
                            type="button"
                            className="bg-transparent theme-text-color"
                          ><IoClose className="custom-close-btn" /></button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <MessageLabel text="Banner" />
            {loading ? (
              <img src={loadings} className="thumbnails-images" />
            ) : (
              <img
                src={editUser?.banner_image_url}
                alt="Image"
                className="thumbnails-images"
              />
            )}
            <div className="mx-0 mt-2 row">
              <div className="col-6 ps-0">
                <div
                  className="imagedrop theme-border-color"
                  onClick={() => fileInputRef2.current.click()}
                >
                  <input
                    type="file"
                    ref={fileInputRef2}
                    onChange={(event) =>
                      handleFileChange(
                        event,
                        fileInputRef2,
                        setSelectedFile2
                      )
                    }
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                  <label className=" theme-text-color">
                    <CameraRetroIcon className=" theme-text-color" />
                  </label>
                </div>
              </div>
              <div className="col-6 pe-0">
                <div>
                  {selectedFile2 && (
                    <div className="imagedropcopy theme-border-color text-end">
                      <div className="row mx-0">
                        <div className=" col-10 ps-0">
                          <img
                            src={URL.createObjectURL(selectedFile2)}
                            alt="Uploaded"
                            className="thumbnails-upload-images"
                          />
                        </div>
                        <div
                          className="col-2 ps-0 text-center"
                          style={{ alignSelf: "center" }}
                        >
                          <button
                            onClick={() =>
                              handleDelete(setSelectedFile2)
                            }
                            type="button"
                            className="bg-transparent theme-text-color"
                          ><IoClose className="custom-close-btn" /></button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Wrapper>
        </RightWrapper >
        <AdminCustomButton saveMethod={handleUpdate} saveText="Update series genre" className="ms-auto" />
      </FormContainer >

    </>
  );
}

export default EditSeriesCategory;