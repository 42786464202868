import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import * as XLSX from "xlsx";
import { CSVLink } from "react-csv";
import { jsPDF } from "jspdf";
import {
  MdDelete,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import { toast } from "react-toastify";
 
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaCirclePlus } from "react-icons/fa6";
import { MdOutlineClose } from "react-icons/md";
import "./Contentallaudioalbum.css";

import deleteitem from "../../../assets/icons/deleteitem.svg";
import edititem from "../../../assets/icons/edititem.svg";
import loadings from "../../../assets/gif/loading.gif";
import defaultImage from "../../../assets/images/default_vertical_image.webp";
import ExportIcon from "../../../assets/icons/export-icon.webp";
import ResponseLoader from "../../../components/Loader/ResponseLoader";

function Contentallaudioalbum() {
  const [getAlbum, setAlblum] = useState([]);
  const [filteredAlbumData, setFilteredAlbumData] = useState([]);
  const [itemToDeleteAlbum, setItemToDeleteAlbum] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [modalCloseAlbum, setModalCloseAlbum] = useState(false);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const access_token_cpp = localStorage.getItem("access_token_cpp");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token_cpp,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const formData = new FormData();

      formData.append("Content_Partner_id", 1);
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/Audio-Album/List`,
        formData,
        { headers: headers }
      );
      const albumData = response?.data?.audio_albums?.data;
      console.log(response?.data);
      const FilterHelp = albumData?.map((item) => {
        const uploadBy =
          item?.uploaded_by == "1"
            ? "Admin"
            : item?.uploaded_by == "5"
            ? "Content Partner"
            : item?.uploaded_by == "4"
            ? "Channel Partner"
            : "";

        return { ...item, uploaded_by: uploadBy };
      });
      setAlblum(FilterHelp);
      setFilteredAlbumData(FilterHelp);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    nextPageText: "Next",
    prePageText: "Prev",
    firstPageText: <MdKeyboardDoubleArrowLeft />,
    lastPageText: <MdKeyboardDoubleArrowRight />,
    showTotal: true,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "All", value: filteredAlbumData?.length },
    ],
  });

  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (row) {
        if (isSelect) {
          setItemToDeleteAlbum([...itemToDeleteAlbum, row.id]);
        } else {
          setItemToDeleteAlbum(
            itemToDeleteAlbum.filter((id) => id !== row?.id)
          );
        }
      }
    },
    selected: itemToDeleteAlbum,
    selectionHeaderRenderer: ({ mode, checked, indeterminate }) => (
      <div>
        <input
          type="checkbox"
          checked={checked}
          indeterminate={indeterminate}
          onChange={handleAll}
        />
      </div>
    ),
  };

  const imageFormatter = (data, row) => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <img
          src={row.image_url ? row.image_url : defaultImage}
          className="profile"
        />

        <div className="rightVideoTitle">
          <p className=" theme-text-color">{data}</p>
          <span className="dateText">{row?.upload_on}</span>
        </div>
      </div>
    );
  };

  const actionFormatter = (data, row) => {
    return (
      <div className="editdropdown">
        <span className="editdropdown-button">
          <span>
            <BsThreeDotsVertical className=" theme-text-color" />
          </span>
        </span>
        <div className="editdropdown-menu theme-bg-color">
          <Link to={`/cpp/edit-albums/${row.id}`} className=" theme-bg-color">
            <span>
              <img src={edititem} alt="flogo" width={20} height={20} />
              <span className="ms-2 theme-text-color"> Edit Album </span>
            </span>
          </Link>

          <div
            onClick={() => openModalSingleDeleteAlbum(row.id)}
            className="commonActionPadding theme-bg-color"
          >
            <img src={deleteitem} alt="flogo" width={20} height={20} />
            <span className="ms-2  theme-bg-color">Delete Album</span>
          </div>
        </div>
      </div>
    );
  };

  const columns = [
    {
      dataField: "albumname",
      text: "Album",
      formatter: imageFormatter,
      sort: true,
    },
    {
      dataField: "uploaded_by",
      text: "Uploaded By",
      sort: true,
    },
    {
      dataField: "ACTIONS",
      text: "Actions",
      formatter: actionFormatter,
    },
  ];

  const exportHeadersAlbum = [
    { label: "ID", key: "id" },
    { label: "ALBUM NAME", key: "albumname" },
    { label: "UPLOADED BY", key: "uploaded_by" },
    { label: "UPLOAD-ON", key: "upload_on" },
  ];

  const conditionalGeneratePDFAlbum = (get) => {
    const doc = new jsPDF();
    doc.text("MANAGE ALBUM  LIST ", 10, 10);
    const columns = ["INDEX", "ID", "ALBUM NAME", "UPLOADED BY", "UPLOAD-ON"];
    const allDataPDF = getAlbum.map((item, index) => [
      index + 1,
      item.id,
      item.albumname,
      item.uploaded_by,
      item.upload_on,
    ]);
    const filterPDF = filteredAlbumData.map((item, index) => [
      index + 1,
      item.id,
      item.albumname,
      item.uploaded_by,
      item.upload_on,
    ]);

    doc.autoTable({
      startY: 20,
      head: [columns],
      body: get == 1 ? allDataPDF : filterPDF,
    });
    doc.save("MANAGE-ALBUM-LIST.pdf");
  };

  const generateXLSXALBUM = (get) => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(get == 1 ? getAlbum : filteredAlbumData);
    XLSX.utils.book_append_sheet(wb, ws, "MySheet");
    XLSX.writeFile(wb, "MANAGE-ALBUM-LIST.xlsx");
  };

  const handleFilterChangeAlbum = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setFilter(searchTerm);
    const filteredResults = getAlbum.filter((item) =>
      ["albumname", "uploaded_by", "upload_on"].some(
        (property) =>
          typeof item[property] == "string" &&
          item[property].toLowerCase().includes(searchTerm)
      )
    );
    setFilteredAlbumData(filteredResults);
  };

  const handleClear = () => {
    fetchData();
    setFilter("");
  };

  const handleAll = () => {
    setSelectAll(!selectAll);
    setItemToDeleteAlbum(
      selectAll ? [] : filteredAlbumData.map((item) => item.id)
    );
  };

  const closeAlbumModal = () => {
    setModalCloseAlbum(!modalCloseAlbum);
  };

  const openModalSingleDeleteAlbum = (id) => {
    setItemToDeleteAlbum([id]);
    setModalCloseAlbum(true);
  };

  const openModalMultiDeleteAlbum = () => {
    setModalCloseAlbum(true);
  };

  const handleDeleteAlbumData = () => {
    closeAlbumModal();
    albumdeleteOperation();
  };

  const albumdeleteOperation = async () => {
    setProcessing(true);
    setShowOverlay(true);
    const formData = new FormData();
    formData.append("id", "[" + itemToDeleteAlbum + "]");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/Audio-Album/multi-delete`,
        formData,
        {
          headers: headers,
        }
      );
      const result = response.data;
      if (result.status == true) {
        // props.setApiresponsealert(result);
        // props.setShowalert(true);
        toast.success(result?.message);
        setProcessing(false);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err.response.data.message;
      // props.setApiresponsealert({ status: false, message: errorMessage });
      // props.setShowalert(true);
      toast.error({ status: false, message: errorMessage });
      setProcessing(false);
      setShowOverlay(false);
    }
  };

  return (
    <>
      <div className="channel_audio_album">
        <div
          className={`modal  packageModel ${modalCloseAlbum ? "show" : ""}`}
          tabIndex="-1"
          style={{ display: modalCloseAlbum ? "block" : "none" }}
        >
          <div className="modal-dialog">
            <div className="modal-content theme-bg-color global-shadow">
              <div className="modal-header">
                <h5 className="modal-title theme-text-color">
                  Confirm Deletion
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeAlbumModal}
                ></button>
              </div>
              <div className="modal-body">
                {itemToDeleteAlbum?.length == 1 ? (
                  <p className="fs-6 theme-text-color">
                    {" "}
                    Are you sure you want to delete this Album ?
                  </p>
                ) : (
                  <p className="fs-6 theme-text-color">
                    Are you sure you want to delete the selected Album ?
                  </p>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={closeAlbumModal}
                >
                  Close
                </button>{" "}
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleDeleteAlbumData}
                >
                  Yes
                </button>{" "}
              </div>
            </div>
          </div>
        </div>
        <div>
        {showOverlay && (<ResponseLoader />)}
        </div>
        <section className="section container-fluid pt-3  theme-bg-color-secondary  paddingbottomm">
          <div id="content-page" className="content-page">
            <div className="row pb-3 admin-section-title">
              <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                <h4 className=" theme-text-color">
                  Audio Album{" "}
                  <span className="fs-6">
                    (Display all {getAlbum?.length} Audio Album)
                  </span>
                </h4>
              </div>
              <div
                className="col-lg-6 col-md-6 col-sm-6 col-6 mt-3 mt-md-0"
                align="right"
              >
                {itemToDeleteAlbum?.length > 0 && (
                  <button
                    className="btn btn-danger d-inline-flex  fw-bolder "
                    onClick={openModalMultiDeleteAlbum}
                  >
                    <MdDelete className="exportIcon " />
                    Delete
                  </button>
                )}

                <Link to="/cpp/add-albums">
                  <button type="button" className="btn btn-primary fw-bolder">
                    <FaCirclePlus /> Add Album
                  </button>
                </Link>
              </div>
            </div>
            <div className="rounded-3 border-0 mt-4 theme-bg-color  p-2">
              <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 gap-3">
                <div className="filterContainer">
                  <input
                    type="text"
                    placeholder="Filter all.."
                    className="filter form-control "
                    value={filter}
                    onChange={handleFilterChangeAlbum}
                  />
                  {filter?.length > 0 && (
                    <button
                      type="button"
                      className="closeBtn"
                      onClick={handleClear}
                    >
                      <MdOutlineClose />
                    </button>
                  )}
                </div>
                <div className="btn-group">
                  <div className="dropdown d-block p-2 expo-rt" key={"2"}>
                    <button
                      className="btn btn-success dropdown-toggle d-flex"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="fw-bolder">Export </span>
                      <img src={ExportIcon} className="ms-2 exportIcon" />
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li className="dropdown-item">
                        <CSVLink
                          headers={exportHeadersAlbum}
                          data={filteredAlbumData}
                          className="text-dark d-block"
                          filename="CONTENT-PARTNER-FILTERED-LIST.csv"
                        >
                          CSV
                        </CSVLink>
                      </li>
                      <li
                        className="dropdown-item"
                        onClick={() => {
                          conditionalGeneratePDFAlbum(0);
                        }}
                      >
                        PDF
                      </li>
                      <li
                        className="dropdown-item"
                        onClick={() => {
                          generateXLSXALBUM(0);
                        }}
                      >
                        XLSX
                      </li>
                    </ul>
                  </div>

                  <div className="dropdown d-block p-2 expo-rt">
                    <button
                      className="btn btn-success dropdown-toggle d-flex"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="fw-bolder">Export All</span>
                      <img src={ExportIcon} className="ms-2 exportIcon" />
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li className="dropdown-item">
                        <CSVLink
                          headers={exportHeadersAlbum}
                          data={getAlbum}
                          className="text-dark d-block"
                          filename="CONTENT-PARTNER-ALL-LIST.csv"
                        >
                          CSV
                        </CSVLink>
                      </li>
                      <li
                        className="dropdown-item"
                        onClick={() => {
                          conditionalGeneratePDFAlbum(1);
                        }}
                      >
                        PDF
                      </li>
                      <li
                        className="dropdown-item"
                        onClick={() => {
                          generateXLSXALBUM(1);
                        }}
                      >
                        XLSX
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="bootstrapTable theme-bg-color">
                <BootstrapTable
                  keyField="id"
                  columns={columns}
                  data={filteredAlbumData}
                  pagination={pagination}
                  className="no-border-table theme-text-color"
                  noDataIndication={
                    <div className="noRecord">
                      {loading ? (
                        <label>
                          {" "}
                          <img
                            src={loadings}
                            width={30}
                            height={30}
                            alt="Animated GIF"
                          />
                          Loading...
                        </label>
                      ) : (
                        filteredAlbumData?.length <= 0 && (
                          <p className=" theme-text-color">
                            No Albums Available !!!
                          </p>
                        )
                      )}
                    </div>
                  }
                  hover
                  headerClasses="AlbumWidth"
                  selectRow={selectRow}
                  selectAll={selectAll}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Contentallaudioalbum;
