const date = new Date();
const todayDate1HourBefore = new Date(date.getTime() - 60 * 60 * 1000).toLocaleString()
const todayDate1HourAfter = new Date(date.getTime() + 60 * 60 * 1000).toLocaleString()
const todayDate2HourBefore = new Date(date.getTime() - 120 * 120 * 1000).toLocaleString()
const todayDate2HourAfter = new Date(date.getTime() + 120 * 120 * 1000).toLocaleString()
const todayDate3HoursBefore = new Date(date.getTime() - 180 * 180 * 1000).toLocaleString();
const todayDate3HoursAfter = new Date(date.getTime() + 120 * 120 * 1000).toLocaleString();
const todayDate1Hours = new Date(date.getTime() + 60 * 60 * 1000).toLocaleString();
const currentDate = new Date();
const nextDay = new Date(currentDate);
nextDay.setDate(currentDate.getDate() + 1);

nextDay.setHours(1, 0, 0, 0);

export const epg = {
    timeline: [

        {
            ChannelName: "SUV TV",
            data: [
                {
                    "id": "ea243808-0773-4bd5-859b-d54e3f94614c",
                    "description": "The misadventures of a talking sea sponge who works at a fast food restaurant, attends a boating school, and lives in an underwater pineapple.",
                    "title": "SpongeBob SquarePants",
                    "since": todayDate1HourBefore,
                    "till": todayDate1HourAfter,
                    "image": "https://www.gstatic.com/webp/gallery/4.sm.webp",
                },
                {
                    "id": "ea243808-0773-4bd5-859b-d54e3f94614c",
                    "description": "A doctor (Shepard) gets caught up in a war between two aliens (Edwards, Forster).",
                    "title": "Vignesh Babu 1",
                    "since": todayDate2HourBefore,
                    "till": todayDate2HourAfter,
                    "image": "https://www.gstatic.com/webp/gallery/4.sm.webp",
                },
                {
                    "id": "ea243808-0773-4bd5-859b-d54e3f94614c",
                    "description": "A doctor (Shepard) gets caught up in a war between two aliens (Edwards, Forster).",
                    "title": "Vignesh Babu 2",
                    "since": todayDate3HoursAfter,
                    "till": todayDate1Hours,
                    "image": "https://www.gstatic.com/webp/gallery/4.sm.webp",
                },
            ]
        },
        {
            ChannelName: "VIJAY TV",
            data: [
                {
                    "id": "ea243808-0773-4bd5-859b-d54e3f94614c",
                    "description": "The misadventures of a talking sea sponge who works at a fast food restaurant, attends a boating school, and lives in an underwater pineapple.",
                    "title": "SpongeBob SquarePants",
                    "since": todayDate1HourBefore,
                    "till": todayDate1HourAfter,
                    "image": "https://www.gstatic.com/webp/gallery/4.sm.webp",
                },
                {
                    "id": "ea243808-0773-4bd5-859b-d54e3f94614c",
                    "description": "A doctor (Shepard) gets caught up in a war between two aliens (Edwards, Forster).",
                    "title": "Vignesh Babu 1",
                    "since": "2024-01-22T18:00:00",
                    "till": "2024-01-22T20:50:00",
                    "image": "https://www.gstatic.com/webp/gallery/4.sm.webp",
                },
                {
                    "id": "ea243808-0773-4bd5-859b-d54e3f94614c",
                    "description": "A doctor (Shepard) gets caught up in a war between two aliens (Edwards, Forster).",
                    "title": "Vignesh Babu 2",
                    "since": "2024-01-22T23:00:00",
                    "till": "2024-01-22T24:50:00",
                    "image": "https://www.gstatic.com/webp/gallery/4.sm.webp",
                },
            ]
        },
        {
            ChannelName: "K TV",
            data: [
                {
                    "id": "ea243808-0773-4bd5-859b-d54e3f94614c",
                    "description": "The misadventures of a talking sea sponge who works at a fast food restaurant, attends a boating school, and lives in an underwater pineapple.",
                    "title": "SpongeBob SquarePants",
                    "since": todayDate1HourBefore,
                    "till": todayDate1HourAfter,
                    "image": "https://www.gstatic.com/webp/gallery/4.sm.webp",
                },
                {
                    "id": "ea243808-0773-4bd5-859b-d54e3f94614c",
                    "description": "A doctor (Shepard) gets caught up in a war between two aliens (Edwards, Forster).",
                    "title": "Vignesh Babu 1",
                    "since": todayDate2HourBefore,
                    "till": todayDate2HourBefore,
                    "image": "https://www.gstatic.com/webp/gallery/4.sm.webp",
                },
                {
                    "id": "ea243808-0773-4bd5-859b-d54e3f94614c",
                    "description": "A doctor (Shepard) gets caught up in a war between two aliens (Edwards, Forster).",
                    "title": "Vignesh Babu 2",
                    "since": "2024-01-21T00:50:00",
                    "till": "2024-01-21T06:30:00",
                    "image": "https://www.gstatic.com/webp/gallery/4.sm.webp",
                },
            ]
        },
        {
            ChannelName: "MEGA TV",
            data: [
                {
                    "id": "ea243808-0773-4bd5-859b-d54e3f94614c",
                    "description": "The misadventures of a talking sea sponge who works at a fast food restaurant, attends a boating school, and lives in an underwater pineapple.",
                    "title": "SpongeBob SquarePants",
                    "since": todayDate1HourBefore,
                    "till": todayDate1HourAfter,
                    "image": "https://www.gstatic.com/webp/gallery/4.sm.webp",
                },
                {
                    "id": "ea243808-0773-4bd5-859b-d54e3f94614c",
                    "description": "A doctor (Shepard) gets caught up in a war between two aliens (Edwards, Forster).",
                    "title": "Vignesh Babu 1",
                    "since": todayDate1HourBefore,
                    "till": todayDate1HourAfter,
                    "image": "https://www.gstatic.com/webp/gallery/4.sm.webp",
                },
                {
                    "id": "ea243808-0773-4bd5-859b-d54e3f94614c",
                    "description": "A doctor (Shepard) gets caught up in a war between two aliens (Edwards, Forster).",
                    "title": "Vignesh Babu 2",
                    "since": "2024-01-21T00:50:00",
                    "till": "2024-01-21T04:30:00",
                    "image": "https://www.gstatic.com/webp/gallery/4.sm.webp",
                },
            ]
        }
    ]
}

