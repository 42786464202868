import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import LazyLoadImage from '../../Pages/HomePage/HomeSwiperComponents/LazyLoadImage';
import { getItem } from '../../Utils/localStorageUtils';
import MessageBox from '../MessageBox';

const AccountInfo = ({ profileDetails }) => {
    const dispatch = useDispatch()
    const userRoleLocal = getItem('role')
    const [profileImage, setProfileImage] = useState()
    const handleCoverImageChange = async (event, errorKey) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            const reader = new FileReader();
            reader.onload = () => {
                setProfileImage(selectedFile);
            };
            reader.readAsDataURL(selectedFile);
            // handleProfileImageUpdate()
        }
    };
    return (
        <div >
            <div className='d-flex align-items-center gap-3  mb-4'>
                <div className='position-relative profileImageUpdate' >
                    <LazyLoadImage key={'logo'} src={profileImage ? URL.createObjectURL(profileImage) : profileDetails?.Profile_details?.user_avatar} alt={'logo'} title={profileDetails?.Profile_details?.username.slice(0, 1)} imageSize="overflow-hidden  object-fit-cover" classNameOptional="rounded-circle profileImageUpdate" />
                    {/* <span className='profileImageLens position-absolute theme-bg-color-secondary p-2 rounded-circle d-flex align-items-center justify-content-center' >
                        <IconMyCamera styled={{ width: "20px", height: "20px", className: "" }} />
                    </span>
                    <input type="file" onChange={(event) => handleCoverImageChange(event)} className='profileInput w-100 h-100 position-absolute' accept='image/*' />
                    <IconMyUpload styled={{ width: "20px", height: "20px", className: "position-absolute uploadIcon" }} /> */}
                </div>
                <p className='small-lg-heading'>{profileDetails?.Profile_details?.name}</p>
            </div>
            <div className='d-flex align-items-center mb-4 flex-wrap'>
                <div className="col-lg-4 col-md-6 col-12 pe-md-5 mb-3 mb-md-0 ">
                    <MessageBox text="Phone Number" classname='opacity-75 mb-2 d-block' />
                    <input type='number' className='w-100 theme-bg-color-secondary rounded-2 px-3 py-3 theme-text-color border-0' value={profileDetails?.Profile_details?.mobile} />
                    <button type="button" className='px-5 py-2 rounded-5 mt-md-3 mt-3 theme-bg-color-secondary theme-text-color'>Edit</button>
                </div>
                <div className="col-lg-5 col-md-6 col-12">
                    <MessageBox text="Email ID" classname='opacity-75 mb-1 d-block' />
                    <input type='email' className='w-100 theme-bg-color-secondary rounded-2 px-3 py-3 theme-text-color border-0' value={profileDetails?.Profile_details?.email} />
                    <button type="button" className='px-5 py-2 rounded-5 mt-md-3 mt-3 theme-bg-color-secondary theme-text-color'>Edit</button>
                </div>
            </div>
        </div>

    )
}

const mapStateToProps = (state) => ({
    languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData,
    profileDetails: state?.get_profileDetails_Reducer?.profileDetails,
});
export default connect(mapStateToProps)(AccountInfo);