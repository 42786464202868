import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import VideoPlayer from './VideoPlayer';
import LivestreamPlayer from './LivestreamPlayer';
import Error404 from '../Error404';
import { getWishListAction } from '../../Redux/Actions/apiActions';
const Player = () => {
    const { sourcetype, slug } = useParams();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getWishListAction());
    }, [dispatch]);
    const isPlayerReady = true;
    return (
        isPlayerReady ? (
            sourcetype == "video" ? (
                <VideoPlayer slug={slug} />
            ) : sourcetype == "live" ? (
                <LivestreamPlayer slug={slug} />
            ) : (
                <Error404 />
            )
        ) : (
            <div>Player not ready, please try again later.</div> // Custom message for when player is not ready
        )
    )
};
export default connect()(Player);