import React, { useState, useEffect, useRef } from 'react';
import '../../Styles/EditMultiprofile.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { IconMyCamera, IconMyNextArrow, IconMyUpload } from '../../assets/icons/MyIcons';
import MessageBox from '../MessageBox';
import ButtonWithLoader from '../../Pages/Music/CommonComponents/ButtonWithLoader';
import { getItem, getItemToken } from '../../Utils/localStorageUtils';
import { toast } from "react-toastify";
import AdminDeleteModal from '../adminCommonComponents/AdminDeleteModal';
import { connect } from 'react-redux';
import { useMyContext } from '../Layout/CommonProvider';
const EditMultiprofile = ({ currentTheme }) => {

    const { id } = useParams();
    const { navigateTranslateChecker } = useMyContext()
    const navigate = useNavigate();

    const userRoleLocal = getItem('role')
    const userIdLocal = getItem('user_id')
    const userAccessToken = getItemToken('access_token');


    const [profileImage, setProfileImage] = useState(null)
    const [inputError, setInputError] = useState({})
    const [modalOpen, setModalOpen] = useState(false)
    const [loading, setLoading] = useState({
        buttonLoad: false,
        domLoad: true,
        buttonLoadDelete: false,
        buttonLoadCreate: false
    })

    const [inputField, setInputField] = useState({
        user_name: '',
        user_type: '',
        theme_mode: "",
        image: ""
    });
    const [SubcriberUser, setSubcriberUser] = useState({
        limit: 0,
        userDetails: {},
        multiProfile: {}
    });



    const fetchProfileData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_Baseurl}/Multi-Profile/edit/${id}`, {
                headers: userAccessToken,
            });
            var Multiprofile = response?.data.Multiprofile[0];
            setInputField(Multiprofile);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchUserMultiProfileData = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${process.env.REACT_APP_Baseurl}/Multi-Profile/index`, { headers: getItemToken("access_token") });
            const result = response?.data
            if (result?.status) {
                setSubcriberUser((prev) => ({
                    ...prev,
                    limit: result?.Multi_User_Limit,
                    userDetails: result?.subcriber_user,
                    multiProfile: result?.Multi_users
                }))
                setLoading(false)
            }
        } catch (error) {
            console.error("Error fetching data:", error);

        }
    };


    const handleInput = (e) => {
        const { name, type, value, checked } = e.target;
        if (type === 'checkbox') {

            if (name == "theme_mode") {
                setInputField((prev) => ({
                    ...prev,
                    [name]: checked ? 0 : 1,
                }));
            }
            else {
                setInputField((prev) => ({
                    ...prev,
                    [name]: checked ? "Kids" : 'Normal',
                }));
            }

        } else {
            setInputField((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
        setInputError((prev) => ({
            ...prev,
            [name]: '',
        }));
    };

    const themeNavigate = {
        "1": "choose-profile",
        "2": "myprofile/profiles",
    };

    const navigateToProfile = (theme) => {
        const profileRoute = themeNavigate[theme]
        navigate(navigateTranslateChecker(`/${profileRoute}`))
    };

    const handleUpdate = async (e) => {
        setLoading((prev) => ({
            ...prev,
            buttonLoad: true
        }))
        e.preventDefault();
        const formData = new FormData();
        formData.append('user_name', inputField.user_name);
        formData.append('user_type', inputField.user_type);
        formData.append('theme_mode', inputField.theme_mode);
        formData.append('user_id', userIdLocal);
        formData.append('sub_user_id', id);
        if (profileImage) {
            formData.append('image', profileImage);
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Multi-Profile/update`, formData, {
                headers: userAccessToken,
            });
            if (response?.data?.status) {
                setLoading((prev) => ({
                    ...prev,
                    buttonLoad: false,
                    domLoad: false
                }))
                toast.success(response.data?.message);
                await navigateToProfile(currentTheme);
            }

        } catch (error) {
            toast.error('Error updating user');
            setLoading((prev) => ({
                ...prev,
                buttonLoad: false,
                domLoad: false
            }))
        }
    };

    const handleCoverImageChange = async (event, errorKey) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            const reader = new FileReader();
            reader.onload = () => {
                setProfileImage(selectedFile);
            };
            reader.readAsDataURL(selectedFile);
        }
    };

    const handleDelete = async () => {
        setLoading((prev) => ({
            ...prev,
            buttonLoadDelete: true
        }))
        try {
            const response = await axios.delete(`${process.env.REACT_APP_Baseurl}/Multi-Profile/delete/${id}`, {
                headers: userAccessToken,
            });
            if (response?.data?.status) {
                setLoading((prev) => ({
                    ...prev,
                    buttonLoadDelete: false,
                    domLoad: false
                }))
                toast.success(response.data?.message + inputField?.user_name);
                await navigateToProfile(currentTheme);
            }

        } catch (error) {
            toast.error('Error delete profile');
            setLoading((prev) => ({
                ...prev,
                buttonLoadDelete: false,
                domLoad: false
            }))
        }
    }

    const handleCloseModal = () => {
        setModalOpen(!modalOpen)
    }

    const handleCreateProfile = async () => {
        const formData = new FormData();
        formData.append("user_id", userIdLocal);
        formData.append("user_name", inputField?.user_name);
        formData.append("user_type", inputField?.user_type);
        formData.append("theme_mode", inputField?.theme_mode);
        formData.append("image", profileImage);
        if (Number(SubcriberUser?.limit) >= Number(SubcriberUser?.multiProfile?.length)) {
            setLoading((prev) => ({
                ...prev,
                buttonLoadCreate: true
            }))
            try {
                const res = await axios.post(`${process.env.REACT_APP_Baseurl}/Multi-Profile/store`, formData, {
                    headers: userAccessToken
                });

                if (res?.data?.status) {
                    setLoading((prev) => ({
                        ...prev,
                        buttonLoadCreate: false
                    }))
                    await navigateToProfile(currentTheme);
                }
            } catch (err) {
                toast.error(err?.response?.data?.message || "Error occurred while creating profile");
            }
        }
        else {
            toast.info(`You can only create up to ${SubcriberUser?.multiProfile?.length} profiles. You have reached the profile limit. You can edit a profile only.`, {
                autoClose: 7000,
            });
        }
    };

    useEffect(() => {
        if (id) {
            fetchProfileData();
        }
        fetchUserMultiProfileData()
    }, [loading?.domLoad]);

    return (
        <>
            {(currentTheme == 1 || currentTheme == 2) && (
                <div>
                    <div className={` d-flex justify-content-center align-items-center  flex-column ${currentTheme == 1 ? "col-11 col-md-5 col-lg-8 col-xl-3 py-5  min-vh-100" : currentTheme == 2 ? "col-11 col-md-5 col-lg-8 col-xl-5" : ""}  mx-auto `} >
                        {modalOpen && <AdminDeleteModal modelClose={modalOpen} deleteClose={handleCloseModal} deleteAction={handleDelete} buttonText={'Delete Profile'} loading={loading?.buttonLoadDelete} />
                        }
                        <h4 className={`small-md-heading mb-2 px-md-3 px-0 ${currentTheme == 1 ? 'text-center' : ""}`}> {id ? "Edit Multi profile" : "Create Multi profile"}</h4>
                        <div className='position-relative profileImageUpdateMulti mx-md-0 mx-auto  border border-2 theme-border-color rounded-circle p-2 mb-5' >
                            <div key={'logo'} style={{ backgroundImage: `url(${profileImage ? URL.createObjectURL(profileImage) : inputField?.image_url})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} className="rounded-circle profileImageUpdateMultiImage">
                                <span className='profileImageLens position-absolute theme-bg-color-secondary p-2 rounded-circle d-flex align-items-center justify-content-center' >
                                    <IconMyCamera styled={{ width: "20px", height: "20px", className: "" }} />
                                </span>
                                <input type="file" onChange={(event) => handleCoverImageChange(event)} className='profileInput w-100 h-100 position-absolute' accept='image/*' />
                                <IconMyUpload styled={{ width: "20px", height: "20px", className: `${id ? '' : profileImage ? '' : "d-block"} position-absolute uploadIcon` }} />
                            </div>
                        </div>
                        <input type='text' name="user_name" value={inputField?.user_name} onChange={handleInput} className='border-2 w-100 theme-text-color border theme-border-color bg-transparent px-4 rounded-2 py-3' autoFocus />
                        <div className="d-flex align-items-center justify-content-between w-100 mt-4">
                            <MessageBox text="kid's profile?" classname='titleProfile' />
                            <label className="switchBox">
                                <input name="user_type" onChange={handleInput} checked={inputField?.user_type == "Kids" ? true : false} type="checkbox" className="rs-input" />
                                <span className="sliderBox round"></span>
                            </label>
                        </div>
                        <div className="d-flex align-items-center justify-content-between w-100 mt-4 mb-4">
                            <MessageBox text={!inputField?.theme_mode ? "Dark" : "Light"} classname='titleProfile' />
                            <label className="switchBox">
                                <input name="theme_mode" onChange={handleInput} checked={!inputField?.theme_mode == 1} type="checkbox" className="rs-input" />
                                <span className="sliderBox round"></span>
                            </label>
                        </div>
                        <MessageBox text="TV shows and movies for ages 12 and under" classname='px-2 align-self-start' />
                        {currentTheme == 1 && <Link to={navigateTranslateChecker(`/choose-profile`)} className='text-end align-self-start d-flex align-items-center'><MessageBox text="Choose profile" classname='px-2 my-3 d-block ' /><IconMyNextArrow styled={{ width: "18px", height: "18px" }} /></Link>}
                        {currentTheme == 2 && <Link to={navigateTranslateChecker(`/myprofile/profiles`)} className='text-end align-self-start d-flex align-items-center'><MessageBox text="Choose profile" classname='px-2 my-3 d-block ' /><IconMyNextArrow styled={{ width: "18px", height: "18px" }} /></Link>}
                        <div className='d-flex align-items-center justify-content-between w-100 mt-3'>
                            {id ? <>
                                <ButtonWithLoader action={handleCloseModal} text="Delete" loading={loading?.buttonLoadDelete} className="rounded-3 bg-transparent text-decoration-underline " type="button" />
                                <ButtonWithLoader action={handleUpdate} text="Update" loading={loading?.buttonLoad} className="rounded-3 py-3 px-5 " type="button" />
                            </> :
                                <> <ButtonWithLoader action={handleCreateProfile} text="Create" loading={loading?.buttonLoadCreate} className="rounded-3 py-3 px-5 ms-auto " type="button" /></>}
                        </div>
                    </div >
                </div>)}
        </>
    );
}


const mapStateToProps = state => ({
    languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData,
    profileDetails: state?.get_profileDetails_Reducer?.profileDetails,
    currentTheme: state.get_headerIndex_Reducer.currentTheme,
})
export default connect(mapStateToProps)(EditMultiprofile)

