import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import LazyLoadImage from '../../Pages/HomePage/HomeSwiperComponents/LazyLoadImage'
import { connect, useDispatch } from 'react-redux'
import appstore from "../../assets/images/apple-store.webp";
import playstore from "../../assets/images/play-store.webp";
import { useTranslation } from 'react-i18next';
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { IoLogoGoogleplus } from "react-icons/io";
import { footerAction } from '../../Redux/Actions/apiActions';


const ThemeTwoFooter = ({ themeMode, languageDetailsData, activeyear, footerlink, sociallink }) => {
    const { t } = useTranslation()
    return (
        <section className="mt-4 py-4  theme-bg-color-secondary px-3">
            <div className="d-flex flex-wrap mb-3 px-3 px-lg-5 justify-content-between">
                <div className="col-lg-4 col-md-8 col-12 ">
                    <Link to="/" className="position-relative homeNavbarBrand d-block" style={{ width: "171px", height: "42px", objectFit: "contain" }} aria-label={"Home"}>
                        <LazyLoadImage src={themeMode?.logoUrl} alt="header-logo" imageSize="loaderMain object-fit-contain p-0 " classNameOptional="rounded-2 " />
                    </Link>
                    <p className='themeTwoFooter mt-3 pe-md-5'>All Rights Reserved. All videos and shows on this platform are trademarks of flimflow and all related images and content are the property of xxx Inc. Duplication and copy of this is strictly prohibited</p>
                    <p className="footerHeading text-start mt-5 theme-text-color"> {t('On Playstore')}   </p>
                    <div className="d-flex align-items-center gap-4 my-4 flex-wrap">
                        <Link to='https://play.google.com/store/apps/details?id=com.webnexs.flicknexs' target="_blank"><img src={playstore} className="footerAppDownload" width="244" height="75" alt="playStore" /></Link>
                        <Link to='https://play.google.com/store/apps/details?id=com.webnexs.flicknexs' target="_blank"><img src={appstore} className="footerAppDownload" width="223" height="75" alt="appStore" /></Link>
                    </div>
                </div>
                <div className="col-lg-3 col-md-4 col-12 ">
                    <p className='footerHeadingThemeTwo mb-3'>Support</p>
                    <ul className='list-unstyled m-0 p-0'>
                        {footerlink?.filter((item) => item?.column_position == 2).map((item) => (<li className='footerNavThemeTwo'><Link key={item?.order} to={`/${languageDetailsData.Language_Code}${item?.link}`}>{t(item?.name)}</Link></li>))}
                    </ul>
                </div>
                <div className="col-lg-3 col-md-4 col-12 ">
                    <p className='footerHeadingThemeTwo mb-3'>Popular</p>
                    <ul className='list-unstyled m-0 p-0'>
                        {footerlink?.filter((item) => item?.column_position == 3).map((item) => (<li className='footerNavThemeTwo'><Link key={item?.order} to={`/${languageDetailsData.Language_Code}${item?.link}`}>{t(item?.name)}</Link></li>))}
                    </ul>
                </div>
                <div className="col-lg-2 col-md-12 col-12 ">
                    <p className='footerHeadingThemeTwo mb-3'>Follow Us :</p>
                    <div className="d-flex align-items-center gap-3">
                        <Link to={sociallink?.facebook_url} target="_blank" className='theme-bg-color rounded-circle p-2' > <FaFacebookF className="footerSocialLinkThemeTwo theme-text-color-button" /></Link>
                        <Link to={sociallink?.twitter_url} target="_blank" className='theme-bg-color rounded-circle p-2' > <FaXTwitter className="footerSocialLinkThemeTwo theme-text-color-button" /></Link>
                        {/* <Link to={sociallink?.facebook_url} target="_blank" className='theme-bg-color rounded-circle p-2' ><IoLogoGoogleplus className="footerSocialLinkThemeTwo theme-text-color-button" /> </Link> */}
                        <Link to={sociallink?.instagram_url} target="_blank" className='theme-bg-color rounded-circle p-2' > <FaInstagram className="footerSocialLinkThemeTwo theme-text-color-button" /> </Link>
                        <Link to={sociallink?.linkedin_url} target="_blank" className='theme-bg-color rounded-circle p-2' > <FaLinkedinIn className="footerSocialLinkThemeTwo theme-text-color-button" /> </Link>
                    </div>
                </div>
            </div>
            <p className='theme-text-color text-center text-md-end footerNavThemeTwo mb-0'>©{activeyear} Filmflow all copyrights reserved</p>
        </section>
    )
}
const mapStateToProps = (state, ownProps) => ({
    props: ownProps,
    settings: state.get_setting_Reducer.settings,
    themeMode: state.get_headerIndex_Reducer.themeMode,
    footerlink: state.get_Footer_Reducer.footerlink,
    isLoading: state.get_Footer_Reducer.isLoading,
    sociallink: state.get_Footer_Reducer.sociallink,
    companyname: state.get_Footer_Reducer.companyname,
    activeyear: state.get_Footer_Reducer.activeyear,
    error: state.get_Footer_Reducer.error,
    languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData
});
export default connect(mapStateToProps)(ThemeTwoFooter);

