import React, { useState } from 'react'
import ExportIcon from "../../assets/icons/export-icon.webp";
import { CSVLink } from "react-csv";
import { IoClose } from 'react-icons/io5';
import { CSVColumn, PDFGenerator, XLSXGenerator, } from '../../Pages/CommonMethods/CommonMethods';
const AdminHeader = ({ searchMethod, reduceData, originalData, fileName }) => {

  const [filter, setFilter] = useState("");
  const handleFilter = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setFilter(searchTerm);
    const filteredResults = originalData.filter((item) =>
      ["title", "artist_name", "artist_type", "uploaded_by", "active", "upload_on"].some(
        (property) =>
          typeof item[property] === "string" && item[property].toLowerCase().includes(searchTerm)
      ) ||
      (typeof item.id === "number" && item.id.toString().includes(searchTerm))
    );
    searchMethod(filteredResults);
  };
  const handleClear = () => {
    searchMethod(originalData);
    setFilter('')
  }
  return (
    <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 gap-3">
      <div className="filterContainer">
        <input type='text' placeholder="Search the list " className="filter form-control theme-text-color theme-border-secondary theme-bg-color-secondary" value={filter} onChange={(event) => handleFilter(event)} />
        {filter?.length > 0 && (<button type="button" className="clearBtn" onClick={() => handleClear()}  ><IoClose className="custom-close-btn" /> </button>)}
      </div>
      <div className="btn-group">
        <div className="dropdown d-block " key={"2"}>
          <button className="btn btn-success dropdown-toggle d-flex hideArrow" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"  >
            <span className="fw-bolder text-white">Export </span>
            <img src={ExportIcon} className="ms-2 exportIcon" alt="exportIcon" />
          </button>
          <ul className="dropdown-menu theme-bg-color" aria-labelledby="dropdownMenuButton1" >
            <li className="dropdown-item theme-text-color"><CSVLink headers={CSVColumn} data={reduceData} className="theme-text-color d-block" filename={`${fileName}.csv`} > CSV</CSVLink> </li>
            <li className="dropdown-item theme-text-color" onClick={() => { PDFGenerator(reduceData, fileName); }} > PDF</li>
            <li className="dropdown-item theme-text-color" onClick={() => { XLSXGenerator(reduceData, `FILTERED-${fileName}`); }}> XLSX </li>
          </ul>
        </div>
        <div className="dropdown d-block ms-2 " key={"2"}>
          <button className="btn btn-success dropdown-toggle d-flex hideArrow" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"  >
            <span className="fw-bolder text-white">Export all</span>
            <img src={ExportIcon} className="ms-2 exportIcon" alt="exportIcon" />
          </button>
          <ul className="dropdown-menu theme-bg-color" aria-labelledby="dropdownMenuButton1" >
            <li className="dropdown-item theme-text-color"><CSVLink headers={CSVColumn} data={originalData} className="theme-text-color d-block" filename={`${fileName}.csv`} > CSV</CSVLink> </li>
            <li className="dropdown-item theme-text-color" onClick={() => { PDFGenerator(originalData, fileName); }}> PDF</li>
            <li className="dropdown-item theme-text-color" onClick={() => { XLSXGenerator(originalData, fileName); }}> XLSX </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
export default AdminHeader