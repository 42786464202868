import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import loadings from "../../../assets/gif/loading.gif";
import ResponseLoader from "../../../components/Loader/ResponseLoader";

const Contentliveeditcategory = () => {
  const { id } = useParams();
  const [editCategory, setEditCategory] = useState({
    name: "",
    slug: "",
    in_menu: false,
    parent_id: "",
    image: "",
    image_url: "",
  });
  const [editname, setEditname] = useState({
    name: "",
  });
  const [image, setImage] = useState("");
  const [category, setCategory] = useState([]);
  const [isChecked, setIsChecked] = useState(false);

  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(true);
  const access_token_cpp = localStorage.getItem("access_token_cpp");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token_cpp,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    const getlanding = async () => {
      const reqData = await fetch(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/live-stream-category/edit/${id}`,
        { headers: headers }
      );
      const resData = await reqData?.json();
      var res = resData?.LiveCategory[0];
      var result = resData?.LiveCategory;
      setEditCategory(res);
      setEditname(res);
      setLoading(false);
      setIsChecked(res?.in_menu == "0" ? false : true);
    };
    getlanding();

    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/live-stream-category/create`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        var result = response?.data?.live_categories;
        setCategory(result);
      })
      .catch((error) => console.log(error));
  }, [id]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditCategory((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessageslug("");
    setIsValidationHiddenslug(true);
    setValidationMessagetitle("");
    setIsValidationHiddentitle(true);

    setEditCategory({ ...editCategory, [e.target.name]: e.target.value });
  };

  const handleChecked = (event) => {
    setEditCategory({
      ...editCategory,
      in_menu: event.value,
    });
    setIsChecked(!isChecked);
  };

  const fileInputRef1 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);

  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = (setSelectedFile1) => {
    setSelectedFile1(null);
    if (fileInputRef1.current) {
      fileInputRef1.current.value = null;
    }
  };

  const [validationMessagetitle, setValidationMessagetitle] = useState("");
  const [isValidationHiddentitle, setIsValidationHiddentitle] = useState(true);

  const [validationMessageslug, setValidationMessageslug] = useState("");
  const [isValidationHiddenslug, setIsValidationHiddenslug] = useState(true);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const titleInputRef = useRef(null);

  const handleUpdate = async (e) => {
    let focusInputRef = null;

    if (editCategory?.name == "") {
      setValidationMessagetitle("Name cannot be empty.");
      setIsValidationHiddentitle(false);
      focusInputRef = titleInputRef;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    e.preventDefault();

    const editInputvalue = {
      name: editCategory?.name,
      id: id,
      slug: editCategory?.slug,
      parent_id: editCategory?.parent_id,
      image: editCategory?.image,
      in_menu: isChecked == true ? 1 : 0,
      Content_Partner_id: 1,
    };

    let firstApiStatus = false;
    let secondApiStatus = false;
    let resultapi = null;
    let resjson = null;
    let resultapifalse = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/live-stream-category/update/${id}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res.json();

      if (resjson.status == true) {
        firstApiStatus = true;

        resultapi = resjson;

        var LiveCategoryid = resjson.LiveCategory.id;

        const formData = new FormData();
        formData.append("liveStreamCategory_id", LiveCategoryid);
        if (selectedFile1) {
          formData.append("image", selectedFile1);
        }

        await axios.post(
          `${process.env.REACT_APP_Baseurl}/CPP/Backend/live-stream-category/image`,
          formData,
          { headers: headers }
        );
        secondApiStatus = true;
      } else if (resjson.status == false) {
        // Handle the case when the first API response status is false
        resultapifalse = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (firstApiStatus && secondApiStatus) {
      setValidationMessagetitle("");
      setValidationMessageslug("");
      toast.success(resultapi?.message);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else if (firstApiStatus && secondApiStatus) {
      toast.error(resultapifalse?.message);
      setProcessing(false);
      setShowOverlay(false);
      setIsValidationHiddentitle(false);
      setValidationMessageslug(false);
    }
  };

  return (
    <div>
      <div className=" m-0 p-0">
        <section className="container-fluid">
          <div className="col-lg-12 row py-3">
            <div className="col-6 col-sm-6 col-lg-6">
              <h3>Edit New Live Stream Category</h3>
            </div>
            <div className="col-6 col-sm-6 col-lg-6">
              <div className="text-end">
                <button onClick={handleUpdate} className="btn btn-primary">
                  Update Category
                </button>
              </div>
            </div>
          </div>


          <div>{showOverlay && <ResponseLoader />}</div>

          <div className="row col-lg-12 ">
            <div className="col-6 col-sm-6 col-md-6 col-lg-6 ">
              <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                <h5>{editname?.name}</h5>
                <hr></hr>
                <div className=" text-start">
                  <label className="m-0">
                    Name<span className="mandatory">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      className="rs-input form-control-lg  theme-bg-color-secondary   theme-text-color  border-0  theme-bg-color-secondary   theme-text-color  border-0  theme-bg-color-secondary   theme-text-color  border-0  theme-bg-color-secondary   theme-text-color  border-0"
                      id="name"
                      name="name"
                      placeholder="Enter Name"
                      onChange={handleInput}
                      value={editCategory?.name}
                      ref={titleInputRef}
                    />
                  </div>
                  <span className="errorred">
                    {!isValidationHiddentitle && (
                      <p>{validationMessagetitle}</p>
                    )}
                  </span>
                </div>
                <div className="mt-2 text-start">
                  <label className="m-0">Slug</label>

                  <div className="mt-2">
                    <input
                      type="text"
                      className="rs-input form-control-lg  theme-bg-color-secondary   theme-text-color  border-0  theme-bg-color-secondary   theme-text-color  border-0  theme-bg-color-secondary   theme-text-color  border-0  theme-bg-color-secondary   theme-text-color  border-0"
                      id="slug"
                      name="slug"
                      placeholder="Enter Slug"
                      onChange={handleInput}
                      value={editCategory?.slug}
                    />
                  </div>
                </div>

                <div className="row mt-2 text-start">
                  <div className="col-12">
                    <label className="m-0">Category</label>

                    <div className="mt-2">
                      <select
                        className="px-3 py-3 rounded-2 w-100  theme-bg-color-secondary  theme-text-color border-0"
                        name="parent_id"
                        id="parent_id"
                        onChange={handleInput}
                        value={editCategory?.parent_id}
                      >
                        {category?.map((item) => (
                          <option value={item?.id}>{item?.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-start mb-3">
                <button onClick={handleUpdate} className="btn btn-primary">
                  Update Category
                </button>
              </div>
            </div>

            {/* Second Part of Section  */}

            <div className="col-6 col-sm-6 col-md-6 col-lg-6">
              <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                <h5>Thumbnails</h5>
                <hr></hr>

                <div className=" text-start mt-2">
                  <label className="m-0">Image</label>

                  {/* <div>
              <img
                src={editCategory?.image_url}
                width="150"
                height="100"
              />
            </div> */}
                  <div>
                    {loading ? (
                      <img src={loadings} className="thumbnails-images" />
                    ) : (
                      <img
                        src={editCategory?.image_url}
                        // alt="Image"
                        className="thumbnails-images"
                      />
                    )}
                  </div>

                  <div className="mt-2">
                    <div className="col-lg-12 row">
                      <div className="col-6 col-sm-6 col-lg-6">
                        <div
                          className="imagedrop"
                          onClick={() => fileInputRef1?.current.click()}
                        >
                          <input
                            type="file"
                            ref={fileInputRef1}
                            onChange={(event) =>
                              handleFileChange(
                                event,
                                fileInputRef1,
                                setSelectedFile1
                              )
                            }
                            accept="image/*"
                            style={{ display: "none" }}
                          />
                          <button>
                            <CameraRetroIcon />
                          </button>
                        </div>
                      </div>
                      <div className="col-6 col-sm-6 col-lg-6">
                        <div>
                          {selectedFile1 && (
                            <div className="imagedropcopy text-end">
                              <div className="col-lg-12 row p-0 m-0">
                                <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                  <img
                                    src={URL?.createObjectURL(selectedFile1)}
                                    alt="Uploaded"
                                    className="thumbnails-upload-images"
                                  />
                                </div>
                                <div
                                  className="col-sm-2 col-lg-2 p-0 m-0"
                                  style={{ alignSelf: "center" }}
                                >
                                  <button
                                    onClick={() =>
                                      handleDelete(setSelectedFile1)
                                    }
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                <h5>Status Settings</h5>
                <hr></hr>
                <div className="col-sm-12 row ">
                  <div className="col-8 col-sm-8 col-lg-8">
                    <label className="p2">Display In Home page</label>
                  </div>
                  <div className="col-4 col-sm-4 col-lg-4 text-center">
                    {/* <label className="text-danger">No</label> */}
                    <label className="switch">
                      <input
                        name="in_menu"
                        type="checkbox"
                        value={editCategory?.in_menu}
                        checked={isChecked}
                        onChange={handleChecked}
                      />
                      <span className="slider round" name="active"></span>
                    </label>
                    {/* <label className="text-success">Yes</label> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <div className="">
    <section className="section container-fluid pt-3">
      <div className="iq-card p-2 mt-3">
        <div className="p-3">
          <div className="col-md-12 mb-3">
            <label className="m-0"> Name:</label>

            <input
              type="text"
              id="name"
              name="name"
              className="form-control"
              placeholder="Enter Name"
              value={editCategory?.name}
              onChange={handleInput}
            />
          </div>

          <div className="col-md-12 mb-3">
            <label className="m-0"> Slug:</label>

            <input
              type="text"
              id="slug"
              name="slug"
              className="form-control"
              placeholder="Enter Slug"
              value={editCategory?.slug}
              onChange={handleInput}
            />
          </div>
          <div className="d-flex align-items-center">
            <label>Display In Home page:</label>

            <div className="ms-3">
              <label className="text-danger">No </label>
              <label className="switch">
                <input
                  name="in_menu"
                  type="checkbox"
                  value={editCategory?.in_menu}
                  checked={isChecked}
                  onChange={handleChecked}
                />

                <span className="slider round" name="active"></span>
              </label>
              <label className="text-success">Yes</label>
            </div>
          </div>
          <div className=" mt-3 form-group">
            <div>
              <label>Image:</label>
            </div>
            <img src={editCategory?.image_url} width={150} height={150} />

            <input
              type="file"
              multiple="true"
              className="form-control"
              name="image"
              id="image"
              onChange={(e) => setImage(e.target.files[0])}
            />
          </div>

          <div className="form-group ">
            <label>Category:</label>
            <select
              className="form-control"
              onChange={handleInput}
              name="parent_id"
              id="parent_id"
            >
              <option value={0}>Select</option>
              {category?.map((item) => (
                <option value={item?.parent_id}>{item?.name}</option>
              ))}
            </select>
          </div>
          <hr />

          <div className="row mt-3">
            <div className="col-sm-12 text-end">
              <input
                type="submit"
                value="Upload"
                className="btn btn-primary "
                onClick={handleUpdate}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div> */}
      </div>
    </div>
  );
};

export default Contentliveeditcategory;
