// External Libraries and Packages
import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import JoditEditor from "jodit-react";
import { TagsInput } from "react-tag-input-component";
import Select from "react-select";
import { toast } from "react-toastify"
import { MdClose } from "react-icons/md";
// Internal Styles
// import "../../Pages/ManageLiveVideo/EditNewLiveVideo.css";
// Internal Components
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import loadings from "../../../assets/gif/loading.gif";
import facebook from "../../../assets/icons/facebook.webp";
import twitter from "../../../assets/icons/twitter.webp";
import youtube from "../../../assets/icons/youtube.webp";
import ResponseLoader from "../../../components/Loader/ResponseLoader";



function ContentEditLiveStream() {
  const { id } = useParams();
  const access_token_cpp = localStorage.getItem("access_token_cpp");
  const [showOverlay, setShowOverlay] = useState(false);
  const [editUserError, setEditUserError] = useState({});
  const [withOutRefresh, setWithOutRefresh] = useState(false)
  const [editUser, setEditUser] = useState({
    title: "",
    slug: "",
    enable_restream: "",
    Stream_key: "",
    TV_image: "",
    player_image: "",
    access: "",
    active: "",
    banner: "",
    live_stream_source: "",
    details: "",
    embed_url: "",
    fb_restream_url: "",
    fb_streamkey: "",
    featured: "",
    footer: "",
    youtube_streamkey: "",
    youtube_restream_url: "",
    year: "",
    video_category_id: "",
    url_type: "",
    twitter_streamkey: "",
    twitter_restream_url: "",
    status: "",
    rating: "",
    publish_status: "",
    publish_type: "",
    publish_time: "",
    ppv_price: "",
    mp4_url: "",
    m3u_url: "",
    live_stream_video: "",
    linkedin_streamkey: "",
    linkedin_restream_url: "",
    language: "",
    ios_ppv_price: "",
    image: "",
    hls_url: "",
    description: "",
    duration: "",
    search_tags: "",
    Video_thumbnail: "",
    Player_thumbnail: "",
    Video_TV_Thumbnail: "",
    enable_Tips: "",
    enable_chats: "",
    website_page_title: "",
    website_URL: "",
    Meta_description: "",
    livestream_URL: "",
    donations_label: "",
  });
  const [formData, setFormData] = useState({});
  const [optionsartists, setOptionsartists] = useState([]);
  const [selectedValuesartists, setSelectedValuesartists] = useState([]);
  const [optionscategories, setOptionscategories] = useState([]);
  const [selectedValuescategories, setSelectedValuescategories] = useState([]);
  const [optionslanguages, setOptionslanguages] = useState([]);
  const [selectedValueslanguages, setSelectedValueslanguages] = useState([]);
  const [optionsavailable_country, setOptionsAvailable_country] = useState([]);
  const [selectedValuesavailable_country, setSelectedValuesAvailable_country] = useState([]);
  const [optionsForBlockCountry, setOptionsForBlockCountry] = useState([]);
  const [allOptions, setAllOptions] = useState([]);
  const [user_access, setUser_access] = useState([]);
  const [livestream_source, setLivestream_source] = useState([]);
  const [inapppurchase, setInappPurchase] = useState("");
  const [live_stream_video, setLive_stream_video] = useState("");
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [inputValueAPI, setInputValueAPI] = useState("");
  const [age_restrictdata, setAge_restrictdata] = useState([]);
  const [publishType, setPublishType] = useState("publish_now");
  const [publishTime, setPublishTime] = useState("");
  const [formDatapublishedit, setFormDatapublishedit] = useState(
    new FormData()
  );
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedValuesrelated_video, setSelectedValuesrelated_video] =
    useState([]);
  const [tags, setTags] = useState([]);
  const [coverImageFile, setCoverImageFile] = useState(null)
  const [playerImageFile, setPlayerImageFile] = useState(null)
  const [tvImageFile, setTvImageFile] = useState(null)

  //input ref 
  const slugInputRef = useRef(null)
  const coverImageRef = useRef(null);
  const playerImageRef = useRef(null);
  const tvImageRef = useRef(null);

  // Handle Auto slug generate function *start*
  const handleBlurtitle = () => {
    generateSlug(editUser?.title);
  };
  const handleBlurTitleEdit = () => {
    generateSlug(editUser?.slug);
  }
  const generateSlug = (title) => {
    title = title?.trim();
    const slug = title?.toLowerCase().replace(/\s+/g, "-");
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      slug: slug,
    }));
  };
  const handleAutoFillChangetitle = (event) => {
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      slug: event.target.value,
    }));
    setEditUserError((prevEditUser) => ({
      ...prevEditUser,
      [event.target.name]: '',
    }));
  };
  const handleInputKeyDowntitle = (event) => {
    if (event.key == "Enter" || event.key == "Tab") {
      generateSlug(editUser?.title);
    }
  };

  //handle input field
  const handleInput = (e, data) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));
    setEditUserError((prevEditUser) => ({
      ...prevEditUser,
      [name]: '',
    }));
    setEditUserError((prevEditUser) => ({
      ...prevEditUser,
      slug: '',
    }));

    if (e.target.checked == true) {
      setEditUser((prevEditUser) => ({
        ...prevEditUser,
        [e.target.name]: editUser.active == 1 ? 0 : 1,
      }));
      setEditUser((prevEditUser) => ({
        ...prevEditUser,
        [e.target.name]: editUser.enable_chats == 1 ? 0 : 1,
      }));
      setEditUser((prevEditUser) => ({
        ...prevEditUser,
        [e.target.name]: editUser.banner == 1 ? 0 : 1,
      }));
      setEditUser((prevEditUser) => ({
        ...prevEditUser,
        [e.target.name]: editUser.featured == 1 ? 0 : 1,
      }));
      setEditUser((prevEditUser) => ({
        ...prevEditUser,
        [e.target.name]: editUser.enable_Tips == 1 ? 0 : 1,
      }));
    }
  };
  //Handle jodit edit 
  const handleJodiEditor = (value) => {
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      details: value,
    }));
  }

  //Handle categories 

  const handleSelectChangecategories = (selectedOptionscategories) => {
    const categories = selectedOptionscategories?.map((option) => option.value);
    setFormData({ ...formData, categories: categories, });
    setSelectedValuescategories(selectedOptionscategories);
    setEditUserError((prevEditUser) => ({ ...prevEditUser, categoryerror: '' }));
  };

  //Handle Lanugage
  const handleSelectChangelanguages = (selectedOptionslanguages) => {
    const languages = selectedOptionslanguages?.map((option) => option.value);
    setFormData({ ...formData, languages: languages, });
    setSelectedValueslanguages(selectedOptionslanguages);
    setEditUserError((prevEditUser) => ({ ...prevEditUser, languageerror: '' }));
  };

  //Handle Lanuage
  const handleSelectChangeavailable_country = (selectedOptionsavailable_country) => {
    const available_country = selectedOptionsavailable_country?.map((option) => option.value);
    setFormData({ ...formData, available_country: available_country, });
    setSelectedValuesAvailable_country(selectedOptionsavailable_country);
  };

  //Handle image upload
  //Handle Image upload
  const handleCoverImageChange = (event) => {
    const selectedFile = event.target.files;
    if (selectedFile.length > 0) {
      const newFile = selectedFile[0];
      const reader = new FileReader();
      reader.onload = () => {
        setCoverImageFile(newFile);
      };
      reader.readAsDataURL(newFile);
    } else {
    }
  };
  const handlePlayerImageChange = (event) => {
    const selectedFile = event.target.files;
    if (selectedFile.length > 0) {
      const newFile = selectedFile[0];
      const reader = new FileReader();
      reader.onload = () => {
        setPlayerImageFile(newFile);
      };
      reader.readAsDataURL(newFile);
    } else {
    }
  };
  const handleTvImageChange = (event) => {
    const selectedFile = event.target.files;
    if (selectedFile.length > 0) {
      const newFile = selectedFile[0];
      const reader = new FileReader();
      reader.onload = () => {
        setTvImageFile(newFile);
      };
      reader.readAsDataURL(newFile);
    } else {
    }
  };

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
    if (!checkboxChecked) {
      setEditUser((prevState) => {
        return { ...prevState, ppv_price: inputValueAPI };
      });
    } else {
      setEditUser((prevState) => {
        return { ...prevState, ppv_price: "" };
      });
    }
  };

  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value; // setInputValue(event.target.value);
    setEditUser((prevState) => {
      return { ...prevState, [name]: value };
    });
  };
  const handleCheckbox = () => {
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      enable_restream: prevEditUser.enable_restream == "1" ? "0" : "1",
    }));
  };

  const handleChangeyear = (event) => {
    const value = event.target.value;
    if (/^\d{0,4}$/.test(value)) {
      setEditUser({ ...editUser, year: value });
    }
  };

  const handleInputdata = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    // Remove non-digit characters from input
    const formattedInput = inputValue.replace(/\D/g, "");

    // Add colons to maintain the format HH:MM:SS
    const formattedTime = formattedInput
      .replace(/^(\d{2})(\d{2})(\d{2})$/, "$1:$2:$3")
      .substr(0, 8);

    setEditUser({
      ...editUser,
      [inputName]: formattedTime,
    });
  };

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token_cpp,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };
  const handleTagsChange = (newTags) => {
    setTags(newTags);
  };


  const handlePublishTypeChange = (event) => {
    const selectedValue = event.target.value;
    setPublishType(selectedValue);

    if (selectedValue == "publish_now") {
      setPublishTime(""); // Reset the publish time when switching to "Publish Now"
      formDatapublishedit.delete("publish_time"); // Remove publish_time from FormData
    }
  };

  const handlePublishTimeChange = (event) => {
    const timeValue = event.target.value;
    setPublishTime(timeValue);
    formDatapublishedit.set("publish_time", timeValue);
    formDatapublishedit.set("publish_type", publishType);
  };
  const [optionsAge_Restrict, setOptionsAge_Restrict] = useState([]);
  const [selectedValuesAge_Restrict, setSelectedValuesAge_Restrict] = useState(
    []
  );



  useEffect(() => {
    fetchData();
  }, [withOutRefresh]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_Baseurl}/CPP/Backend/Livestream-edit/${id}`, { headers: headers });
      var res = response?.data?.Live_Stream[0];
      const data = response?.data?.Live_Stream[0]?.selected_Blocklivestream;
      const dataavailable_country = response?.data?.Live_Stream[0]?.available_countries;
      const dataartists = response?.data?.Live_Stream[0]?.selected_artist;
      const datacategories = response?.data?.Live_Stream[0]?.selected_category;
      const datalanguages = response?.data?.Live_Stream[0]?.selected_language;
      const dataAge_Restrict = response?.data?.Live_Stream[0]?.selected_Age_Restrict;
      var result = response?.data?.user_access;
      var InappPurchase = response?.data?.InappPurchase;
      setEditUser(res);
      setUser_access(result);
      setInappPurchase(InappPurchase);
      setPublishType(res?.publish_type);
      setPublishTime(res?.publish_time);
      setInputValueAPI(response?.data?.ppv_gobal_price);
      const selectedData = data;
      const formattedOptions = selectedData?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      const formattedOptionsavailable_country = dataavailable_country?.map(
        (item) => ({
          value: item?.id,
          label: item?.country_name,
        })
      );
      const formattedOptionsartists = dataartists?.map((item) => ({
        value: item?.artist_id,
        label: item?.name,
      }));
      const formattedOptionscategories = datacategories?.map((item) => ({
        value: item?.category_id,
        label: item?.name,
      }));
      const formattedOptionslanguages = datalanguages?.map((item) => ({
        value: item?.language_id,
        label: item?.name,
      }));
      const formattedOptionsAge_Restrict = dataAge_Restrict?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      setSelectedValues(formattedOptions);
      setOptionsForBlockCountry(formattedOptions);
      setSelectedValuesAvailable_country(formattedOptionsavailable_country);
      setOptionsAvailable_country(formattedOptionsavailable_country);
      setSelectedValuesartists(formattedOptionsartists);
      setOptionsartists(formattedOptionsartists);
      setSelectedValuescategories(formattedOptionscategories);
      setOptionscategories(formattedOptionscategories);
      setSelectedValueslanguages(formattedOptionslanguages);
      setOptionslanguages(formattedOptionslanguages);
      setSelectedValuesAge_Restrict(formattedOptionsAge_Restrict);
      setOptionsAge_Restrict(formattedOptionsAge_Restrict);
      setShowOverlay(false);
      setTags(response?.data?.Live_Stream[0]?.search_tags?.split(",").map((tag) => tag.trim()));
    } catch (error) {
      console.error(error);
      setShowOverlay(false);
    }
    try {
      const response = await axios.get(`${process.env.REACT_APP_Baseurl}/CPP/Backend/Livestream-Create`, { headers: headers });
      const data = response?.data?.Block_Country;
      const dataavailable_country = response?.data?.Available_country;
      const dataartists = response?.data?.livestream_artist;
      const datacategories = response?.data?.livestream_category;
      const datalanguages = response?.data?.livestream_language;
      const dataAge_Restrict = response?.data?.Age_Restrict;
      var age_restrictdata = response?.data?.Age_Restrict;
      setAge_restrictdata(age_restrictdata);
      const globelset = response?.data?.ppv_gobal_price;
      setInputValueAPI(globelset);
      setLivestream_source(response?.data?.Livestream_source);
      // Extract the selected data from the response
      const selectedData = data;
      const formattedOptions = selectedData?.map((item) => ({
        value: item.id,
        label: item.country_name,
      }));
      const formattedOptionsavailable_country = dataavailable_country?.map(
        (item) => ({
          value: item.id,
          label: item.country_name,
        })
      );
      const formattedOptionsartists = dataartists?.map((item) => ({
        value: item.id,
        label: item.artist_name,
      }));
      const formattedOptionscategories = datacategories?.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      const formattedOptionslanguages = datalanguages?.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      const formattedOptionsAge_Restrict = dataAge_Restrict?.map((item) => ({
        value: item.id,
        label: item.id,
      }));
      // Set the options for the multi-select component
      setOptionsForBlockCountry(formattedOptions);
      setOptionsAvailable_country(formattedOptionsavailable_country);
      setOptionsartists(formattedOptionsartists);
      setOptionscategories(formattedOptionscategories);
      setOptionslanguages(formattedOptionslanguages);
      setOptionsAge_Restrict(formattedOptionsAge_Restrict);
      setShowOverlay(false);
    } catch (error) {
      console.error(error);
      setShowOverlay(false);
    }
  };

  const handleSelectChangeForBlockCountry = (selectedOptions) => {
    const block_country = selectedOptions?.map((option) => option.value);
    setFormData({
      ...formData,
      block_country: block_country,
    });
    setSelectedValues(selectedOptions);
  };

  const handleSelectChangeartists = (selectedOptionsartists) => {
    const artists = selectedOptionsartists?.map((option) => option.value);
    setFormData({
      ...formData,
      artists: artists,
    });

    setSelectedValuesartists(selectedOptionsartists);
  };
  const handleSelectChangerelated_video = (selectedOptionsrelated_video) => {
    const related_video = selectedOptionsrelated_video?.map(
      (option) => option.value
    );
    setFormData({
      ...formData,
      related_video: related_video,
    });
    setSelectedValuesrelated_video(selectedOptionsrelated_video);
  };


  const handleSelectChangeAge_Restrict = (selectedOptionsAge_Restrict) => {
    const Age_Restrict = selectedOptionsAge_Restrict?.map(
      (option) => option.value
    );
    setFormData({
      ...formData,
      Age_Restrict: Age_Restrict,
    });

    setSelectedValueslanguages(selectedOptionsAge_Restrict);
  };

  const handleInputenable = (e) => {
    if (e.target.checked == true) {
      setEditUser({ ...editUser, [e.target.name]: 1 });
    } else {
      setEditUser({ ...editUser, [e.target.name]: 0 });
    }
  };

  const convertToTitleCase = (str) => {
    return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const formattedDatatype = convertToTitleCase(editUser?.url_type);

  const titleInputRef = useRef(null);
  const categoryInputRef = useRef(null);
  const languageInputRef = useRef(null);
  const handleLiveUpdate = async (e) => {
    e.preventDefault();
    if (formValidation()) {
      console.log("data true go to api")
      updateLiveData()
    }
    else {
      console.log('Form validation failed ');
    }
  };

  const formValidation = () => {
    let formIsValid = true;
    if (!editUser?.title) {
      setEditUserError((prevEditUser) => ({ ...prevEditUser, title: 'Title cannot be empty.' }));
      titleInputRef.current.focus();
      formIsValid = false;
    }
    else if (!editUser?.slug) {
      setEditUserError((prevEditUser) => ({ ...prevEditUser, slug: 'Slug cannot be empty.' }));
      slugInputRef.current.focus();
      formIsValid = false;
    }

    if (!selectedValuescategories || selectedValuescategories?.length == 0) {
      setEditUserError((prevEditUser) => ({ ...prevEditUser, categoryerror: 'Please select at least one Category' }));
      categoryInputRef.current.focus();
      formIsValid = false;
    }
    if (!selectedValueslanguages || selectedValueslanguages?.length == 0) {
      setEditUserError((prevEditUser) => ({ ...prevEditUser, languageerror: 'Please select at least one Language' }));
      languageInputRef.current.focus();
      formIsValid = false;
    }
    return formIsValid;
  }

  const updateLiveData = async () => {
    try {
      setShowOverlay(true)
      const inputUpdateData = {
        title: editUser?.title,
        slug: editUser?.slug,
        details: editUser?.details,
        description: editUser?.description,
        duration: editUser?.duration,
        year: editUser?.year,
        url_type: editUser?.url_type,
        mp4_url: editUser?.mp4_url,
        embed_url: editUser?.embed_url,
        m3u_url: editUser?.m3u_url,
        live_stream_video: live_stream_video,
        rating: editUser?.rating,
        access: editUser?.access,
        publish_status: editUser?.publish_status,
        publish_type: publishType,
        publish_time: publishTime,
        enable_restream: editUser?.enable_restream,
        featured: editUser?.featured,
        banner: editUser?.banner,
        active: editUser?.active,
        search_tags: tags?.join(", "),
        ppv_price: editUser?.ppv_price,
        age_restrict: editUser?.age_restrict,
        enable_chats: editUser?.enable_chats,
        enable_Tips: editUser?.enable_Tips,
        fb_restream_url: editUser?.fb_restream_url,
        youtube_restream_url: editUser?.youtube_restream_url,
        twitter_restream_url: editUser?.twitter_restream_url,
        twitter_streamkey: editUser?.twitter_streamkey,
        youtube_streamkey: editUser?.youtube_streamkey,
        linkedin_streamkey: editUser?.linkedin_streamkey,
        donations_label: editUser?.donations_label,
        fb_streamkey: editUser?.fb_streamkey,
        languages_id: selectedValueslanguages?.map((option) => option?.value),
        artist_id: selectedValuesartists?.map((option) => option?.value),
        block_country_id: selectedValues?.map((option) => option?.value),
        category_id: selectedValuescategories?.map((option) => option?.value),
      }
      const response = await axios.post(`${process.env.REACT_APP_Baseurl}/CPP/Backend/Livestream-update/${id}`, JSON.stringify(inputUpdateData), { headers })

      const seoFormData = new FormData();
      seoFormData.append("livestream_id", id);
      seoFormData.append("website_page_title", editUser?.website_page_title);
      seoFormData.append("website_URL", editUser?.website_URL);
      seoFormData.append("Meta_description", editUser?.Meta_description);
      const responseSeo = await axios.post(`${process.env.REACT_APP_Baseurl}/CPP/Backend/Livestream-SEO`, seoFormData, { headers });

      const imageFormData = new FormData()
      imageFormData.append('livestream_id', id)
      imageFormData.append('image', coverImageFile)
      imageFormData.append('player_image', playerImageFile)
      imageFormData.append('TV_image', tvImageFile)
      const imageResponse = await axios.post(`${process.env.REACT_APP_Baseurl}/CPP/Backend/Livestream-image-upload/${id}`, imageFormData, { headers });

      if (response.data.status && responseSeo.data.status && imageResponse.data.status) {
        setShowOverlay(false);
        toast.success(response.data.message);
        setWithOutRefresh(!withOutRefresh)
        setTimeout(() => {
          window.location.reload()
        }, 3000);
      } else {
        setShowOverlay(false);
        toast.error(response.data.message);
      }
    }
    catch (err) {
      setShowOverlay(false);
      if (err.response) {
        const errorMessage = err.response.data.message;
        toast.error(errorMessage);
      } else {
        toast.error('Error:', err.message);
      }
    }
  }

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_Baseurl}/CPP/Backend/Livestream-Create`,
          {
            headers: headers,
          }
        );
        const data = await response?.json();
        const options = data?.livestream_category?.map((category) => ({
          value: category?.id,
          label: category?.name,
        }));
        setAllOptions(options);
      } catch (error) {
        console.log("Error fetching options:", error);
      }
    };

    fetchOptions();
  }, []);

  const [alllivestream_source, setalllivestream_source] = useState("");


  function alllive_stream_source() {
    switch (alllivestream_source) {
      case "0":
        return <div></div>;
      case "mp4":
        return (
          <div className="mt-2">
            <label className="theme-text-color  py-2">Mp4/M3U8 URL</label>
            <input
              type="text"
              className="rs-input mt-2 form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
              onChange={handleInput}
              value={editUser?.mp4_url}
              placeholder=""
              name="mp4_url"
              id="mp4_url"
            />
          </div>
        );
      case "embed":
        return (
          <div className="mt-2">
            <label className="theme-text-color  py-2">Embed Url</label>
            <input
              type="text"
              name="embed_url"
              id="embed_url"
              onChange={handleInput}
              className="rs-input mt-2 form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
              value={editUser?.embed_url}
              placeholder=""
            />
          </div>
        );
      case "live_stream_video":
        return (
          <div className="mt-2">
            <label className="theme-text-color  py-2" >Live Stream Video</label>
            <div>
              {showOverlay ? (
                <img src={loadings} className="thumbnails-images" />
              ) : (
                <media-player
                  src={editUser?.livestream_URL}
                  poster=""
                  controls
                >
                  <media-outlet></media-outlet>
                </media-player>
              )}
            </div>
            <input
              type="file"
              accept="video/mp4"
              className="rs-input mt-2 form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
              onChange={(e) => setLive_stream_video(e.target.files[0])}
              placeholder=""
            />
          </div>
        );
      case "m3u_url":
        return (
          <div className="mt-2">
            <label className="theme-text-color  py-2">M3u url</label>
            <input
              type="text"
              name="m3u_url"
              id="m3u_url"
              onChange={handleInput}
              className="rs-input mt-2 form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
              value={editUser?.m3u_url}
              placeholder=""
            />
          </div>
        );

      case "Encode_stream_video":
        return (
          <div className="mt-2">
            <label className="theme-text-color  py-2">Encode Stream Video</label>
            <input
              type="text"
              className="rs-input mt-2 form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
              value={editUser?.live_stream_video}
              readOnly
              placeholder="Encode_stream_video"
            />
          </div>
        );

      default:
        return null;
    }
  }

  const [allliveuseraccess, setallliveuseraccess] = useState("");
  const [storeliveuseraccess, setStoreliveuseraccess] = useState("");

  function alllive_streamuseraccess() {
    switch (allliveuseraccess) {
      case "guest":
        return <div></div>;
      case "subscriber":
        return <div></div>;
      case "registered":
        return <div></div>;
      case "ppv":
        return (
          <div className="row mt-3 text-start ">
            <div className="mt-2">
              <label className="mt-2 theme-text-color">PPV Price</label>
              <div className="d-flex align-items-center justify-content-between">
                <p className="theme-text-color mt-2">Apply PPV Price from Global Settings? </p>
                <label className="switch">
                  <input
                    name="ppv_price"
                    defaultChecked={checkboxChecked}
                    onChange={handleCheckboxChange}
                    type="checkbox"
                    className="rs-input"
                  />
                  <span
                    className="slider round"
                    name="ppv_price"
                    checked={checkboxChecked}
                    onChange={handleCheckboxChange}
                  ></span>
                </label>
              </div>
              <input
                type="number"
                className="rs-input mt-2  theme-bg-color-secondary  theme-text-color border-0"
                placeholder="PPV Price"
                value={editUser?.ppv_price}
                id="ppv_price"
                name="ppv_price"
                // value={inputValue}
                onChange={handleInputChange}
              />
            </div>
            <div className="mt-3">
              <label className="mt-2 theme-text-color"> IOS PPV Price</label>
              <p className="mt-2 theme-text-color">Apply IOS PPV Price from Global Settings?</p>
              <div className="mt-2">
                <select
                  // onChange={(e) => setIos_ppv_price(e.target.value)}
                  name="ios_ppv_price"
                  id="ios_ppv_price"
                  onChange={handleInput}
                  value={editUser?.ios_ppv_price}
                  className="form-select  theme-bg-color-secondary  theme-text-color border-0"
                >
                  {inapppurchase?.map((item, key) => (
                    <option value={item.product_id} className="theme-text-color">{item.plan_price}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  return (
    <div>
      {showOverlay && <ResponseLoader />}
      <>
        <section className="container-fluid p-0  theme-bg-color-secondary  ">
          <div className="d-flex align-items-center flex-wrap p-3">
            <div className="col-sm-6 col-12 ">
              <h4 className="admin-title mb-0  theme-text-color text-md-start ">Edit LiveStream</h4>
            </div>
            <div className="col-sm-6 col-12 d-flex align-items-center justify-content-end" >
              <button onClick={handleLiveUpdate} className="btn btn-primary admin-button-text text-white rounded-2  py-2 px-3">
                Update LiveStream
              </button>
            </div>
          </div>
          <div className="row m-0 px-1">
            <div className="col-12 col-md-6">
              <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                <h6 className="theme-text-color trial-border-bottom  pb-3 text-break">{editUser?.title}</h6>
                <div className="mt-3 text-start">
                  <label className="mt-2 d-block mandatorySimple position-relative theme-text-color">Title</label>
                  <p className="mt-2 theme-text-color opacity-75">Add the Live stream title in the textbox below</p>
                  <div className="mt-2">
                    <input
                      type="text"
                      id="title"
                      name="title"
                      value={editUser?.title}
                      onChange={handleInput}
                      onBlur={handleBlurtitle}
                      onKeyDown={handleInputKeyDowntitle}
                      className="rs-input form-control-lg  theme-bg-color-secondary   theme-text-color  border-0"
                      placeholder="Title"
                      ref={titleInputRef}
                    />
                  </div>
                  {editUserError?.title && (<span className="errorred mt-2 d-block">{editUserError?.title}</span>)}
                </div>
                <div className="mt-2 text-start">
                  <label className="mt-2 theme-text-color">Slug</label>
                  <p className="mt-2 theme-text-color opacity-75" > Add the Live stream slug in the textbox below </p>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="slug"
                      id="slug"
                      onChange={handleAutoFillChangetitle}
                      value={editUser?.slug}
                      ref={slugInputRef}
                      onBlur={handleBlurTitleEdit}
                      className="rs-input form-control-lg  theme-bg-color-secondary   theme-text-color  border-0"
                      placeholder="slug"
                    />
                  </div>
                  {editUserError?.slug && (<span className="errorred mt-2 d-block">{editUserError?.slug}</span>)}
                </div>
                <div className="mt-2 text-start">
                  <label className="mt-2 theme-text-color">Short Description</label>
                  <p className="mt-2 opacity-75">Add a short description of the Livestream below</p>
                  <div className="mt-2">
                    <textarea
                      className="rs-input short-description  theme-bg-color-secondary  theme-text-color border-0"
                      id="description"
                      name="description"
                      placeholder="Description"
                      onChange={handleInput}
                      value={editUser?.description}
                    />
                  </div>
                </div>
                <div className="mt-2 text-start">
                  <label className="mt-2">
                    Live Stream Details, Links, and Info
                  </label>
                  <div className="mt-2">
                    <JoditEditor
                      className="rs-input theme-text-color  theme-bg-color-secondary  border-0"
                      value={editUser?.details}
                      name="details"
                      onChange={handleJodiEditor}
                    />
                  </div>
                </div>
                <div className="mt-2 text-start">
                  <label className="mt-2 theme-text-color">Duration</label>
                  <p className="mt-2 theme-text-color opacity-75"> Enter The Live Stream Duration In (HH : MM : SS)</p>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="duration"
                      className="rs-input form-control-lg  theme-text-color  theme-bg-color-secondary  border-0"
                      value={editUser?.duration}
                      maxLength="6"
                      placeholder="duration"
                      onChange={handleInputdata}
                    />
                  </div>
                </div>
                <div className="mt-2 text-start">
                  <label className="mt-2 theme-text-color">Year</label>
                  <p className="mt-2 theme-text-color opacity-75">Enter The Live Stream Year</p>
                  <div className="mt-2">
                    <input
                      type="number"
                      name="year"
                      value={editUser?.year}
                      onChange={handleChangeyear}
                      className="rs-input form-control-lg theme-text-color  theme-bg-color-secondary  border-0"
                      placeholder="Year"
                    />
                  </div>
                </div>
              </div>
              <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                <h6 className="theme-text-color trial-border-bottom  pb-3">Organize</h6>
                <div className="mt-3  text-start">
                  <label className="mt-2 theme-text-color">LiveStream Ratings</label>
                  <p className="mt-2 theme-text-color opacity-75">IMDb Ratings 10 out of 10</p>
                  <div className="mt-2">
                    <select
                      className="px-3 py-3 rounded-2 w-100  theme-bg-color-secondary  theme-text-color border-0"
                      id="rating"
                      name="rating"
                      size="lg"
                      onChange={handleInput}
                      value={editUser?.rating}
                    >
                      <option value="" className=" theme-bg-color-secondary  theme-text-color">Select Rating</option>
                      <option value="1" className=" theme-bg-color-secondary  theme-text-color">1</option>
                      <option value="2" className=" theme-bg-color-secondary  theme-text-color">2</option>
                      <option value="3" className=" theme-bg-color-secondary  theme-text-color">3</option>
                      <option value="4" className=" theme-bg-color-secondary  theme-text-color">4</option>
                      <option value="5" className=" theme-bg-color-secondary  theme-text-color">5</option>
                      <option value="6" className=" theme-bg-color-secondary  theme-text-color">6</option>
                      <option value="7" className=" theme-bg-color-secondary  theme-text-color">7</option>
                      <option value="8" className=" theme-bg-color-secondary  theme-text-color">8</option>
                      <option value="9" className=" theme-bg-color-secondary  theme-text-color">9</option>
                      <option value="10" className=" theme-bg-color-secondary  theme-text-color">10</option>
                    </select>
                  </div>
                </div>
                <div className="mt-2 text-start">
                  <label className="mt-2 theme-text-color">Age Restrict</label>
                  <p className="mt-2 theme-text-color opacity-75">Select A Live Stream Age Below </p>
                  <div className="mt-2">
                    <select
                      className="px-3 py-3 rounded-2 w-100  theme-bg-color-secondary  theme-text-color border-0"
                      id="age_restrict"
                      name="age_restrict"
                      onChange={handleInput}
                      value={editUser?.age_restrict}
                    >
                      <option value="" className=" theme-bg-color-secondary  theme-text-color">Choose an Age</option>
                      {age_restrictdata?.map((item) => (
                        <option value={item?.age} className=" theme-bg-color-secondary  theme-text-color">{item?.slug}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="text-start mt-2">
                  <label className="mt-2 theme-text-color">Artists</label>
                  <p className="mt-2 theme-text-color opacity-75">Select A Live Stream Artist Below</p>
                  <div className="mt-2">
                    <Select
                      options={optionsartists}
                      isMulti
                      className=" theme-bg-color-secondary  theme-text-color"
                      onChange={handleSelectChangeartists}
                      value={selectedValuesartists}
                    />
                  </div>
                </div>
                <div className="text-start mt-2">
                  <label className="mt-2 d-block mandatorySimple position-relative theme-text-color">Category</label>
                  <p className="mt-2 theme-text-color opacity-75"> Select A Live Stream Category Below </p>
                  <div className="mt-2">
                    <Select
                      options={optionscategories}
                      isMulti
                      className=" theme-bg-color-secondary  theme-text-color"
                      onChange={handleSelectChangecategories}
                      value={selectedValuescategories}
                      ref={categoryInputRef}
                    />
                  </div>
                  {editUserError?.categoryerror && (<span className="errorred mt-2 d-block">{editUserError?.categoryerror}</span>)}
                </div>
                <div className="text-start mt-2">
                  <label className="mt-2 d-block mandatorySimple position-relative theme-text-color">Language</label>
                  <p className="mt-2 theme-text-color opacity-75">Select A Live Stream Language Below</p>
                  <div className="text-dark">
                    <Select
                      options={optionslanguages}
                      isMulti
                      className=" theme-bg-color-secondary  theme-text-color"
                      onChange={handleSelectChangelanguages}
                      value={selectedValueslanguages}
                      ref={languageInputRef}
                    />
                  </div>
                  {editUserError?.languageerror && (<span className="errorred mt-2 d-block">{editUserError?.languageerror}</span>)}
                </div>
                <div className="text-start mt-2">
                  <label className="mt-2 theme-text-color">Block Country</label>
                  <p className="mt-2 theme-text-color opacity-75">Select A Live Stream country Below</p>
                  <div className="mt-2">
                    <Select
                      options={optionsForBlockCountry}
                      isMulti
                      className=" theme-bg-color-secondary  theme-text-color"
                      onChange={handleSelectChangeForBlockCountry}
                      value={selectedValues}
                    />
                  </div>
                </div>
                <div className="text-start mt-2">
                  <label className="mt-2 theme-text-color">Available Country</label>
                  <p className="mt-2 theme-text-color opacity-75">Select a Audio country Below</p>
                  <div className="mt-2">
                    <Select
                      options={optionsavailable_country}
                      isMulti
                      className=" theme-bg-color-secondary  theme-text-color"
                      onChange={handleSelectChangeavailable_country}
                      value={selectedValuesavailable_country}
                    />
                  </div>
                </div>
              </div>
              <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                <h6 className="theme-text-color trial-border-bottom  pb-3">SEO</h6>
                <div className="mt-3 text-start">
                  <label className="mt-2 theme-text-color">Website Page Title</label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="website_page_title"
                      value={editUser?.website_page_title}
                      className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                      onChange={handleInput}
                    // placeholder="websitetitle"
                    />
                  </div>
                </div>
                <div className="mt-2 text-start">
                  <label className="mt-2 theme-text-color">Website URL</label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="website_URL"
                      value={editUser?.website_URL}
                      className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                      onChange={handleInput}
                    // placeholder="websiteurl"
                    />
                  </div>
                </div>
                <div className="mt-2 text-start">
                  <label className="mt-2 theme-text-color">Meta Description</label>
                  <div className="mt-2">
                    <textarea
                      type="text"
                      value={editUser?.Meta_description}
                      name="Meta_description"
                      className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                      onChange={handleInput}
                    // placeholder="metadescription"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3 ">
                <h6 className="theme-text-color trial-border-bottom pb-3">Search Tags</h6>
                <div className="mt-2 ">
                  <p className="theme-text-color mt-2"> You don't have any search keywords.   </p>
                  <div className="mt-2">
                    <TagsInput
                      value={tags}
                      onChange={handleTagsChange}
                      name="tags"
                      classNames="rs-input theme-text-color  theme-bg-color-secondary  border-0"
                    />
                  </div>
                </div>
              </div>
              <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                <h6 className="theme-text-color trial-border-bottom pb-3">Status Settings</h6>
                <div className="d-flex align-items-center justify-content-between mt-3">
                  <p className="theme-text-color">Is this Live stream Featured</p>
                  <label className="switch">
                    <input
                      name="featured"
                      id="featured"
                      onChange={handleInputenable}
                      className="rs-input"
                      defaultChecked={
                        editUser?.featured == 1 ? true : false
                      }
                      checked={
                        editUser?.featured == 1 ? true : false
                      }
                      type="checkbox"
                    />
                    <span
                      className="slider round"
                      name="featured"
                      id="featured"
                      onChange={handleInputenable}
                      value={editUser?.featured == 1 ? "1" : "0"}
                    ></span>
                  </label>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-3">
                  <p className="theme-text-color">Is this Live stream Active</p>
                  <label className="switch">
                    <input
                      name="active"
                      id="active"
                      onChange={handleInputenable}
                      defaultChecked={
                        editUser?.active == 1 ? true : false
                      }
                      checked={editUser?.active == 1 ? true : false}
                      className="rs-input"
                      type="checkbox"
                    />
                    <span
                      className="slider round"
                      name="active"
                      id="active"
                      onChange={handleInputenable}
                      value={editUser?.active == 1 ? "1" : "0"}
                    ></span>
                  </label>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-3">
                  <p className="theme-text-color"> Is this Live stream display in Banner</p>
                  <label className="switch">
                    <input
                      name="banner"
                      id="banner"
                      onChange={handleInputenable}
                      className="r-input"
                      defaultChecked={
                        editUser?.banner == 1 ? true : false
                      }
                      checked={editUser?.banner == 1 ? true : false}
                      type="checkbox"
                    />
                    <span
                      className="slider round"
                      name="banner"
                      id="banner"
                      onChange={handleInputenable}
                      value={editUser?.banner == 1 ? "1" : "0"}
                    ></span>
                  </label>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-3">
                  <p className="theme-text-color"> Is this Live stream in Enable Chats </p>
                  <label className="switch">
                    <input
                      name="enable_chats"
                      id="enable_chats"
                      onChange={handleInputenable}
                      className="r-input"
                      defaultChecked={
                        editUser?.enable_chats == 1 ? true : false
                      }
                      checked={
                        editUser?.enable_chats == 1 ? true : false
                      }
                      type="checkbox"
                    />
                    <span
                      className="slider round"
                      name="enable_chats"
                      id="enable_chats"
                      onChange={handleInputenable}
                      value={
                        editUser?.enable_chats == 1 ? "1" : "0"
                      }
                    ></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              {editUser?.livestream_URL ? (
                <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                  <h6 className="heme-text-color trial-border-bottom  pb-3">LiveStream {formattedDatatype}</h6>
                  <div className="text-start mt-2">
                    {/* <ReactPlayer
                      url={editUser?.livestream_URL}
                      controls={true}
                      width="100%"
                      height="230px"
                    /> */}
                  </div>
                </div>
              ) : null}
              <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                <h6 className="theme-text-color trial-border-bottom  pb-3" >Thumbnails</h6>
                <div className="text-start mt-2">
                  <label className="mt-3 theme-text-color">Live Stream Image Cover</label>
                  <p className="mt-2 theme-text-color opacity-75">Select the Live stream image( 16:9 Ratio or1280X720px )</p>
                  <img
                    src={editUser?.Video_thumbnail}
                    className="my-1"
                    style={{ width: '100px', height: '100px', objectFit: "contain" }}
                  />
                  <div className="mt-2">
                    <div className="d-flex align-items-center">
                      <div className="col-6 col-sm-6 col-lg-6 p-1">
                        <div className="imagedrop">
                          <input type="file" ref={coverImageRef} className="opacity-0" onChange={(event) => handleCoverImageChange(event)} accept="image/*" key={coverImageFile ? coverImageFile : "inputKey"} />
                          <button type="button" className="bg-transparent position-absolute">
                            <CameraRetroIcon />
                          </button>
                        </div>
                      </div>
                      <div className="col-6 col-sm-6 col-lg-6 p-1">
                        {coverImageFile && (
                          <div className="imagedropcopy text-end">
                            <div className="d-flex align-items-center">
                              <img
                                src={URL.createObjectURL(coverImageFile)}
                                alt="Uploaded"
                                className="thumbnails-upload-images"
                              />
                              <button onClick={() => setCoverImageFile(null)} type="button" className="bg-transparent" > <MdClose fill="currentColor" /> </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-start mt-2">
                  <label className="mt-3 theme-text-color">Player Image Cover</label>
                  <p className="mt-2 theme-text-color opacity-75"> Select the Live stream image( 16:9 Ratio or1280X720px )</p>
                  <img
                    src={editUser?.Player_thumbnail}
                    className="my-1"
                    style={{ width: '100px', height: '100px', objectFit: "contain" }}
                  />
                  <div className="mt-2">
                    <div className="d-flex align-items-center">
                      <div className="col-6 col-sm-6 col-lg-6 p-1">
                        <div className="imagedrop">
                          <input type="file" ref={playerImageRef} className="opacity-0" onChange={(event) => handlePlayerImageChange(event)} accept="image/*" key={playerImageFile ? playerImageFile : "inputKey"} />
                          <button type="button" className="bg-transparent position-absolute">
                            <CameraRetroIcon />
                          </button>
                        </div>
                      </div>
                      <div className="col-6 col-sm-6 col-lg-6 p-1">
                        {playerImageFile && (
                          <div className="imagedropcopy text-end">
                            <div className="d-flex align-items-center">
                              <img
                                src={URL.createObjectURL(playerImageFile)}
                                alt="Uploaded"
                                className="thumbnails-upload-images"
                              />
                              <button onClick={() => setPlayerImageFile(null)} type="button" className="bg-transparent" > <MdClose fill="currentColor" /> </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-start mt-2">
                  <label className="mt-3 theme-text-color">TV Image Cover</label>
                  <p className="mt-2 theme-text-color opacity-75">  Select the Live stream image( 16:9 Ratio or 1280X720px )</p>
                  <img
                    src={editUser?.Video_TV_Thumbnail}
                    className="my-1"
                    style={{ width: '100px', height: '100px', objectFit: "contain" }}
                  />
                  <div className="mt-2">
                    <div className="d-flex align-items-center">
                      <div className="col-6 col-sm-6 col-lg-6 p-1">
                        <div className="imagedrop">
                          <input type="file" ref={tvImageRef} className="opacity-0" onChange={(event) => handleTvImageChange(event)} accept="image/*" key={tvImageFile ? tvImageFile : "inputKey"} />
                          <button type="button" className="bg-transparent position-absolute">
                            <CameraRetroIcon />
                          </button>
                        </div>
                      </div>
                      <div className="col-6 col-sm-6 col-lg-6 p-1">
                        {tvImageFile && (
                          <div className="imagedropcopy text-end">
                            <div className="d-flex align-items-center">
                              <img
                                src={URL.createObjectURL(tvImageFile)}
                                alt="Uploaded"
                                className="thumbnails-upload-images"
                              />
                              <button onClick={() => setTvImageFile(null)} type="button" className="bg-transparent" > <MdClose fill="currentColor" /> </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                <h6 className="theme-text-color trial-border-bottom pb-3">Live Stream Source</h6>
                <div className="text-start mt-3">
                  <p className="mt-2 theme-text-color">Select the Live Stream Source</p>
                  <div className="mt-2">
                    <select
                      onChange={handleInput}
                      name="url_type"
                      id="url_type"
                      value={editUser?.url_type}
                      className="px-3 py-3 rounded-2 w-100  theme-bg-color-secondary  theme-text-color border-0"
                      onClick={(event) => { setalllivestream_source(event.target.value); }}
                    >
                      <option value="0" className="theme-text-color  theme-bg-color-secondary ">Choose Live Source </option>
                      {livestream_source?.map((item, key) => (
                        <option value={item?.value} className="theme-text-color  theme-bg-color-secondary ">
                          {item?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>{alllive_stream_source()}</div>
                </div>
              </div>
              <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                <h6 className="theme-text-color trial-border-bottom pb-3">ReStream</h6>
                <div className="text-start mt-3">
                  <div className="row m-0">
                    <div className="col-9">
                      <label className="m-0">Enable ReStream</label>
                    </div>
                    <div className="col-3 text-end">
                      <label className="switch">
                        <input
                          name="featured"
                          checked={editUser?.enable_restream == "1"}
                          onChange={handleCheckbox}
                          type="checkbox"
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  </div>

                </div>
                {editUser?.enable_restream == 1 && (
                  <>
                    <div
                      className="accordion mt-3"
                      id="accordionSocialExample"
                    >
                      <div className="accordion-item  border border-light">
                        <h2
                          className="accordion-header"
                          id="headingOne"
                        >
                          <button
                            className="accordion-button collapsed p-3  theme-bg-color-secondary "
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            <img
                              src={youtube}
                              className="accordionImage"
                              alt="Animated GIF"
                            />
                            <strong className="lh-1 ms-1 theme-text-color">
                              YouTube
                            </strong>
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse "
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionSocialExample"
                        >
                          <div className="accordion-body py-3 px-0  theme-bg-color-secondary ">
                            <div className="row text-start m-0 p-0">
                              <div className="col-12 mb-2">
                                <div className="">
                                  <label className="mb-1 theme-text-color">
                                    YouTube Stream{" "}
                                    <small>(RTMP URL)</small>
                                  </label>

                                  <input
                                    type="text"
                                    className="rs-input form-control-lg theme-bg-color  theme-text-color border-0"
                                    name="youtube_restream_url"
                                    id="youtube_restream_url"
                                    onChange={handleInput}
                                    value={
                                      editUser?.youtube_restream_url
                                    }
                                  // placeholder="youtube_restream_url"
                                  />
                                </div>
                              </div>
                              <div className="col-12 ">
                                <div className="" id="">
                                  <div>
                                    <label className="mb-1 theme-text-color">
                                      YouTube Stream Key
                                    </label>
                                  </div>{" "}
                                  <input
                                    type="text"
                                    className="rs-input form-control-lg theme-bg-color  theme-text-color border-0"
                                    name="youtube_streamkey"
                                    id="youtube_streamkey"
                                    onChange={handleInput}
                                    value={editUser?.youtube_streamkey}
                                  // placeholder="youtube_streamkey"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item border border-light">
                        <h2
                          className="accordion-header"
                          id="headingTwo"
                        >
                          <button
                            className="accordion-button collapsed p-3  theme-bg-color-secondary "
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            <img
                              src={facebook}
                              className="accordionImage"
                              alt="Animated GIF"
                            />
                            <strong className="lh-1 ms-1 theme-text-color"> Facebook</strong>
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionSocialExample"
                        >
                          <div className="accordion-body py-3 px-0  theme-bg-color-secondary ">
                            <div className="row  text-start p-0 m-0">
                              <div className="col-12 mb-2 ">
                                <div className="">
                                  <label className="mb-1 theme-text-color">
                                    FaceBook Stream{" "}
                                    <small>(RTMP URL)</small>
                                  </label>
                                  <input
                                    type="text"
                                    className="rs-input form-control-lg theme-bg-color  theme-text-color border-0"
                                    name="fb_restream_url"
                                    id="fb_restream_url"
                                    onChange={handleInput}
                                    value={editUser?.fb_restream_url}
                                  // placeholder="fb_restream_url"
                                  />
                                </div>
                              </div>

                              <div className="col-12 ">
                                <div className="" id="">
                                  <div>
                                    <label className="mb-1 theme-text-color">
                                      FaceBook Stream Key
                                    </label>
                                  </div>{" "}
                                  <input
                                    type="text"
                                    className="rs-input form-control-lg theme-bg-color  theme-text-color border-0"
                                    name="fb_streamkey"
                                    id="fb_streamkey"
                                    onChange={handleInput}
                                    value={editUser?.fb_streamkey}
                                  // placeholder="fb_streamkey"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item border border-light">
                        <h2
                          className="accordion-header"
                          id="headingThree"
                        >
                          <button
                            className="accordion-button collapsed p-3  theme-bg-color-secondary "
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            <img
                              src={twitter}
                              className="accordionImage"
                              alt="Animated GIF"
                            />
                            <strong className="lh-1 ms-1 theme-text-color">
                              {" "}
                              Twitter
                            </strong>
                          </button>
                        </h2>
                        <div
                          id="collapseThree"
                          className="accordion-collapse collapse "
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionSocialExample"
                        >
                          <div className="accordion-body py-3 px-0  theme-bg-color-secondary ">
                            <div className="row  text-start m-0 p-0">
                              <div className="col-12 mb-2">
                                <div className="">
                                  <label className="mb-1 theme-text-color">
                                    Twitter Stream{" "}
                                    <small>(RTMP URL)</small>
                                  </label>
                                  <input
                                    type="text"
                                    className="rs-input form-control-lg theme-bg-color  theme-text-color border-0"
                                    name="twitter_restream_url"
                                    id="twitter_restream_url"
                                    onChange={handleInput}
                                    value={
                                      editUser?.twitter_restream_url
                                    }
                                  // placeholder="twitter_restream_url"
                                  />
                                </div>
                              </div>

                              <div className="col-12">
                                <div className="mt-2">
                                  <label className="mb-1">
                                    {" "}
                                    Twitter Stream Key{" "}
                                  </label>

                                  <input
                                    type="text"
                                    className="rs-input form-control-lg theme-bg-color  theme-text-color border-0"
                                    name="twitter_streamkey"
                                    id="twitter_streamkey"
                                    onChange={handleInput}
                                    value={editUser?.twitter_streamkey}
                                  // placeholder="twitter_streamkey"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                <h6 className="theme-text-color trial-border-bottom pb-3">Visibility</h6>
                <div className="text-start mt-3">
                  <label className="mt-2 theme-text-color">Publish Type</label>
                  <div className="mt-2">
                    <input
                      type="radio"
                      id="publish_now"
                      name="publish_type"
                      value="publish_now"
                      className=" theme-bg-color-secondary  theme-text-color border-0 "
                      checked={publishType == "publish_now"}
                      onChange={handlePublishTypeChange}
                    />
                    <label htmlFor="publish_now" className="mt-2 ms-1 theme-text-color">Publish Now </label>
                  </div>
                  <div className="mt-2">
                    <input
                      type="radio"
                      id="publish_later"
                      name="publish_type"
                      value="publish_later"
                      checked={publishType == "publish_later"}
                      onChange={handlePublishTypeChange}
                    />
                    <label
                      htmlFor="publish_later"
                      className="mt-2 ms-1 theme-text-color"
                    >
                      Publish Later
                    </label>
                    {publishType == "publish_later" && (
                      <div className="mb-3">
                        <label className="mt-3 theme-text-color d-block">Publish Time</label>
                        <input
                          type="datetime-local"
                          className="mt-2 w-100 theme-text-color  theme-bg-color-secondary  border-0 p-2 rounded-2"
                          id="publish_time"
                          name="publish_time"
                          value={publishTime}
                          onChange={handlePublishTimeChange}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                <h6 className="theme-text-color trial-border-bottom pb-3" >Access</h6>
                <div className="mt-3">
                  <label className="mt-2 theme-text-color">User Access</label>
                  <p className="mt-2 theme-text-color opacity-75">
                    Who Is Allowed To View This Live Stream ?
                  </p>
                  <select
                    onChange={handleInput}
                    name="access"
                    id="access"
                    value={editUser?.access}
                    className="px-3 py-3 mt-2 rounded-2 w-100  theme-bg-color-secondary  theme-text-color border-0"
                    onClick={(event) => {
                      setallliveuseraccess(event.target.value);
                    }}
                  >
                    {user_access?.map((item, key) => (
                      <option value={item?.value} className="theme-text-color">
                        {item?.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div>{alllive_streamuseraccess()}</div>
              </div>
              <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                <h6 className="theme-text-color trial-border-bottom pb-3">Live Event Artist</h6>
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <p className="theme-text-color"> Is this Live stream in Enable Tips</p>
                  <label className="switch">
                    <input
                      name="enable_Tips"
                      onChange={handleInputenable}
                      className="r-input"
                      defaultChecked={
                        editUser?.enable_Tips == 1 ? true : false
                      }
                      checked={
                        editUser?.enable_Tips == 1 ? true : false
                      }
                      type="checkbox"
                    />
                    <span
                      className="slider round"
                      name="enable_Tips"
                      onChange={handleInputenable}
                      value={editUser?.enable_Tips == 1 ? "1" : "0"}
                    ></span>
                  </label>
                </div>
                <div className="mt-2">
                  <label className="mt-2 theme-text-color">Donations</label>
                  <div className="mt-2">
                    <input
                      type="number"
                      name="donations_label"
                      id="donations_label"
                      className="rs-input form-control-lg theme-text-color  theme-bg-color-secondary  border-0"
                      value={editUser?.donations_label}
                      placeholder=""
                      onChange={handleInput}
                    />
                  </div>
                </div>
              </div>
              <div className="text-end mb-3">
                <button onClick={handleLiveUpdate} className="btn btn-primary">
                  Update LiveStream
                </button>
              </div>
            </div>
          </div>
        </section>
      </>
    </div >
  );
}

export default ContentEditLiveStream;
