import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import axios, { CancelToken, isCancel } from "axios";
import { ProgressBar } from "react-bootstrap";


export default function Edit_Episode_Video() {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const { id } = useParams();
  const { seriesid } = useParams();

  // console.log(seriesid)
  // console.log(id)

  const [file, setFile] = useState("");
  const access_token = localStorage.getItem("access_token");
  const [Channel_Partner_id, setChannel_Partner_id] = useState("1");
  const [message, setMessage] = useState("");

  const [uploadPercentage, setUploadPercentage] = useState(0);
  const cancelFileUpload = useRef(null);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  // const uploadFile = ({ target: { files } }) => {
  //   let data = new FormData();
  //   data.append("file", files[0]);

  //   const options = {
  //     onUploadProgress: (progressEvent) => {
  //       const { loaded, total } = progressEvent;

  //       let percent = Math.floor((loaded * 100) / total);

  //       if (percent < 100) {
  //         setUploadPercentage(percent);
  //       }
  //     },
  //     cancelToken: new CancelToken(
  //       (cancel) => (cancelFileUpload.current = cancel)
  //     ),
  //   };
  //   axios
  //     .post(`${process.env.REACT_APP_Baseurl}/admin/Episode_Upload`, data, {
  //       headers: headers,
  //     })
  //     .then((res) => {
  //       console.log(res);
  //       if (res.data.status == true) {
  //         var data = res.data;
  //         var episode_id = res.data.Episode_id;
  //         // console.log(data)
  //         // console.log(Audio_id)
  //         setUploadPercentage(100);
  //         alert("Added successfully  !");
  //         setTimeout(() => {
  //           setUploadPercentage(0);
  //         }, 1000);
  //       } else {
  //         setMessage("Some error Occured");
  //         console.log("Error");
  //         alert("Enter Correct Details");
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       alert(err);

  //       if (isCancel(err)) {
  //         alert(err.message);
  //       }
  //       setUploadPercentage(0);
  //     });
  // };

  // const cancelUpload = () => {
  //   if (cancelFileUpload.current)
  //     cancelFileUpload.current("User has canceled the file upload.");
  // };

  // const [uploadPercentagefile, setUploadPercentagefile] = useState(0);
  // const cancelFileUploadfile = useRef(null);

  // const cancelUploadfile = () => {
  //   if (cancelFileUploadfile.current)
  //     cancelFileUploadfile.current("User has canceled the file upload.");
  // };

  const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setSelectedFile(selectedFile);
    uploadFile(selectedFile);
  };

  const uploadFile = (selectedFile) => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("Channel_Partner_id", Channel_Partner_id);

      const xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-Episode/upload-update/${id}`,
        { headers: headers }
      );
      xhr.setRequestHeader("Authorization", "Bearer " + access_token);

      xhr.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded / event.total) * 100);
          setUploadProgress(progress);
        }
      });

      xhr.onreadystatechange = () => {
        if (xhr.readyState == 4 && xhr.status == 200) {
          // File upload completed
          alert("Added successfully  !");
          // console.log("Upload completed");
        }
      };

      xhr.send(formData);
    }
  };

  const getFileSizeInMB = () => {
    if (selectedFile) {
      const fileSizeInBytes = selectedFile.size;
      const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
      return fileSizeInMB.toFixed(2);
    }
    return null;
  };

  return (
    <div className=" m-0 p-0">
      <div className="">
        <section className="section container-fluid pt-3">
          <div className="p-2">
            <div className="iq-card">
              <div className="modal-body">
                <div>
                  <div>
                    <div>
                      <h3>Upload Full Episode Here</h3>

                      <div className=" text-center file-drag mt-3 editvideo mb-5">
                        <label
                          id="label-file-upload"
                          htmlFor="input-file-upload"
                        >
                          <div>
                            <p>
                              Trailers Can Be Uploaded From Video Edit Screen
                            </p>
                            <p>
                              <input
                                type="file"
                                accept="video/mp4"
                                id="input-file-upload"
                                name="addvideo"
                                multiple
                                className="form-control"
                                onChange={handleFileChange}
                              />
                            </p>
                            {selectedFile && (
                              <div>File Size: {getFileSizeInMB()} MB</div>
                            )}
                            {selectedFile && (
                              <div>Upload Progress: {uploadProgress}%</div>
                            )}
                          </div>
                          <br />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

// function EditEpisodeVideo() {

// 	const { id } = useParams();
// 	const navigate = useNavigate();
// 	const [editUser, setEditUser] = useState({ id: '', user_id: '', series_id: '', trailer: '', image: '', access: '', ppv_price: '', ppv_interval: '', trailer_type: '', ios_product_id: '', uploaded_by: '', })

// 	const [trailer, setTrailer] = useState('');
// 	const [trailer_type, setTrailer_type] = useState('');
// 	const [trailerimage, setTrailerImage] = useState('');

// 	// console.log(editUser)
// 	const [message, setMessage] = useState('');
// 	const access_token = localStorage.getItem("access_token");

// 	const [order, setOrder] = useState({});
// 	const [data, setData] = useState([]);
// 	const [publish, setPublish] = useState([]);
// 	const [order1, setOrder1] = useState({});
// 	const [order2, setOrder2] = useState({});
// 	const [language, setLanguage] = useState([]);

// 	const [category, setCategory] = useState([]);
// 	const [useraccess, setUseraccess] = useState([]);

// 	// console.log(access_token)

// 	const headers = {
// 		'Content-Type': 'application/json',
// 		Authorization: 'Bearer ' + access_token,
// 		"Accept": 'application/json',
// 		'Access-Control-Allow-Origin': '*',
// 	};

// 	useEffect(() => {
// 		const getUser = async () => {
// 			const reqData = await fetch(`https://api.flicknexs.com/admin/series_season/edit/${id}`, { headers: headers });
// 			const resData = await reqData.json();
// 			var res = resData.SeriesSeason[0]
// 			var result = resData.user_access
// 			setEditUser(res);
// 			setUseraccess(result)
// 			// console.log(resData)
// 			// console.log(result)
// 			// console.log(res)
// 		}
// 		getUser();
// 	}, [id]);

// 	const handleInput = (e) => {
// 		setEditUser({ ...editUser, [e.target.name]: e.target.value });
// 	}
// 	const handleUpdate = async (e) => {
// 		e.preventDefault();
// 		const editInputvalue = { id: editUser.id, user_id: editUser.user_id, series_id: editUser.series_id, trailer: editUser.trailer, image: editUser.image, access: editUser.access, ppv_price: editUser.ppv_price, ppv_interval: editUser.ppv_interval, trailer_type: editUser.trailer_type, ios_product_id: editUser.ios_product_id, uploaded_by: editUser.uploaded_by, };
// 		// console.log(editInputvalue);
// 		let res = await fetch("https://api.flicknexs.com/admin/series_season/update/" + id, {
// 			method: "POST",
// 			headers: headers,
// 			body: JSON.stringify(editInputvalue)
// 		});

// 		let resjson = await res.json();
// 		// console.log(resjson)
// 		if (res.status == 200) {
// 			setMessage(resjson.success);
// 			// alert('Success')
// 			setTimeout(() => {
// 				// navigate('/admin/series-list');
// 			}, 2000);

// 			const formDat = new FormData();

// 			formDat.append('season_id', id);
// 			formDat.append('image', trailerimage);

// 			axios.post('https://api.flicknexs.com/admin/Image-upload-series_season', formDat, { headers: headers })
// 				.then(response => {
// 					// console.log('api', response);
// 					if (response.data.status == true) {
// 						var result = response.data;
// 						console.log('result', result);
// 						alert("Added successfully  !")
// 					}
// 				})

// 			const formDa = new FormData();

// 			formDa.append('season_id', id);
// 			formDa.append('trailer', trailer);
// 			formDa.append('trailer_type', trailer_type);

// 			axios.post('https://api.flicknexs.com/admin/Trailer-series_season', formDa, { headers: headers })
// 				.then(response => {
// 					// console.log('api', response);
// 					if (response.data.status == true) {
// 						var result = response.data;
// 						console.log('result', result);
// 						alert("Added successfully  !")
// 					}
// 				})

// 		} else {
// 			setMessage("Some error Occured");
// 			console.log('Error');
// 			alert('Enter Correct Details')
// 		}

// 	}

// 	return (

// 		<>
// 			<div className='row m-0 p-0'>
// 				<div className='col-lg-2'>  </div>
// 				<div className='col-lg-10'>
//
// 					<section className="section container-fluid pt-3">
// 						<div className="p-2">
// 							<div className="iq-card">
// 								<div className="modal-body">
// 									<div>
// 										<div>
// 											<div>
// 												<label>Upload Full Episode Here</label>

// 												<div className=" text-center file-drag mt-2">
// 													<label id="label-file-upload" htmlFor="input-file-upload">
// 														<div>
// 															<p>Trailers Can Be Uploaded From Video Edit Screen</p>
// 															<p>
// 																<input
// 																	type="file"
// 																	className="form-control-file"
// 																	onChange={uploadFile} accept="video/*" id="input-file-upload"
// 																/>
// 															</p>
// 														</div>
// 														<br />
// 													</label>
// 													<div className='mb-5'>
// 														{uploadPercentage > 0 && (
// 															<div className="row mt-3">
// 																<div className="col pt-1">
// 																	<ProgressBar
// 																		now={uploadPercentage}
// 																		striped={true}
// 																		label={`${uploadPercentage}%`}
// 																	/>
// 																</div>
// 																<div className="col-auto">
// 																	<span
// 																		className="text-primary cursor-pointer"
// 																		onClick={() => cancelUpload()}
// 																	>
// 																		Cancel
// 																	</span>
// 																</div>
// 															</div>
// 														)}
// 														<br />
// 														<div className='text-center'>
// 															<button onClick={handleNext} className='btn btn-primary'>Proceed to Next </button>
// 														</div>
// 													</div>
// 												</div>
// 											</div>
// 										</div>

// 										<div className="modal-footer form-group">
// 											<button type="submit" className="btn btn-primary" id="submit-update-cat" onClick={handleUpdate}>Update</button>
// 										</div>
// 									</div>
// 								</div>
// 							</div>
// 						</div>
// 					</section>
// 				</div></div>
// 		</>
// 	);
// }

// export default EditEpisodeVideo;
