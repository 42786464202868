import React, { useEffect, useRef, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import axios from 'axios';
import {  MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from 'react-icons/md';
import paginationFactory from 'react-bootstrap-table2-paginator';
import jsPDF from 'jspdf';
import * as XLSX from "xlsx";
import { CSVLink } from 'react-csv';
import Select from "react-select";
import ExportIcon from "../../../assets/icons/export-icon.webp";
import ResponseLoader from '../../../components/Loader/ResponseLoader'
import NoRecordTable from '../../../components/adminCommonComponents/NoRecordTable';
import MessageBox from '../../../components/MessageBox';



const VideoViewRegion = () => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [filter, setFilter] = useState("");
  const [filteredLiveData, setFilteredLiveData] = useState([]);
  const [withOutRefresh, setWithOutRefresh] = useState(false)
  const [getLiveData, setLiveData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [block_country, setBlock_Country] = useState([]);
  const [editUserError, setEditUserError] = useState({});
  const [blockCountryData, setBlockCountryData] = useState([])
  const [selectAll, setSelectAll] = useState(false);
  //input ref
  const languageInputRef = useRef(null);

  const access_token = localStorage.getItem("access_token_channel");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    fetchData();
  }, [withOutRefresh]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/*`,
        { headers: headers }
      );
      const liveData = response?.data;
      const filterHelp = liveData;
      setLiveData(liveData);
      setFilteredLiveData(filterHelp);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    nextPageText: "Next",
    prePageText: "Prev",
    firstPageText: <MdKeyboardDoubleArrowLeft />,
    lastPageText: <MdKeyboardDoubleArrowRight />,
    showTotal: true,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "All", value: filteredLiveData?.length },
    ],
  });

  const columns = [
    {
      dataField: "ID",
      text: "ID",
      sort: true,
    },
    {
      dataField: "Video Name",
      text: "Video Name",
      sort: true,
    },
    {
      dataField: "Country Name",
      text: "Country Name",
      sort: true,
    },
    {
      dataField: "IP Number",
      text: "IP Number",
    },
    {
      dataField: "Views",
      text: "Views",
    },
  ];

  const exportHeadersLive = [
    { label: "ID", key: "id" },
    { label: "VIDEOS NAME", key: "title" },
    { label: "EMAIL", key: "uploaded_by" },
    { label: "CHANNEL NAME", key: "active" },
    { label: "TOTAL VIEWS", key: "banner" },
    { label: "UPLOAD ON", key: "upload_on" },
  ];

  const conditionalGeneratePDFLive = (get) => {
    const doc = new jsPDF();
    doc.text("ALL LIVE LIST ", 10, 10);
    const columns = [
      "Index",
      "ID",
      "VIDEOS NAME",
      "EMAIL",
      "CHANNEL NAME",
      "TOTAL VIEWS",
      "UPLOAD ON",
    ];
    const allDataPDF = getLiveData?.map((item, index) => [
      index + 1,
      item.id,
      item.title,
      item.email,
      item.channel_name,
      item.total_views,
      item.upload_on,
    ]);
    const filterPDF = filteredLiveData?.map((item, index) => [
      index + 1,
      item.id,
      item.title,
      item.email,
      item.channel_name,
      item.total_views,
      item.upload_on,
    ]);

    doc.autoTable({
      startY: 20,
      head: [columns],
      body: get == 1 ? allDataPDF : filterPDF,
    });
    doc.save("ALL-LIVE-LIST.pdf");
  };

  const generateXLSXLIVE = (get) => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(get == 1 ? getLiveData : filteredLiveData);
    XLSX.utils.book_append_sheet(wb, ws, "MySheet");
    XLSX.writeFile(wb, "ALL-LIVE-LIST.xlsx");
  };

  //Handle Blocked country
  const handleSelectChangeBlockedCountry = (selectedValues) => {
    setBlockCountryData(selectedValues)
  };

  //handle dropdown data function
  const fetchOptions = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_Baseurl}/Channel/Backend/Livestream-Create`, { headers });
      setBlock_Country(response?.data?.Block_Country);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
    },
    selectionHeaderRenderer: ({ mode, checked, indeterminate }) => (
      <div>
        <input
          type="checkbox"
          checked={false}
          indeterminate={indeterminate}
        // onChange={}
        />
      </div>
    ),
  };

  return (
    <>
      {showOverlay && (<ResponseLoader />)}
      <h4 className="admin-title mb-0  pb-3 theme-text-color text-md-start"> <MessageBox text='View By Region' /></h4>
      <label className="me-3 mb-3">Region : </label>
      <div className="theme-bg-color p-3 rounded-2 bootstrapTable pb-5 multi-delete-width">
        <div className="row mx-0 mb-3">
          <div className='col-4 ps-0'>
            <Select
              options={block_country?.map((option) => ({
                value: option?.id,
                label: option?.country_name,
              }))}
              className="theme-bg-color-secondary custom-placeholder theme-text-color border-0 mb-3"
              isMulti
              value={blockCountryData}
              onChange={handleSelectChangeBlockedCountry}
            />
          </div>
          <div className="btn-group col-8 pe-0 justify-content-end">
            <div className="dropdown d-block " key={"2"}>
              <button className="btn btn-success dropdown-toggle d-flex hideArrow" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"  >
                <span className="fw-bolder text-white">Export </span>
                <img src={ExportIcon} className="ms-2 exportIcon" alt="exportIcon" />
              </button>
              <ul className="dropdown-menu theme-bg-color" aria-labelledby="dropdownMenuButton1" >
                <li className="dropdown-item theme-text-color"> <CSVLink headers={exportHeadersLive} data={filteredLiveData} className="theme-text-color d-block" filename="LIVE-FILTERED-LIST.csv"> CSV </CSVLink> </li>
                <li className="dropdown-item theme-text-color" onClick={() => { conditionalGeneratePDFLive(0); }} > PDF</li>
                {/* <li className="dropdown-item theme-text-color" onClick={() => { generateXLSXLIVE(0); }}> XLSX </li> */}
              </ul>
            </div>
            <div className="dropdown d-block ms-2 " key={"2"}>
              <button className="btn btn-success dropdown-toggle d-flex hideArrow" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"  >
                <span className="fw-bolder text-white">Export </span>
                <img src={ExportIcon} className="ms-2 exportIcon" alt="exportIcon" />
              </button>
              <ul className="dropdown-menu theme-bg-color" aria-labelledby="dropdownMenuButton1" >
                <li className="dropdown-item theme-text-color"><CSVLink headers={exportHeadersLive} data={getLiveData} className="theme-text-color d-block " filename="ALL-LIVES-LIST.csv" > CSV</CSVLink> </li>
                <li className="dropdown-item theme-text-color" onClick={() => { conditionalGeneratePDFLive(1); }} > PDF</li>
                {/* <li className="dropdown-item theme-text-color" onClick={() => {  generateXLSXLIVE(1); }}> XLSX </li> */}
              </ul>
            </div>
          </div>
        </div>
        <BootstrapTable keyField="id" columns={columns} data={filteredLiveData} pagination={pagination} className="no-border-table theme-text-color" noDataIndication={<NoRecordTable result={filteredLiveData?.length} loader={loading} />} hover headerClasses="videoWidth" selectRow={selectRow} selectAll={selectAll} />
      </div>
    </>
  )
}

export default VideoViewRegion