import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from "react-icons/md";
import ResponseLoader from "../../components/Loader/ResponseLoader";
import AdvertiserPanel from "./AdvertiserPanel";
import AdminNavbar from "./Layout/AdminNavbar";
import { getItemToken } from "../../Utils/localStorageUtils";
import AdminSliderModal from "./Modal/AdminSliderModal";
import AdminDeleteModal from "./Modal/AdminDeleteModal";
import AdminHeader from "./Layout/AdminHeader";
import NoRecordTable from "./Layout/NoRecordTable";
import { useTranslation } from "react-i18next";

function AdvertiserAllAdvertisements() {
    const { t } = useTranslation();
    const [apiData, setApiData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showOverlay, setShowOverlay] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [selectAll, setSelectAll] = useState(false);
    const [modelClose, setModelClose] = useState(false);
    const [itemToDelete, setItemToDelete] = useState([]);
    const [withOutRefresh, setWithoutRefresh] = useState(false)
    const userAccessToken = getItemToken('access_token');

    const columns = [
        {
            dataField: "adsname",
            text: "Ads Name",
            sort: true
        },
        {
            dataField: "adstype",
            text: "Ads Type",
            // sort: true,
        },
        {
            dataField: "adscategory",
            text: "Ads Category",
            // sort: true,
        },
        {
            dataField: "adsposition",
            text: "Ads Position Play",
            // sort: true,
        },
        {
            dataField: "location",
            text: "Location",
            // sort: true,
        },
        {
            dataField: "views",
            text: "Views",
            // sort: true,
        },
        {
            dataField: "active",
            text: "Status"
        },
        {
            dataField: "action",
            text: "Actions",
        },
    ];

    const options = {
        pageStartIndex: 1,
        paginationSize: 4,
        sizePerPage: 10,
        nextPageText: "Next",
        prePageText: "Prev",
        firstPageText: <MdKeyboardDoubleArrowLeft />,
        lastPageText: <MdKeyboardDoubleArrowRight />,
        showTotal: true,
        alwaysShowAllBtns: true,
        sizePerPageList: [{
            text: '5', value: 5
        }, {
            text: '10', value: 10
        }, {
            text: 'All', value: Number(filteredData?.length)
        }]
    };

    const selectRow = {
        mode: "checkbox",
        onSelect: (row, isSelect) => {
            if (row) {
                if (isSelect) {
                    setItemToDelete([...itemToDelete, row.id]);
                } else {
                    setItemToDelete(itemToDelete?.filter((id) => id !== row?.id));
                }
            }
        },
        style: { width: '30px' },
        selected: itemToDelete,
        selectionHeaderRenderer: ({ mode, checked }) => (
            <div>
                <input type="checkbox" checked={checked} onChange={handleAll} />
            </div>
        ),
    };
    //Bootstrap Table util end

    const fetchData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_Baseurl}/admin/audio/index`, { headers: userAccessToken });
            const result = response?.data?.['AllAudio'];
            const updatedList = result?.map((item) => {
                const UploadStatus = item?.uploaded_by === "1" ? "Admin" : item?.uploaded_by === "4" ? "Channel Partner" : item?.uploaded_by === "5" ? "Content Partner" : "";
                let published = item?.active === 1 && item?.status === 1 && item?.draft === 1 ? true : false
                const statusText = published ? "PUBLISHED" : 'UNPUBLISHED';
                return { ...item, uploaded_by: UploadStatus, active: statusText };
            });
            // setApiData(updatedList);
            // setFilteredData(updatedList);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false);
        }
    };

    const handleConfirmDelete = async () => {
        setShowOverlay(true);
        try {
            const formData = new FormData();
            formData.append(`${'key'}`, "[" + itemToDelete + "]");
            const response = await axios.post(`${'url'}`, formData, { headers: userAccessToken });
            const result = response?.data;
            if (result?.status) {
                setWithoutRefresh(!withOutRefresh);
                toast.success(t(result?.message));
                setShowOverlay(false);
                setItemToDelete([])
            }
            else {
                toast.error(t(result?.message));
                setShowOverlay(false);
            }
        } catch (err) {
            var errorMessage = err?.response?.data?.message;
            toast.error(t(errorMessage));
            setShowOverlay(false);
        }
    };

    const confirmToggle = async () => {
        setShowOverlay(true);
        setShowModal(false);
        try {
            const formData = new FormData();
            const newStatus = currentItem?.banner === 1 ? 0 : 1;
            formData.append(`${'key'}`, currentItem?.id);
            formData.append("banner_status", newStatus,);
            const response = await axios.post(`${'url'}`, formData, { headers: userAccessToken });
            const result = response.data
            if (result.status) {
                setWithoutRefresh(!withOutRefresh);
                toast.success(t(result?.message));
                setShowOverlay(false);
                setCurrentItem(null);
            }
            else {
                toast.error(t(result?.message));
                setShowOverlay(false);
            }
        } catch (err) {
            var errorMessage = err?.response?.data?.message;
            toast.error(t(errorMessage));
            setShowOverlay(false);
        }
    };

    // Api links end 
    const handleToggle = async (item) => {
        setCurrentItem(item);
        setShowModal(true);
    };

    const closeModal = () => {
        setModelClose(!closeModal);
        setShowModal(false);
    };

    const openModal = () => {
        setModelClose(true);
    };

    const singleDelete = (id) => {
        setModelClose(true);
        setItemToDelete([id]);
    };

    const handleDeletedata = () => {
        handleConfirmDelete();
        closeModal();
    };

    const handleAll = () => {
        setSelectAll(!selectAll);
        setItemToDelete(selectAll ? [] : filteredData?.map((item) => item?.id));
    };

    const handleFilterChange = (data) => {
        setFilteredData(data);
    };


    useEffect(async () => {
        fetchData();
    }, [withOutRefresh])

    return (
        <>
            <AdvertiserPanel>
                {showModal && <AdminSliderModal modelClose={showModal} sliderClose={closeModal} sliderAction={confirmToggle} buttonText={currentItem?.banner} />}
                {modelClose && <AdminDeleteModal modelClose={modelClose} deleteClose={closeModal} deleteAction={handleDeletedata} buttonText={itemToDelete?.length} />}
                {showOverlay && (<ResponseLoader />)}
                <AdminNavbar heading="All Advertisements" totalDataLength={apiData?.length} deleteCount={itemToDelete?.length} navigation={`advertiser/upload-ads`} deleteMethod={openModal} navText="Add New Ads" />
                <div className="theme-bg-color p-3 rounded-2 bootstrapTable  multi-delete-width" >
                    <AdminHeader searchMethod={handleFilterChange} reduceData={filteredData} originalData={apiData} fileName={'ADS-LIST'} />
                    <BootstrapTable keyField="id" pagination={paginationFactory(options)} columns={columns} data={filteredData} className="no-border-table theme-text-color " noDataIndication={<NoRecordTable result={filteredData?.length} loader={loading} />} hover headerClasses="videoWidth" selectRow={selectRow} selectAll={selectAll} />
                </div>
            </AdvertiserPanel>
        </>
    );
}

export default AdvertiserAllAdvertisements;