import { useRef } from "react";
import { connect } from "react-redux";
const MiniPrograssBar = ({ handleTime, progressValue, duration, currentloadings, readyState }) => {
    const progressBarRef = useRef(null);

    const handleSeekClick = (e) => {
        const clickPositionX = e.nativeEvent.offsetX;
        const progressBarWidth = progressBarRef.current.offsetWidth;
        const newTime = (clickPositionX / progressBarWidth) * duration;
        handleTime(newTime);
    };
    return (

        <>
            {readyState < 1 ?
                <div className="square" style={{ height: "2px", width: "100%" }}></div> :
                <div className="progress-container w-100 position-relative" >
                    <span className="progress-filled-circle bgButton" style={{ [document.body.getAttribute('dir') === 'rtl' ? 'right' : "left"]: `${progressValue}%` }}></span>
                    <div ref={progressBarRef} className={`progress`} onClick={handleSeekClick}  >
                        <div className="progress-filled bgButton" style={{ width: `${progressValue}%` }} ></div>
                    </div>
                </div>
            }
        </>
    )
}

const mapStateToProps = state => ({
    currentloadings: state.get_current_Reducers.currentloadings,
});
export default connect(mapStateToProps)(MiniPrograssBar)