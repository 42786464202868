import React, { useState, useEffect } from "react";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from "react-icons/md";
import { toast } from "react-toastify";

import ResponseLoader from "../../../components/Loader/ResponseLoader";
import AdminHeader from "../../../components/adminCommonComponents/AdminHeader";
import AdminSliderModal from "../../../components/adminCommonComponents/AdminSliderModal";
import AdminDeleteModal from "../../../components/adminCommonComponents/AdminDeleteModal";
import NoRecordTable from "../../../components/adminCommonComponents/NoRecordTable";
import AdminNavbar from "../../../components/adminCommonComponents/AdminNavbar";
import { ActionFormatterComponent, SliderFormatterComponent, statusFormatter, videoFormatter } from "../../../Pages/CommonMethods/CommonBoostrapFormatter";
import { channelApiHeader, videoDefaultURL } from "../../CommonApis/CommonApiUrl";
import { useTranslation } from "react-i18next";
import useCommonApiUrl from "../../CommonApis/CommonApiUrl";


function ChannelAllVideos() {
  const { videoDefaultURL, channelApiHeader } = useCommonApiUrl();
  const { t } = useTranslation();
  const [apiData, setApiData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showOverlay, setShowOverlay] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [modelClose, setModelClose] = useState(false);
  const [itemToDelete, setItemToDelete] = useState([]);
  const [withOutRefresh, setWithoutRefresh] = useState(false)


  //Bootstrap Table util start

  const columns = [
    {
      dataField: "Thumbnail",
      text: "Videos",
      formatter: (cell, row) => videoFormatter(cell, row, videoDefaultURL?.viewTab),
      sort: true,
      headerClasses: 'video-width-table'
    },
    {
      dataField: "uploaded_by",
      text: "Upload by",
      sort: true,
    },
    {
      dataField: "active",
      text: "Status",
      formatter: (cell, row) => statusFormatter(cell, row),
    },
    {
      dataField: "banner",
      text: "Slider",
      formatter: (cell, row) => <SliderFormatterComponent cell={cell} row={row} action={handleToggle} />,
    },
    {
      dataField: "ACTIONS",
      text: "Actions",
      formatter: (cell, row) => <ActionFormatterComponent cell={cell} row={row} link={videoDefaultURL?.editRouteLink} action={singleDelete} />,
    },
  ];

  const options = {
    pageStartIndex: 1,
    paginationSize: 4,
    sizePerPage: 10,
    nextPageText: "Next",
    prePageText: "Prev",
    firstPageText: <MdKeyboardDoubleArrowLeft />,
    lastPageText: <MdKeyboardDoubleArrowRight />,
    showTotal: true,
    alwaysShowAllBtns: true,
    sizePerPageList: [{
      text: '5', value: 5
    }, {
      text: '10', value: 10
    }, {
      text: 'All', value: Number(filteredData?.length)
    }]
  };

  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (row) {
        if (isSelect) {
          setItemToDelete([...itemToDelete, row.id]);
        } else {
          setItemToDelete(itemToDelete?.filter((id) => id !== row?.id));
        }
      }
    },
    style: { width: '30px' },
    selected: itemToDelete,
    selectionHeaderRenderer: ({ mode, checked }) => (
      <div>
        <input type="checkbox" checked={checked} onChange={handleAll} />
      </div>
    ),
  };

  //Bootstrap Table util end

  // Api links start 

  const fetchData = async () => {
    try {
      const response = await axios.get(`${videoDefaultURL?.listApi}`, { headers: channelApiHeader });
      const result = response?.data?.[videoDefaultURL?.fetchArrayName];
      const updatedList = result?.map((item) => {
        const UploadStatus = item?.uploaded_by === "1" ? "Admin" : item?.uploaded_by === "4" ? "Channel Partner" : item?.uploaded_by === "5" ? "Content Partner" : "";
        let published = item?.active === 1 && item?.publish_type === "publish_now" && item?.status === 1 && item?.draft === 1 ? true : false
        let scheduled = item?.active == 1 && item?.publish_type === "publish_later" && item?.status == 1 && item?.draft == 1 ? true : false
        const statusText = published ? "PUBLISHED" : scheduled ? ' SCHEDULED' : "DRAFT"
        return { ...item, uploaded_by: UploadStatus, active: statusText };
      });
      setApiData(updatedList);
      setFilteredData(updatedList);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const handleConfirmDelete = async () => {
    setShowOverlay(true);
    try {
      const formData = new FormData();
      formData.append(`${videoDefaultURL?.reqBodyName?.deleteName}`, "[" + itemToDelete + "]");
      const response = await axios.post(`${videoDefaultURL?.MultiDeleteApi}`, formData, { headers: channelApiHeader });
      const result = response?.data;
      if (result?.status) {
        setWithoutRefresh(!withOutRefresh);
        toast.success(t(result?.message));
        setShowOverlay(false);
        setItemToDelete([])
      }
      else {
        toast.error(t(result?.message));
        setShowOverlay(false);
      }
    } catch (err) {
      var errorMessage = err?.response?.data?.message;
      toast.error(t(errorMessage));
      setShowOverlay(false);
    }
  };

  const confirmToggle = async () => {
    setShowOverlay(true);
    setShowModal(false);
    try {
      const formData = new FormData();
      const newStatus = currentItem?.banner === 1 ? 0 : 1;
      formData.append(`${videoDefaultURL?.reqBodyName?.sliderName}`, currentItem?.id);
      formData.append("banner_status", newStatus,);
      const response = await axios.post(`${videoDefaultURL?.sliderApi}`, formData, { headers: channelApiHeader });
      const result = response.data
      if (result.status) {
        setWithoutRefresh(!withOutRefresh);
        toast.success(t(result?.message));
        setShowOverlay(false);
        setCurrentItem(null);
      }
      else {
        toast.error(t(result?.message));
        setShowOverlay(false);
      }
    } catch (err) {
      var errorMessage = err?.response?.data?.message;
      toast.error(t(errorMessage));
      setShowOverlay(false);
    }
  };

  // Api links end 

  const handleToggle = async (item) => {
    setCurrentItem(item);
    setShowModal(true);
  };

  const closeModal = () => {
    setModelClose(!closeModal);
    setShowModal(false);
  };

  const openModal = () => {
    setModelClose(true);
  };

  const singleDelete = (id) => {
    setModelClose(true);
    setItemToDelete([id]);
  };

  const handleDeletedata = () => {
    handleConfirmDelete();
    closeModal();
  };

  const handleAll = () => {
    setSelectAll(!selectAll);
    setItemToDelete(selectAll ? [] : filteredData?.map((item) => item?.id));
  };

  const handleFilterChange = (data) => {
    setFilteredData(data);
  };


  useEffect(async () => {
    fetchData();
  }, [withOutRefresh]);

  return (
    <div className="">
      {showOverlay && <ResponseLoader />}
      {showModal && <AdminSliderModal modelClose={showModal} sliderClose={closeModal} sliderAction={confirmToggle} buttonText={currentItem?.banner} />}
      {modelClose && <AdminDeleteModal modelClose={modelClose} deleteClose={closeModal} deleteAction={handleDeletedata} buttonText={itemToDelete?.length} />}
      <AdminNavbar heading={videoDefaultURL?.headingText} totalDataLength={apiData?.length} deleteCount={itemToDelete?.length} navigation={videoDefaultURL?.addRouteLink} deleteMethod={openModal} navText={videoDefaultURL?.buttonText} />
      <div className="theme-bg-color p-3 rounded-2 bootstrapTable  multi-delete-width" >
        <AdminHeader searchMethod={handleFilterChange} reduceData={filteredData} originalData={apiData} fileName={videoDefaultURL?.exportFileName} />
        <BootstrapTable keyField="id" pagination={paginationFactory(options)} columns={columns} data={filteredData} className="no-border-table theme-text-color " noDataIndication={<NoRecordTable result={filteredData?.length} loader={loading} />} hover headerClasses="videoWidth" selectRow={selectRow} selectAll={selectAll} />
      </div>
    </div>
  );
}
export default ChannelAllVideos;