// External Libraries and Packages
import React from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
// Internal Styles
// Internal Components
import Error404 from "../../Error404";
import SeriesViewAll from "./SeriesViewAll";
import LiveViewAll from "./LiveViewAll";
import SongViewAll from "./SongViewAll";
import ChannelViewAll from "./ChannelViewAll";
import ArtistViewAll from "./ArtistViewAll";
import ContentViewAll from "./ContentViewAll";
import CategoryViewAll from "./CategoryViewAll";
import LanguageViewAll from "./LanguageViewAll";
import HomeVideoViewAll from "./HomeVideoViewAll";
import ContinueWatchingViewAll from "./ContinueWatchingViewAll";
import Result from "../../../Pages/Result";
import { URLDashRemover } from "../../../Pages/CommonMethods/CommonMethods";
import PageLoader from "../../Loader/PageLoader";



const ViewAllPage = ({ getSource, isLoading, siteTheme }) => {
  const { source } = useParams();
  return (
    <div className="paddingTop">
      {isLoading ?
        <PageLoader />
        : (source === "latest-videos" || source === "featured-videos" || source === "scheduled-publish-video" || source === "video-schedule" || source === "latest-viewed-videos" || source === "recommended-videos-site" || source === "recommended-videos-country" || source === "recommended-videos-users")
            ? <HomeVideoViewAll />
            : (source === "continue-watching") ? <ContinueWatchingViewAll />
              : (source === "latest-series" || source === "single-series" || source === "latest-viewed-episode")
                ? <SeriesViewAll />
                : (source === "livestream-videos" || source === "scheduled-publish-livestream")
                  ? <LiveViewAll />
                  : (source === "audio-albums" || source === "audios" || source === "latest-viewed-audios")
                    ? <SongViewAll />
                    : (source === "artists")
                      ? <ArtistViewAll />
                      : (source === "channel-partner" || source === "individual-channelpartner")
                        ? <ChannelViewAll />
                        : (source === "content-partner" || source === "individual-contentpartner")
                          ? <ContentViewAll />
                          : (source === "video-categories" || source === "series-categories" || source === "live-categories" || source === "audios-categories")
                            ? <CategoryViewAll />
                            : (source === "alllanguage") ? <LanguageViewAll />
                              :  siteTheme?.translate_checkout ? <Result text={`Not Available! ${URLDashRemover(source)} `} /> : <Error404 />}
    </ div>
  );
};
const mapStateToProps = state => ({
  getSource: state.get_allHomePage_Reducer.getSource,
  isLoading: state.get_allHomePage_Reducer.isLoading,
  siteTheme: state.get_siteTheme_Reducer.siteTheme,
});

export default connect(mapStateToProps)(ViewAllPage);