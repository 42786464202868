// External Libraries and Packages
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
// Internal Styles

// Internal Components
import { viewAllPageCategoryIdAction, } from "../../Redux/Actions/apiActions";
import PageLoader from "../Loader/PageLoader";
import LayoutViewAll from "./Source/LayoutViewAll";
import Error404 from "../Error404";

const CategoryBasedAudioViewAll = ({ viewallPageCatId, isLoading, error, settings, }) => {
  const { slug } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const user_role = localStorage.getItem("role");
  const user_id = localStorage.getItem("user_id");

  const [videoCategoriesData, setVideoCategoriesData] = useState([]);

  useEffect(() => {
    const requestApi = `${process.env.REACT_APP_Baseurl}/Front-End/Audio-Genre-Page-List`;
    const AudioBasedApi = `${process.env.REACT_APP_Baseurl}/Front-End/Audio-based-Genre-Page-List`;
    if (settings && Object.keys(settings)?.length > 0) {
      dispatch(viewAllPageCategoryIdAction(slug, AudioBasedApi));
    }
  }, [settings, user_role, dispatch, user_id, location]);

  useEffect(() => {
    setVideoCategoriesData((viewallPageCatId?.data || []).flatMap((item) => item.category__audios || []));
  }, [viewallPageCatId]);


  function capitalizeText(text) {
    return text?.charAt(0).toUpperCase() + text?.slice(1);
  }
  return isLoading ? <PageLoader /> : error ? <Error404 /> : <div className="paddingTop">
    <LayoutViewAll mapData={videoCategoriesData} source={`Audios Category ${capitalizeText(slug)}`} path='music/track' headingClass='pb-4 text-center' />
  </div>
};
const mapStateToProps = (state) => ({
  viewallPageCatId: state.get_viewAll_CatId_Reducer.viewallPageCatId,
  isLoading: state.get_viewAll_CatId_Reducer.isLoading,
  error: state.get_viewAll_CatId_Reducer.error,
  settings: state.get_setting_Reducer.settings,
});

export default connect(mapStateToProps)(CategoryBasedAudioViewAll);
