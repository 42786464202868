import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import './UserPreference.css';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const UserPreference = () => {
  const [categories, setCategories] = useState([]);
  const [groupedCategories, setGroupedCategories] = useState([]);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const access_token = localStorage.getItem("access_token");
  const headers = useMemo(() => ({
    "Content-Type": "application/json", headers: { "Access-Control-Allow-Origin": "*" },
    Authorization: `Bearer ${'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiZW1haWwiOiJhZG1pbkBhZG1pbi5jb20iLCJpYXQiOjE3Mjk1MTE1NzgsImV4cCI6MTcyOTY4NDM3OH0.mnEPvO47x9x2qnmNuXpVSM07sBCIGdHoKUk8H559o-c'}`,
  }), [access_token]);
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`http://localhost:7005/Front-End/Choose-User-Interest-Content`);
        const { VideoCategory, LiveCategory, SeriesGenre, AudioGenre } = response?.data;
        const mergedCategories = [...VideoCategory.map(category => ({ ...category, source_type: 1 })),
        ...LiveCategory.map(category => ({ ...category, source_type: 2 })),
        ...SeriesGenre.map(category => ({ ...category, source_type: 3 })),
        ...AudioGenre.map(category => ({ ...category, source_type: 4 })),];
        setCategories(mergedCategories);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
    fetchCategories();
  }, []);
  const handleCategoryClick = (category) => {
    setGroupedCategories(prev => {
      const existing = prev.find(item => item.source_type === category.source_type);
      let updated;
      if (existing) {
        const sourceIdExists = existing.source_id.includes(category.id);
        updated = prev.map(item => item.source_type === category.source_type ? { ...item, source_id: sourceIdExists ? item.source_id.filter(id => id !== category.id) : [...item.source_id, category.id] } : item);
      } else {
        updated = [...prev, { source_type: category.source_type, source_id: [category.id] }];
      }
      return updated.filter(item => item.source_id.length > 0);
    });
  };
  const handleContinue = async () => {
    const groupedIds = type => groupedCategories.find(item => item.source_type === type)?.source_id || [];
    const data = {
      Login_device_type: 'web',
      Video_source_type: 1,
      Livestream_source_type: 2,
      Series_source_type: 3,
      Audio_source_type: 4,
      Video_source_id: JSON.stringify(groupedIds(1)),
      Livestream_source_id: JSON.stringify(groupedIds(2)),
      Series_source_id: JSON.stringify(groupedIds(3)),
      Audio_source_id: JSON.stringify(groupedIds(4)),
    };
    try {
      const response = await axios.post(`http://localhost:7005/Front-End/Choose-User-Interest-Store`, data, { headers });
      if (response?.data?.status) {
        toast.success(t('Categories successfully submitted!'));
        navigate('/');
      } else {
        toast.error(t('Failed to submit categories.'));
      }
    } catch (error) { console.error('Error submitting categories:', error) }
  };
  return (
    <div className="choose-interest-page container py-5"><h5 className="text-center font-weight-bold mb-4">{t("Choose Your Interest")}</h5>
      <p className="text-center lead mb-5">{t("Choose your interests and get the best movie recommendations. Don't worry, you can always change it later.")}</p>
      <div className="d-flex justify-content-center flex-wrap">
        {categories.map((category, index) => (
          <div key={index} className="category-card m-2">
            <button key={index} className={`btn px-4 py-2 ${groupedCategories.some(item => item.source_type === category.source_type && item.source_id.includes(category.id)) ? "theme-button-active-category" : "theme-button-inactive-category"}`} onClick={() => handleCategoryClick(category)}>{t(category.name)}</button>
          </div>))}</div>
      <div className="d-flex justify-content-center gap-2 mt-4">
        <button className="btn btn-secondary" onClick={() => navigate('/')}>{t("Skip")}</button>
        <button className="btn btn-primary" onClick={handleContinue} disabled={groupedCategories.length === 0}>
          {t("Continue")}
        </button>
      </div>
    </div>
  );
};
export default UserPreference;