// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.themeTwoFaq .accordion-button:focus,
.themeTwoFaq .accordion-button:not(.collapsed) {
    box-shadow: unset;
}

.themeTwoFaq .accordion-button::after {
    background-image: none;
    clip-path: polygon(82% 59%, 83% 70%, 50% 100%, 13% 70%, 13% 59%, 50% 88%);
}

.themeTwoFaq .accordion-button.genre::after {
    display: none;
}

.accorditionImage {
    width: 35px !important;
    height: 35px !important;
}

.faqSearchThemeTwo {
    top: 24px;
    left: 15px;
}

.themeTwoFaq input:focus-visible {
    outline: none;
}

.themeTwoFaq .question {
    font-size: 14px;
    font-weight: 400;
}

.themeTwoFaq .answer {
    font-size: 13px;
    line-height: 26px;
    font-weight: 300;
    opacity: 0.8;
}`, "",{"version":3,"sources":["webpack://./src/Styles/FaqHelp.css"],"names":[],"mappings":"AAAA;;IAEI,iBAAiB;AACrB;;AAEA;IACI,sBAAsB;IACtB,yEAAyE;AAC7E;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;IACI,SAAS;IACT,UAAU;AACd;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,YAAY;AAChB","sourcesContent":[".themeTwoFaq .accordion-button:focus,\n.themeTwoFaq .accordion-button:not(.collapsed) {\n    box-shadow: unset;\n}\n\n.themeTwoFaq .accordion-button::after {\n    background-image: none;\n    clip-path: polygon(82% 59%, 83% 70%, 50% 100%, 13% 70%, 13% 59%, 50% 88%);\n}\n\n.themeTwoFaq .accordion-button.genre::after {\n    display: none;\n}\n\n.accorditionImage {\n    width: 35px !important;\n    height: 35px !important;\n}\n\n.faqSearchThemeTwo {\n    top: 24px;\n    left: 15px;\n}\n\n.themeTwoFaq input:focus-visible {\n    outline: none;\n}\n\n.themeTwoFaq .question {\n    font-size: 14px;\n    font-weight: 400;\n}\n\n.themeTwoFaq .answer {\n    font-size: 13px;\n    line-height: 26px;\n    font-weight: 300;\n    opacity: 0.8;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
