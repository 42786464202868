import React, { useState } from 'react'
import AdvertiserPanel from './AdvertiserPanel';
import "../Styles/Advertiser.css"
import Select from "react-select";
import { BiSolidTrash } from "react-icons/bi";
import { FaPlus } from "react-icons/fa";
import MessageHeading from './CommonComponents/MessageHeading';
import MessageBox from '../../components/MessageBox';
import { IoInformation } from 'react-icons/io5';
import { RiDeleteBin6Line } from "react-icons/ri";
const AdvertiserAddNewAds = () => {
  // const colourStyles = {
  //   control: styles => ({ ...styles, backgroundColor: 'white' }),
  //   option: (styles, { data, isDisabled, isFocused, isSelected }) => {
  //     return {
  //       ...styles,
  //       backgroundColor: isDisabled ? 'red' : 'white',
  //       color: 'black',
  //       cursor: isDisabled ? 'not-allowed' : 'default'
  //     };
  //   }
  // }


  const [store, setStore] = useState({
    active: 0,
    ageGroups: [
      { id: 1, label: '18-24' },
      { id: 2, label: '25-34' },
      { id: 3, label: '35-44' },
      { id: 4, label: '45-54' },
      { id: 5, label: '55-64' },
      { id: 6, label: '65' },
      { id: 7, label: 'unknown' }
    ],
    gender: [
      { id: 1, label: 'Male' },
      { id: 2, label: 'Female' },
      { id: 3, label: 'Kids' },
    ],
    devices: [
      { id: 1, label: 'Website' },
      { id: 2, label: 'Android' },
      { id: 3, label: 'IOS' },
      { id: 4, label: 'TV' },
      { id: 5, label: 'Roku' },
      { id: 6, label: 'LG' },
      { id: 7, label: 'Samsung' },
    ],
    selectedAgeGroups: [],
    selectGender: [],
    selectDevice: [],
    adsCategory: [
      { id: 1, label: 'Kids' },
      { id: 2, label: 'Anime' },
    ],
    adsPosition: [
      { id: 1, label: 'Pre' },
      { id: 2, label: 'Mid' },
      { id: 3, label: 'Post' },
      { id: 4, label: 'All Position (Only for Ads Type - Tag URL)' },
    ],
    selectType: null,
    adsName: '',
    adsUrl: '',
    redirectUrl: "",
    adsFile: "",
    locationGroup: [
      { id: 1, label: 'All Countries & Territories' },
      { id: 2, label: 'India' },
      { id: 3, label: 'Enter The Location' },
    ],
    selectedLocation: '',
    locationName: "",
    advertisementWeek: [
      {
        id: 1,
        day: "Monday",
        timing: [{
          startTime: "09:00",
          endTime: "01:00"
        },
        {
          startTime: "",
          endTime: ""
        }]
      },
      {
        id: 2,
        day: "Tuesday",
        timing: [{
          startTime: "09:00",
          endTime: "01:00"
        }]
      },
      {
        id: 3,
        day: "Wednesday",
        timing: [{
          startTime: "09:00",
          endTime: "01:00"
        }]
      },
      {
        id: 4,
        day: "Thrusday",
        timing: [{
          startTime: "09:00",
          endTime: "01:00"
        }]
      },
      {
        id: 5,
        day: "Friday",
        timing: [{
          startTime: "09:00",
          endTime: "01:00"
        }]
      },
      {
        id: 6,
        day: "Saturday",
        timing: [{
          startTime: "09:00",
          endTime: "01:00"
        }]
      },
      {
        id: 7,
        day: "Sunday",
        timing: [{
          startTime: "09:00",
          endTime: "01:00"
        }]
      }
    ]
  })

  const infoValidation = () => {
    return (
      store.selectedAgeGroups.length > 0 &&
      store.selectGender.length > 0 &&
      store.selectDevice.length > 0
    );
  };

  const uploadValidation = () => {
    return (
      store.selectType !== null &&
      (store.selectType == 0 ? store.adsUrl !== '' : store?.redirectUrl !== '' && store?.adsFile !== '') &&
      store.adsName !== ''
    );
  };
  const locationValidation = () => {
    return (store.selectedLocation !== '' && (store.selectedLocation == 3 ? store.locationName !== '' : true));
  }

  const handleCheckboxChange = (id) => {
    setStore((prev) => ({
      ...prev,
      selectedAgeGroups: prev.selectedAgeGroups.includes(id)
        ? prev.selectedAgeGroups.filter((groupId) => groupId !== id)
        : [...prev.selectedAgeGroups, id]
    }));
  };
  const handleCheckboxLocation = (e, id) => {
    setStore((prev) => ({
      ...prev,
      selectedLocation: e.target.checked ? id : ''
    }));
  };

  const handleSelectGender = (selectedValues) => {
    setStore((prev) => ({
      ...prev,
      selectGender: selectedValues,
    }));
  }
  const handleSelectDevice = (selectedValues) => {
    setStore((prev) => ({
      ...prev,
      selectDevice: selectedValues,
    }));
  }
  const handlePrev = () => {
    setStore((prev) => ({
      ...prev,
      active: prev.active - 1
    }))
  }

  const handleNext = () => {
    if (infoValidation()) {
      setStore((prev) => ({
        ...prev,
        active: prev.active + 1
      }))
    }
    else if (uploadValidation()) {
      setStore((prev) => ({
        ...prev,
        active: prev.active + 1
      }))
    }
    else if (locationValidation()) {
      setStore((prev) => ({
        ...prev,
        active: prev.active + 1
      }))
    }
    else return null
  }

  const handleSelectType = (e) => {
    setStore((prev) => ({
      ...prev,
      selectType: e.target.value
    }))
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setStore((prev) => ({
      ...prev,
      [name]: value
    }))

  }
  const handleFile = (event) => {
    const selectedFile = event.target.files;
    if (selectedFile.length > 0) {
      const newFile = selectedFile[0];
      const reader = new FileReader();
      reader.onload = () => {
        setStore((prev) => ({
          ...prev,
          adsFile: newFile
        }))
      };
      reader.readAsDataURL(newFile);
    } else {
      return null
    }
  }


  const handleTimeChange = (dayIndex, timeIndex, e) => {
    const { name, value } = e.target;
    setStore((prev) => {
      // Make a copy of the advertisementWeek array
      const newAdvertisementWeek = [...prev.advertisementWeek];

      // Make a copy of the specific day object
      const dayToUpdate = { ...newAdvertisementWeek[dayIndex] };

      // Make a copy of the timing array within the day object
      const newTiming = [...dayToUpdate.timing];

      // Update the specific timing object
      const updatedTiming = { ...newTiming[timeIndex], [name]: value };

      // Replace the updated timing object back into the timing array
      newTiming[timeIndex] = updatedTiming;

      // Replace the updated timing array back into the day object
      dayToUpdate.timing = newTiming;

      // Replace the updated day object back into the advertisementWeek array
      newAdvertisementWeek[dayIndex] = dayToUpdate;

      // Return the new state
      return { ...prev, advertisementWeek: newAdvertisementWeek };
    });


  }
  return (
    <AdvertiserPanel>
      <div className='p-3 rounded-3'>
        <MessageHeading text={'Upload Advertisements'} className="advertiserHeading" />
        <div className='d-flex align-items-center justify-content-between'>
          <div className='col-3 p-2'>
            <div className={` d-flex align-items-center justify-content-center flex-column rounded-2 p-2 ${store?.active >= 0 ? 'theme-button-bg-color' : 'theme-bg-color'} `}>
              <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" className='uploadBoxAdvertiser' style={{ width: "80px", height: "80px" }} viewBox="0 0 100 100.5" >
                <g>
                  <line style={{ fill: "none", stroke: "currentcolor", strokeLinecap: "round", strokeMiterlimit: "10", strokeWidth: "2" }}  className="st0" x1="80.2" y1="25.7" x2="84.3" y2="23.8" />
                  <line style={{ fill: "none", stroke: "currentcolor", strokeLinecap: "round", strokeMiterlimit: "10", strokeWidth: "2" }}  className="st0" x1="86.3" y1="38.6" x2="90.4" y2="36.7" />
                  <line style={{ fill: "none", stroke: "currentcolor", strokeLinecap: "round", strokeMiterlimit: "10", strokeWidth: "2" }}  className="st0" x1="87.8" y1="49.9" x2="92.1" y2="51.3" />
                  <line style={{ fill: "none", stroke: "currentcolor", strokeLinecap: "round", strokeMiterlimit: "10", strokeWidth: "2" }}  className="st0" x1="82" y1="61.8" x2="84.8" y2="65.3" />
                </g>
                <path style={{ fill: "currentcolor", stroke: "currentcolor", strokeMiterlimit: "10", strokeWidth: "0.2" }}  className="st1" d="M81.6,46.1c0-2.6-1-5-2.7-6.9s-4.1-3-6.7-3.2V21c0-0.4-0.3-0.7-0.7-0.7h-6.3c-0.2,0-0.4,0.1-0.5,0.2  s-0.2,0.3-0.2,0.5v5.8l-18.2,6.1h-21c-2.6,0-5.2,1-7.2,2.7s-3.3,4.1-3.7,6.7h-2.5c-1.4,0-2.7,0.7-3.4,1.9c-0.7,1.2-0.7,2.7,0,3.9  s2,1.9,3.4,1.9h2.5c0.4,2.6,1.7,5,3.7,6.7s4.6,2.7,7.2,2.7h1.6L33,84c0.1,0.3,0.4,0.6,0.7,0.5H40c0.4,0,0.7-0.3,0.7-0.7V65.3  l5.7-5.8l17.9,6v5.8c0,0.2,0.1,0.4,0.2,0.5S64.8,72,65,72h6.3c0.4,0,0.7-0.3,0.7-0.7v-15c2.6-0.2,5-1.3,6.7-3.2  C80.6,51.1,81.6,48.7,81.6,46.1L81.6,46.1z M11.8,48.5c-0.9,0-1.7-0.5-2.1-1.2s-0.4-1.7,0-2.4s1.2-1.2,2.1-1.2h2.4v0.2v4.5v0.2  L11.8,48.5z M15.6,48.3v-4.5c0-2.5,1-5,2.8-6.8s4.2-2.8,6.8-2.8h1.5v23.7h-1.5c-2.5,0-5-1-6.8-2.8C16.6,53.3,15.6,50.9,15.6,48.3  L15.6,48.3z M34.3,83.1l-5.9-23.7h16.2l-4.8,4.8h-6c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h5.6V83L34.3,83.1z M47,58.1v-5.8  c0-0.4-0.3-0.7-0.7-0.7s-0.7,0.3-0.7,0.7v5.6H28.2V34.3h17.4v11.8c0,0.4,0.3,0.7,0.7,0.7s0.7-0.3,0.7-0.7v-12l17.4-5.8V64L47,58.1z   M70.7,70.5h-4.8V21.7h4.8V70.5z M72.2,54.8V37.4c2.9,0.2,5.5,1.9,6.9,4.5s1.4,5.7,0,8.3S75.1,54.5,72.2,54.8L72.2,54.8z" />
              </svg>
              <MessageBox classname='advertiserSubHeading d-block' text={'Ads Info'} />
            </div>
          </div>

          <div className=' col-3 p-2'>
            <div className={` d-flex align-items-center justify-content-center flex-column rounded-2 p-2 ${store?.active >= 1 ? 'theme-button-bg-color' : 'theme-bg-color'} `}>
              <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 100 100.5" className='uploadBoxAdvertiser' style={{ width: "80px", height: "80px" }}>
                <path style={{ fill: "none", stroke: "currentcolor", strokeLinecap: "round", strokeMiterlimit: "10", strokeWidth: "2" }}  className="st0" d="M75.2,61.5v16c0,3.7-3,6.7-6.7,6.7H31.5c-3.7,0-6.7-3-6.7-6.7v-15v-0.9 M55,51.5v26.9 M44.9,73.5V39.9  c0-0.8-0.6-1.5-1.3-1.6l0,0l0,0h-0.1h-0.1h-0.1h-0.1h-0.1h-5.8c-0.5,0-0.8-0.6-0.6-1l13.1-21l13.2,21.1c0.3,0.4,0,0.9-0.5,0.9h-5.9  c-0.8,0-1.5,0.6-1.6,1.4l0,0l0,0v0.1v0.1V40v0.1v6.7" />
              </svg>
              <MessageBox classname='advertiserSubHeading d-block' text={'Upload Ads'} />
            </div>
          </div>

          <div className=' col-3  p-2' >
            <div className={` d-flex align-items-center justify-content-center flex-column rounded-2 p-2 ${store?.active >= 2 ? 'theme-button-bg-color' : 'theme-bg-color'}`}>
              <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 100 100.5" className='uploadBoxAdvertiser' style={{ width: "80px", height: "80px" }}>
                <g>
                  <path style={{ fill: "none", stroke: "currentcolor", strokeLinecap: "round", strokeMiterlimit: "10", strokeWidth: "2" }}  className="st0" d="M27,38.2c0-1.8-1.5-3.3-3.3-3.3h-6.6c-1.8,0-3.3,1.5-3.3,3.3v6.6c0,1.8,1.5,3.3,3.3,3.3h6.6   c1.8,0,3.3-1.5,3.3-3.3V38.2z M73,41.5v-3.3c0-1.8,1.5-3.3,3.3-3.3h6.6c1.8,0,3.3,1.5,3.3,3.3l0,6.6 M43.1,51.2   c-1.2,0-2.2-1-2.2-2.2V35.4c0-1.2,1-2.2,2.2-2.2h13.5c1.3,0,2.3,1,2.3,2.2v6c0,0.7-0.7,1.2-1.4,1L50.6,40c-0.8-0.3-1.8-0.1-2.3,0.6   c-0.6,0.6-0.8,1.5-0.5,2.3l2.4,7c0.2,0.7-0.3,1.3-0.9,1.3L43.1,51.2z M55.3,46.5l27.2,9.3c0.8,0.3,0.8,1.4,0.1,1.7l-11.3,5   c-0.5,0.2-0.9,0.6-1.1,1.2l-5,11.2c-0.3,0.8-1.4,0.7-1.7-0.1l-9.3-27.2C53.9,46.9,54.6,46.2,55.3,46.5z" />
                </g>
              </svg>
              <MessageBox classname='advertiserSubHeading d-block' text={'Choose Region'} />
            </div>
          </div>

          <div className='col-3 p-2'>
            <div className={` d-flex align-items-center justify-content-center flex-column rounded-2 p-2 ${store?.active == 3 ? 'theme-button-bg-color' : 'theme-bg-color'}`}>
              <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 100 100.5" className='uploadBoxAdvertiser' style={{ width: "80px", height: "80px" }} >
                <g>
                  <g>
                    <path style={{ fill: "none", stroke: "currentcolor", strokeLinecap: "round", strokeMiterlimit: "10", strokeWidth: "2" }}  className="st0" d="M61.5,39.1c-5.8,0-11.4,2.3-15.6,6.4c-4.1,4.1-6.4,9.7-6.4,15.6s2.3,11.4,6.4,15.6c4.1,4.1,9.7,6.4,15.6,6.4    c5.9,0,11.4-2.3,15.6-6.4c4.1-4.1,6.4-9.7,6.4-15.6c0-5.8-2.3-11.4-6.5-15.5C72.9,41.4,67.3,39.1,61.5,39.1L61.5,39.1z" />
                    <path style={{ fill: "none", stroke: "currentcolor", strokeLinecap: "round", strokeMiterlimit: "10", strokeWidth: "2" }}  className="st0" d="M76.3,39.3V20.6c0-0.6-0.5-1.1-1.1-1.1H17.5c-0.6,0-1,0.5-1,1v50.8c0,0.6,0.5,1,1,1h22" />
                    <line style={{ fill: "none", stroke: "currentcolor", strokeLinecap: "round", strokeMiterlimit: "10", strokeWidth: "2" }}  className="st0" x1="29.6" y1="13" x2="29.6" y2="24.4" />
                    <line style={{ fill: "none", stroke: "currentcolor", strokeLinecap: "round", strokeMiterlimit: "10", strokeWidth: "2" }}  className="st0" x1="62.4" y1="13" x2="62.4" y2="24.4" />
                    <line style={{ fill: "none", stroke: "currentcolor", strokeLinecap: "round", strokeMiterlimit: "10", strokeWidth: "2" }}  className="st0" x1="39.7" y1="61.1" x2="43.5" y2="61.1" />
                    <line style={{ fill: "none", stroke: "currentcolor", strokeLinecap: "round", strokeMiterlimit: "10", strokeWidth: "2" }}  className="st0" x1="79.6" y1="61.1" x2="83.6" y2="61.1" />
                    <line style={{ fill: "none", stroke: "currentcolor", strokeLinecap: "round", strokeMiterlimit: "10", strokeWidth: "2" }}  className="st0" x1="61.5" y1="79.2" x2="61.5" y2="83.1" />
                    <line style={{ fill: "none", stroke: "currentcolor", strokeLinecap: "round", strokeMiterlimit: "10", strokeWidth: "2" }}  className="st0" x1="61.5" y1="39.1" x2="61.5" y2="43" />
                    <line style={{ fill: "none", stroke: "currentcolor", strokeLinecap: "round", strokeMiterlimit: "10", strokeWidth: "1.5" }}  className="st1" x1="62" y1="62" x2="62" y2="68.8" />
                    <line style={{ fill: "none", stroke: "currentcolor", strokeLinecap: "round", strokeMiterlimit: "10", strokeWidth: "1.5" }}  className="st1" x1="65.8" y1="53.3" x2="62.4" y2="59.3" />
                  </g>
                  <circle cx="62" cy="60.4" r="1.4" style={{ fill: "none", stroke: "currentcolor" }} />
                </g>
              </svg>
              <MessageBox classname='advertiserSubHeading d-block' text={'Ads Schedule'} />
            </div>
          </div>

        </div>
        <div className='py-4 '>
          {store?.active == 0 ?
            <div className='col-6'>
              <MessageHeading text={'General Information'} className="advertiserHeading" />
              <MessageBox classname='advertiserSubHeading d-block my-3' text={'Age'} />
              <div className='d-flex align-items-center'>
                {store?.ageGroups.map((group) => (
                  <div key={group.id} className=''>
                    <input type="checkbox" name="check" autoComplete="off" checked={store?.selectedAgeGroups.includes(group.id)} onChange={() => handleCheckboxChange(group.id)} />
                    <label className='px-2'>{group.label}</label>
                  </div>
                ))}
              </div>
              <MessageBox classname='advertiserSubHeading d-block my-3' text={'Gender'} />
              <Select options={store?.gender?.map((option) => ({ value: option?.id, label: option?.label }))} isMulti onChange={handleSelectGender} value={store?.selectGender} className='my-3 theme-bg-color' />
              <MessageBox classname='advertiserSubHeading d-block my-3' text={'Advertisement Devices'} />
              <Select options={store?.devices?.map((option) => ({ value: option?.id, label: option?.label }))} isMulti onChange={handleSelectDevice} value={store?.selectDevice} className='my-3 theme-bg-color' />
            </div>
            : null}

          {store?.active == 1 ?
            <div>
              <MessageHeading text={'Ads Details'} className="advertiserHeading" />
              <div className=''>
                <div className='d-flex flex-wrap'>
                  <div className='col-6 pe-md-3'>

                    <MessageBox classname='advertiserSubHeading theme-text-color d-block position-relative my-3 before-star' text={'Ads Name'} />
                    <input type="text" name="adsName" value={store?.adsName} onChange={handleChange} placeholder={'Please enter ads name'} className="rounded-2 border-0 w-100 px-3 py-3 theme-bg-color theme-text-color" />

                    <MessageBox classname='advertiserSubHeading theme-text-color d-block position-relative my-3 ' text={'Ads Category'} />
                    <select className="rounded-2 border-0 w-100 px-3 py-3 theme-bg-color theme-text-color" >
                      {store?.adsCategory.map((item, index) => <option>{item?.label}</option>)}
                    </select>

                    <MessageBox classname='advertiserSubHeading theme-text-color d-block position-relative my-3 ' text={'Ads Position'} />
                    <select className="rounded-2 border-0 w-100 px-3 py-3 theme-bg-color theme-text-color" >
                      {store?.adsPosition.map((item, index) => <option>{item?.label}</option>)}
                    </select>

                  </div>
                  <div className='col-6 ps-md-3'>
                    <MessageBox classname='advertiserSubHeading theme-text-color d-block position-relative my-3 before-star' text={'Ads Upload type'} />
                    <select className="rounded-2 border-0 w-100 px-3 py-3 theme-bg-color theme-text-color" onChange={handleSelectType}>
                      <option value={null}>Select ads type</option>
                      <option value={0}>Ads Tag url</option>
                      <option value={1}>Ads Video upload</option>
                    </select>
                    {store.selectType !== null ? store.selectType == 0 ?
                      <>
                        <MessageBox classname='advertiserSubHeading theme-text-color d-block position-relative my-3 before-star' text={'Ads url'} />
                        <input type="text" name='adsUrl' value={store?.adsUrl} onChange={handleChange} placeholder={'Paste here ads url'} className="rounded-2 border-0 w-100 px-3 py-3 theme-bg-color theme-text-color" />
                      </>
                      :
                      <>
                        <MessageBox classname='advertiserSubHeading theme-text-color d-block position-relative my-3 ' text={'Ads Video Upload'} />
                        <input type="file" onChange={handleFile} className="rounded-2 border-0 w-100 px-3 py-3 theme-bg-color theme-text-color" />
                        <MessageBox classname='advertiserSubHeading theme-text-color d-block position-relative my-3 ' text={'Ads redireact url'} />
                        <input type="text" name="redirectUrl" value={store?.redirectUrl} onChange={handleChange} placeholder={'https://example.com'} className="rounded-2 border-0 w-100 px-3 py-3 theme-bg-color theme-text-color" />
                      </>
                      : ""}
                  </div>
                </div>
              </div>
            </div>
            : null}

          {store?.active == 2 ? <div className='col-6'>
            <MessageHeading text={'Location Details'} className="advertiserHeading" />
            <div className='d-flex align-items-center flex-wrap'>
              {store?.locationGroup.map((group) => (
                <div key={group.id} className='col-12 mb-4'>
                  <input type="checkbox" name="check" autoComplete="off" checked={store?.selectedLocation == group.id} onChange={(e) => handleCheckboxLocation(e, group.id)} />
                  <label className='px-2'>{group.label}</label>
                </div>
              ))}
            </div>
            {store.selectedLocation == 3 && <>
              <MessageBox classname='advertiserSubHeading theme-text-color d-block position-relative my-3 before-star' text={'Enter location'} />
              <input type="text" name="locationName" value={store?.locationName} onChange={handleChange} placeholder={'Please enter ads name'} className="rounded-2 border-0 w-100 px-3 py-3 theme-bg-color theme-text-color" />

            </>}
          </div> : null}
          {store?.active == 3 ?
            <div className='col-12'>
              <MessageHeading text={'Set your weekly hours'} className="advertiserHeading" />
              <div className='d-flex align-items-center flex-wrap mt-5'>
                {store?.advertisementWeek?.map((group, index) => {
                  return (
                    <div className='d-flex  flex-wrap w-100 mb-4'>
                      <div className='col-3'>
                        <div key={group.id} className='d-flex align-items-center'>
                          <input type="checkbox" name="check" autoComplete="off" checked={true} onChange={(e) => handleCheckboxLocation(e, group.id)} />
                          <label className='px-2'>{group.day}</label>
                        </div>
                      </div>
                      <div className='col-5'>
                        <div className='d-flex justify-content-between'>
                          <div className='w-100'>
                            {group.timing.map((time, timeIndex) => {
                              return (
                                <div className='d-flex align-items-center gap-3 mb-3'>
                                  <input type='time' name="startTime" className='theme-text-color bg-transparent  border-0 px-3 py-3 rounded-2' value={time.startTime} onChange={(e) => handleTimeChange(index, timeIndex, e)} />
                                  <span>-</span>
                                  <input type='time' name="endTime" className='theme-text-color bg-transparent border-0 px-3 py-3 rounded-2' value={time.endTime} onChange={(e) => handleTimeChange(index, timeIndex, e)} />
                                  <RiDeleteBin6Line className='homeIcon' />
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                      <div className='col-4'>
                        <button className='theme-button-bg-color px-4 py-2 rounded-2 ms-auto d-block' >Add</button>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
            : null}
        </div>
        {/* {
          generalInfo === false
            ? <div>
              <div className='adsHeaderText px-sm-5 py-sm-2 '>General Information</div>
              <div className='px-lg-5 py-lg-4 px-sm-5 pb-sm-3 adsSmallText'>Age :</div>
              <div className='d-flex gap-2 px-sm-5'>
                <div className=''>
                  <input type="checkbox" name="check" autoComplete="off" /><label className='px-2'>18-24</label>
                </div>
                <div className=''>
                  <input type="checkbox" name="check" autoComplete="off" /><label className='px-2'>25-34</label>
                </div>
                <div className=''>
                  <input type="checkbox" name="check" autoComplete="off" /><label className='px-2'>35-44</label>
                </div>
                <div className=''>
                  <input type="checkbox" name="check" autoComplete="off" /><label className='px-2'>45-54</label>
                </div>
                <div className=''>
                  <input type="checkbox" name="check" autoComplete="off" /><label className='px-2'>55-64</label>
                </div>
                <div className=''>
                  <input type="checkbox" name="check" autoComplete="off" /> <label className='px-2'>65</label>
                </div>
                <div className=''>
                  <input type="checkbox" name="check" autoComplete="off" /><label className='px-2'>unknown</label>
                </div>
              </div>
              <div className='px-lg-5 py-lg-4 px-sm-5 py-sm-3 adsSmallText'>Gender :</div>
              <Select styles={colourStyles} options={options} autosize={true} className="mt-2 px-sm-5 advertiserSelect" />
              <div className='d-flex justify-content-end p-2'>
                <button className='p-3 rounded-3' onClick={() => setGeneralInfo(true)}>Next Step</button>
              </div>
            </div >
            : generalInfo === true ? (
              <><div className='adlist px-sm-5 py-sm-2'>Ads details</div>
                <div className='row py-sm-4'>
                  <div className='col-md-5'>
                    <div className='adsText px-sm-5'>Ads Name:</div>
                    <Select styles={colourStyles} options={options} autosize={true} className="mt-2 advertiserSelect px-sm-5" />
                  </div>
                  <div className='col-md-5'>
                    <div className='adsText px-sm-5'>Ads Upload Type:</div>
                    <Select styles={colourStyles} options={options} autosize={true} className="mt-2 advertiserSelect px-sm-5" />
                  </div>
                </div>
                <div className='row py-sm-4'>
                  <div className='col-md-5'>
                    <div className='adsText px-sm-5'>Ads Category:</div>
                    <Select styles={colourStyles} options={options} autosize={true} className="mt-2 advertiserSelect px-sm-5" />
                  </div>
                  <div className='col-md-5'>
                    <div className='adsText px-sm-5'>Ad Tag Url</div>
                    <Select styles={colourStyles} options={options} autosize={true} className="mt-2  advertiserSelect px-sm-5" />
                  </div>
                </div>
                <div className='row py-sm-3'>
                  <div className='col-md-5'>
                    <div className='adsText px-sm-5'>Ads Position Type:</div>
                    <Select styles={colourStyles} options={options} autosize={true} className="mt-2 advertiserSelect px-sm-5" />
                  </div>
                  <div className='col-md-5'></div>
                  <div className='d-flex justify-content-end'>
                    <button className='p-3 rounded-3 m-2' onClick={adsInfoSubmit}>Next Step</button>
                    <button className='p-3 rounded-3 m-2' onClick={() => setGeneralInfo(false)}>Previous</button>
                  </div>
                </div>
              </>)
              : adInfo === true ? <>
                <div className='adlist px-sm-5 py-sm-3'>Location details</div>
                <div className='px-sm-5 py-sm-2'>
                  <input type="radio" id="country1" name="country1" value="1" />
                  <label for="country1" className='p-2'>All Countries & Territories</label><br />
                  <input type="radio" id="country2" name="country2" value="2" className='p-2' />
                  <label for="country2" className='p-2'>India</label><br />
                  <input type="radio" id="country3" name="country3" value="3" className='p-2' />
                  <label for="country3" className='p-2'>Enter The Location</label><br />
                </div>
                <div className='d-flex justify-content-end'>
                  <button className='p-3 rounded-3 m-2' onClick={adsLocationSubmit}>Next Step</button>
                  <button className='p-3 rounded-3 m-2' onClick={() => setGeneralInfo(false)}>Previous</button>
                </div></>
                : adlocationInfo === true ? (
                  <><div className='adlist px-sm-5 py-sm-3'>Set your weekly hours</div>
                    <div className='row d-flex align-items-center px-sm-5 py-sm-3' >
                      <div className='col-md-1'>
                        <input type="checkbox" name="check" autoComplete="off" />
                      </div>
                      <div className='col-md-2'>
                        <label className=''>Monday</label>
                      </div>
                      <div className='col-md-4'>
                        <input type="time" name="time" autoComplete="off" className='text-black p-2 mx-2' /> <input type="time" name="time" autoComplete="off" className='text-black p-2 mx-2' />
                      </div>
                      <div className='col-md-2'>
                        <BiSolidTrash className='m-3' style={{ "width": "20px", "height": "20px" }} />
                        <FaPlus className='m-3' style={{ "width": "20px", "height": "20px" }} />
                      </div>
                    </div>
                    <div className='row d-flex align-items-center px-sm-5 py-sm-3' >
                      <div className='col-md-1'>
                        <input type="checkbox" name="check" autoComplete="off" />
                      </div>
                      <div className='col-md-2'>
                        <label className=''>Tuesday</label>
                      </div>
                      <div className='col-md-4'>
                        <input type="time" name="time" autoComplete="off" className='text-black p-2 mx-2' /> <input type="time" name="time" autoComplete="off" className='text-black p-2 mx-2' />
                      </div>
                      <div className='col-md-2'>
                        <BiSolidTrash className='m-3' style={{ "width": "20px", "height": "20px" }} />
                        <FaPlus className='m-3' style={{ "width": "20px", "height": "20px" }} />
                      </div>
                    </div>
                    <div className='row d-flex align-items-center px-sm-5 py-sm-3' >
                      <div className='col-md-1'>
                        <input type="checkbox" name="check" autoComplete="off" />
                      </div>
                      <div className='col-md-2'>
                        <label className=''>Wednesday</label>
                      </div>
                      <div className='col-md-4'>
                        <input type="time" name="time" autoComplete="off" className='text-black p-2 mx-2' /> <input type="time" name="time" autoComplete="off" className='text-black p-2 mx-2' />
                      </div>
                      <div className='col-md-2'>
                        <BiSolidTrash className='m-3' style={{ "width": "20px", "height": "20px" }} />
                        <FaPlus className='m-3' style={{ "width": "20px", "height": "20px" }} />
                      </div>
                    </div>
                    <div className='row d-flex align-items-center px-sm-5 py-sm-3' >
                      <div className='col-md-1'>
                        <input type="checkbox" name="check" autoComplete="off" />
                      </div>
                      <div className='col-md-2'>
                        <label className=''>Thursday</label>
                      </div>
                      <div className='col-md-4'>
                        <input type="time" name="time" autoComplete="off" className='text-black p-2 mx-2' /> <input type="time" name="time" autoComplete="off" className='text-black p-2 mx-2' />
                      </div>
                      <div className='col-md-2'>
                        <BiSolidTrash className='m-3' style={{ "width": "20px", "height": "20px" }} />
                        <FaPlus className='m-3' style={{ "width": "20px", "height": "20px" }} />
                      </div>
                    </div>
                    <div className='row d-flex align-items-center px-sm-5 py-sm-3' >
                      <div className='col-md-1'>
                        <input type="checkbox" name="check" autoComplete="off" />
                      </div>
                      <div className='col-md-2'>
                        <label className=''>Friday</label>
                      </div>
                      <div className='col-md-4'>
                        <input type="time" name="time" autoComplete="off" className='text-black p-2 mx-2' /> <input type="time" name="time" autoComplete="off" className='text-black p-2 mx-2' />
                      </div>
                      <div className='col-md-2'>
                        <BiSolidTrash className='m-3' style={{ "width": "20px", "height": "20px" }} />
                        <FaPlus className='m-3' style={{ "width": "20px", "height": "20px" }} />
                      </div>
                    </div>
                    <div className='row d-flex align-items-center px-sm-5 py-sm-3' >
                      <div className='col-md-1'>
                        <input type="checkbox" name="check" autoComplete="off" />
                      </div>
                      <div className='col-md-2'>
                        <label className=''>Saturday</label>
                      </div>
                      <div className='col-md-4'>
                        <input type="time" name="time" autoComplete="off" className='text-black p-2 mx-2' /> <input type="time" name="time" autoComplete="off" className='text-black p-2 mx-2' />
                      </div>
                      <div className='col-md-2'>
                        <BiSolidTrash className='m-3' style={{ "width": "20px", "height": "20px" }} />
                        <FaPlus className='m-3' style={{ "width": "20px", "height": "20px" }} />
                      </div>
                    </div>
                    <div className='row d-flex align-items-center px-sm-5 py-sm-3' >
                      <div className='col-md-1'>
                        <input type="checkbox" name="check" autoComplete="off" />
                      </div>
                      <div className='col-md-2'>
                        <label className=''>Sunday</label>
                      </div>
                      <div className='col-md-4'>
                        <input type="time" name="time" autoComplete="off" className='text-black p-2 mx-2' /> <input type="time" name="time" autoComplete="off" className='text-black p-2 mx-2' />
                      </div>
                      <div className='col-md-2'>
                        <BiSolidTrash className='m-3' style={{ "width": "20px", "height": "20px" }} />
                        <FaPlus className='m-3' style={{ "width": "20px", "height": "20px" }} />
                      </div>
                    </div>
                    <div className='d-flex justify-content-end'>
                      <button className='p-3 rounded-3 m-2' >Save</button>
                      <button className='p-3 rounded-3 m-2' >Previous</button>
                    </div></>)
                  : (<>
                  </>)}</div> */}

        <div className='d-flex align-items-center justify-content-end gap-2'>
          {store?.active > 0 && <button className='px-3 py-3 theme-button-bg-color rounded-2' onClick={() => handlePrev()}><MessageBox text="Prev Step" /></button>}
          {store?.active !== 3 ?
            <button className={`px-3 py-3 theme-button-bg-color rounded-2 ${store?.active == 0 ? !infoValidation() ? 'opacity-75' : '' : store?.active == 1 ? !uploadValidation() ? 'opacity-75' : '' : store?.active == 2 ? !locationValidation() ? 'opacity-75' : '' : false}`} onClick={() => handleNext()} disabled={store?.active == 0 ? !infoValidation() : store?.active == 1 ? !uploadValidation() : store?.active == 2 ? !locationValidation() : false}><MessageBox text="Next Step" /></button>
            :
            <button className='px-3 py-3 theme-button-bg-color rounded-2' onClick={() => handleNext()}>Submit</button>}
        </div>
      </div>
    </AdvertiserPanel >
  )
}
export default AdvertiserAddNewAds;