// External Libraries and Packages
import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, EffectFade } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import LazyLoadImage from '../HomePage/HomeSwiperComponents/LazyLoadImage';
import { IconMyBellMute } from '../../assets/icons/MyIcons';
import StaticHomeSwiper from '../HomePage/HomeSwiperComponents/StaticHomeSwiper';
import PartnerVideo from './PartnerVideo';
import PartnerAudio from './PartnerAudio';
import PartnerSeries from './PartnerSeries';
import PartnerLiveStream from './PartnerLiveStream';
const PartnerHomepage = ({ sliderData, data, thumbnailTitle, type }) => {

    const swiperConfig = {
        slidesPerView: 1,
        spaceBetween: 10,
        lazy: true,
        centeredSlides: true,
        grabCursor: true,
        loop: false,
        effect: 'fade',
        modules: [Autoplay, Pagination, EffectFade],
        className: 'w-100 h-100 partnerSwiper pb-4',
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        },
        pagination: {
            clickable: true,
            dynamicBullets: true
        }
    }


    return (
        <div className='paddingTop'>
            <Swiper {...swiperConfig}  >
                {sliderData?.length >= 0 && sliderData?.map((item, index) => (
                    <SwiperSlide key={index} >
                        <div className='partnerBanner w-100 h-100' style={{ backgroundImage: `url(${item?.imageURL})` }}></div>
                    </SwiperSlide>
                ))
                }
            </Swiper >
            <div className='px-md-4 px-2 py-md-5 py-2'>
                <div className='d-flex flex-wrap'>
                    <div className="col-12 col-md-6">
                        <LazyLoadImage src={'https://www.gstatic.com/webp/gallery/4.sm.webp'} alt="channel-log" imageSize="object-fit-contain partnerImage" classNameOptional="" />
                    </div>
                    <div className="col-12 col-md-6 px-md-4">
                        <h1 className='partnerHeading mb-3 mt-md-0 mt-3'>About {type}</h1>
                        <p className='text-break partnerDes mb-4'>Lorem ipsum dolor sit amet consectetur. Eleifend odio sed nunc congue viverra a integer nec pretium. Et facilisis tempor vitae purus dolor arcu fermentum phasellus. Tortor tortor mauris nulla et nec eros About Channel : Lorem ipsum dolor sit amet consectetur. Eleifend odio sed nunc congue viverra a integer nec pretium. Et facilisis tempor vitae purus dolor arcu fermentum phasellus. Tortor tortor mauris nulla et nec eros</p>
                        <div className='d-flex flex-wrap align-items-center gap-2 mb-2'>
                            <p className='partnerDes m-0'>Mobile Number :</p>
                            <span className='partnerSmall'>9876543210</span>
                        </div>
                        <div className='d-flex flex-wrap align-items-center gap-2'>
                            <p className='partnerDes m-0'>Email id :</p>
                            <span className='partnerSmall'>samplemil@admin.com</span>
                        </div>
                        <div className='d-flex flex-wrap align-items-center gap-3 mt-4'>
                            <button type='button ' className='px-3 py-2 rounded-2' >Subscribe {type}</button>
                            <button type='button' className='partnerBellIcon bg-transparent p-2 rounded-2 theme-text-color-button rounded-2 px-3 py-2 border-1 border theme-border-button-color'><IconMyBellMute styled={{ width: "20px", height: "20px" }} /></button>
                        </div>
                    </div>
                </div>
                <div className="paddingBottom pt-5" style={{ overflow: "hidden" }}>
                    {false ?
                        Array.from({ length: 8 }).map((n) => <StaticHomeSwiper key={n} />)
                        : data?.map((item, index) => {
                            return (
                                item?.source == "latest_videos" ? <PartnerVideo item={item} key={item.id} />
                                    : item?.source == "audios" ? <PartnerAudio item={item} key={item.id} />
                                        : item?.source == "livestream_videos" ? <PartnerLiveStream item={item} key={item.id} />
                                            : item?.source == "latest_series" ? <PartnerSeries item={item} ThumbnailTitle={thumbnailTitle} key={item.id} /> : null
                            )
                        }
                        )}
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = state => ({
    channelDetailsData: state.get_channelDetails_Reducer.channelDetailsData,
    isLoading: state.get_channelDetails_Reducer.isLoading,
    error: state.get_channelDetails_Reducer.error,
    settings: state.get_setting_Reducer.settings,
    homepage: state.get_allHomePage_Reducer.homepage,
    thumbnailTitle: state.get_allThumbnail_Reducer.thumbnailTitle
});
export default connect(mapStateToProps)(PartnerHomepage)
