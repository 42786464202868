import { useState } from "react";
import { Navigate } from "react-router-dom";
import { useMyContext } from "../Layout/CommonProvider";

const ProtectedRoute = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem("access_token"));
  const { navigateTranslateChecker } = useMyContext()
  if (!isLoggedIn) {
    return <Navigate to={navigateTranslateChecker(`/login`)} />;
  }

  return children;
};

export default ProtectedRoute