
import React, { useEffect, useRef } from 'react'
import { connect, useDispatch } from 'react-redux';
import { liveDetailsAction } from '../../Redux/Actions/apiActions';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LiveJs from './LiveJs';
import PageLoader from '../Loader/PageLoader';

const LivestreamPlayer = ({ liveDetailsData, isLoading, error, settings, slug, getWishListData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const user_role = localStorage.getItem("role");
  const user_id = localStorage.getItem("user_id");
  console.log(liveDetailsData?.type, "type")
  const playerType = liveDetailsData?.url_type;
  const embedSource = liveDetailsData?.embed_url;
  const access_token = localStorage.getItem("access_token");
  const headers = { "Content-Type": "application/json", Authorization: "Bearer " + access_token, Accept: "application/json", };
  const videoJsOptions = {
    controlBar: {
      subtitlesButton: false,
      remainingTimeDisplay: true,
      volumePanel: { inline: false, vertical: true },
      pictureInPictureToggle: true
    },
    bigPlayButton: false,
    autoplay: true,
    controls: true,
    responsive: true,
    breakpoints: [{ tiny: 300, xsmall: 400, small: 500, medium: 600, large: 700, xlarge: 800, huge: 900 }],
    textTrackSettings: false,
    fluid: true,
    playbackRates: [0.5, 1, 1.25, 1.5, 1.75, 2],
    sources: [{
      src: liveDetailsData?.livestream_URL,
      type: liveDetailsData?.livestream_player_type
    }]
  };

  useEffect(() => {
    if (settings && Object.keys(settings).length > 0) {
      !user_role && settings.access_free == 1
        ? dispatch(liveDetailsAction(null, 9, "web", slug, headers))
        : user_role && dispatch(liveDetailsAction(user_id, user_role, "web", slug, headers));
    }
  }, [settings, user_role])

  const playerRef = useRef(null);
  const handlePlayerReady = (player) => {
    playerRef.current = player;
    player.on("waiting", () => {
    });
    player.on("dispose", () => {
    });
  };
  console.log(liveDetailsData, "livedetails")
  var imaOptions = {
    forceNonLinearFullSlot: true,
    showControlsForAds: true,
    timeout: 5000,
    prerollTimeout: 10000,
    debug: true,
    adTagUrl: 'https://pubads.g.doubleclick.net/gampad/ads?sz=640x480&iu=/124319096/external/ad_rule_samples&ciu_szs=300x250&ad_rule=1&impl=s&gdfp_req=1&env=vp&output=vmap&unviewed_position_start=1&cust_params=deployment%3Ddevsite%26sample_ar%3Dpremidpost&cmsid=496&vid=short_onecue&correlator='
  }

  const wishListCheck = getWishListData?.live_stream_videos?.some(item => item.id === liveDetailsData.id);
  return (
    <> {isLoading
      ? <div className='d-flex justify-content-center align-items-center paddingTop'>
        <PageLoader /></div>
      : playerType == "embed"
        ? <iframe src={embedSource} allowfullscreen width="1360" height="628" title="Live"></iframe>
        : <LiveJs options={videoJsOptions} onReady={handlePlayerReady} liveDetailsData={liveDetailsData} imaOptions={imaOptions} settings={settings} wishListCheckValue={wishListCheck} />
    }</>
  )
}
const mapStateToProps = state => ({
  liveDetailsData: state.get_liveDetails_Reducer.liveDetailsData,
  isLoading: state.get_liveDetails_Reducer.isLoading,
  settings: state.get_setting_Reducer.settings,
  getWishListData: state?.get_wishList_Reducer?.getWishListData
});
export default connect(mapStateToProps)(LivestreamPlayer)