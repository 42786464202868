import React, { useEffect, useRef, useState } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import VolumnOn from "../../assets/icons/volumn-on.webp";
import VolumnOff from "../../assets/icons/volumn-off.webp";
import BannerOverlay from './BannerOverlay';
import { RxReload } from "react-icons/rx";
import BannerOverlayThemeTwo from './BannerOverlayThemeTwo';
import { connect } from 'react-redux';
import { useInView } from 'react-intersection-observer';

const BannerPlayer = ({ data, index, sliderCurrentIndex, currentTheme, page, siteTheme }) => {
    const [isMuted, setIsMuted] = useState(true);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isRetry, setIsRetry] = useState(false);
    const videoRef = useRef(null);
    const playerRef = useRef(null);


    const [ref, inView] = useInView({
        triggerOnce: true,
    });


    useEffect(() => {
        if (inView) {
            const player = videojs(videoRef.current, {
                controls: false,
                autoplay: false,
                muted: true,
                playsInline: true,
                sources: [
                    {
                        src: data?.trailer_videos_url || data?.trailer_link,
                        type: data?.trailer_video_player_type || 'video/mp4',
                    },
                ],
                poster: data?.Player_image_url,
            });

            playerRef.current = player;

            player.ready(() => {
                const tracks = player.textTracks();
                for (let i = 0; i < tracks.length; i++) {
                }
            });

            return () => {
                if (playerRef.current) {
                    playerRef.current.dispose();
                }
            };
        }
    }, [inView, data]);

    // useEffect(() => {
    //     const player = playerRef.current;
    //     if (player && sliderCurrentIndex !== index) {
    //         player.currentTime(0);
    //     }
    // }, [sliderCurrentIndex, index]);

    const handleMuteToggle = () => {
        const player = videoRef.current;
        if (player) {
            player.muted = !player.muted;
            setIsMuted(!isMuted);
        }
    };
    const handleMouseEnter = () => {
        const player = videoRef.current;
        if (player) {
            player.play().then(() => {
                player.muted = false;
                setIsMuted(false);
                setIsPlaying(true);
                setIsRetry(false);
            }).catch((error) => {
                console.error('Error playing video:', error);
            });
        }
    };

    const handleMouseLeave = () => {
        const player = videoRef.current;
        if (player) {
            player.pause();
            player.muted = true;
            setIsMuted(true);
            setIsPlaying(false);
        }
    };

    const handleVideoEnd = () => {
        const player = playerRef.current;
        setIsRetry(true);
        player.currentTime(0);
        player.pause();
    };
    const handleRetry = () => {
        const player = playerRef.current;
        if (player) {
            player.play().then(() => {
                setIsRetry(false);
            }).catch((error) => {
                console.error('Error retrying video play:', error);
            });
        }
    };


    return (
        <div ref={ref} className={`bannerContainer w-100 h-100 ${data?.id}`} key={data?.id} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
            {inView && (
                <video ref={videoRef} className={`vjs-theme-fantasy w-100 h-100 ${isPlaying ? 'hide-poster' : 'vicky'}`} onEnded={handleVideoEnd}></video>
            )}
            <button type="button" className="border-0 bg-transparent p-0 mb-3 d-inline-block volumnButtonImage" style={{ width: "15px", height: "15px" }} onClick={() => handleMuteToggle()}>
                {isMuted
                    ? <img src={VolumnOff} style={{ width: "15px", height: "15px" }} alt='volumnoff' />
                    : <img src={VolumnOn} style={{ width: "15px", height: "15px" }} alt='volumnon' />
                }
            </button>
            {currentTheme === 1 && <BannerOverlay data={data} type="video" page={page} translateCheckout={siteTheme?.translate_checkout} />}
            {currentTheme === 2 && <BannerOverlayThemeTwo translateCheckout={siteTheme?.translate_checkout} data={data} type="video" className={`${page === "movie" ? "px-2 px-md-3 px-lg-5 py-2 py-md-3 py-lg-5 " : ""} position-absolute overlayAbsoluteVideo`} page={page} />}
            {isRetry && (
                <div className='retryButton position-absolute d-flex align-items-center justify-content-center'>
                    <button className='theme-button-bg-color p-2 rounded-circle retryButtonSize' onClick={handleRetry}>
                        <RxReload className='theme-text-color' style={{ width: "15px", height: "15px" }} />
                    </button>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    currentTheme: state.get_siteTheme_Reducer.currentTheme,
    siteTheme: state.get_siteTheme_Reducer.siteTheme,
});
export default connect(mapStateToProps)(BannerPlayer);
