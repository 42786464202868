import React, { useEffect, useState } from 'react'
import Signup from './Signup'
import SignUpThemeTwo from './SignUpThemeTwo'
import { useNavigate, useParams } from 'react-router-dom';
import { getItem, getItemToken, setItem } from '../../Utils/localStorageUtils';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { currentIpAddressDetails } from '../CommonMethods/CommonMethods';
import { useMyContext } from '../../components/Layout/CommonProvider';

const SignUpIndex = ({ languageDetailsData, popUpClass, headerDisable, modalClose, signInModal, signUpModal, settings, currentTheme, fieldRestrict }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { navigateTranslateChecker } = useMyContext()
    const [verificationCode, setVerificationCode] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState('');
    const [passwordType, setPasswordType] = useState(true)
    const [passwordTypeDoubleCheck, setPasswordTypeDoubleCheck] = useState(true)
    const [ipDetails, setIpDetails] = useState(null)
    const [counter, setCounter] = useState(20);
    const [inputField, setInputField] = useState({ email: "", username: "", lastname: "", psetValidationassword: "", mobile: "", ccode: "", confirmPassword: "", check: false });
    const [errField, setErrField] = useState({ emailerr: "", usernameerr: "", lastnameerr: "", passworderr: "", numbererr: "", ccodeerr: "", confirmPassworderr: "", checkerr: "" });
    const [loading, setLoading] = useState(false)
    const [profileImage, setProfileImage] = useState(null)
    const [countryid, setCountryid] = useState(0);
    const [stateid, setstateid] = useState(0);
    const [cityid, setCityid] = useState(0);
    const userRoleLocal = getItem('role')
    const userIdLocal = getItem('user_id')

    const userAccessToken = getItemToken('access_token');

    const handleCountry = (id) => {
        setCountryid(id)
    }
    const handleState = (id) => {
        setstateid(id)
    }
    const handleCity = (id) => {
        setCityid(id)
    }

    const handleOnChange = (value, country, e, formattedValue) => {
        setPhoneNumber(value);
        setInputField({
            ...inputField,
            // ccode: country?.name + country?.dialCode,
            ccode: country?.dialCode,
            mobile: value.slice(country.dialCode.length)
        });
    };

    const togglePasswordCheck = () => {
        setPasswordType(!passwordType)
    }

    const togglePasswordDoubleCheck = () => {
        setPasswordTypeDoubleCheck(!passwordTypeDoubleCheck)
    }

    function handleChange(e) {
        setInputField({ ...inputField, [e.target.name]: e.target.value });
    }

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setInputField((prev) => ({
            ...prev,
            [name]: checked,
        }));
    };

    const handleEmailVerification = async (e) => {
        await axios.post(`${process.env.REACT_APP_Baseurl}/users/email-code-verification`, {
            email: inputField?.email,
            Url: `${process.env.REACT_APP_API_PATHss}/en/verify`,
        }).then((res) => {
            toast.success(res?.data?.message)
            setVerificationCode(!verificationCode)
        }).catch(() => {
            toast.error("Error fsadfafd")
        })

    }

    const signup = async () => {
        setLoading(true)
        try {
            const response = await axios.post(`${process.env.REACT_APP_Baseurl}/users/signup`, {
                username: inputField?.username,
                email: inputField?.email,
                last_name: inputField?.lastname,
                password: inputField?.password,
                ccode: inputField?.ccode,
                mobile: inputField?.mobile,
                confirm_password: inputField?.confirmPassword,
            });
            const result = response?.data;
            if (result?.status) {
                let username = result?.User?.username;
                let user_id = result?.User?.id;
                let email = result?.User?.email;
                let role = result?.User?.role;
                localStorage.setItem("signup_user_id", user_id);
                localStorage.setItem("signup_email", email);
                // localStorage.setItem("signup_role", role);
                localStorage.setItem("signup_username", username);
                toast.success(t(result?.message))
                setLoading(false)
                if (Number(settings?.activation_email)) {
                    handleEmailVerification()
                }
                else {
                    loginMethod()
                    if (headerDisable) {
                        await modalClose();
                    }
                }

            }
            else {
                toast.error(`The email has already been taken. ${t(result?.message)}`)
                setLoading(false)
            }
        } catch (error) {
            var errorMessage = error?.response?.data?.message;
            toast.error(`The email has already been taken. ${t(errorMessage)}`)
            setLoading(false)
        }
    }

    const loginMethod = async () => {
        const Data =
        {
            email: inputField?.email,
            password: inputField?.password,
        }
        await axios.post(`${process.env.REACT_APP_Baseurl}/users/signin`, Data).then((response) => {
            const result = response?.data;
            const resultUser = result?.user;
            if (result?.status) {
                localStorage.setItem("access_token", result?.access_token);
                localStorage.setItem("user_id", resultUser?.id);
                localStorage.setItem("role", Number(resultUser?.role));
                localStorage.setItem("username", resultUser?.username);
                localStorage.setItem("last_name", resultUser?.last_name);
                localStorage.setItem("image_url", resultUser?.image_url);
                localStorage.setItem("email", resultUser?.email);
                localStorage.setItem("expires_in", result?.expires_in);
                navigate(navigateTranslateChecker(`/plan`))
            }

        }).catch((error) => {
            var errorMessage = error?.response?.data?.message;
            toast.error(t(errorMessage))
        })
    }

    const validForm = () => {
        let formIsValid = true;
        // debugger;
        if (!inputField?.username) {
            setErrField((prevState) => ({ ...prevState, usernameerr: "Please Enter First Name" }));
            formIsValid = false;
        }
        else {
            setErrField((prevState) => ({ ...prevState, usernameerr: "" }));
        }
        if (!inputField?.lastname) {
            setErrField((prevState) => ({ ...prevState, lastnameerr: "Please Enter Last Name" }));
            formIsValid = false;
        }
        else {
            setErrField((prevState) => ({ ...prevState, lastnameerr: "" }));
        }
        if (!inputField?.email) {
            setErrField((prevState) => ({ ...prevState, emailerr: "Please Enter Email", }));
            formIsValid = false;
        } else if (!/\S+@\S+\.\S+/.test(inputField.email)) {
            setErrField((prevState) => ({ ...prevState, emailerr: "Please Enter Valid Email ID", }));
            formIsValid = false;
        }
        else {
            setErrField((prevState) => ({ ...prevState, emailerr: "" }));
        }

        if (!inputField?.mobile) {
            setErrField((prevState) => ({ ...prevState, numbererr: "Please Enter Number" }));
            formIsValid = false;
        }
        else if (inputField?.mobile?.length < 6) {
            setErrField((prevState) => ({ ...prevState, numbererr: "Plese enter minimum 6 digit" }));
            formIsValid = false;
        }
        else {
            setErrField((prevState) => ({ ...prevState, numbererr: "" }));
        }
        if (!inputField?.password) {
            setErrField((prevState) => ({ ...prevState, passworderr: "Please Enter Password" }));
            formIsValid = false;
        }
        else {
            setErrField((prevState) => ({ ...prevState, passworderr: "" }));
        }
        if (!inputField?.confirmPassword) {
            setErrField((prevState) => ({ ...prevState, confirmPassworderr: "Please Enter Confirm Password" }));
            formIsValid = false;
        }
        else {
            setErrField((prevState) => ({ ...prevState, confirmPassworderr: "" }));
        }
        if (inputField?.confirmPassword !== inputField?.password) {
            setErrField((prevState) => ({ ...prevState, confirmPassworderr: "Password and Confirm Password do not match", }));
            formIsValid = false;
        }

        if (!inputField?.check) {
            setErrField((prevState) => ({ ...prevState, checkerr: "Please Tick CheckBox", }));
            formIsValid = false;
        }
        else {
            setErrField((prevState) => ({ ...prevState, checkerr: "" }));
        }
        return formIsValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validForm()) {
            signup()
        } else {
            console.log('Form validation failed');
        }
    };

    useEffect(() => {
        currentIpAddressDetails().then(data => {
            if (data) {
                setIpDetails(data)
                console.log('IP Address Details:', data);
            } else {
                console.log('Failed to retrieve IP address details.');
            }
        });
    }, [])


    const handleCoverImageChange = async (event, errorKey) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            const reader = new FileReader();
            reader.onload = () => {
                setProfileImage(selectedFile);
            };
            reader.readAsDataURL(selectedFile);
        }
    };


    return (
        <div>
            {currentTheme == 1 && <Signup checkChange={handleCheckboxChange} imageOnChange={handleCoverImageChange} phoneChange={handleOnChange} numberValue={phoneNumber} ipConfig={ipDetails} authAction={handleSubmit} passAction={togglePasswordCheck} passTwoAction={togglePasswordDoubleCheck} error={errField} field={inputField} onchange={handleChange} show={passwordType} showTwo={passwordTypeDoubleCheck} handleCountry={handleCountry} handleState={handleState} handleCity={handleCity} data={{ lang: languageDetailsData, load: loading, pop: popUpClass, headerDisable: headerDisable, verify: verificationCode, count: counter, fieldEnable: fieldRestrict, previewImage: profileImage, countryid: countryid, cityid: cityid, stateid: stateid }} modalClose={modalClose} signUpModal={signUpModal} signInModal={signInModal} />}
            {currentTheme == 2 && <SignUpThemeTwo checkChange={handleCheckboxChange} imageOnChange={handleCoverImageChange} phoneChange={handleOnChange} numberValue={phoneNumber} ipConfig={ipDetails} authAction={handleSubmit} passAction={togglePasswordCheck} passTwoAction={togglePasswordDoubleCheck} error={errField} field={inputField} onchange={handleChange} show={passwordType} showTwo={passwordTypeDoubleCheck} handleCountry={handleCountry} handleState={handleState} handleCity={handleCity} data={{ lang: languageDetailsData, load: loading, pop: popUpClass, headerDisable: headerDisable, verify: verificationCode, count: counter, fieldEnable: fieldRestrict, previewImage: profileImage, countryid: countryid, cityid: cityid, stateid: stateid }} modalClose={modalClose} signUpModal={signUpModal} signInModal={signInModal} />}
        </div>
    )
}

const mapStateToProps = (state) => ({
    languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData,
    settings: state.get_setting_Reducer.settings,
    currentTheme: state.get_headerIndex_Reducer.currentTheme,
    fieldRestrict: state.signupSettings?.fieldRestrict
});
export default connect(mapStateToProps)(SignUpIndex);
