import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import loadings from "../../assets/gif/loading.gif";
import "../../Styles/Transcation_List_Register_Payments.css";
import { useTranslation } from "react-i18next";
import { FaSearch } from "react-icons/fa";

const Transcation_List_Register_Payments = () => {
  const [datatranscation, setDatatranscation] = useState([]);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [currentPagetranscation, setCurrentPagetranscation] = useState(1);
  const itemsPerPagetranscation = 10;

  // New state to store the search term
  const [searchTerm, setSearchTerm] = useState('');

  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const sourceTypeLabels = {
    1: 'Video',
    2: 'Livestream',
    3: 'Series',
    4: 'Episode',
    5: 'Audios',
    6: 'Season',
    7: 'Global'
  };

  const getSourceTypeLabel = (type) => {
    return sourceTypeLabels[type] || 'Unknown'; // Default to 'Unknown' if type is not found
  };

  const indexOfLastItemtranscation = currentPagetranscation * itemsPerPagetranscation;
  const indexOfFirstItemtranscation = indexOfLastItemtranscation - itemsPerPagetranscation;

  // Filter data based on the search term, including sourceTypeLabels
  const filteredTransactions = datatranscation.filter((item) => {
    const sourceTypeLabel = getSourceTypeLabel(item.source_type);

    return (
      sourceTypeLabel.toLowerCase().includes(searchTerm.toLowerCase()) || // Match source type label
      item.source_type?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.payment_price?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.current_status?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.start_timestamp?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.card_type?.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.source_details?.title?.toString().toLowerCase().includes(searchTerm.toLowerCase()) // Match source details title
    );
  });

  // Get the current items based on the filtered data
  const currentItemstranscation = filteredTransactions.slice(
    indexOfFirstItemtranscation,
    indexOfLastItemtranscation
  );

  // Fetch the data for the table
  useEffect(() => {
    setLoading(true);
    // Simulate fetching data
    setTimeout(() => {
      setLoading(false); // After fetching data, set loading to false
    }, 1000);
  }, [currentPagetranscation]);

  // Handle page change
  const handlePageChange = (newPage) => {
    setCurrentPagetranscation(newPage);
  };

  // Calculate total number of pages
  const totalPages = Math.ceil(filteredTransactions.length / itemsPerPagetranscation);

  // Fetch transaction data
  const fetchDatanextpretranscation = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_Baseurl}/Front-End/user-details`, {
        method: "POST",
        headers: headers,
      });
      const result = await response.json();
      setDatatranscation(result.transaction_details?.PPV_Purchase || []);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching transaction data", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDatanextpretranscation();
  }, [currentPagetranscation]);



  return (
    <div className="paddingTop">
      <section className="container mt-4">
        <div className="transcation_list_bg p-2">
          <div>
            <h3 className="text-center theme-text-color">
              {t("Transaction Payments History")}
            </h3>
          </div>

          <div className="p-2 mt-2">
            {/* Add search input */}
            <div className="col-lg-4 col-sm-4 col-md-4 col-12 d-flex"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control theme-text-color theme-bg-color-secondary"
                  placeholder={t("Search transactions")}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <div className="searchPageIconFaSearchtranssearchPageIconFaSearchtrans">
                <FaSearch
                  className="searchPageIconFaSearchtranssearchPageIconFaSearchtrans theme-text-color theme-bg-color-secondary p-1 fs-5"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>

            {/* Table responsive wrapper */}
            <div className="col-lg-12 table-responsive">
              {loading ? (
                <div className="p-2 text-start">
                  <Table>
                    <thead className="p-1">
                      <tr className="title">
                        <th className="fs-5 theme-text-color">{t("STATUS")}</th>
                        <th className="fs-5 theme-text-color">{t("SOURCE TYPE")}</th>
                        <th className="fs-5 theme-text-color">{t("SOURCE NAME")}</th>
                        <th className="fs-5 theme-text-color">{t("AMOUNT")}</th>
                        <th className="fs-5 theme-text-color">{t("DATE")}</th>
                        <th className="fs-5 theme-text-color">{t("PAYMENT TYPE")}</th>
                      </tr>
                    </thead>
                  </Table>

                  <div className="text-center">
                    <label>
                      <img src={loadings} width={100} alt="Loading" /> {t("Loading...")}
                    </label>
                  </div>
                </div>
              ) : currentItemstranscation.length > 0 ? (
                <div className="p-2 text-start iq-card-body">
                  <Table>
                    <thead className="p-1">
                      <tr className="title">
                        <th className="fs-5 theme-text-color">{t("STATUS")}</th>
                        <th className="fs-5 theme-text-color">{t("SOURCE TYPE")}</th>
                        <th className="fs-5 theme-text-color">{t("SOURCE NAME")}</th>
                        <th className="fs-5 theme-text-color">{t("AMOUNT")}</th>
                        <th className="fs-5 theme-text-color">{t("DATE")}</th>
                        <th className="fs-5 theme-text-color">{t("PAYMENT TYPE")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItemstranscation?.map((item, key) => (
                        <tr key={item?.id}>
                          <td>
                            {item?.current_status === 1 || item?.current_status === "1" ? (
                              <button className="bg-success w-100 p-2">
                                {t("Active")}
                              </button>
                            ) : item?.current_status === 0 ? (
                              <button className="bg-danger w-100 p-2">
                                {t("Inactive")}
                              </button>
                            ) : (
                              <button className="bg-warning w-100 p-2">
                                {t("Pending")}
                              </button>
                            )}
                          </td>
                          <td>
                            <div className="theme-text-color">{t(getSourceTypeLabel(item?.source_type))}</div>
                          </td>
                          <td>
                            {item?.source_details === null ? (
                              <div className="video-deleted-text">{t(getSourceTypeLabel(item?.source_type))} {t("Deleted")} </div>
                            ) : (
                              <div className="theme-text-color">{t(item?.source_details?.title)}</div>
                            )}
                          </td>

                          <td>
                            <div className="theme-text-color">
                              {t(item?.currency?.symbol)} {t(item?.payment_price)}
                            </div>
                          </td>
                          <td>
                            <div className="theme-text-color">{t(item?.start_timestamp)}</div>
                          </td>
                          <td>
                            <div className="theme-text-color">{t(item?.card_type)}</div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  {/* Pagination */}
                  <div className="pagination-container text-end mt-3">
                    <button
                      className="btn btn-primary m-1"
                      disabled={currentPagetranscation === 1}
                      onClick={() => handlePageChange(currentPagetranscation - 1)}
                    >
                      {t("Prev")}
                    </button>
                    {Array.from({ length: totalPages }, (_, index) => (
                      <button
                        key={index}
                        className={`btn m-1 ${currentPagetranscation === index + 1
                          ? "btn-secondary"
                          : "btn-primary"
                          }`}
                        onClick={() => handlePageChange(index + 1)}
                      >
                        {index + 1}
                      </button>
                    ))}
                    <button
                      className="btn btn-primary m-1"
                      disabled={currentPagetranscation === totalPages}
                      onClick={() => handlePageChange(currentPagetranscation + 1)}
                    >
                      {t("Next")}
                    </button>
                  </div>
                </div>
              ) : (
                <div className="p-2 text-start iq-card-body">
                  <Table>
                    <thead className="p-1">
                      <tr className="title">
                        <th className="fs-5 theme-text-color">{t("STATUS")}</th>
                        <th className="fs-5 theme-text-color">{t("SOURCE TYPE")}</th>
                        <th className="fs-5 theme-text-color">{t("SOURCE NAME")}</th>
                        <th className="fs-5 theme-text-color">{t("AMOUNT")}</th>
                        <th className="fs-5 theme-text-color">{t("DATE")}</th>
                        <th className="fs-5 theme-text-color">{t("PAYMENT TYPE")}</th>
                      </tr>
                    </thead>
                  </Table>

                  <div className="text-center mt-5 mb-5">
                    <label>{t("No transactions found")}</label>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>

  );
};
export default Transcation_List_Register_Payments;
