import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import JoditEditor from "jodit-react";
import StepContent from "@mui/material/StepContent";
import Typography from "@mui/material/Typography";
import { Table } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import axios, { CancelToken, isCancel } from "axios";
import { ProgressBar } from "react-bootstrap";
import Button from "@mui/material/Button";
import "./Contentmanageepisode.css";
import Select from "react-select";
import { FaEllipsisVertical } from "react-icons/fa6";
import { FaShareAlt } from "react-icons/fa";
import { toast } from "react-toastify";

import Dropzone from "react-dropzone";
import { TagsInput } from "react-tag-input-component";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import deleteitem from "../../../assets/icons/deleteitem.svg";
import edititem from "../../../assets/icons/edititem.svg";
import loadings from "../../../assets/gif/loading.gif";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import * as XLSX from "xlsx";
import { CSVLink } from "react-csv";
import { jsPDF } from "jspdf";
import {
  MdDelete,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import ExportIcon from "../../../assets/icons/export-icon.webp";
import ResponseLoader from "../../../components/Loader/ResponseLoader";

export default function Contentmanageepisode() {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const [series, setSeries] = useState([]);
  const [count, setCount] = useState();
  const [data, setData] = useState([]);
  const [videodata, setVideodata] = useState("");
  const [defaultImage, setDefaultimage] = useState();
  const [defaultImageurl, setDefaultimageurl] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [loading, setLoading] = useState(true);
  const [previous, setPrevious] = useState([]);
  const [next, setNext] = useState([]);
  const [page, setPage] = useState(1);
  const [toggle, setToggle] = useState(true);
  const [isOff, setIsOff] = useState(false);
  const [message, setMessage] = useState("");
  const [Subtitlesmap, setSubtitlesmap] = useState([]);
  const { id } = useParams();
  const { seriesid } = useParams();

  const [episode_id, setEpisode_id] = useState("");

  const [type, setType] = useState("");
  const [access, setAccess] = useState("");
  const [ppv_status, setPpv_status] = useState("");
  const [description, setDescription] = useState("");
  const [mp4_url, setMp4_url] = useState("");
  const [url, setUrl] = useState("");
  const [path, setPath] = useState("");
  const [disk, setDisk] = useState("");
  const [stream_path, setStream_path] = useState("");
  const [processed_low, setProcessed_low] = useState("");
  const [converted_for_streaming_at, setConverted_for_streaming_at] =
    useState("");
  const [active, setActive] = useState("");
  const [skip_recap, setSkip_recap] = useState("");
  const [skip_intro, setSkip_intro] = useState("");
  const [recap_start_time, setRecap_start_time] = useState("");
  const [recap_end_time, setRecap_end_time] = useState("");
  const [intro_start_time, setIntro_start_time] = useState("");
  const [intro_end_time, setIntro_end_time] = useState("");
  const [featured, setFeatured] = useState("");

  const [banner, setBanner] = useState("");
  const [footer, setFooter] = useState("");
  const [uploaded_by, setUploaded_by] = useState("");
  const [views, setViews] = useState("");
  const [rating, setRating] = useState("");
  const [status, setStatus] = useState("");
  const [free_content_duration, setFree_content_duration] = useState("");
  const [searchTags, setSearchTags] = useState([]);
  const [episode_ads, setEpisode_ads] = useState("");
  const [ads_position, setAds_position] = useState("");

  const [websitetitle, setWebsitetitle] = useState("");
  const [metadescription, setMetadescription] = useState("");
  const [websiteurl, setWebsiteurl] = useState("");

  const [free_duration_status, setFree_duration_status] = useState("");
  const [editUser, setEditUser] = useState({
    title: "",
    duration: "",
    free_duration_time: "",
  });

  const [adsPositions, setAdsPositions] = useState([]);
  const [selectedAdsPosition, setSelectedAdsPosition] = useState(null);
  const [secondSelectOptions, setSecondSelectOptions] = useState([]);

  const [user_access, setUser_access] = useState([]);
  const [inapppurchase, setInappPurchase] = useState("");
  const [age_restrictdata, setAge_restrictdata] = useState([]);
  const [age_restrict, setAge_restrict] = useState([]);
  const [publish_time, setPublish_time] = useState("");

  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValueAPI, setInputValueAPI] = useState("");

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
    if (!checkboxChecked) {
      setInputValue(inputValueAPI);
    } else {
      setInputValue("");
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const [ios_ppv_price, setIos_ppv_price] = useState("");

  // console.log(userAccessesValue, "userAccess");

  const handleAdsPositionChange = (selectedValue5) => {
    setSelectedAdsPosition(selectedValue5);
    // console.log(selectedValue5); // Selected value
  };

  const navigate = useNavigate();

  const [videosubtitles, setVideoSubtitles] = useState({});

  const handleDropsubtitles = (language, acceptedFiles) => {
    // Handle dropped files here
    setVideoSubtitles((prevState) => ({
      ...prevState,
      [language]: acceptedFiles,
    }));
  };

  // let access_token, access_token_cpp;

  // if (localStorage.getItem("username")) {
  //   access_token = localStorage.getItem("access_token");
  // } else {
  //   access_token_cpp = localStorage.getItem("access_token_cpp");
  // }

  // const headers = {
  //   "Content-Type": "application/json",
  //   // Authorization: "Bearer " + access_token_cpp,
  //   Accept: "application/json",
  //   "Access-Control-Allow-Origin": "*",
  // };

  // if (access_token) {
  //   headers.Authorization = "Bearer " + access_token;
  // } else if (access_token_cpp) {
  //   headers.Authorization = "Bearer " + access_token_cpp;
  // }

  const access_token_cpp = localStorage.getItem("access_token_cpp");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token_cpp,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const [autoFillValuetitle, setAutoFillValuetitle] = useState("");

  const handleInputKeyDowntitle = (event) => {
    if (event.key == "Enter" || event.key == "Tab") {
      generateSlug(editUser?.title);
    }
  };
  const handleBlurtitle = () => {
    generateSlug(editUser?.title);
  };
  const handleAutoFillChangetitle = (event) => {
    setValidationMessageslug("");
    setIsValidationHiddenslug(true);
    setAutoFillValuetitle(event.target.value);
  };

  const generateSlug = (title) => {
    title = title.trim();
    const slug = title.toLowerCase().replace(/\s+/g, "-");
    setAutoFillValuetitle(slug);
  };
  const [inputValueyear, setInputValueyear] = useState("");

  const handleChangeyear = (event) => {
    const value = event.target.value;
    if (/^\d{0,4}$/.test(value)) {
      setInputValueyear(value);
    }
  };

  const [errors, setErrors] = useState({
    error_skip_start_time: "",
    error_skip_end_time: "",
    error_skip_start_session: "",
    error_recap_start_time: "",
    error_recap_end_time: "",
    error_recap_start_session: "",
  });

  const [userTime, setUserTime] = useState({
    skip_start_time: "",
    skip_end_time: "",
    skip_start_session: "",
    recap_start_time: "",
    recap_end_time: "",
    recap_start_session: "",
    url_linktym: "",
  });

  const handleInputintrotime = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    // Remove non-digit characters from input
    const formattedInput = inputValue.replace(/\D/g, "");

    // Add colons to maintain the format HH:MM:SS
    const formattedTime = formattedInput
      .replace(/^(\d{2})(\d{2})(\d{2})$/, "$1:$2:$3")
      .substr(0, 8);

    setUserTime({
      ...userTime,
      [inputName]: formattedTime,
    });

    validateInput(inputName, formattedTime);
  };

  const validateInput = (inputName, formattedTime) => {
    const errorsCopy = { ...errors };

    switch (inputName) {
      case "skip_start_time":
        if (formattedTime == "") {
          errorsCopy.error_skip_start_time = "";
        } else {
          errorsCopy.error_skip_start_time = "";
        }
        break;

      case "skip_end_time":
        if (formattedTime == "") {
          errorsCopy.error_skip_end_time = "";
        } else if (formattedTime <= userTime.skip_start_time) {
          errorsCopy.error_skip_end_time =
            "Skip End time must be greater than Skip Start time";
        } else {
          errorsCopy.error_skip_end_time = "";
        }
        break;

      case "skip_start_session":
        if (formattedTime == "") {
          errorsCopy.error_skip_start_session = "";
        } else if (formattedTime <= userTime.skip_end_time) {
          errorsCopy.error_skip_start_session =
            "Skip Start Session must be greater than Skip End time";
        } else {
          errorsCopy.error_skip_start_session = "";
        }
        break;

      case "recap_start_time":
        if (formattedTime == "") {
          errorsCopy.error_recap_start_time = "";
        } else {
          errorsCopy.error_recap_start_time = "";
        }
        break;

      case "recap_end_time":
        if (formattedTime == "") {
          errorsCopy.error_recap_end_time = "";
        } else if (formattedTime <= userTime.recap_start_time) {
          errorsCopy.error_recap_end_time =
            "Recap End time must be greater than Recap Start time";
        } else {
          errorsCopy.error_recap_end_time = "";
        }
        break;

      case "recap_start_session":
        if (formattedTime == "") {
          errorsCopy.error_recap_start_session = "";
        } else if (formattedTime <= userTime.recap_end_time) {
          errorsCopy.error_recap_start_session =
            "Recap Start Session must be greater than Recap End time";
        } else {
          errorsCopy.error_recap_start_session = "";
        }
        break;

      default:
        break;
    }

    setErrors(errorsCopy);
  };

  const insertColons = (time) => {
    if (time?.length >= 6) {
      const hours = time?.substr(0, 2);
      const minutes = time?.substr(2, 2);
      const seconds = time?.substr(4, 2);

      return `${hours}:${minutes}:${seconds}`;
    }
    return time;
  };

  const handleInputdata = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    // Remove non-digit characters from input
    const formattedInput = inputValue.replace(/\D/g, "");

    // Add colons to maintain the format HH:MM:SS
    const formattedTime = formattedInput
      .replace(/^(\d{2})(\d{2})(\d{2})$/, "$1:$2:$3")
      .substr(0, 8);

    setEditUser({
      ...editUser,
      [inputName]: formattedTime,
    });
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessageslug("");
    setIsValidationHiddenslug(true);
    setValidationMessagetitle("");
    setIsValidationHiddentitle(true);

    setEditUser({ ...editUser, title: e.target.value });
    setEditUser({ ...editUser, [e.target.name]: e.target.value });

    setActive({ [e.target.name]: e.target.value });
    setFeatured({ ...featured, [e.target.name]: e.target.value });
    setStatus({ ...status, [e.target.name]: e.target.value });
    setPpv_status({ ...ppv_status, [e.target.name]: e.target.value });
    setBanner({ ...banner, [e.target.name]: e.target.value });
    setActive({ ...active, [e.target.name]: e.target.value });
    setViews({ ...views, [e.target.name]: e.target.value });
    setFooter({ ...footer, [e.target.name]: e.target.value });
    setFree_duration_status({
      ...free_duration_status,
      [e.target.name]: e.target.value,
    });

    if (e.target.checked == true) {
      setPpv_status({ ...ppv_status, [e.target.name]: 1 });
      setFeatured({ ...featured, [e.target.name]: 1 });
      setStatus({ ...status, [e.target.name]: 1 });
      setBanner({ ...banner, [e.target.name]: 1 });
      setActive({ ...active, [e.target.name]: 1 });
      setViews({ ...views, [e.target.name]: 1 });
      setFooter({ ...footer, [e.target.name]: 1 });
      setFree_duration_status({
        ...free_duration_status,
        [e.target.name]: 1,
      });
    } else {
      setPpv_status({ ...ppv_status, [e.target.name]: 0 });
      setFeatured({ ...featured, [e.target.name]: 0 });
      setBanner({ ...banner, [e.target.name]: 0 });
      setActive({ ...active, [e.target.name]: 0 });
      setViews({ ...views, [e.target.name]: 0 });
      setFooter({ ...footer, [e.target.name]: 0 });
      setFree_duration_status({
        ...free_duration_status,
        [e.target.name]: 0,
      });
    }
  };

  const contentFieldChanaged = (data) => {
    setDescription(data);
    // console.log("datasssssss", data);
  };

  const handleTagsChange = (newTags) => {
    // Convert tags to lowercase, remove duplicates, and filter out empty tags
    const cleanedTags = newTags
      .map((tag) => tag.trim().toLowerCase())
      .filter((tag) => tag !== "")
      .filter((value, index, self) => self.indexOf(value) == index);
    setSearchTags(cleanedTags);
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const [uploadPercentage, setUploadPercentage] = useState(0);
  const cancelFileUpload = useRef(null);

  const cancelUpload = () => {
    if (cancelFileUpload.current)
      cancelFileUpload.current("User has canceled the file upload.");
  };

  const [uploadPercentagefile, setUploadPercentagefile] = useState(0);
  const cancelFileUploadfile = useRef(null);

  const cancelUploadfile = () => {
    if (cancelFileUploadfile.current)
      cancelFileUploadfile.current("User has canceled the file upload.");
  };

  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const handleUpload = async (files) => {
    const uploadUrl = `${process.env.REACT_APP_Baseurl}/CPP/Backend/series-Episode/upload`;

    const uploaders = files.map((file) => {
      const formData = new FormData();
      formData.append("series_id", seriesid);
      formData.append("file", file);
      formData.append("season_id", id);
      formData.append("Content_Partner_id", 1);

      return axios.post(uploadUrl, formData, {
        headers: headers,
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
      });
    });

    try {
      await Promise.all(uploaders);
      const responses = await Promise.all(uploaders);
      // console.log("Responses after upload:", responses);

      if (responses[0]?.data?.status == true) {
        responses?.forEach((response, index) => {
          // console.log(`Response for video ${index + 1}:`, response.data.Videos);
          setEditUser(response?.data?.Episode);
          setEpisode_id(response?.data?.Episode_id);
          // Process the response data as needed for each video
        });
        const uploadedDetails = files.map((file, index) => ({
          name: file.name,
          size: (file.size / (1024 * 1024)).toFixed(2), // Convert bytes to MB with 2 decimal places
        }));

        setUploadedFiles(uploadedDetails);
        setUploadCompleted(true);
        setUploadProgress(0);
        // props.setApiresponsealert(responses[0].data);
        // props.setShowalert(true);

        toast.success(responses[0]?.data?.message);
      } else {
        // Handle error response
        var resultError = responses[0].data;
        // props.setApiresponsealert(resultError);
        // props.setShowalert(true);
        toast.error(responses[0]?.data?.message);
        setUploadProgress(0);
      }
    } catch (error) {
      alert("Error uploading videos. Please try again.");
      setUploadProgress(0);
    }
  };

  const resetUploadState = () => {
    setUploadProgress(0);
    setUploadCompleted(false);
    setUploadedFiles([]);
  };

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);

  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = (
    setSelectedFile1,
    setSelectedFile2,
    setSelectedFile3
  ) => {
    setSelectedFile1(null);
    if (fileInputRef1.current) {
      fileInputRef1.current.value = null;
    }
    setSelectedFile2(null);
    if (fileInputRef2.current) {
      fileInputRef2.current.value = null;
    }
    setSelectedFile3(null);
    if (fileInputRef3.current) {
      fileInputRef3.current.value = null;
    }
  };

  // Multiselect
  const [block_country, setBlock_Country] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [available_country, setAvailable_country] = useState([]);
  const [
    selectedOptionsAvailable_country,
    setSelectedOptionsAvailable_country,
  ] = useState([]);

  const [related_videos, setRelated_videos] = useState([]);
  const [selectedOptionsRelated_videos, setSelectedOptionsRelated_videos] =
    useState([]);

  const [language, setLanguage] = useState([]);
  const [selectedOptionslanguage, setSelectedOptionslanguage] = useState([]);

  const [artists, setArtists] = useState([]);
  const [selectedOptionsartists, setSelectedOptionsartists] = useState([]);

  const [category, setCategory] = useState([]);
  const [selectedOptionscategory, setSelectedOptionscategory] = useState([]);

  useEffect(() => {
    fetchOptions();
  }, []);

  const fetchOptions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/series-Episode/create`,
        { headers: headers }
      );
      setBlock_Country(response?.data?.Block_Country);
      setAvailable_country(response?.data?.Available_country);
      setRelated_videos(response?.data?.Related_videos);
      setLanguage(response?.data?.video_language);
      // setAge_Restrict(response.data.Age_Restrict);
      setArtists(response?.data?.video_artist);
      setCategory(response?.data?.video_category);
      setInputValueAPI(response?.data?.ppv_gobal_price);
      // setAudio_albums(response.data.audio_albums);
      setSubtitlesmap(response?.data?.Subtitle);

      var age_restrictdata = response?.data?.Age_Restrict;

      setAge_restrictdata(age_restrictdata);

      var result = response?.data?.user_access;
      var InappPurchase = response?.data?.InappPurchase;
      setUser_access(result);
      setInappPurchase(InappPurchase);

      //  console.log(response.data);
      // console.log(response.data.video_artist);
      // console.log(response);
      // console.log(response.data.ppv_gobal_price);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
  };

  const handleSelectChangeRelated_videos = (selectedValues) => {
    setSelectedOptionsRelated_videos(selectedValues);
  };

  const handleSelectChangeAvailable_country = (selectedValues) => {
    setSelectedOptionsAvailable_country(selectedValues);
  };

  const handleSelectChangelanguage = (selectedValues) => {
    setSelectedOptionslanguage(selectedValues);
  };

  const handleSelectChangeartists = (selectedValues) => {
    setSelectedOptionsartists(selectedValues);
  };

  const handleSelectChangecategory = (selectedValues) => {
    setSelectedOptionscategory(selectedValues);
  };

  // Multiselect

  useEffect(async () => {


    const formData = new FormData();

    formData.append("series_id", seriesid);
    formData.append("season_id", id);


    await axios
      .post(`${process.env.REACT_APP_Baseurl}/CPP/Backend/series-Episode/list`, formData, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.user_access)
        var result = response?.data?.Episodes;
        setData(result);
        // console.log('orders', result);
      })
      .catch((error) => console.log(error));
  }, []);

  const [validationMessagetitle, setValidationMessagetitle] = useState("");
  const [isValidationHiddentitle, setIsValidationHiddentitle] = useState(true);

  const [validationMessageslug, setValidationMessageslug] = useState("");
  const [isValidationHiddenslug, setIsValidationHiddenslug] = useState(true);

  const [validationMessagecategory, setValidationMessagecategory] =
    useState("");
  const [isValidationHiddencategory, setIsValidationHiddencategory] =
    useState(true);

  const [validationMessagelanguage, setValidationMessagelanguage] =
    useState("");
  const [isValidationHiddenlanguage, setIsValidationHiddenlanguage] =
    useState(true);

  const titleInputRef = useRef(null);
  const categoryInputRef = useRef(null);
  const languageInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  async function Manage_Episode() {
    let focusInputRef = null;

    if (editUser?.title == "") {
      setValidationMessagetitle("Title cannot be empty.");
      setIsValidationHiddentitle(false);
      focusInputRef = titleInputRef;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    const editInputfeatured = featured?.featured;
    var datafeatured = JSON.stringify(editInputfeatured);
    var featureddata = datafeatured;

    const editInputbanner = banner?.banner;
    var databanner = JSON.stringify(editInputbanner);
    var bannerdata = databanner;

    const editInputvalue = active?.active;
    var data = JSON.stringify(editInputvalue);
    var aactivedata = data;

    const editInputvaluefooter = footer?.footer;
    var data = JSON.stringify(editInputvaluefooter);
    var footerdata = data;

    const editInputfree_duration_statusdata =
      free_duration_status?.free_duration_status;
    var data = JSON.stringify(editInputfree_duration_statusdata);
    var free_duration_statusdata = data;

    const formData = new FormData();

    formData.append("Content_Partner_id", 1);

    formData.append("skip_start_time", userTime?.skip_start_time);
    formData.append("skip_end_time", userTime?.skip_end_time);
    formData.append("skip_start_session", userTime?.skip_start_session);
    formData.append("recap_start_time", userTime?.recap_start_time);
    formData.append("recap_end_time", userTime?.recap_end_time);
    formData.append("recap_start_session", userTime?.recap_start_session);

    formData.append("series_id", seriesid);
    formData.append("season_id", id);
    formData.append("episode_id", episode_id);
    formData.append("title", editUser?.title);
    formData.append("slug", autoFillValuetitle);
    // formData.append("episode_description", episode_description);
    formData.append("episode_description", description);
    formData.append("type", type);
    formData.append("access", access);
    formData.append("ppv_status", inputValue);
    formData.append("ios_ppv_price", ios_ppv_price?.value);
    formData.append("ppv_price", inputValue);
    formData.append("mp4_url", mp4_url);
    formData.append("url", url);
    formData.append("path", path);
    formData.append("disk", disk);
    formData.append("stream_path", stream_path);
    formData.append("processed_low", processed_low);
    formData.append("converted_for_streaming_at", converted_for_streaming_at);
    formData.append("active", aactivedata);
    formData.append("year", inputValueyear);
    formData.append("free_duration_status", free_duration_statusdata);
    formData.append("free_duration_time", editUser?.free_duration_time);

    formData.append(
      "artist_id",
      JSON.stringify(selectedOptionsartists?.map((option) => option?.value))
    );
    formData.append(
      "block_country_id",
      JSON.stringify(selectedOptions?.map((option) => option?.value))
    );
    formData.append(
      "available_countries_id",
      JSON.stringify(
        selectedOptionsAvailable_country?.map((option) => option?.value)
      )
    );
    formData.append(
      "category_id",
      JSON.stringify(selectedOptionscategory?.map((option) => option?.value))
    );
    formData.append(
      "languages_id",
      JSON.stringify(selectedOptionslanguage?.map((option) => option?.value))
    );
    formData.append("age_restrict", age_restrict);
    formData.append(
      "related_videos",
      JSON.stringify(
        selectedOptionsRelated_videos?.map((option) => option?.value)
      )
    );

    // formData.append("recap_start_time", recap_start_time);
    // formData.append("recap_end_time", recap_end_time);
    // formData.append("intro_start_time", intro_start_time);
    // formData.append("intro_end_time", intro_end_time);
    formData.append("featured", featureddata);
    formData.append("banner", bannerdata);
    formData.append("footer", footerdata);
    formData.append("duration", editUser?.duration);
    formData.append("age_restrict", age_restrict);
    formData.append("views", views);
    formData.append("rating", rating);
    formData.append("status", status);
    formData.append("free_content_duration", free_content_duration);
    formData.append("search_tags", searchTags);
    formData.append("uploaded_by", uploaded_by);
    formData.append("ads_position", ads_position);
    formData.append("episode_ads", episode_ads);

    let firstApiStatus = false;
    let secondApiStatus = false;
    let seventhApiStatus = false;

    let resultapi = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/series-Episode/store`,
        formData,
        { headers: headers }
      );

      if (response.data.status == true) {
        firstApiStatus = true;

        resultapi = response.data;

        const imageFormData = new FormData();
        imageFormData.append("episode_id", episode_id);
        if (selectedFile1) {
          imageFormData.append("image", selectedFile1);
        }
        if (selectedFile2) {
          imageFormData.append("player_image", selectedFile2);
        }
        if (selectedFile3) {
          imageFormData.append("tv_image", selectedFile3);
        }
        const imageResponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/CPP/Backend/series-Episode/Image-upload`,
          imageFormData,
          { headers: headers }
        );

        const formDataseo = new FormData();
        formDataseo.append("episode_id", episode_id);
        formDataseo.append("website_page_title", websitetitle);
        formDataseo.append("website_URL", websiteurl);
        formDataseo.append("Meta_description", metadescription);

        const formDataseoresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/CPP/Backend/series-Episode/SEO`,
          formDataseo,
          { headers: headers }
        );

        if (imageResponse.data.status == true) {
          secondApiStatus = true;
        }
        if (formDataseoresponse.data.status == true) {
          seventhApiStatus = true;
        }
      }
    } catch (error) { }

    if (firstApiStatus && secondApiStatus && seventhApiStatus) {
      setValidationMessagetitle("");
      setValidationMessageslug("");
      // props.setApiresponsealert(resultapi);
      // props.setShowalert(true);
      toast.success(resultapi?.message);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resultapi == false) {
        var resultError = resultapi;
        // props.setApiresponsealert(resultError);
        // props.setShowalert(true);
        toast.error(resultError?.message);
        setIsValidationHiddentitle(false);
        setValidationMessageslug(false);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  }

  useEffect(() => {
    if (selectedAdsPosition !== "") {
      // console.log(selectedAdsPosition, "adsssssssssss");
      const formData = new FormData();
      formData.append("position", selectedAdsPosition?.value);

      axios

        .post(
          `${process.env.REACT_APP_Baseurl}/CPP/advertisement-tag-url`,
          formData,
          { headers: headers }
        )

        .then((response) => {
          const data = response?.data;
          // console.log(data, "exceptioned");

          const adsNames = data?.Advertisement?.map((position) => ({
            value: position?.id,
            label: position?.ads_name,
          }));

          setSecondSelectOptions(adsNames);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [selectedAdsPosition]);

  const [Episodesource, setEpisodesource] = useState("");

  function Episode_stream_source() {
    switch (Episodesource) {
      case "guest":
        return <div></div>;
      case "subscriber":
        return <div></div>;
      case "registered":
        return <div></div>;
      case "ppv":
        return (
          <div className="row mt-3 text-start ">
            <div className="col-sm-6">
              <label className="m-0">PPV Price</label>
              <div className="col-lg-12 row">
                <div className="col-8 col-lg-8">
                  <p className="p1">Apply PPV Price from Global Settings? </p>
                </div>
                <div className="col-4 col-lg-4">
                  <label className="switch">
                    <input
                      name="ppv_price"
                      defaultChecked={checkboxChecked}
                      onChange={handleCheckboxChange}
                      type="checkbox"
                    />
                    <span
                      className="slider round"
                      name="ppv_price"
                      checked={checkboxChecked}
                      onChange={handleCheckboxChange}
                    ></span>
                  </label>
                </div>
              </div>

              <div className="panel-body">
                <input
                  type="number"
                  className="form-control"
                  placeholder="PPV Price"
                  name="ppv_price"
                  id="price"
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="col-sm-6">
              <label className="m-0"> IOS PPV Price</label>
              <p className="p1">Apply IOS PPV Price from Global Settings?</p>
              <div className="panel-body ppv_price_ios">
                <select
                  onChange={(e) => setIos_ppv_price(e.target.value)}
                  className="form-select"
                  id="ios_ppv_price"
                  name="ios_ppv_price"
                >
                  <option value="">Choose a PPV Price</option>
                  {inapppurchase?.map((item, key) => (
                    <option value={item.product_id}>{item.plan_price}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  const [enable_restreampublic, setEnable_restreampublic] = useState("");
  const [enablestreamvisiblepublic, setEnablestreamvisiblepublic] =
    useState("");

  function proceednextpublic() {
    switch (enable_restreampublic) {
      case "publish_now":
        return <div></div>;
      case "publish_later":
        return (
          <div className="mb-3">
            <label className="m-0">Publish Time</label>
            <input
              type="datetime-local"
              className="rs-input mt-2  form-control-lg"
              id="publish_time"
              name="publish_time"
              onChange={(e) => setPublish_time(e.target.value)}
            />
          </div>
        );
      default:
        return null;
    }
  }

  const [allEpisodeuseraccess, setallEpisodeuseraccess] = useState("");
  const [storeEpisodeuseraccess, setStoreEpisodeuseraccess] = useState("");

  function allEpisode_streamuseraccess() {
    switch (allEpisodeuseraccess) {
      case "guest":
        return <div></div>;
      case "subscriber":
        return <div></div>;
      case "registered":
        return <div></div>;
      case "ppv":
        return (
          <div className="row mt-3 text-start ">
            <div className="col-sm-12">
              <label className="m-0">PPV Price</label>
              <div className="col-lg-12 row">
                <div className="col-8 col-lg-8">
                  <p className="p1">Apply PPV Price from Global Settings? </p>
                </div>
                <div className="col-4 col-lg-4">
                  <label className="switch">
                    <input
                      name="ppv_price"
                      defaultChecked={checkboxChecked}
                      onChange={handleCheckboxChange}
                      type="checkbox"
                      className="rs-input"
                    />
                    <span
                      className="slider round"
                      name="ppv_price"
                      checked={checkboxChecked}
                      onChange={handleCheckboxChange}
                    ></span>
                  </label>
                </div>
              </div>

              <div className="panel-body">
                <input
                  type="text"
                  className="rs-input"
                  placeholder="PPV Price"
                  name="ppv_price"
                  id="price"
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="col-sm-12 mt-2">
              <label className="m-0"> IOS PPV Price</label>
              <p className="p1">Apply IOS PPV Price from Global Settings?</p>
              <div className="panel-body ppv_price_ios  mt-2">
                <select
                  onChange={(e) => setIos_ppv_price(e.target.value)}
                  className="form-select"
                  id="ios_ppv_price"
                  name="ios_ppv_price"
                >
                  <option value="">Choose a PPV Price</option>
                  {inapppurchase?.map((item, key) => (
                    <option value={item?.id}>{item?.plan_price}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  useEffect(() => {
    fetchData();
    fetchdefaultimage();
    fetchdefaultimageurl();
    fetchDatanextpre();
  }, [page]);

  const fetchDatanextpre = () => {


    const formData = new FormData();

    formData.append("series_id", seriesid);
    formData.append("season_id", id);


    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/series-Episode/list`, formData,
        {
          headers: headers,
        }
      )
      .then((res) => {
        const videosData = res?.data?.Episodes;
        setVideodata(videosData);
        if (videosData?.length > 0) {
          setData(videosData);
          setLoading(false);
        } else {
          setData([]);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchData = async () => {
    try {

      const formData = new FormData();

      formData.append("series_id", seriesid);
      formData.append("season_id", id);

      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/series-Episode/list`, formData,
        { headers: headers }
      );
      setCount(response?.data?.Total_count);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const fetchdefaultimage = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/CPP/Vertical-Default-Image`,
        { headers: headers }
      );
      setDefaultimage(response?.data?.Vertical_Default_Image);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchdefaultimageurl = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/CPP/Default-image-url`,
        { headers: headers }
      );
      setDefaultimageurl(response?.data?.Default_Image_Url);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [showModal, setShowModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const handleToggle = async (item) => {
    setCurrentItem(item);
    setShowModal(true);
  };

  const confirmToggle = async () => {
    try {
      setShowModal(false);
      const newStatus = currentItem.banner == 1 ? 0 : 1;

      const response = await fetch(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/series-Episode/slider-update`,
        {
          method: "POST",
          body: JSON.stringify({
            episode_id: currentItem.id,
            banner_status: newStatus,
          }),
          headers: headers,
        }
      );

      const responseData = await response.json();

      if (response.ok) {
        const updatedItems = series.map((currItem) => {
          if (currItem.id == currentItem.id) {
            return { ...currItem, banner: newStatus };
          }
          return currItem;
        });

        setSeries(updatedItems);

        // props.setApiresponsealert(responseData);
        // props.setShowalert(true);
        setMessage(responseData.message);
      } else {
        // props.setApiresponsealert(responseData);
        // props.setShowalert(true);
      }
    } catch (error) { }
  };

  const closeModalslider = () => {
    setShowModal(false);
    setCurrentItem(null);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);

  const handlePreClick = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNxtClick = () => {
    setPage(page + 1);
  };

  const videosdeleteOperation = (id) => {
    const access_token = localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + access_token,
    };
    axios
      .delete(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/series-Episode/delete/` +
        id,
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (response.data.status == true) {
          var responseData = response.data;
          // props.setApiresponsealert(responseData);
          // props.setShowalert(true);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          if (responseData == false) {
            var resultError = responseData;
            // props.setApiresponsealert(resultError);
            // props.setShowalert(true);
          }
        }
      });
  };

  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isSuccessMessageOpen, setIsSuccessMessageOpen] = useState(false);

  const handleSingleSelect = (itemId) => {
    setSelectedItems([itemId]);
    setSelectAll(false);
  };

  const handleMultiSelect = (itemId) => {
    if (selectedItems.includes(itemId)) {
      setSelectedItems(selectedItems.filter((id) => id !== itemId));
    } else {
      setSelectedItems([...selectedItems, itemId]);
    }
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectedItems(selectAll ? [] : videodata.map((item) => item.id));
  };

  const handleDeletemulti = () => {
    setIsConfirmationOpen(true);
  };
  const handleCancelDelete = () => {
    setIsConfirmationOpen(false);
  };

  const handleConfirmDelete = () => {
    setIsConfirmationOpen(false);
    for (const itemId of selectedItems) {
      axios
        .delete(
          `${process.env.REACT_APP_Baseurl}/CPP/Backend/series-Episode/delete/${itemId}`,
          {
            headers: headers,
          }
        )
        .then((response) => {
          if (response.data.status == true) {
            setVideodata(videodata.filter((item) => item.id !== itemId));
            setSelectedItems(selectedItems.filter((id) => id !== itemId));
            setIsSuccessMessageOpen(true);
            setTimeout(() => {
              setIsSuccessMessageOpen(false);
            }, 5000);
            var responseData = response.data;
            // props.setApiresponsealert(responseData);
            // props.setShowalert(true);
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          } else {
            if (responseData == false) {
              var resultError = responseData;
              // props.setApiresponsealert(resultError);
              // props.setShowalert(true);
            }
          }
        });
    }
  };

  const [itemToDelete, setItemToDelete] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");

  const openModal = (id) => {
    setItemToDelete(id);
    setSuccessMessage("");
  };

  const closeModal = () => {
    setItemToDelete(null);
  };
  const handleDeletedata = () => {
    closeModal();
    if (itemToDelete) {
      videosdeleteOperation(itemToDelete);
    }
  };

  // all List of episode

  const [getEpisodeData, setEpisodeData] = useState([]);
  const [filteredEpisodeData, setFilteredEpisodeData] = useState([]);
  const [itemToDeleteEpisode, setItemToDeleteEpisode] = useState([]);
  const [modalCloseEpisode, setModalCloseEpisode] = useState(false);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    fetchDatas();
  }, []);

  const fetchDatas = async () => {
    try {

      const formData = new FormData();

      formData.append("series_id", seriesid);
      formData.append("season_id", id);

      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/series-Episode/list`, formData,
        { headers: headers }
      );
      const EpisodeData = response?.data?.Episodes;
      const filterHelp = EpisodeData?.map((item) => {
        const UploadStatus =
          item?.uploaded_by == "1"
            ? "Admin"
            : item?.uploaded_by == "4"
              ? "Channel Partner"
              : item?.uploaded_by == "5"
                ? "Content Partner"
                : "";
        const statusText =
          item?.active == 1 &&
            item?.publish_type == "publish_now" &&
            item?.status == 1
            ? // &&
            // item.draft == 1
            "PUBLISHED"
            : item?.active == null &&
              item?.publish_type == "publish_later" &&
              item?.status == null
              ? //  &&
              // item.draft == null
              "SCHEDULED"
              : "DRAFT";

        return { ...item, uploaded_by: UploadStatus, active: statusText };
      });
      setEpisodeData(filterHelp);
      setFilteredEpisodeData(filterHelp);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    nextPageText: "Next",
    prePageText: "Prev",
    firstPageText: <MdKeyboardDoubleArrowLeft />,
    lastPageText: <MdKeyboardDoubleArrowRight />,
    showTotal: true,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "All", value: filteredEpisodeData?.length },
    ],
  });

  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (row) {
        if (isSelect) {
          setItemToDeleteEpisode([...itemToDeleteEpisode, row?.id]);
        } else {
          setItemToDeleteEpisode(
            itemToDeleteEpisode?.filter((id) => id !== row?.id)
          );
        }
      }
    },
    selected: itemToDeleteEpisode,
    selectionHeaderRenderer: ({ mode, checked, indeterminate }) => (
      <div>
        <input
          type="checkbox"
          checked={checked}
          indeterminate={indeterminate}
          onChange={handleAll}
        />
      </div>
    ),
  };

  const videoFormatter = (data, row) => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <img src={row?.Thumbnail} className="profile" />
        <div className="rightVideoTitle">
          <p className=" theme-text-color">{data}</p>
          <span className="me-1 text-dark dateText  theme-text-color">
            {" "}
            {row?.duration}
          </span>
          <span className="dateText">{row?.upload_on}</span>
        </div>
      </div>
    );
  };

  const statusFormatter = (data, row) => {
    return (
      <div
        className={`  font-weight-bolder  d-flex justify-content-center ${data == "PUBLISHED"
          ? "PUBLISHED_active"
          : data == "SCHEDULED"
            ? " DISABLED_active"
            : "UNPUBLISHED_active"
          }`}
      >
        {data}
      </div>
    );
  };

  const actionFormatter = (data, row) => {
    return (
      <div className="editdropdown">
        <span className="editdropdown-button">
          <span>
            <FaEllipsisVertical className=" theme-text-color" />
          </span>
        </span>
        <div className="editdropdown-menu theme-bg-color">
          <Link
            to={`/cpp/edit-episode/manage/${row?.id}`}
            className=" theme-bg-color"
          >
            <span>
              <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <g>
                  <path
                    className="cls-1"
                    style={{
                      fill: "none",
                      stroke: "currentColor",
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeWidth: "0.75px",
                    }}
                    d="M10.3,18.5l-4.74.64A.49.49,0,0,1,5,18.59l.64-4.74a.42.42,0,0,1,.14-.28L17.31,2a.59.59,0,0,1,.85,0L22.11,6a.6.6,0,0,1,0,.84L10.58,18.36A.53.53,0,0,1,10.3,18.5Z"
                  />
                  <line
                    className="cls-1"
                    style={{
                      fill: "none",
                      stroke: "currentColor",
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeWidth: "0.75px",
                    }}
                    x1="1.01"
                    y1="21.58"
                    x2="18.89"
                    y2="21.58"
                  />
                </g>
              </svg>
              <span className="ms-2  theme-text-color">Edit Episode</span>
            </span>
          </Link>

          <div
            onClick={() => openModalSingleDeleteEpisode(row?.id)}
            className="commonActionPadding theme-bg-color"
          >
            <svg
              id="Layer_1"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <g>
                <rect
                  className="cls-1"
                  style={{
                    fill: "none",
                    stroke: "currentColor",
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "0.75px",
                  }}
                  x="4.38"
                  y="5.54"
                  width="14.49"
                  height="17.71"
                />
                <line
                  className="cls-1"
                  style={{
                    fill: "none",
                    stroke: "currentColor",
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "0.75px",
                  }}
                  x1="1.16"
                  y1="5.54"
                  x2="22.09"
                  y2="5.54"
                />
                <g>
                  <line
                    className="cls-1"
                    style={{
                      fill: "none",
                      stroke: "currentColor",
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeWidth: "0.75px",
                    }}
                    x1="9.21"
                    y1="9.56"
                    x2="9.21"
                    y2="19.06"
                  />
                  <line
                    className="cls-1"
                    style={{
                      fill: "none",
                      stroke: "currentColor",
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeWidth: "0.75px",
                    }}
                    x1="14.04"
                    y1="9.56"
                    x2="14.04"
                    y2="19.06"
                  />
                </g>
                <path
                  className="cls-1"
                  style={{
                    fill: "none",
                    stroke: "currentColor",
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "0.75px",
                  }}
                  d="M7.45,5.22a2.72,2.72,0,0,1,0-.28,4.19,4.19,0,1,1,8.37,0c0,.09,0,.19,0,.29"
                />
              </g>
            </svg>
            <span className="ms-2 theme-text-color"> Delete Episode</span>
          </div>
        </div>
      </div>
    );
  };

  const sliderFormatter = (data, row) => {
    return (
      <div>
        <label className="switch">
          <input
            name="banner"
            id="banner"
            className={data == 0 ? "inactive" : null}
            checked={data == 1}
            onChange={() => handleToggle(row)}
            onClick={() => handleToggle(row)}
            type="checkbox"
          />
          <span className="slider round"></span>
        </label>
      </div>
    );
  };

  const columns = [
    {
      dataField: "title",
      text: "EPISODE",
      sort: true,
      formatter: videoFormatter,
    },
    {
      dataField: "uploaded_by",
      text: "UPLOADED BY",
      sort: true,
    },
    {
      dataField: "active",
      text: "STATUS",
      formatter: statusFormatter,
    },
    {
      dataField: "banner",
      text: "SLIDER",
      formatter: sliderFormatter,
    },
    {
      dataField: "ACTIONS",
      text: "ACTION",
      formatter: actionFormatter,
    },
  ];

  const exportHeadersEpisode = [
    { label: "ID", key: "id" },
    { label: "EPISODE", key: "title" },
    { label: "UPLOADED BY", key: "uploaded_by" },
    { label: "STATUS", key: "active" },
    { label: "SLIDER", key: "banner" },
    { label: "UPLOAD ON", key: "upload_on" },
  ];

  const conditionalGeneratePDFEpisode = (get) => {
    const doc = new jsPDF();
    doc.text("ALL Episode LIST ", 10, 10);
    const columns = [
      "Index",
      "ID",
      "EPISODE",
      "UPLOADED BY",
      "STATUS",
      "BANNER",
      "UPLOAD ON",
    ];
    const allDataPDF = getEpisodeData?.map((item, index) => [
      index + 1,
      item?.id,
      item?.title,
      item?.uploaded_by,
      item?.active,
      item?.banner,
      item?.upload_on,
    ]);
    const filterPDF = filteredEpisodeData?.map((item, index) => [
      index + 1,
      item?.id,
      item?.title,
      item?.uploaded_by,
      item?.active,
      item?.banner,
      item?.upload_on,
    ]);

    doc.autoTable({
      startY: 20,
      head: [columns],
      body: get == 1 ? allDataPDF : filterPDF,
    });
    doc.save("ALL-Episode-LIST.pdf");
  };

  const generateXLSXEpisode = (get) => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(
        get == 1 ? getEpisodeData : filteredEpisodeData
      );
    XLSX.utils.book_append_sheet(wb, ws, "MySheet");
    XLSX.writeFile(wb, "ALL-Episode-LIST.xlsx");
  };

  const handleFilterChangeEpisode = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setFilter(searchTerm);
    const filteredResults = getEpisodeData.filter((item) =>
      ["id", "title", "uploaded_by", "active", "upload_on"].some(
        (property) =>
          typeof item[property] == "string" &&
          item[property].toLowerCase().includes(searchTerm)
      )
    );
    setFilteredEpisodeData(filteredResults);
  };

  const handleClear = () => {
    fetchData();
    setFilter("");
  };

  const handleAll = () => {
    setSelectAll(!selectAll);
    setItemToDeleteEpisode(
      selectAll ? [] : filteredEpisodeData?.map((item) => item?.id)
    );
  };

  const closeEpisodeModal = () => {
    setModalCloseEpisode(!modalCloseEpisode);
  };

  const openModalSingleDeleteEpisode = (id) => {
    setItemToDeleteEpisode([id]);
    setModalCloseEpisode(true);
  };

  const openModalMultiDeleteEpisode = () => {
    setModalCloseEpisode(true);
  };

  const handleDeleteEpisodeData = () => {
    closeEpisodeModal();
    EpisodeDeleteOperation();
  };

  const EpisodeDeleteOperation = async () => {
    setProcessing(true);
    setShowOverlay(true);
    const formData = new FormData();
    formData.append("id", "[" + itemToDeleteEpisode + "]");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/series-Episode/multi-delete`,
        formData,
        {
          headers: headers,
        }
      );
      const result = response.data;
      if (result.status == true) {
        // props.setApiresponsealert(result);
        // props.setShowalert(true);
        setProcessing(false);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err.response.data.message;
      // props.setApiresponsealert({ status: false, message: errorMessage });
      // props.setShowalert(true);
      setProcessing(false);
      setShowOverlay(false);
    }
  };

  const steps = [
    {
      label: "Select campaign settings",
      description: (
        <>
          <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3 mt-3">
            <h5 className="mb-2 theme-text-color">Upload Episode </h5>
            <Dropzone
              accept="video/*"
              onDrop={(acceptedFiles) => handleUpload(acceptedFiles)}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p className="draganddrop theme-text-color theme-border-color">
                    Drag and drop video files here, or click to select files
                  </p>
                </div>
              )}
            </Dropzone>
            <div className="text-center">
              {uploadProgress > 0 && (
                <div>
                  <p className=" theme-text-color">
                    Uploading... {uploadProgress}%
                  </p>
                  <progress value={uploadProgress} max="100" />
                </div>
              )}

              {uploadCompleted && (
                <div>
                  {uploadedFiles.length > 0 && (
                    <div className="text-center">
                      <p className=" theme-text-color">Uploaded Files:</p>
                      <ul>
                        {uploadedFiles.map((file, index) => (
                          <li key={index} className=" theme-text-color">
                            {file.name} - {file.size} MB
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleNext();
                    }}
                    className="btn button-color button-bg-color admin-button-text"
                  >
                    Proceed to Next
                  </Button>
                </div>
              )}

              {/* {uploadCompleted && (
              <div className="mt-4 text-center">
                <Button
                  variant="contained"
                  onClick={() => {
                    handleNext();
                  }}
                  className="btn button-color button-bg-color admin-button-text"
                >
                  Proceed to Next
                </Button>
              </div>
            )} */}
            </div>
          </div>

          <div
            className="modal fade"
            id="exampleModalEpisode"
            tabindex="-1"
            aria-labelledby="exampleModalLabelEpisode"
            aria-hidden="true"
          >
            <div className="modal-dialog delete_confirm">
              <div className="modal-content text-center">
                <div className="modal-body">
                  {isConfirmationOpen && (
                    <div>
                      <h5
                        className="modal-title p-3"
                        id="exampleModalLabelEpisode"
                      >
                        <p className="fs-6 theme-text-color">
                          Are you sure you want to delete the selected items?
                        </p>
                      </h5>
                      <div className="col-lg-12 row p-4">
                        <div className="col-lg-6">
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              handleConfirmDelete();
                            }}
                          >
                            Confirm
                          </button>
                        </div>
                        <div className="col-lg-6">
                          <button
                            className="btn btn-primary"
                            onClick={handleCancelDelete}
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  {isSuccessMessageOpen && (
                    <div className="success-message">
                      <div className="p-5">
                        <i className="fa fa-check" aria-hidden="true"></i>
                      </div>
                      <p className="fs-6 fw-bold theme-text-color">
                        Selected items have been successfully deleted.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="banner-slider">
            {showModal && (
              <div
                className="modal"
                tabIndex="-1"
                role="dialog"
                style={{ display: "block" }}
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content theme-bg-color global-shadow">
                    <div className="modal-header">
                      <h5 className="modal-title theme-text-color">Slider </h5>
                      <button
                        type="button"
                        className="btn-close"
                        onClick={closeModalslider}
                      ></button>
                    </div>
                    <div className="modal-body theme-text-color">
                      Are you sure you want to Switch the Slider status?
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={closeModalslider}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={confirmToggle}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div
            className={`modal ${itemToDelete !== null ? "show" : ""}`}
            tabIndex="-1"
            style={{ display: itemToDelete !== null ? "block" : "none" }}
          >
            <div className="modal-dialog">
              <div className="modal-content theme-bg-color global-shadow">
                <div className="modal-header">
                  <h5 className="modal-title theme-text-color">
                    Confirm Deletion
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={closeModal}
                  ></button>
                </div>
                <div className="modal-body">
                  {successMessage ? (
                    <div className="alert alert-success" role="alert">
                      {successMessage}
                    </div>
                  ) : (
                    <p className=" theme-text-color">
                      Are you sure you want to delete this Series?
                    </p>
                  )}
                </div>
                <div className="modal-footer">
                  {successMessage ? (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={closeModal}
                    >
                      Close
                    </button>
                  ) : (
                    <>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={handleDeletedata}
                      >
                        Yes
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="panel-body mt-3">
              <div id="nestable" className="nested-list dd with-margins">
                <>
                  <div
                    className={`modal  packageModel ${modalCloseEpisode ? "show" : ""
                      }`}
                    tabIndex="-1"
                    style={{ display: modalCloseEpisode ? "block" : "none" }}
                  >
                    <div className="modal-dialog">
                      <div className="modal-content theme-bg-color global-shadow">
                        <div className="modal-header">
                          <h5 className="modal-title">Confirm Deletion</h5>
                          <button
                            type="button"
                            className="btn-close"
                            onClick={closeEpisodeModal}
                          ></button>
                        </div>
                        <div className="modal-body">
                          {itemToDeleteEpisode?.length == 1 ? (
                            <p className="fs-6 theme-text-color">
                              {" "}
                              Are you sure you want to delete this Episode ?
                            </p>
                          ) : (
                            <p className="fs-6 theme-text-color">
                              Are you sure you want to delete the selected
                              Episodes ?
                            </p>
                          )}
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={closeEpisodeModal}
                          >
                            Close
                          </button>

                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={handleDeleteEpisodeData}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`modal packageModel ${showModal ? "show fade" : ""
                      }`}
                    tabIndex="-1"
                    role="dialog"
                    style={{ display: `${showModal ? "block" : "none"}` }}
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content theme-bg-color global-shadow">
                        <div className="modal-header">
                          <h5 className="modal-title theme-text-color">
                            Slider{" "}
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            onClick={closeModalslider}
                          ></button>
                        </div>
                        <div className="modal-body theme-text-color">
                          Are you sure you want to Switch the Slider status?
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={closeModalslider}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={confirmToggle}
                          >
                            Yes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>{showOverlay && <ResponseLoader />}</div>

                  <section className="section pt-1  theme-bg-color-secondary  pt-2 all-video-stream ">
                    <div id="content-page" className="content-page">
                      <div className="row pb-1 admin-section-title">
                        <div
                          className="col-md-12 col-12 mt-1 mt-md-0"
                          align="right"
                        >
                          {itemToDeleteEpisode?.length > 0 && (
                            <button
                              className="btn btn-danger d-inline-flex  fw-bolder "
                              onClick={openModalMultiDeleteEpisode}
                            >
                              <MdDelete className="exportIcon " />
                              Delete
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3 mt-3">
                        <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 gap-3">
                          <div className="filterContainer">
                            <input
                              type="text"
                              placeholder="Filter all.."
                              className="filter form-control "
                              value={filter}
                              onChange={handleFilterChangeEpisode}
                            />
                            {filter?.length > 0 && (
                              <button
                                type="button"
                                className="closeBtn"
                                onClick={handleClear}
                              >
                                <i className="fa fa-times"></i>
                              </button>
                            )}
                          </div>
                          <div className="btn-group">
                            <div className="dropdown d-block p-2" key={"2"}>
                              <button
                                className="btn btn-success dropdown-toggle d-flex"
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <span className="fw-bolder">Export </span>
                                <img
                                  src={ExportIcon}
                                  className="ms-2 exportIcon"
                                />
                              </button>
                              <ul
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton1"
                              >
                                <li className="dropdown-item">
                                  <CSVLink
                                    headers={exportHeadersEpisode}
                                    data={filteredEpisodeData}
                                    className="text-dark d-block"
                                    filename="Episode-FILTERED-LIST.csv"
                                  >
                                    CSV
                                  </CSVLink>
                                </li>
                                <li
                                  className="dropdown-item"
                                  onClick={() => {
                                    conditionalGeneratePDFEpisode(0);
                                  }}
                                >
                                  PDF
                                </li>
                                <li
                                  className="dropdown-item"
                                  onClick={() => {
                                    generateXLSXEpisode(0);
                                  }}
                                >
                                  XLSX
                                </li>
                              </ul>
                            </div>

                            <div className="dropdown d-block p-2">
                              <button
                                className="btn btn-success dropdown-toggle d-flex"
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <span className="fw-bolder">Export All</span>
                                <img
                                  src={ExportIcon}
                                  className="ms-2 exportIcon"
                                />
                              </button>
                              <ul
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton1"
                              >
                                <li className="dropdown-item">
                                  <CSVLink
                                    headers={exportHeadersEpisode}
                                    data={getEpisodeData}
                                    className="text-dark d-block"
                                    filename="ALL-EpisodeS-LIST.csv"
                                  >
                                    CSV
                                  </CSVLink>
                                </li>
                                <li
                                  className="dropdown-item"
                                  onClick={() => {
                                    conditionalGeneratePDFEpisode(1);
                                  }}
                                >
                                  PDF
                                </li>
                                <li
                                  className="dropdown-item"
                                  onClick={() => {
                                    generateXLSXEpisode(1);
                                  }}
                                >
                                  XLSX
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="bootstrapTable theme-bg-color">
                          <BootstrapTable
                            keyField="id"
                            columns={columns}
                            data={filteredEpisodeData}
                            pagination={pagination}
                            className="no-border-table theme-text-color"
                            noDataIndication={
                              <div className="noRecord">
                                {loading ? (
                                  <label>
                                    {" "}
                                    <img
                                      src={loadings}
                                      width={30}
                                      height={30}
                                      alt="Animated GIF"
                                    />
                                    Loading...
                                  </label>
                                ) : (
                                  filteredEpisodeData?.length <= 0 && (
                                    <p className=" theme-text-color">
                                      No Episode Streams Available. !!!
                                    </p>
                                  )
                                )}
                              </div>
                            }
                            hover
                            headerClasses="videoWidth"
                            selectRow={selectRow}
                            selectAll={selectAll}
                          />
                        </div>
                      </div>
                    </div>
                  </section>
                </>
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      label: "Create an ad group",
      description: (
        <div className="iq-card-body ">
          <section className="">
            <div className="col-lg-12 row py-3">
              <div className="col-6 col-sm-6 col-lg-6">
                <h3 className=" theme-text-color">Add New Episode</h3>
              </div>
              <div className="col-6 col-sm-6 col-lg-6">
                <div className="text-end">
                  <button onClick={Manage_Episode} className="btn btn-primary">
                    Save Episode
                  </button>
                </div>
              </div>
            </div>

            <div>{showOverlay && <ResponseLoader />}</div>

            <div className="row col-lg-12 ">
              <div className="col-6 col-sm-6 col-md-6 col-lg-6 ">
                <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                  <h5 className="theme-text-color">Create Episode</h5>
                  <hr className=" theme-text-color"></hr>
                  <div className=" text-start">
                    <label className="m-0 theme-text-color">
                      Title<span className="mandatory">*</span>
                    </label>
                    <p className="t-1">
                      Add the episodes title in the textbox below
                    </p>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="title"
                        name="title"
                        className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                        onChange={handleInput}
                        value={editUser?.title}
                        onKeyDown={handleInputKeyDowntitle}
                        placeholder="Title"
                        onBlur={handleBlurtitle}
                        ref={titleInputRef}
                      />
                    </div>
                    <span className="errorred">
                      {!isValidationHiddentitle && (
                        <p>{validationMessagetitle}</p>
                      )}
                    </span>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="m-0 theme-text-color">Slug</label>
                    <p className="t-1 theme-text-color">
                      Add the episodes slug in the textbox below
                    </p>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="slug"
                        name="slug"
                        className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                        value={autoFillValuetitle}
                        onChange={handleAutoFillChangetitle}
                        placeholder="Episode Slug"
                      />
                    </div>
                  </div>
                  {/* <div className="mt-2 text-start">
                  <label className="m-0">Episode Description</label>
                  <p className="t-1">
                  Add a description of the Episode below:
                  </p>
                  <div className="mt-2">
                    <textarea
                      className="rs-input short-description"
                      id="description"
                      name="description"
                      onChange={(e) => setDescription(e.target.value)}
                      placeholder="Description"
                    />
                  </div>
                </div> */}
                  <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0 theme-text-color">
                        Episode Description
                      </label>
                      <div className="mt-2">
                        <JoditEditor
                          className="rs-input  theme-bg-color-secondary  theme-text-color border-0"
                          value={description}
                          onChange={(newContent) =>
                            contentFieldChanaged(newContent)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2 text-start">
                    <div className="col-12">
                      <label className="m-0 theme-text-color">Duration</label>
                      <p className="t-1 theme-text-color">
                        Enter the episode duration in the following format
                        (Hours : Minutes : Seconds)
                      </p>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="duration"
                          maxLength="8"
                          className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                          onChange={handleInputdata}
                          value={editUser?.duration}
                          placeholder="duration"
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="row mt-2 text-start">
                  <div className="col-12">
                    <label className="m-0">Year</label>
                    <p className="t-1">Enter The episode Year</p>
                    <div className="mt-2">
                      <input
                        type="number"
                        name="number"
                        value={inputValueyear}
                        onChange={handleChangeyear}
                       className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                        placeholder="Year"
                      />
                    </div>
                  </div>
                </div> */}
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
                  <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                    <h5 className=" theme-text-color">Organize</h5>
                    <hr className="theme-text-color"></hr>

                    <div className=" text-start">
                      <label className="m-0 theme-text-color">
                        Episode Ratings
                      </label>
                      <p className="t-1 theme-text-color">
                        IMDb Ratings 10 out of 10
                      </p>
                      <div className="mt-2">
                        <select
                          className="form-select   theme-bg-color-secondary  theme-text-color border-0"
                          id="rating"
                          name="rating"
                          size="lg"
                          onChange={(e) => setRating(e.target.value)}
                        >
                          <option value="0">0</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                      </div>
                    </div>

                    {/* <div className=" mt-2 text-start">
                    <label className="m-0">Age Restrict</label>
                    <p className="t-1">Select A episode Age Below</p>
                    <div className="mt-2">
                      <select
                        className="form-select   theme-bg-color-secondary  theme-text-color border-0"
                        id="age_restrict"
                        name="age_restrict"
                        size="lg"
                        onChange={(e) => setAge_restrict(e.target.value)}
                      >
                        <option value="">
                          Choose an Age
                        </option>
                        {age_restrictdata.map((item) => (
                          <option selected value={item.id}>
                            {item.slug}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div> */}

                    {/* <div className=" text-start mt-2">
                    <label className="m-0">Artists</label>
                    <p className="t-1">Select A episode Artist Below:</p>

                    <div className="mt-2">
                      <Select
                        options={artists?.map((option) => ({
                          value: option?.id,
                          label: option?.name,
                        }))}
                        className=""
                        isMulti
                        value={selectedOptionsartists}
                        onChange={handleSelectChangeartists}
                      />
                    </div>
                  </div> */}
                    {/* <div className=" text-start mt-2">
                    <label className="m-0">Category</label>
                    <p className="t-1">
                      Select A episode Category Below:
                    </p>

                    <div className="mt-2">
                      <Select
                        options={category?.map((option) => ({
                          value: option?.id,
                          label: option?.name,
                        }))}
                        className=""
                        isMulti
                        value={selectedOptionscategory}
                        onChange={handleSelectChangecategory}
                      />
                    </div>
                  </div> */}

                    {/* <div className=" text-start mt-2">
                    <label className="m-0">Language</label>
                    <p className="t-1">
                      Select A episode Language Below:
                    </p>
                    <div className="text-dark">
                      <Select
                        options={language?.map((option) => ({
                          value: option?.id,
                          label: option?.name,
                        }))}
                        className=""
                        isMulti
                        value={selectedOptionslanguage}
                        onChange={handleSelectChangelanguage}
                      />
                    </div>
                  </div> */}

                    <div className=" text-start mt-2">
                      <label className="m-0 theme-text-color">
                        Block Country
                      </label>
                      <p className="t-1 theme-text-color">
                        Select A episode country Below
                      </p>
                      <div className="text-dark">
                        <Select
                          options={block_country?.map((option) => ({
                            value: option?.id,
                            label: option?.country_name,
                          }))}
                          className="  theme-bg-color-secondary  theme-text-color"
                          isMulti
                          value={selectedOptions}
                          onChange={handleSelectChange}
                        />
                      </div>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="m-0 theme-text-color">
                        Available Country
                      </label>
                      <p className="t-1 theme-text-color">
                        Block the Audio for Selected Country
                      </p>
                      <div className="text-dark">
                        <Select
                          options={available_country?.map((option) => ({
                            value: option?.id,
                            label: option?.country_name,
                          }))}
                          className="  theme-bg-color-secondary  theme-text-color"
                          isMulti
                          value={selectedOptionsAvailable_country}
                          onChange={handleSelectChangeAvailable_country}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                  <h5 className=" theme-text-color">Search Tags</h5>
                  <hr className="theme-text-color"></hr>

                  <div className="col-sm-12">
                    <p className="p2 theme-text-color">
                      You don't have any search keywords.
                    </p>

                    <div className="mt-2">
                      <TagsInput
                        value={searchTags}
                        className="rs-input"
                        onChange={handleTagsChange}
                        name="search_tags"
                      />
                    </div>
                  </div>
                </div>

                <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                  <h5 className=" theme-text-color">SEO</h5>
                  <hr className="theme-text-color"></hr>

                  <div className="col-sm-12">
                    <label className="m-0 theme-text-color">
                      Website Page Title
                    </label>

                    <div className=" mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                        onChange={(e) => setWebsitetitle(e.target.value)}
                        placeholder=""
                      />
                    </div>
                  </div>

                  <div className="col-sm-12 mt-2">
                    <label className="m-0 theme-text-color">Website URL</label>

                    <div className=" mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                        onChange={(e) => setWebsiteurl(e.target.value)}
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 mt-2">
                    <label className="m-0 theme-text-color">
                      Meta Description
                    </label>

                    <div className=" mt-2">
                      <textarea
                        type="text"
                        className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                        onChange={(e) => setMetadescription(e.target.value)}
                        placeholder=""
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div className="text-start mb-3">
                  <button onClick={Manage_Episode} className="btn btn-primary">
                    Save Episode
                  </button>
                </div>
              </div>

              {/* Second Part of Section  */}

              <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                  <h5 className=" theme-text-color">Thumbnails</h5>
                  <hr className="theme-text-color"></hr>

                  <div className=" text-start mt-2">
                    <label className="m-0 theme-text-color">
                      Episode Image Cover
                    </label>
                    <p className="t-1 theme-text-color">
                      Select the episodes image (1080 X 1920px or 9:16 ratio)
                    </p>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef1.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef1}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef1,
                                  setSelectedFile1
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label className=" theme-text-color">
                              <CameraRetroIcon className=" theme-text-color" />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile1 && (
                              <div className="imagedropcopy text-end">
                                <img
                                  src={URL.createObjectURL(selectedFile1)}
                                  alt="Uploaded"
                                  width="150"
                                  height="100"
                                />
                                <button
                                  onClick={() => handleDelete(setSelectedFile1)}
                                  type="button"
                                  className="btn-close theme-text-color"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>

                                {/* <button onClick={() => handleDelete(setSelectedFile1)}>Delete</button> */}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0 theme-text-color">
                      Episode Player Image
                    </label>
                    <p className="t-1 theme-text-color">
                      Select the player image ( 1280 X 720px or 16:9 Ratio )
                    </p>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef2.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef2}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef2,
                                  setSelectedFile2
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label className=" theme-text-color">
                              <CameraRetroIcon className=" theme-text-color" />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile2 && (
                              <div className="imagedropcopy text-end">
                                <img
                                  src={URL.createObjectURL(selectedFile2)}
                                  alt="Uploaded"
                                  width="150"
                                  height="100"
                                />
                                <button
                                  onClick={() => handleDelete(setSelectedFile2)}
                                  type="button"
                                  className="btn-close theme-text-color"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="m-0 theme-text-color">
                      Episode TV Image
                    </label>
                    <p className="t-1 theme-text-color">
                      Select the player image ( 16:9 Ratio or 1920 X 1080 px)
                    </p>
                    <div className="mt-2">
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop"
                            onClick={() => fileInputRef3.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef3}
                              onChange={(event) =>
                                handleFileChange(
                                  event,
                                  fileInputRef3,
                                  setSelectedFile3
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label className=" theme-text-color">
                              <CameraRetroIcon className=" theme-text-color" />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile3 && (
                              <div className="imagedropcopy text-end">
                                <img
                                  src={URL.createObjectURL(selectedFile3)}
                                  alt="Uploaded"
                                  width="150"
                                  height="100"
                                />
                                <button
                                  onClick={() => handleDelete(setSelectedFile3)}
                                  type="button"
                                  className="btn-close theme-text-color"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
                  <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                    <h5 className=" theme-text-color">
                      Intro Time <small>(Optional)</small>
                    </h5>
                    <hr className="theme-text-color"></hr>

                    <div className="col-lg-12 row">
                      <div className="col-lg-6">
                        <div className="text-start">
                          <label className="m-0 theme-text-color">
                            Skip Start Time{" "}
                            <small>(Please Give In Seconds)</small>
                          </label>

                          <div className="panel-body">
                            <input
                              type="text"
                              value={userTime?.skip_start_time}
                              onChange={handleInputintrotime}
                              placeholder="HH:MM:SS"
                              maxLength="8"
                              className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                              name="skip_start_time"
                              id="skip_start_time"
                            />
                          </div>
                          <div>
                            <span className="intro_time_error">
                              {errors?.error_skip_start_time}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="text-start">
                          <label className="m-0 theme-text-color">
                            Recap Start Time{" "}
                            <small>(Please Give In Seconds)</small>
                          </label>

                          <div className="panel-body">
                            <input
                              type="text"
                              value={userTime?.recap_start_time}
                              onChange={handleInputintrotime}
                              placeholder="HH:MM:SS"
                              maxLength="8"
                              className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                              name="recap_start_time"
                              id="recap_start_time"
                            />
                          </div>
                          <div>
                            <span className="intro_time_error">
                              {errors?.error_recap_start_time}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 row mt-2">
                      <div className="col-lg-6">
                        {" "}
                        <div className="text-start">
                          <label className="m-0 theme-text-color">
                            Skip End Time{" "}
                            <small>(Please Give In Seconds)</small>
                          </label>

                          <div className="panel-body">
                            <input
                              type="text"
                              value={userTime?.skip_end_time}
                              onChange={handleInputintrotime}
                              placeholder="HH:MM:SS"
                              maxLength="8"
                              className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                              name="skip_end_time"
                              id="skip_end_time"
                            />
                          </div>
                          <div>
                            <span className="intro_time_error">
                              {errors?.error_skip_end_time}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="text-start">
                          <label className="m-0 theme-text-color">
                            Recap End Time{" "}
                            <small>(Please Give In Seconds)</small>
                          </label>

                          <div className="panel-body">
                            <input
                              type="text"
                              maxLength="8"
                              value={userTime?.recap_end_time}
                              onChange={handleInputintrotime}
                              placeholder="HH:MM:SS"
                              className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                              name="recap_end_time"
                              id="recap_end_time"
                            />
                          </div>
                          <div>
                            <span className="intro_time_error">
                              {errors?.error_recap_end_time}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 row mt-2">
                      <div className="col-lg-6">
                        <div className="text-start">
                          <label className="m-0 theme-text-color">
                            Skip Start Session{" "}
                            <small>(Please Give In Seconds)</small>
                          </label>

                          <div className="panel-body">
                            <input
                              type="text"
                              maxLength="8"
                              value={userTime?.skip_start_session}
                              onChange={handleInputintrotime}
                              placeholder="HH:MM:SS"
                              className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                              name="skip_start_session"
                              id="skip_start_session"
                            />
                          </div>
                          <div>
                            <span className="intro_time_error">
                              {errors?.error_skip_start_session}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="text-start">
                          <label className="m-0 theme-text-color">
                            Recap Start Session{" "}
                            <small>(Please Give In Seconds)</small>
                          </label>

                          <div className="panel-body">
                            <input
                              type="text"
                              maxLength="8"
                              value={userTime?.recap_start_session}
                              onChange={handleInputintrotime}
                              placeholder="HH:MM:SS"
                              className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                              name="recap_start_session"
                              id="recap_start_session"
                            />
                          </div>
                          <div>
                            <span className="intro_time_error">
                              {errors?.error_recap_start_session}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                    <h5 className=" theme-text-color">Access</h5>
                    <hr className="theme-text-color"></hr>

                    <div className="col-sm-12">
                      <label className="m-0 theme-text-color">
                        User Access
                      </label>
                      <p className="p1 theme-text-color">
                        Who Is Allowed To View This episode ?
                      </p>
                      <select
                        onChange={(e) =>
                          setStoreEpisodeuseraccess(e.target.value)
                        }
                        className="form-select   theme-bg-color-secondary  theme-text-color border-0"
                        id="access"
                        name="access"
                        onClick={(event) => {
                          setallEpisodeuseraccess(event.target.value);
                        }}
                      >
                        {/* <option selected value="">
                        Choose an User Access
                      </option> */}
                        {user_access?.map((item, key) => (
                          <option value={item?.role}>{item?.name}</option>
                        ))}
                      </select>
                    </div>

                    <div>{allEpisode_streamuseraccess()}</div>
                  </div>

                  {/* <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                  <h5 className=" theme-text-color">Choose Ads Settings</h5>
                  <hr className="theme-text-color"></hr>

                  <div className="col-sm-12 mt-2">
                    <label className="m-0 theme-text-color">Choose Ads Position</label>

                    <div className=" mt-2">
                      <Select
                        options={adsPositions}
                        className="rs-input"
                        onChange={(e) => handleAdsPositionChange(e)}
                      />
                    </div>
                  </div>

                  <div className="col-sm-12 mt-2">
                    <label className="m-0">Choose Advertisement</label>

                    <div className=" mt-2">
                      <Select
                        options={secondSelectOptions}
                        className="rs-input"
                        onChange={(e) => setSecondSelectOptions(e)}
                      />
                    </div>
                  </div>
                </div> */}
                </div>

                <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                  <h5 className=" theme-text-color">Free Duration</h5>
                  <hr className=" theme-text-color"></hr>

                  <div className="col-sm-12">
                    <div className="col-sm-12 row">
                      <div className="col-9 col-sm-9 col-lg-9">
                        <p className="p2 theme-text-color">
                          Enable Free Duration Status
                        </p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3">
                        <label className="switch">
                          <input
                            name="free_duration_status"
                            id="free_duration_status"
                            onChange={handleInput}
                            defaultChecked={
                              free_duration_status?.free_duration_status == 1
                                ? true
                                : false
                            }
                            checked={
                              free_duration_status?.free_duration_status == 1
                                ? true
                                : false
                            }
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            id="free_duration_status"
                            name="free_duration_status"
                            onChange={handleInput}
                            value={
                              free_duration_status?.free_duration_status == 1
                                ? "1"
                                : "0"
                            }
                          ></span>
                        </label>
                      </div>
                    </div>

                    <div className="row mt-2 text-start">
                      <div className="col-12">
                        <label className="m-0 theme-text-color">
                          Free Duration Time
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="free_duration_time"
                            className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                            maxLength="8"
                            placeholder="HH:MM:SS"
                            value={editUser?.free_duration_time}
                            onChange={handleInputdata}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                  <h5 className=" theme-text-color">Status Settings</h5>
                  <hr className="theme-text-color"></hr>

                  <div className="col-sm-12 row">
                    <div className="col-9 col-sm-9 col-lg-9">
                      <p className="p2 theme-text-color">
                        Is this episode Featured
                      </p>
                    </div>
                    <div className="col-3 col-sm-3 col-lg-3">
                      <label className="switch">
                        <input
                          name="featured"
                          onChange={handleInput}
                          className="rs-input"
                          defaultChecked={
                            featured?.featured == 1 ? true : false
                          }
                          checked={featured?.featured == 1 ? true : false}
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="featured"
                          onChange={handleInput}
                          value={featured?.featured == 1 ? "1" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div>

                  <div className="col-sm-12 row mt-2">
                    <div className="col-9 col-sm-9 col-lg-9">
                      <p className="p2 theme-text-color">
                        Is this episode Active
                      </p>
                    </div>
                    <div className="col-3 col-sm-3 col-lg-3">
                      <label className="switch">
                        <input
                          name="active"
                          onChange={handleInput}
                          defaultChecked={active?.active == 1 ? true : false}
                          checked={active?.active == 1 ? true : false}
                          className="rs-input"
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="active"
                          onChange={handleInput}
                          value={active?.active == 1 ? "1" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-12 row mt-2">
                    <div className="col-9 col-sm-9 col-lg-9">
                      <p className="p2 theme-text-color">
                        Is this episode display in Banner
                      </p>
                    </div>
                    <div className="col-3 col-sm-3 col-lg-3">
                      <label className="switch">
                        <input
                          name="banner"
                          onChange={handleInput}
                          className="r-input"
                          defaultChecked={banner?.banner == 1 ? true : false}
                          checked={banner?.banner == 1 ? true : false}
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          name="banner"
                          onChange={handleInput}
                          value={banner?.banner == 1 ? "1" : "0"}
                        ></span>
                      </label>
                    </div>
                  </div>
                  {/* <div className="col-sm-12 row mt-2">
                  <div className="col-9 col-sm-9 col-lg-9">
                    <p className="p2">Is this episode display in Footer</p>
                  </div>
                  <div className="col-3 col-sm-3 col-lg-3">
                    <label className="switch">
                      <input
                        name="footer"
                        id="footer"
                        onChange={handleInput}
                        className="r-input"
                        defaultChecked={footer.footer == 1 ? true : false}
                        checked={footer.footer == 1 ? true : false}
                        type="checkbox"
                      />
                      <span
                        className="slider round"
                        name="footer"
                        id="footer"
                        onChange={handleInput}
                        value={footer.footer == 1 ? "1" : "0"}
                      ></span>
                    </label>
                  </div>
                </div> */}
                </div>

                <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                  <h5 className=" theme-text-color">Subtitles (srt)</h5>
                  <hr className=" theme-text-color"></hr>
                  <div className="mt-0">
                    {Subtitlesmap?.map((item, index) => (
                      <div className="col-sm-12 mt-2" key={index}>
                        <label className=" theme-text-color">
                          {item.language}
                        </label>
                        <div className="file-draganddrop mt-2">
                          <Dropzone
                            onDrop={(acceptedFiles) =>
                              handleDropsubtitles(
                                item.short_code,
                                acceptedFiles
                              )
                            }
                            accept="video/*"
                            multiple={false}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div {...getRootProps()} className="dropzone">
                                <input {...getInputProps()} />
                                <p className="p-3 theme-text-color">
                                  Drag and drop subtitles videos here
                                </p>
                                {videosubtitles[item.short_code]?.length >
                                  0 && (
                                    <p className=" theme-text-color">
                                      {videosubtitles[item?.short_code].length}{" "}
                                      {videosubtitles[item?.short_code].length ==
                                        1
                                        ? "file"
                                        : "files"}{" "}
                                      selected
                                    </p>
                                  )}
                              </div>
                            )}
                          </Dropzone>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      ),
    },
  ];

  return (
    <div className=" m-0 p-0">
      <div className="allepisodechannel">
        <section className="section container-fluid   theme-bg-color-secondary  ">
          <div className="pt-3">
            <div className="row">
              <div className="col-sm-12">
                <div className="addaudio">
                  <Stepper activeStep={activeStep} orientation="horizontal">
                    {steps?.map((step, index) => (
                      <Step key={step?.label}>
                        <StepContent>
                          <Typography>{step?.description}</Typography>
                          <div>
                            <div className="text-end mt-3">
                              {/* <button onClick={handleNext} className='btn btn-primary' hidden={index === 1}>
                                Proceed to Next
                              </button> */}
                              {/* <button
                                hidden={index === 0} className='btn btn-primary'
                                onClick={handleBack}
                              >
                                Back
                              </button> */}
                            </div>
                          </div>
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
