// External Libraries and Packages
import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import HomePageNav from '../CommonComponents/HomePageNav'
import MusicBasedWriterCard from '../CommonComponents/MusicBasedWriterCard';
import { connect } from 'react-redux';

const MusicBasedWriter = ({ item, swiperOptions,languageDetailsData }) => {
    return (
        <div className='mb-4'>
            <HomePageNav link={item?.artist_slug} headerName={item?.artist_name} store={item?.Song_Writer} seg={`music/song-writer/`} />
            <Swiper {...swiperOptions} className='musicHomePageSwiper'>
                {item?.Song_Writer?.map((value, index) => (
                    <SwiperSlide key={value?.id} >
                        <MusicBasedWriterCard store={value} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}
const mapStateToProps = state => ({
    languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData
})
export default connect(mapStateToProps)(MusicBasedWriter)