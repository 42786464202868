import { connect } from 'react-redux';
import LazyLoadImage from '../Pages/HomePage/HomeSwiperComponents/LazyLoadImage'
import MessageBox from '../components/MessageBox';
import { useMyContext } from '../components/Layout/CommonProvider';
import defaultImage from '../assets/images/default_vertical_image.webp'
import { useEffect, useState } from 'react';
import { MdOutlineFullscreen } from "react-icons/md";
import PlayButton from '../Pages/Music/CommonComponents/PlayButton';


const MiniFloatPlayer = ({ currentAudio }) => {
    const { minimizePlayer } = useMyContext();

    const [position, setPosition] = useState({ x: 100, y: 100 });
    const [dragging, setDragging] = useState(false);
    const [rel, setRel] = useState({ x: 0, y: 0 });

    const handleMouseDown = (e) => {
        if (e.button !== 0) return;

        const pos = e.target.getBoundingClientRect();
        setRel({
            x: e.pageX - pos.left,
            y: e.pageY - pos.top
        });
        setDragging(true);
        e.stopPropagation();
        e.preventDefault();
    };

    const handleMouseUp = (e) => {
        setDragging(false);
        e.stopPropagation();
        e.preventDefault();
    };

    const handleMouseMove = (e) => {
        if (!dragging) return;

        const newX = e.pageX - rel.x;
        const newY = e.pageY - rel.y;

        const minX = 0;
        const minY = 0;
        const maxX = window.innerWidth - e.target.offsetWidth;
        const maxY = window.innerHeight - e.target.offsetHeight;

        setPosition({
            x: Math.min(Math.max(newX, minX), maxX),
            y: Math.min(Math.max(newY, minY), maxY)
        });

        e.stopPropagation();
        e.preventDefault();
    };

    useEffect(() => {
        const handleWindowMouseMove = (e) => handleMouseMove(e);
        const handleWindowMouseUp = (e) => handleMouseUp(e);

        if (dragging) {
            window.addEventListener('mousemove', handleWindowMouseMove);
            window.addEventListener('mouseup', handleWindowMouseUp);
        } else {
            window.removeEventListener('mousemove', handleWindowMouseMove);
            window.removeEventListener('mouseup', handleWindowMouseUp);
        }

        return () => {
            window.removeEventListener('mousemove', handleWindowMouseMove);
            window.removeEventListener('mouseup', handleWindowMouseUp);
        };
    }, [dragging]);

    return (
        <div className='position-fixed musicFloatPlayer p-2 rounded-2 custom-shadow bg-white' style={{ left: `${position.x}px`, top: `${position.y}px`, cursor: "grab" }}>
            <div className="d-flex " onMouseDown={handleMouseDown}>
                <div className='position-relative'>
                    <LazyLoadImage src={currentAudio?.image_url ? currentAudio?.image_url : currentAudio?.Thumbnail ? currentAudio?.Thumbnail : defaultImage} alt={currentAudio?.title} title={currentAudio?.title?.slice(0, 1)} imageSize="rounded-2 overflow-hidden miniPlayerImage object-fit-cover" classNameOptional="rounded-2 miniPlayerImage" />
                    <div className='musicFloatPlayerHeight' >
                        <PlayButton className="position-relative bg-transparent" type="AUDIO" store={currentAudio} />
                    </div>
                </div>
                <div className='d-flex align-items-center justify-content-between flex-column '>
                    <MessageBox text={currentAudio?.title} classname="text-start text-dark musicFloatPlayerTitle ps-2" />
                    <MdOutlineFullscreen className='text-dark align-self-end miniFloatPlayerMinimize' onClick={minimizePlayer} />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    currentAudio: state.get_current_Reducers.currentAudio,
    currentIndex: state.get_current_Reducers.currentIndex,
    playlistAudio: state.get_playlist_Reducer.playlistAudio,
    currentPlaying: state.get_current_Reducers.currentPlaying
});
export default connect(mapStateToProps)(MiniFloatPlayer)