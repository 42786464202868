import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { capitalizeFirstLetter } from '../Pages/CommonMethods/CommonMethods';
import { useLocation } from 'react-router-dom';
const HelmetDetails = ({ siteTheme, metaURL, metaTitle, metaDescription, metaImage, pageTitle, headerSetting, languageDetailsData }) => {
    const location = useLocation();
    const segments = location.pathname?.split('/');
    const lastSegment = segments.filter(segment => segment !== '').pop();
    let url = capitalizeFirstLetter(lastSegment?.replace(/\-/g, ' '));

    const navigateTranslateChecker = (path) => {
        if (siteTheme?.translate_checkout) {
            return `/${languageDetailsData?.Language_Code}${path}`
        }
        else {
            return `${path}`
        }
    }


    useEffect(() => {

        const URL = location.pathname == (siteTheme?.translate_checkout ? navigateTranslateChecker(`/`) : "/")
        const newTitle = URL ? pageTitle : url;
        document.title = newTitle;
    }, [pageTitle, languageDetailsData, url, location]);

    return (
        <Helmet>
            <meta name="description" content={metaDescription} />
            <meta property="og:description" content={metaDescription} />
            <meta property="og:url" content={pageTitle} />
            <meta property="og:title" content={metaTitle} />
            <meta property="og:image" content={metaImage} />
            <link rel="canonical" href={process.env.REACT_APP_API_PATHss} />
            <link rel="icon" type="image/x-icon" href={headerSetting?.favicon_url} sizes={`${headerSetting?.logo_height}x${headerSetting?.logo_width}`} />
        </Helmet>
    )
}
const mapStateToProps = state => ({
    headerSetting: state.get_headerIndex_Reducer.headerSetting,
    languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData,
    siteTheme: state.get_siteTheme_Reducer.siteTheme,
});
export default connect(mapStateToProps)(HelmetDetails)