import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store, persistor } from "./Store.js";
import { PersistGate } from "redux-persist/integration/react";
import PageLoader from "./components/Loader/PageLoader.jsx";
import { initializeI18n } from "./Utils/Translate.js";
import MyProvider from "./components/Layout/CommonProvider.js"
await initializeI18n()
ReactDOM.render(
  <Suspense fallback={<PageLoader />} >
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <BrowserRouter>
            <MyProvider>
              <App />
            </MyProvider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  </Suspense >,
  document.getElementById("root")
);
reportWebVitals();