import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { URLGenerater } from '../../../CommonMethods/CommonMethods';
import { MusicViewAllPageAction, getMusicHomePageAction } from '../../../../Redux/Actions/MusicAction';
import PageError from '../../../../components/PageError';
import MusicLayoutViewAll from '../../CommonComponents/MusicLayoutViewAll';
import EmptyLoaderStatic from '../../CommonComponents/EmptyLoaderStatic';
import { musicDeletePlaylist, musicStationDeleteApi } from '../../../../components/Api/MusicApi';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
const MusicFormatViewAll = ({ isLoading, viewAllData, musicHomePage, settings, error }) => {
    const { source } = useParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [response, setResponse] = useState({
        deleteResponse: null,
        homeListerror: ""
    })


    useEffect(() => {
        try {
            const dataList = musicHomePage.find((item) => URLGenerater(item?.source) == source);
            if (dataList) {
                const requestApi = dataList.Redirection_PageList_URL;
                if (settings && Object.keys(settings).length > 0) {
                    dispatch(MusicViewAllPageAction("AUDIO", requestApi));
                }
            } else {
                setResponse((prev) => ({
                    ...prev,
                    homeListerror: `No ${source} Found`
                }))
            }
        } catch (err) {
            setResponse((prev) => ({
                ...prev,
                homeListerror: `No ${source} Found`
            }))
        }
    }, [location, response]);

    useEffect(() => {
        if (response.deleteResponse !== null || response?.deleteResponse !== '') {
            dispatch(getMusicHomePageAction())
        }
    }, [response])

    const handleDeletePlayList = (deleteId) => {
        return axios.delete(`${musicDeletePlaylist}/${deleteId}`)
            .then((res) => {
                toast.success(t(res?.data?.message))
                setResponse((prev) => ({
                    ...prev,
                    deleteResponse: res?.data?.message
                }))
            })
            .catch((err) => {
                toast.error(t(err?.response?.data?.message))
            });
    }
    const handleDeleteStation = (deleteId) => {
        return axios.delete(`${musicStationDeleteApi}/${deleteId}`)
            .then((res) => {
                toast.success(t(res?.data?.message))
                setResponse((prev) => ({
                    ...prev,
                    deleteResponse: res?.data?.message
                }))
            })
            .catch((err) => {
                toast.error(t(err?.response?.data?.message))
            });
    }

    return response?.homeListerror ? <PageError error={response?.homeListerror} /> : isLoading ? <EmptyLoaderStatic /> : error ? <PageError /> : <MusicLayoutViewAll mapData={viewAllData} source={source} path='music' headingClass='pb-4 text-center' methodAction={source == "playlist" ? handleDeletePlayList : handleDeleteStation} />
}

const mapStateToProps = (state) => ({
    musicHomePage: state.get_Music_Home_Page_Reducer.musicHomePage,
    viewAllData: state.get_Music_ViewAll_Reducer.viewAllData,
    isLoading: state.get_Music_ViewAll_Reducer.isLoading,
    error: state.get_Music_ViewAll_Reducer.error,
    settings: state.get_setting_Reducer.settings,
});
export default connect(mapStateToProps)(MusicFormatViewAll)