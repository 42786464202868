import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import * as XLSX from "xlsx";
import { CSVLink } from "react-csv";
import { jsPDF } from "jspdf";
import {
  MdDelete,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import { FaCirclePlus } from "react-icons/fa6";
import { MdOutlineClose } from "react-icons/md";
import { BsThreeDotsVertical } from "react-icons/bs";
import { toast } from "react-toastify";
 
import deleteitem from "../../../assets/icons/deleteitem.svg";
import edititem from "../../../assets/icons/edititem.svg";
import loadings from "../../../assets/gif/loading.gif";
import defaultImage from "../../../assets/images/default_vertical_image.webp";
import ExportIcon from "../../../assets/icons/export-icon.webp";
import ResponseLoader from "../../../components/Loader/ResponseLoader";

function Content_AllArtist() {
  const [getArtist, setArtist] = useState([]);
  const [filteredArtistData, setFilteredArtistData] = useState([]);
  const [itemToDeleteArtist, setItemToDeleteArtist] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [modalCloseArtist, setModalCloseArtist] = useState(false);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);


  // let access_token, access_token_cpp;

  // if (localStorage.getItem("username")) {
  //   access_token = localStorage.getItem("access_token");
  // } else {
  //   access_token_cpp = localStorage.getItem("access_token_cpp");
  // }

  // const headers = {
  //   "Content-Type": "application/json",
  //   // Authorization: "Bearer " + access_token_cpp,
  //   Accept: "application/json",
  //   "Access-Control-Allow-Origin": "*",
  // };

  // if (access_token) {
  //   headers.Authorization = "Bearer " + access_token;
  // } else if (access_token_cpp) {
  //   headers.Authorization = "Bearer " + access_token_cpp;
  // }

  const access_token_cpp = localStorage.getItem("access_token_cpp");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token_cpp,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/artist/index`,
        { headers: headers }
      );
      const artistData = response?.data?.AllArtist;
      setArtist(artistData);
      setFilteredArtistData(artistData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(async () => {
    fetchData();
  }, []);
  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    nextPageText: "Next",
    prePageText: "Prev",
    firstPageText: <MdKeyboardDoubleArrowLeft />,
    lastPageText: <MdKeyboardDoubleArrowRight />,
    showTotal: true,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "All", value: filteredArtistData?.length },
    ],
  });
  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (row) {
        if (isSelect) {
          setItemToDeleteArtist([...itemToDeleteArtist, row?.id]);
        } else {
          setItemToDeleteArtist(
            itemToDeleteArtist.filter((id) => id !== row?.id)
          );
        }
      }
    },
    selected: itemToDeleteArtist,
    selectionHeaderRenderer: ({ mode, checked, indeterminate }) => (
      <div>
        <input
          type="checkbox"
          checked={checked}
          indeterminate={indeterminate}
          onChange={handleAll}
        />
      </div>
    ),
  };
  const imageFormatter = (data, row) => {
    return (
      <div className="d-flex align-items-center">
        <img
          src={row.image_url ? row.image_url : defaultImage}
          className="profile"
        />

        <div className="rightVideoTitle">
          <p className="theme-text-color">{data}</p>
          <span className="me-1 text-dark dateText theme-text-color">
            {" "}
            {row?.duration}
          </span>
          <span className="dateText">{row?.upload_on}</span>
        </div>
      </div>
    );
  };
  const actionFormatter = (data, row) => {
    return (
      <div className="editdropdown">
        <span className="editdropdown-button">
          <span>
            {" "}
            <BsThreeDotsVertical className=" theme-text-color" />
          </span>
        </span>
        <div className="editdropdown-menu  theme-bg-color">
          <Link to={`/edit-artist/${row?.id}`} className="theme-text-color  theme-bg-color">
            <span>
              <img src={edititem} alt="flogo" width={20} height={20} />
              <span className="ms-2 theme-text-color "> Edit Artist </span>
            </span>
          </Link>

          <div
            onClick={() => openModalSingleDeleteArtist(row?.id)}
            className="commonActionPadding theme-bg-color"
          >
            <img src={deleteitem} alt="flogo" width={20} height={20} />
            <span className="ms-2 theme-text-color ">Delete Artist</span>
          </div>
        </div>
      </div>
    );
  };
  const columns = [
    {
      dataField: "artist_name",
      text: "Artists",
      formatter: imageFormatter,
      sort: true,
    },
    {
      dataField: "artist_type",
      text: "Artists Type ",
      sort: true,
    },
    {
      dataField: "ACTIONS",
      text: "Actions",
      formatter: actionFormatter,
    },
  ];
  const exportHeadersArtist = [
    { label: "ID", key: "id" },
    { label: "ARTISTS", key: "artist_name" },
    { label: "ARTISTS TYPE ", key: "artist_type" },
    { label: "UPLOADED BY", key: "uploaded_by" },
    { label: "UPLOAD-ON", key: "upload_on" },
  ];

  const conditionalGeneratePDFArtist = (get) => {
    const doc = new jsPDF();
    doc.text("ARTISTS LIST ", 10, 10);
    const columns = ["INDEX", "ID", "ARTISTS", "ARTISTS TYPE ", "UPLOAD-ON"];
    const allDataPDF = getArtist?.map((item, index) => [
      index + 1,
      item?.id,
      item?.artist_name,
      item?.artist_type,
      item?.upload_on,
    ]);
    const filterPDF = filteredArtistData?.map((item, index) => [
      index + 1,
      item?.id,
      item?.artist_name,
      item?.artist_type,
      item?.upload_on,
    ]);

    doc.autoTable({
      startY: 20,
      head: [columns],
      body: get == 1 ? allDataPDF : filterPDF,
    });
    doc.save("ARTISTS-LIST.pdf");
  };
  const generateXLSXARTIST = (get) => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(get === 1 ? getArtist : filteredArtistData);
    XLSX.utils.book_append_sheet(wb, ws, "MySheet");
    XLSX.writeFile(wb, "ARTISTS-LIST.xlsx");
  };

  const handleFilterChangeArtist = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setFilter(searchTerm);
    const filteredResults = getArtist.filter((item) =>
      ["artist_name", "artist_type", "uploaded_by", "upload_on"].some(
        (property) =>
          typeof item[property] === "string" &&
          item[property].toLowerCase().includes(searchTerm)
      )
    );
    setFilteredArtistData(filteredResults);
  };

  const handleClear = () => {
    fetchData();
    setFilter("");
  };
  const handleAll = () => {
    setSelectAll(!selectAll);
    setItemToDeleteArtist(
      selectAll ? [] : filteredArtistData?.map((item) => item?.id)
    );
  };

  const closeArtistModal = () => {
    setModalCloseArtist(!modalCloseArtist);
  };
  const openModalSingleDeleteArtist = (id) => {
    setItemToDeleteArtist([id]);
    setModalCloseArtist(true);
  };
  const openModalMultiDeleteArtist = () => {
    setModalCloseArtist(true);
  };
  const handleDeleteArtistData = () => {
    closeArtistModal();
    artistDeleteOperation();
  };
  const artistDeleteOperation = async () => {
    setProcessing(true);
    setShowOverlay(true);
    const formData = new FormData();
    formData.append("id", "[" + itemToDeleteArtist + "]");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/artist/multi-delete`,
        formData,
        {
          headers: headers,
        }
      );
      const result = response?.data;
      if (result?.status === true) {
        // props.setApiresponsealert(result);
        // props.setShowalert(true);
        toast.success(result?.message);
        setProcessing(false);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err?.response?.data?.message;
      // props.setApiresponsealert({ status: false, message: errorMessage });
      // props.setShowalert(true);
      toast.error({ status: false, message: errorMessage });
      setProcessing(false);
      setShowOverlay(false);
    }
  };
  return (
    <div className="allaudioscpp">
      <div
        className={`modal  packageModel ${modalCloseArtist ? "show" : ""}`}
        tabIndex="-1"
        style={{ display: modalCloseArtist ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content theme-bg-color shadow">
            <div className="modal-header">
              <h5 className="modal-title">Confirm Deletion</h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeArtistModal}
              ></button>
            </div>
            <div className="modal-body">
              {itemToDeleteArtist?.length === 1 ? (
                <p className="fs-6 theme-text-color">
                  {" "}
                  Are you sure you want to delete this Artist ?
                </p>
              ) : (
                <p className="fs-6 theme-text-color">
                  Are you sure you want to delete the selected Artist ?
                </p>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={closeArtistModal}
              >
                Close
              </button>

              <button
                type="button"
                className="btn btn-danger"
                onClick={handleDeleteArtistData}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>
        {showOverlay && (<ResponseLoader />)}
      </div>

      <section className="section container-fluid  theme-bg-color-secondary  paddingbottomm pt-2 all-audio-stream">
        <div id="content-page" className="content-page">
          <div className="row pb-3 admin-section-title">
            <div className="col-md-6">
              <h4 className=" theme-text-color">
                Manage Artist{" "}
                <span className="fs-6">
                  (Display all {getArtist?.length} Artist)
                </span>
              </h4>
            </div>
            <div className="col-md-6 col-12 mt-3 mt-md-0" align="right">
              {itemToDeleteArtist?.length > 0 && (
                <button
                  className="btn btn-danger d-inline-flex  fw-bolder "
                  onClick={openModalMultiDeleteArtist}
                >
                  <MdDelete className="exportIcon " />
                  Delete
                </button>
              )}
              <Link
                to="cpp/add-artists"
                className="btn btn-primary fw-bolder"
              >
                <FaCirclePlus /> Add Artist
              </Link>
            </div>
          </div>
          <div className="iq-card p-3 mt-3 theme-bg-color">
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 gap-3">
              <div className="filterContainer">
                <input
                  type="text"
                  placeholder="Filter all.."
                  className="filter form-control "
                  value={filter}
                  onChange={handleFilterChangeArtist}
                />
                {filter?.length > 0 && (
                  <button
                    type="button"
                    className="closeBtn"
                    onClick={handleClear}
                  >
                    <MdOutlineClose />
                  </button>
                )}
              </div>
              <div className="btn-group">
                <div className="dropdown d-block " key={"2"}>
                  <button
                    className="btn btn-success dropdown-toggle d-flex"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="fw-bolder">Export </span>
                    <img src={ExportIcon} className="ms-2 exportIcon" />
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li className="dropdown-item">
                      <CSVLink
                        headers={exportHeadersArtist}
                        data={filteredArtistData}
                        className="text-dark d-block"
                        filename="ARTISTS-LIST.csv"
                      >
                        CSV
                      </CSVLink>
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        conditionalGeneratePDFArtist(0);
                      }}
                    >
                      PDF
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        generateXLSXARTIST(0);
                      }}
                    >
                      XLSX
                    </li>
                  </ul>
                </div>

                <div className="dropdown d-block ms-2">
                  <button
                    className="btn btn-success dropdown-toggle d-flex"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="fw-bolder">Export All</span>
                    <img src={ExportIcon} className="ms-2 exportIcon" />
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li className="dropdown-item">
                      <CSVLink
                        headers={exportHeadersArtist}
                        data={getArtist}
                        className="text-dark d-block"
                        filename="ARTISTS-LIST.csv"
                      >
                        CSV
                      </CSVLink>
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        conditionalGeneratePDFArtist(1);
                      }}
                    >
                      PDF
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        generateXLSXARTIST(1);
                      }}
                    >
                      XLSX
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="bootstrapTable theme-bg-color">
              <BootstrapTable
                keyField="id"
                columns={columns}
                data={filteredArtistData}
                pagination={pagination}
                className="no-border-table theme-text-color"
                noDataIndication={
                  <div className="noRecord">
                    {loading ? (
                      <label>
                        {" "}
                        <img
                          src={loadings}
                          width={30}
                          height={30}
                          alt="Animated GIF"
                        />
                        Loading...
                      </label>
                    ) : (
                      filteredArtistData?.length <= 0 && (
                        <p className=" theme-text-color">No Artist Available !!!</p>
                      )
                    )}
                  </div>
                }
                hover
                headerClasses="AlbumWidth"
                selectRow={selectRow}
                selectAll={selectAll}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Content_AllArtist;
