// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ThemeTwoSignIcon {
    width: 30px;
    height: 30px;
    object-fit: contain;
}

.themeTwoHead {
    font-family: "Poppins";
    font-size: 22px;
    font-weight: 600;
    line-height: 33px;
    text-align: center;
}

.ThemeTwoCheckbox {
    width: 15px;
    height: 15px;
}

.themeTwoBannerWrapper {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.7) 50%, rgba(0, 0, 0, 0.7));
    background-position: top left;
    background-size: 100% 800px;
    background-repeat: no-repeat;
}

.codeWidth {
    width: 50px;
    height: 50px;
}
.themeTwoBannerWrapper .eyeButton{
    position: absolute;
    top: 6px;
    right: 0;
}`, "",{"version":3,"sources":["webpack://./src/Styles/AuthStyle.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,oFAAoF;IACpF,6BAA6B;IAC7B,2BAA2B;IAC3B,4BAA4B;AAChC;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;AACA;IACI,kBAAkB;IAClB,QAAQ;IACR,QAAQ;AACZ","sourcesContent":[".ThemeTwoSignIcon {\n    width: 30px;\n    height: 30px;\n    object-fit: contain;\n}\n\n.themeTwoHead {\n    font-family: \"Poppins\";\n    font-size: 22px;\n    font-weight: 600;\n    line-height: 33px;\n    text-align: center;\n}\n\n.ThemeTwoCheckbox {\n    width: 15px;\n    height: 15px;\n}\n\n.themeTwoBannerWrapper {\n    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.7) 50%, rgba(0, 0, 0, 0.7));\n    background-position: top left;\n    background-size: 100% 800px;\n    background-repeat: no-repeat;\n}\n\n.codeWidth {\n    width: 50px;\n    height: 50px;\n}\n.themeTwoBannerWrapper .eyeButton{\n    position: absolute;\n    top: 6px;\n    right: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
