import axios from "axios";
import { jsPDF } from "jspdf";
import * as XLSX from "xlsx";
import { getItem, getItemToken } from "../../Utils/localStorageUtils";

import { toast } from 'react-toastify';

const URLGenerater = (URLNAME) => {
    return URLNAME.replace(/ /g, '-').replace(/_/g, '-').toLowerCase();
}
const URLDashRemover = (URLNAME) => {
    return capitalizeFirstLetter(URLNAME.replace(/-/g, ' '));
}

const formatDuration = (duration) => {
    const parts = duration.split(':');
    if (parts.length === 3) {
        const [hours, minutes, seconds] = parts;
        if (parseInt(hours, 10) > 0) {
            return `${hours}:${minutes}:${seconds}`;
        } else {
            return `${minutes}:${seconds}`;
        }
    }
    return duration;
};

const calculateTotalTime = (data) => {
    if (!data) return;

    const [hours, minutes, seconds] = data.split(':').map(Number);

    let formattedTime = '';
    if (hours > 0) {
        formattedTime += `${hours}h `;
    }
    if (minutes > 0 || hours > 0) {
        formattedTime += `${minutes}m`;
    }
    formattedTime += `${seconds}s`;

    return formattedTime.trim()
};


// const LogOut = (PATH) => {
//     setTimeout(() => {
//         localStorage.clear();
//         window.location.href = PATH;
//     }, 1000);
// }

function LogOut(PATH) {
    localStorage.removeItem('expires_in');
    localStorage.clear(); // Clear expiry time on logout
    window.location.href = PATH;
}

function checkLogout() {
    const expiresIn = localStorage.getItem('expires_in');
    if (expiresIn) {
        const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
        if (currentTime >= parseInt(expiresIn)) {
            LogOut(); // Logout if expiry time has passed
        }
    }
}

// Call checkLogout periodically, for example every hour
setInterval(checkLogout, 1000 * 60 * 60); // Check every hour

const capsFirstLetter = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
};
const capitalizeFirstLetter = (str) => {
    return str?.split(' ').map(word => word?.charAt(0).toUpperCase() + word?.slice(1)).join(' ');
};

const LikeAction = async (userId, userType, deviceType, sourceId, sourceType, status, accessHeader) => {

    return axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/Like-Update`, {
        Login_user_type: userType,
        Login_user_id: userId,
        Login_device_type: deviceType,
        source_id: sourceId,
        source_type: sourceType,
        like_status: status,
    }, { headers: accessHeader }).then(response => {
        return response?.data;
    }).catch(err => {
        const errorMessage = err.response?.data?.message || 'An error occurred';
    });
};

const disLikeAction = async (userId, userType, deviceType, sourceId, sourceType, status) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/Dislike-Update`, {
            Login_user_type: userType,
            Login_user_id: userId,
            Login_device_type: deviceType,
            source_id: sourceId,
            source_type: sourceType,
            dislike_status: status,
        });
        console.log(response?.data)
        if (response.data.status) {
            return response?.data;
        } else {
        }
        return response.data.status;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

const watchLaterAction = async (userId, userType, deviceType, sourceId, sourceType, status) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/Watchlater-Update`, {
            Login_user_type: parseInt(getItem('role'), 10) || 9,
            Login_user_id: parseInt(getItem('user_id'), 10) || null,
            Login_device_type: "web",
            source_id: sourceId,
            source_type: sourceType
        }, { headers: getItemToken('access_token') || null });
        if (response.data.status) {
            return response?.data;
        } else { }
        return response.data.status;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

const wishListAction = async (userId, userType, deviceType, sourceId, sourceType, accessHeader) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/Wishlist-Update`, {
            Login_user_type: parseInt(getItem('role'), 10) || 9,
            Login_device_type: "web",
            Login_user_id: parseInt(getItem('user_id'), 10) || null,
            source_id: sourceId,
            source_type: sourceType
        }, { headers: getItemToken('access_token') || null });
        if (response.data.status) {
            toast.success("Completed items have been added to the completed list.")
            return response?.data
        } else { }
        return response.data.status;
    } catch (error) {
        console.error(error);
    }
};
const themeToggleMethod = async (themeModeNumber) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Front-End/theme-mode`, {
            Login_user_type: parseInt(getItem('role'), 10) || 9,
            Login_user_id: parseInt(getItem('user_id'), 10) || null,
            Login_device_type: "web",
            theme_mode: themeModeNumber
        }, { headers: getItemToken('access_token') || null });
        return response.data;
    } catch (error) {
        console.error("Error in themeToggleMethod:", error);
        throw error;
    }
};

const currentIpAddressDetails = async () => {
    try {
        const responseIP = await axios.get(`${process.env.REACT_APP_API_IP_Location}`);
        return responseIP?.data;
    } catch (error) {
        console.error('Error fetching IP address details:', error);
        return null;
    }
};
const PDFGenerator = (data, fileName) => {
    const doc = new jsPDF();
    const PDFColumns = ["INDEX", "ID", "TITLE", "UPLOADED BY", "STATUS", "BANNER", "UPLOAD ON"];
    const database = data?.map((item, index) => [
        index + 1,
        item?.id,
        item?.title,
        item?.uploaded_by,
        item?.active,
        item?.banner,
        item?.upload_on
    ]);
    doc.autoTable({
        startY: 10,
        startX: 10,
        head: [PDFColumns],
        body: database,
        margin: { top: 5, right: 5, bottom: 5, left: 5 },
        styles: {
            cellPadding: 3,
            fontSize: 10,
            lineColor: [240, 240, 240],
            lineWidth: 0.1,
        }
    });
    doc.save(`${fileName}.pdf`);
};

const XLSXGenerator = (data, fileName) => {
    const extractedData = data.map((item, index) => {
        return {
            Index: index + 1,
            ID: item?.id,
            TITLE: item?.title,
            UPLOADED_BY: item?.uploaded_by,
            STATUS: item?.active,
            BANNER: item?.banner,
            UPLOAD_ON: item?.upload_on
        };
    });
    var wb = XLSX.utils.book_new(), ws = XLSX.utils.json_to_sheet(extractedData);
    XLSX.utils.book_append_sheet(wb, ws, "MySheet");
    XLSX.writeFile(wb, `${fileName}.xlsx`);
};

const CSVColumn = [
    { label: "ID", key: "id" },
    { label: "TITLE", key: "title" },
    { label: "UPLOADED BY", key: "uploaded_by" },
    { label: "STATUS", key: "active" },
    { label: "BANNER", key: "banner" },
    { label: "UPLOAD ON", key: "upload_on" },
];


export { calculateTotalTime, URLGenerater, URLDashRemover, LogOut, capsFirstLetter, LikeAction, disLikeAction, watchLaterAction, wishListAction, PDFGenerator, XLSXGenerator, CSVColumn, themeToggleMethod, capitalizeFirstLetter, currentIpAddressDetails, formatDuration }
