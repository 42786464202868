// External Libraries and Packages
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
// Internal Styles
// Internal Components
import {
  languagePageListAction,
  viewAllPageIdAction,
} from "../../Redux/Actions/apiActions";
import Error404 from "../Error404";
import PageLoader from "../Loader/PageLoader";
import LayoutLanguageSwiper from "./Source/LayoutLanguageSwiper";

const CategoryBasedLanguageViewAll = ({ settings, isLoading, languagePageList, viewallPageId, error,themeMode }) => {
  const user_role = localStorage.getItem("role");
  const user_id = localStorage.getItem("user_id");
  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    headers: { "Access-Control-Allow-Origin": "*" },
    Authorization: "Bearer " + access_token,
  };

  const { slug } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();

  function capitalizeText(text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  useEffect(() => {
    const requestApi = `${process.env.REACT_APP_Baseurl}/Front-End/Language-Page-List`;
    const languageBasedApi = `${process.env.REACT_APP_Baseurl}/Front-End/Language-depends-videos`;
    if (settings && Object.keys(settings)?.length > 0) {
      if (!user_role) {
        try {
          dispatch(languagePageListAction(null, 9, "web", headers, requestApi)).then((data) => {
            const filteredData = data.filter((item) => item.slug == slug);
            const get = filteredData.flatMap((item) => item.id);
            dispatch(viewAllPageIdAction(null, 9, "web", get[0], headers, languageBasedApi));
          })
            .catch((error) => {
              console.error("Error:", error);
            });
        } catch (error) {
          console.error("Error:", error);
        }
      } else {
        try {
          dispatch(languagePageListAction(user_id, user_role, "web", headers, requestApi))
            .then((data) => {
              const filteredData = data.filter((item) => item.slug == slug);
              const get = filteredData.flatMap((item) => item.id);
              dispatch(viewAllPageIdAction(user_id, user_role, "web", get[0], headers, languageBasedApi));
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        } catch (error) {
          console.error("Error:", error);
        }
      }
    }
  }, [settings, user_role, user_id, dispatch, location]);

  return (
    isLoading ? <PageLoader /> : error ? <Error404 /> :
      languagePageList.some((item) => item.slug == slug) ?
        <div className="paddingTop px-md-4 px-2 paddingBottom" style={{ overflowX: "hidden" }}>
          <h2 className="viewAllTitle py-md-4 py-2 text-center mb-0  theme-text-color ">{capitalizeText(slug)} </h2>
          {viewallPageId?.videos.length !== 0 && <LayoutLanguageSwiper mapData={viewallPageId?.videos} heading="Videos" sectionClass="pb-4"  path="videos" viewAllLink="" hoverCard={themeMode?.hoverCard}/>}
          {viewallPageId?.series.length !== 0 && <LayoutLanguageSwiper mapData={viewallPageId?.series} heading="Series" sectionClass="pb-4" path="shows" viewAllLink=""  hoverCard={themeMode?.hoverCard}/>}
          {viewallPageId?.Livestream.length !== 0 && <LayoutLanguageSwiper mapData={viewallPageId?.Livestream} heading="Live Streams" sectionClass="pb-4" path="live"  viewAllLink="" hoverCard={themeMode?.hoverCard}/>}
          {viewallPageId?.Audios.length !== 0 && <LayoutLanguageSwiper mapData={viewallPageId?.Audios} heading="Audios" sectionClass="pb-4" path="audios" viewAllLink="" hoverCard={themeMode?.hoverCard} />}
        </div> :
        <Error404 />)
};

const mapStateToProps = (state) => ({
  languagePageList: state.get_languagePageList_Reducer.languagePageList,
  viewallPageId: state.get_viewAll_Id_Reducer.viewallPageId,
  isLoading: state.get_viewAll_Id_Reducer.isLoading,
  error: state.get_viewAll_Id_Reducer.error,
  settings: state.get_setting_Reducer.settings,
  themeMode: state.get_headerIndex_Reducer.themeMode,
});
export default connect(mapStateToProps)(CategoryBasedLanguageViewAll);