import React, { useEffect, useRef, useState } from 'react'
import { FcOk } from 'react-icons/fc'
import { Link, useNavigate, useParams } from 'react-router-dom'
import PageLoader from '../../components/Loader/PageLoader'
import axios from 'axios'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import ButtonWithLoader from '../Music/CommonComponents/ButtonWithLoader'
import { VscEye } from 'react-icons/vsc'
import { TbEyeClosed } from "react-icons/tb";
import { useMyContext } from '../../components/Layout/CommonProvider'

const VerifyOtp = ({ languageDetailsData }) => {
    const { code } = useParams()
    const [message, setMessage] = useState(null)
    const [loading, setLoading] = useState(true)
    const [buttonLoad, setButtonLoad] = useState(false)
    const [inputField, setInputField] = useState({})
    const [inputFieldError, setInputFieldError] = useState({})
    const [verify, setVerify] = useState("email")
    const [counter, setCounter] = useState(5);
    const [passwordType, setPasswordType] = useState(true)
    const inputRefs = useRef([]);
    const emailRef = useRef()
    const otpRef = useRef()
    const passwordRef = useRef()
    const [otp, setOtp] = useState(new Array(4).fill(""));

    const navigate = useNavigate()
    const { navigateTranslateChecker } = useMyContext()



    const handleCodeVerify = async () => {
        setLoading(true)
        await axios.post(`${process.env.REACT_APP_Baseurl}/users/code-verification`, {
            code: code,
        }).then((res) => {

            setMessage(res?.data?.message)
            setLoading(false)
        }).catch(() => {
            toast.error("Error fdasdf")
            setLoading(false)
        })
    }

    const handleCodeVerifyManual = async (e) => {
        e.preventDefault()
        setButtonLoad(true)
        await axios.post(`${process.env.REACT_APP_Baseurl}/users/code-verification`, {
            code: otp.join('')
        }).then((res) => {
            if (res?.data?.users_exists) {
                navigate(navigateTranslateChecker(`/login`))
                toast.success(res?.data?.message)
                setMessage(res?.data?.message)
            }
            else {
                toast.success(res?.data?.message)
                setMessage(res?.data?.message)
            }
            setButtonLoad(false)
        }).catch((err) => {
            toast.error(err?.data?.message)
            setButtonLoad(false)
        })
    }

    const handleVerify = async (e) => {
        setButtonLoad(true)
        e.preventDefault()
        await axios.post(`${process.env.REACT_APP_Baseurl}/users/email-code-verification`, {
            email: inputField?.email,
            Url: `${process.env.REACT_APP_API_PATHss}/en/verify`,
        }).then((res) => {
            toast.success(res?.data?.message)
            setVerify("otp")
            setButtonLoad(false)
        }).catch(() => {
            toast.error("Error")
            setButtonLoad(false)
        })
    }

    const handleForgetPasswordEmail = async (e) => {
        e.preventDefault()
        if (!inputField?.email) {
            setInputFieldError((inputFieldError) => ({
                ...inputFieldError,
                'email': "Email field can't be empty"
            }))
            emailRef?.current.focus()
        }
        else {
            setButtonLoad(true)
            await axios.post(`${process.env.REACT_APP_Baseurl}/users/verify-forgot-password`, {
                email: inputField?.email,
                Url: `${process.env.REACT_APP_API_PATHss}/en/verify`,
            }).then((res) => {
                toast.success(res?.data?.message)
                setVerify("otp")
                setButtonLoad(false)
            }).catch(() => {
                toast.error("Error")
                setButtonLoad(false)
            })
        }

    }

    const formValidation = async (e) => {

        let formValidate = true
        if (!inputField?.email) {
            setInputFieldError((inputFieldError) => ({
                ...inputFieldError,
                'email': "Email field can't be empty"
            }))
            emailRef?.current.focus()
            formValidate = false
        }
        if (!inputField?.password) {
            setInputFieldError((inputFieldError) => ({
                ...inputFieldError,
                'password': "Password field can't be empty"
            }))
            emailRef?.current.focus()
            formValidate = false
        }
        if (!inputField?.code) {
            setInputFieldError((inputFieldError) => ({
                ...inputFieldError,
                'code': "Code field can't be empty"
            }))
            emailRef?.current.focus()
            formValidate = false
        }
        return formValidate

    }

    const handleForgetPasswordEmailCode = async (e) => {
        e.preventDefault()
        if (formValidation()) {
            setButtonLoad(true)
            await axios.post(`${process.env.REACT_APP_Baseurl}/users/reset-password`, {
                email: inputField?.email,
                code: inputField?.code,
                password: inputField?.password,
            }).then((res) => {
                setButtonLoad(false)
                if (res?.data?.users_exists) {
                    navigate(navigateTranslateChecker(`/login`))
                    toast.success(res?.data?.message)
                }
                else {
                    toast.error(res?.data?.message)
                }
            }).catch(() => {
                toast.error("Error")
                setButtonLoad(false)
            })
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputField((inputField) => ({
            ...inputField,
            [name]: value
        }))
        setInputFieldError((inputFieldError) => ({
            ...inputFieldError,
            [name]: ''
        }))
    }

    const handleInputChange = (e, index) => {
        const value = e.target.value;
        const newOtp = [...otp];

        newOtp[index] = value.slice(0, 1);
        setOtp(newOtp);

        if (value.length === 1 && index < inputRefs.current.length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace" && otp[index] === "" && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    const passAction = () => {
        setPasswordType(!passwordType)
    }

    useEffect(() => {
        if (code && code.length === 4 && /^\d{4}$/.test(code)) {
            handleCodeVerify()
        }
        else {
            setLoading(false)
        }
    }, [code])


    useEffect(() => {

        if (code == "verify") {
            const timer = setInterval(() => {
                setCounter((prevCounter) => prevCounter - 1);
            }, 1000);

            if (counter === 0) {
                clearInterval(timer);
                navigate(navigateTranslateChecker(`/login`))
            }


            return () => clearInterval(timer);
        }
    }, [counter]);

    return (
        <div className='paddingTop d-flex align-items-center justify-content-center'>
            {loading ? <PageLoader /> :

                <div className="col-11 col-md-5 col-lg-3 col-xl-4 mx-auto">
                    {code && code.length == 4 && /^\d{4}$/.test(code) &&
                        <div className="rounded-3 theme-bg-color-secondary p-5">
                            <FcOk className="mb-3" style={{ width: "80px", height: "80px", margin: "0px auto", display: "block" }} />
                            <h1 className="pb-2 text-center small-lg-heading">{message}</h1>
                            <Link to={navigateTranslateChecker(`/login`)} className="d-block text-center theme-text-color text-decoration-underline">Back to Login</Link>
                        </div>
                    }

                    {code == "code" && <div className='theme-bg-color-secondary p-5 rounded-2'>
                        <form onSubmit={handleCodeVerifyManual}>
                            <p className='mb-5 text-center d-block themeTwoHead theme-text-color-button'>Verification</p>
                            <span className="d-block my-4 text-center theme-text-color opacity-75">Enter Verification code</span>
                            <div className="d-flex align-items-center justify-content-center gap-3">
                                {[0, 1, 2, 3].map((_, index) => (
                                    <input
                                        key={index}
                                        type="number"
                                        maxLength="1"
                                        placeholder="*"
                                        value={otp[index]}
                                        className="p-3 theme-bg-color text-center codeWidth rounded-2 border-0"
                                        ref={(el) => (inputRefs.current[index] = el)}
                                        onChange={(e) => handleInputChange(e, index)}
                                        onKeyDown={(e) => handleKeyDown(e, index)}
                                    />
                                ))}
                            </div>
                            <span className="text-center d-block mt-4 mb-5">I you didn't receive a code? <Link to={navigateTranslateChecker(`/verify/resend`)} className="theme-text-color-button ">Resend</Link></span>
                            <ButtonWithLoader action={handleCodeVerifyManual} text="Verify OTP" type={"submit"} loading={buttonLoad} className='d-block p-3 rounded-2 w-100' />
                        </form>
                    </div>}

                    {code == "resend" &&
                        <div className='rounded-3 theme-bg-color-secondary p-5'>
                            <p className='mb-5 text-center d-block themeTwoHead theme-text-color-button'> {verify == "otp" ? "Email Send SuccessFully" : "Verify Email"}</p>

                            {verify == "email" && <form onSubmit={handleVerify}>
                                <span className="d-block my-4 text-center theme-text-color opacity-75">Enter the email address with your account and we'll send an email with confirmation to verify account</span>
                                <span className="d-block mb-3 text-start theme-text-color opacity-75">Enter Email Address</span>
                                <input type="email" placeholder="Email" className="mb-4 d-block bg-transparent py-3 px-3 w-100 outline-0 border border-1" name="email" value={inputField?.email} onChange={handleChange} />
                                <ButtonWithLoader action={handleVerify} text="Verify Account" type={"submit"} loading={buttonLoad} className='d-block p-3 rounded-2 w-100' />
                            </form>}

                            {verify == "otp" && <><div className="d-flex align-items-center gap-2 mb-3"><div style={{ width: "20px", height: "20px" }} > <FcOk className="" style={{ width: "20px", height: "20px" }} /></div><p className="theme-text-color">Check your Email</p></div>
                                <div className="d-flex align-items-center gap-2 mb-3"> <div style={{ width: "20px", height: "20px" }} ><FcOk className="" style={{ width: "20px", height: "20px" }} /></div><p className="theme-text-color"> Click the verification link. The link automatically rediect our website and automatically verify your account!</p></div>
                                <div className="d-flex align-items-center gap-2 mb-3"> <div style={{ width: "20px", height: "20px" }} > <FcOk className="" style={{ width: "20px", height: "20px" }} /></div><p className="theme-text-color"> Then, <Link to={navigateTranslateChecker(`/login`)} className="d-inline theme-text-color text-decoration-underline ">Login </Link></p></div>

                            </>}

                        </div>}

                    {code == "forget" &&
                        <div className='rounded-3 theme-bg-color-secondary p-5'>
                            <p className='mb-5 text-center d-block themeTwoHead theme-text-color-button'> {verify == "otp" ? "Email Send SuccessFully" : "Forget Password"}</p>
                            {verify == "email" && <form onSubmit={handleForgetPasswordEmail}>

                                <span className="d-block my-4 text-center theme-text-color opacity-75">Enter the email address with your account and we'll send an email with confirmation to reset your password.</span>
                                <span className="d-block mb-3 text-start theme-text-color opacity-75">Enter Email Address</span>
                                <input type="email" placeholder="Email" className="mb-4 d-block bg-transparent py-3 px-3 w-100 outline-0 border border-1" name="email" value={inputField?.email} onChange={handleChange} ref={emailRef} />
                                {inputFieldError?.email !== "" && (<span className="d-flex text-danger py-2 position-relative">  {inputFieldError?.email} </span>)}
                                <ButtonWithLoader action={handleForgetPasswordEmail} text="Send Code" type={"submit"} loading={buttonLoad} className='d-block p-3 rounded-2 w-100' />
                                {/* <Link to={navigateTranslateChecker(`/login`)} className="d-block mb-3 text-center theme-text-color opacity-75">Back to Login</Link> */}
                            </form>}

                            {verify == "otp" &&
                                <>
                                    <div className="d-flex align-items-center gap-2 mb-3"><div style={{ width: "20px", height: "20px" }} > <FcOk className="" style={{ width: "20px", height: "20px" }} /></div><p className="theme-text-color">Check your Email</p></div>
                                    <div className="d-flex align-items-center gap-2 mb-3"> <div style={{ width: "20px", height: "20px" }} ><FcOk className="" style={{ width: "20px", height: "20px" }} /></div><p className="theme-text-color"> Copy your OTP</p></div>
                                    <div className="d-flex align-items-center gap-2 mb-5"> <div style={{ width: "20px", height: "20px" }} > <FcOk className="" style={{ width: "20px", height: "20px" }} /></div><p className="theme-text-color"> Then, Paste your OTP and reset change password </p></div>

                                    <form onSubmit={handleForgetPasswordEmailCode}>
                                        <span className="d-block mb-3 text-start theme-text-color opacity-75">Enter Email Address</span>
                                        <input type="email" placeholder="Email" className="mb-4 d-block bg-transparent py-3 px-3 w-100 outline-0 border border-1" name="email" value={inputField?.email} onChange={handleChange} ref={emailRef} />
                                        {inputFieldError?.email !== "" && (<span className="d-flex text-danger py-2 position-relative">  {inputFieldError?.email} </span>)}
                                        <span className="d-block mb-3 text-start theme-text-color opacity-75">Enter OTP </span>
                                        <input type="number" placeholder="OTP" className="mb-4 d-block bg-transparent py-3 px-3 w-100 outline-0 border border-1" name="code" value={inputField?.code} onChange={handleChange} ref={otpRef} />
                                        {inputFieldError?.code !== "" && (<span className="d-flex text-danger py-2 position-relative">  {inputFieldError?.code} </span>)}
                                        <span className="d-block mb-3 text-start theme-text-color opacity-75">Enter new password </span>
                                        <div className='position-relative'>
                                            <button onClick={passAction} type="button" className="p-2 border-0 bg-transparent  eyeButton">{passwordType ? <TbEyeClosed className="theme-text-color-button eyeIcons" style={{ width: "25px", height: "25px" }} /> : <VscEye className="theme-text-color-button eyeIcons" style={{ width: "25px", height: "25px" }} />}</button>
                                            <input type={passwordType ? "password" : "text"} placeholder="password" className="mb-4 d-block bg-transparent py-3 px-3 w-100 outline-0 border border-1" name="password" value={inputField?.password} onChange={handleChange} ref={passwordRef} />
                                        </div>
                                        {inputFieldError?.password !== "" && (<span className="d-flex text-danger py-2 position-relative">  {inputFieldError?.password} </span>)}
                                        <ButtonWithLoader action={handleForgetPasswordEmailCode} text="Reset password" type={"submit"} loading={buttonLoad} className='d-block p-3 rounded-2 w-100' />
                                    </form>
                                </>}

                        </div>}
                </div>
            }
        </div>
    )
}

const mapStateToProps = (state) => ({
    themeMode: state.get_headerIndex_Reducer.themeMode,
    languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData
});

export default connect(mapStateToProps)(VerifyOtp);

