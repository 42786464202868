// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sideBarText {
    text-transform: capitalize;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
    padding: 6px;
    text-align: center;
    position: relative;
    width: 100%;
}

.sideBarText::before {
    content: "";
    width: 5px;
    height: 100%;
    position: absolute;
    background-color: #38b2ac;
    left: 0;
}`, "",{"version":3,"sources":["webpack://./src/Styles/MusicSideBar.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,eAAe;IACf,iBAAiB;IACjB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,WAAW;IACX,UAAU;IACV,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,OAAO;AACX","sourcesContent":[".sideBarText {\n    text-transform: capitalize;\n    font-size: 22px;\n    font-weight: bold;\n    color: #fff;\n    padding: 6px;\n    text-align: center;\n    position: relative;\n    width: 100%;\n}\n\n.sideBarText::before {\n    content: \"\";\n    width: 5px;\n    height: 100%;\n    position: absolute;\n    background-color: #38b2ac;\n    left: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
