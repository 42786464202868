// External Libraries and Packages
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { connect, useDispatch } from 'react-redux';
// Internal Styles
import "swiper/css";
import "swiper/css/navigation";
// Internal Components
import { clearViewAllList } from '../../../Redux/Actions/apiActions';
import { URLGenerater } from '../../../Pages/CommonMethods/CommonMethods';
import MessageBox from "../../../components/MessageBox"
import PriceLabel from '../../../components/PriceLabel';
import { useTranslation } from 'react-i18next';
import CardWithHover from './Theme/CardWithHover';
import CardWithoutHover from './Theme/CardWithoutHover';
import { useMyContext } from '../../../components/Layout/CommonProvider';
const ContinueWatchingAll = ({ item, themeMode }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { navigateTranslateChecker } = useMyContext()
    const swiperConfig = {
        slidesPerView: 2,
        spaceBetween: 15,
        navigation: true,
        lazy: true,
        slidesPerGroup: 2,
        breakpoints: {
            376: { slidesPerView: 2, spaceBetween: 15, slidesPerGroup: 2 },
            576: { slidesPerView: 3, spaceBetween: 15, slidesPerGroup: 3 },
            768: { slidesPerView: 4, spaceBetween: 15, slidesPerGroup: 4 },
            1024: { slidesPerView: 5, spaceBetween: 15, slidesPerGroup: 5 },
            1200: { slidesPerView: 6, spaceBetween: 15, slidesPerGroup: 6 },
            1440: { slidesPerView: 6, spaceBetween: 15, slidesPerGroup: 6 },
        },
        modules: [Navigation],
        className: 'homePageSwiper'
    }
    return (

        <div className='px-3 px-md-4 px-lg-4 pt-1 zIndex'>
            {item?.data?.length > 0 && (
                <> <Link
                    to={navigateTranslateChecker(`/${URLGenerater(item.source)}`)}
                    className="text-decoration-none swiperHeading theme-text-color d-inline-block">
                    {<MessageBox text={t(item.header_name)} />}
                </Link>
                    <Link to={navigateTranslateChecker(`/${URLGenerater(item.source)}`)}
                        className="text-decoration-none swiperHeading theme-text-color float-end viewall-homepage"
                        onClick={() => dispatch(clearViewAllList())} >
                        <MessageBox text={t("View All")} />
                    </Link>
                </>
            )}
            <Swiper {...swiperConfig}>
                {item?.data
                    ?.filter(value => value.Login_device_type == "web")
                    .map((value) => (
                        <SwiperSlide key={value.id} className='theme-bg-color-secondary'>
                            {themeMode?.hoverCard ? (<CardWithHover value={value} segMent={value.series_slug ? "shows" : "videos"} hoverClass="homeSwiperContainer" />) : (<CardWithoutHover value={value} segMent={value.series_slug ? "shows" : "videos"} />)}
                        </SwiperSlide>
                    ))}
            </Swiper>
        </div>
    )
}
const mapStateToProps = (state) => ({
    themeMode: state.get_headerIndex_Reducer.themeMode,
});
export default connect(mapStateToProps)(ContinueWatchingAll)