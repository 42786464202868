import { useEffect, useState } from "react";
// import { Line } from "react-chartjs-2";
import * as XLSX from "xlsx";
import BootstrapTable from "react-bootstrap-table-next";
import {
  MdClose,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import { CSVLink } from "react-csv";
import axios from "axios";
import paginationFactory from "react-bootstrap-table2-paginator";
import jsPDF from "jspdf";

import ExportIcon from "../../../assets/icons/export-icon.webp";
import loadings from "../../../assets/gif/loading.gif";
import ResponseLoader from "../../../components/Loader/ResponseLoader";

const Contentvideoanalytics = () => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [filter, setFilter] = useState("");
  const [filteredLiveData, setFilteredLiveData] = useState([]);
  const [withOutRefresh, setWithOutRefresh] = useState(false);
  const [getLiveData, setLiveData] = useState([]);
  const [loading, setLoading] = useState(true);

  // let access_token, access_token_cpp;

  // if (localStorage.getItem("username")) {
  //   access_token = localStorage.getItem("access_token");
  // } else {
  //   access_token_cpp = localStorage.getItem("access_token_cpp");
  // }

  // const headers = {
  //   "Content-Type": "application/json",
  //   // Authorization: "Bearer " + access_token_cpp,
  //   Accept: "application/json",
  //   "Access-Control-Allow-Origin": "*",
  // };

  // if (access_token) {
  //   headers.Authorization = "Bearer " + access_token;
  // } else if (access_token_cpp) {
  //   headers.Authorization = "Bearer " + access_token_cpp;
  // }

  const access_token_cpp = localStorage.getItem("access_token_cpp");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token_cpp,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    fetchData();
  }, [withOutRefresh]);
  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_Baseurl}/*`, {
        headers: headers,
      });
      const liveData = response?.data;
      const filterHelp = liveData;
      setLiveData(liveData);
      setFilteredLiveData(filterHelp);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    nextPageText: "Next",
    prePageText: "Prev",
    firstPageText: <MdKeyboardDoubleArrowLeft />,
    lastPageText: <MdKeyboardDoubleArrowRight />,
    showTotal: true,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "All", value: filteredLiveData?.length },
    ],
  });

  const columns = [
    {
      dataField: "title",
      text: "Video Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "content_Name",
      text: "Content Name",
    },
    {
      dataField: "total_views",
      text: "Total Views",
    },
  ];

  const exportHeadersLive = [
    { label: "ID", key: "id" },
    { label: "VIDEOS NAME", key: "title" },
    { label: "EMAIL", key: "uploaded_by" },
    { label: "Content Name", key: "active" },
    { label: "TOTAL VIEWS", key: "banner" },
    { label: "UPLOAD ON", key: "upload_on" },
  ];

  const conditionalGeneratePDFLive = (get) => {
    const doc = new jsPDF();
    doc.text("ALL LIVE LIST ", 10, 10);
    const columns = [
      "Index",
      "ID",
      "VIDEOS NAME",
      "EMAIL",
      "Content Name",
      "TOTAL VIEWS",
      "UPLOAD ON",
    ];
    const allDataPDF = getLiveData?.map((item, index) => [
      index + 1,
      item.id,
      item.title,
      item.email,
      item.content_Name,
      item.total_views,
      item.upload_on,
    ]);
    const filterPDF = filteredLiveData?.map((item, index) => [
      index + 1,
      item.id,
      item.title,
      item.email,
      item.content_Name,
      item.total_views,
      item.upload_on,
    ]);

    doc.autoTable({
      startY: 20,
      head: [columns],
      body: get == 1 ? allDataPDF : filterPDF,
    });
    doc.save("ALL-LIVE-LIST.pdf");
  };

  const generateXLSXLIVE = (get) => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(get == 1 ? getLiveData : filteredLiveData);
    XLSX.utils.book_append_sheet(wb, ws, "MySheet");
    XLSX.writeFile(wb, "ALL-LIVE-LIST.xlsx");
  };

  const handleFilterChangeLive = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setFilter(searchTerm);
    const filteredResults = getLiveData.filter((item) =>
      ["id", "title", "uploaded_by", "active", "upload_on"].some(
        (property) =>
          typeof item[property] == "string" &&
          item[property].toLowerCase().includes(searchTerm)
      )
    );
    setFilteredLiveData(filteredResults);
  };

  const handleClear = () => {
    fetchData();
    setFilter("");
  };

  const datas = [
    {
      user: "Admin",
      mail: "admin@admin.com",
      video: "Zootopia",
      amount: "$",
      date: "02 Jul 2021",
    },
    {
      user: "Admin",
      mail: "admin@admin.com",
      video: "Zootopia",
      amount: "$",
      date: "02 Jul 2021",
    },
  ];

  const options = {
    type: "line",
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
  };

  const labels = [
    "June",
    "June",
    "June",
    "June",
    "August",
    "August",
    "August",
    "August",
    "August",
    "September",
    "September",
    "October",
    "November",
    "December",
    "February",
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "Video Analytics ",

        data: [5, 0, 40, 0, 10, 20, 0, 30, 0, 80, 0, 60, 0, 10, 0 - 20, 100],
        borderColor: "rgb(53, 162, 235)",
        lineTension: 0.4,
      },
    ],
  };

  return (
    <>
      {showOverlay && <ResponseLoader />}
      <section className="container-fluid p-0  theme-bg-color-secondary  adminHeadContainer pb-5">
        <div className="d-flex align-items-center flex-wrap p-3">
          <div className="col-sm-6 col-12 ">
            <h4 className="admin-title mb-0  theme-text-color text-md-start ">
              Content Partners Video Analytics{" "}
            </h4>
          </div>
        </div>
        <div className=" border-0 px-3">
          <div className="row p-3 theme-bg-color  m-0 rounded-3">
            <div className="col-12 col-md-6 col-lg-4">
              <div className="d-flex align-items-center ">
                <label className="me-3 theme-text-color">Start Date : </label>{" "}
                <input
                  type="date"
                  className=" w-50 rs-input mt-2 form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="d-flex align-items-center ">
                <label className="me-3 theme-text-color">End Date : </label>{" "}
                <input
                  type="date"
                  className=" w-50 rs-input mt-2 form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                />
              </div>
            </div>
            {/* <div className="col-12 col-md-6 col-lg-4">
              <button type="button" className="btn btn-primary float-end">
                Download CSV
              </button>
            </div> */}

            <div className="mt-2">
              <h4 className="theme-text-color">Video View Through Graph </h4>
            </div>
            <div>
              <div className="row mt-5 text-center">
                <div className="col-md-10">
                  {/* <Line options={options} data={data} /> */}
                  <p className="text-center theme-text-color">
                    Video Analytics{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 pt-5 ">
              <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 gap-3">
                <div className="filterContainer position-relative">
                  <input
                    type="text"
                    placeholder="Filter all.."
                    className="filter form-control position-relative"
                    value={filter}
                    onChange={handleFilterChangeLive}
                  />
                  {filter?.length > 0 && (
                    <button
                      type="button"
                      className="closeBtn"
                      onClick={handleClear}
                    >
                      <MdClose fill="currentColor" />
                    </button>
                  )}
                </div>
                <div className="btn-group">
                  <div className="dropdown d-block p-2" key={"2"}>
                    <button
                      className="btn btn-success dropdown-toggle d-flex align-items-center"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="admin-button-text">Export </span>
                      <img src={ExportIcon} className="ms-2 exportIcon" />
                    </button>
                    <ul
                      className="dropdown-menu  theme-bg-color-secondary "
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li className="dropdown-item text-light">
                        <CSVLink
                          headers={exportHeadersLive}
                          data={filteredLiveData}
                          className="text-light d-block"
                          filename="LIVE-FILTERED-LIST.csv"
                        >
                          CSV
                        </CSVLink>
                      </li>
                      <li
                        className="dropdown-item text-light"
                        onClick={() => {
                          conditionalGeneratePDFLive(0);
                        }}
                      >
                        PDF
                      </li>
                      <li
                        className="dropdown-item text-light"
                        onClick={() => {
                          generateXLSXLIVE(0);
                        }}
                      >
                        XLSX
                      </li>
                    </ul>
                  </div>

                  <div className="dropdown d-block p-2">
                    <button
                      className="btn btn-success dropdown-toggle d-flex align-items-center"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="admin-button-text">Export All</span>
                      <img src={ExportIcon} className="ms-2 exportIcon" />
                    </button>
                    <ul
                      className="dropdown-menu  theme-bg-color-secondary "
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li className="dropdown-item">
                        <CSVLink
                          headers={exportHeadersLive}
                          data={getLiveData}
                          className="text-light d-block "
                          filename="ALL-LIVES-LIST.csv"
                        >
                          CSV
                        </CSVLink>
                      </li>
                      <li
                        className="dropdown-item text-light"
                        onClick={() => {
                          conditionalGeneratePDFLive(1);
                        }}
                      >
                        PDF
                      </li>
                      <li
                        className="dropdown-item text-light"
                        onClick={() => {
                          generateXLSXLIVE(1);
                        }}
                      >
                        XLSX
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="bootstrapTable theme-bg-color">
                <BootstrapTable
                  keyField="id"
                  columns={columns}
                  data={filteredLiveData}
                  pagination={pagination}
                  className="no-border-table theme-text-color"
                  noDataIndication={
                    <div className="noRecord">
                      {loading ? (
                        <label>
                          {" "}
                          <img
                            src={loadings}
                            width={30}
                            height={30}
                            alt="Animated GIF"
                          />
                          Loading...
                        </label>
                      ) : (
                        filteredLiveData?.length <= 0 && (
                          <p className=" theme-text-color">No Analysis data Available. !!!</p>
                        )
                      )}
                    </div>
                  }
                  hover
                  headerClasses="videoWidth"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contentvideoanalytics;

// import React from 'react'
// import {
//     Chart as ChartJS,
//     CategoryScale,
//     LinearScale,
//     PointElement,
//     LineElement,
//     Title,
//     Tooltip,
//     Legend,
//   } from "chart.js";
//   import { Line } from "react-chartjs-2";

//   ChartJS.register(
//     CategoryScale,
//     LinearScale,
//     PointElement,
//     LineElement,
//     Title,
//     Tooltip,
//     Legend
//   );

// const Contentvideoanalytics = () => {

//     const datas = [
//         {
//           user: "Admin",
//           mail: "admin@admin.com",
//           video: "Zootopia",
//           amount: "$",
//           date: "02 Jul 2021",
//         },
//         {
//           user: "Admin",
//           mail: "admin@admin.com",
//           video: "Zootopia",
//           amount: "$",
//           date: "02 Jul 2021",
//         },
//       ];

//       const options = {
//         type: "line",
//         responsive: true,
//         plugins: {
//           legend: {
//             position: "top",
//           },
//         },
//       };

//       const labels = [
//         "June",
//         "June",
//         "June",
//         "June",
//         "August",
//         "August",
//         "August",
//         "August",
//         "August",
//         "September",
//         "September",
//         "October",
//         "November",
//         "December",
//         "February",
//       ];

//       const data = {
//         labels,
//         datasets: [
//           {
//             label: "Video Analytics ",

//             data: [5, 0, 40, 0, 10, 20, 0, 30, 0, 80, 0, 60, 0, 10, 0 - 20, 100],
//             borderColor: "rgb(53, 162, 235)",
//             lineTension: 0.4,
//           },
//         ],
//       };

//   return (
//     <div>
//         <div className=" m-0 p-0">
//         <div className="">
//           <section className="section container-fluid pt-3">
//             <div id="content-page" className="content-page">
//               <div className="iq-card">
//                 <div className="iq-card-header  justify-content-between">
//                   <div className="iq-header-title p-0">
//                     <h4 className="card-title">Content Partners Video Analytics :</h4>
//                   </div>
//                 </div>

//                 <div className="row p-3">
//                   <hr />
//                   <div className="col-md-12">
//                     <div className="row mt-3">
//                       <div className="col-md-3">
//                         <label for="start_time"> Start Date: </label>
//                         <input
//                           type="date"
//                           className="form-control"
//                           id="start_time"
//                           name="start_time"
//                         />
//                       </div>
//                       <div className="col-md-3">
//                         <label for="start_time"> End Date: </label>
//                         <input
//                           type="date"
//                           className="form-control"
//                           id="end_time"
//                           name="end_time"
//                         />
//                       </div>

//                       <div className="col-md-3 d-flex align-items-end">
//                         <div className="dropdown">
//                           <button
//                             className="btn btn-outline-dark filter-btn dropdown-toggle"
//                             type="button"
//                             data-bs-toggle="dropdown"
//                             aria-expanded="false"
//                           >
//                             Filter
//                           </button>
//                           <ul className="dropdown-menu">
//                             <li>
//                               <a className="dropdown-item" href="#">
//                                 1 Day
//                               </a>
//                             </li>
//                             <li>
//                               <a className="dropdown-item" href="#">
//                                 1 Week
//                               </a>
//                             </li>
//                             <li>
//                               <a className="dropdown-item" href="#">
//                                 1 Month{" "}
//                               </a>
//                             </li>
//                           </ul>
//                         </div>
//                       </div>

//                       <div className="col-md-3 d-flex align-items-end">
//                         <span
//                           id="export"
//                           className="btn btn-primary download-btn btn-sm"
//                         >
//                           Download CSV
//                         </span>
//                       </div>
//                     </div>
//                     <div className="clear"></div>

//                     <div className="mt-5">
//                       <h4>Video View Through Graph :</h4>
//                     </div>
//                     <div>
//                       <div className="row mt-5">
//                         <div className="col-md-2"></div>
//                         <div className="col-md-7">
//                           <Line options={options} data={data} />
//                           <p className="text-center">Video Analytics </p>
//                         </div>
//                         <div className="col-md-3"></div>
//                       </div>
//                     </div>

//                     <div className="d-flex justify-content-between p-3">
//                       <div className="d-flex">
//                         <p>Show 10</p>
//                         <p>&nbsp; &nbsp; entries</p>
//                       </div>
//                       <div className="d-flex">
//                         <p>Search:</p>
//                         <input
//                           type="search"
//                           id="gsearch"
//                           name="gsearch"
//                           className="border-0 search-rev"
//                         />
//                       </div>
//                     </div>

//                     <div className="row">
//                       <div className="col-md-12">
//                         <table className="table" id="user_tabledss">
//                           <thead>
//                             <tr className="r1">
//                               <th>#</th>
//                               <th>Video Name</th>
//                               <th>Email</th>
//                               <th>Content Name</th>
//                               <th> Total Views</th>

//                             </tr>
//                           </thead>
//                           {/* {datas.map((item, key) => (
//                             <thead>
//                               <tr className="r1">
//                                 <td key={key}> {key + 1} </td>
//                                 <td>{item.user}</td>
//                                 <td>{item.mail}</td>
//                                 <td>{item.video}</td>
//                                 <td>{item.amount} </td>

//                               </tr>
//                             </thead>
//                           ))} */}
//                           <tbody></tbody>
//                         </table>
//                       </div>
//                       <div className="col-md-4"></div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div></div>
//             </div>
//           </section>
//         </div>
//       </div>
//     </div>
//   )
// }

// export default Contentvideoanalytics
