// External Libraries and Packages
import React from 'react'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
// Internal Styles
import "swiper/css";
import "swiper/css/navigation";
import LazyLoadImage from './LazyLoadImage';
import { useTranslation } from 'react-i18next';
// Internal Components
import { URLGenerater } from '../../CommonMethods/CommonMethods';
import { connect } from 'react-redux';
import { useMyContext } from '../../../components/Layout/CommonProvider';
const AudioAlbumAll = ({ item, languageDetailsData }) => {
    const { t } = useTranslation();
    const { navigateTranslateChecker } = useMyContext()
    return (
        <div className='px-3 px-md-4 px-lg-4 pt-3 zIndex'>
            {
                item?.data.length > 0 && (<>
                    <Link to={navigateTranslateChecker(`/${URLGenerater(item?.source)}`)} className="blocklinetitle text-decoration-none  d-inline-block  swiperHeading theme-text-color" aria-label={`View all ${item?.header_name}`}>{t(item?.header_name?.charAt(0).toUpperCase() + item?.header_name?.slice(1))}  </Link>
                    <Link to={navigateTranslateChecker(`/${URLGenerater(item?.source)}`)} className="blocklinetitle text-decoration-none float-end  swiperHeading theme-text-color  viewall-homepage " aria-label={`View all ${item?.header_name}`}>{t('View All')} </Link>
                </>)
            }
            <div className="">
                <Swiper
                    slidesPerView={2}
                    spaceBetween={15}
                    navigation={true}
                    lazy={true}
                    slidesPerGroup={2}
                    breakpoints={{
                        376: {
                            slidesPerView: 2,
                            spaceBetween: 15,
                            slidesPerGroup: 2,
                        },
                        576: {
                            slidesPerView: 3,
                            spaceBetween: 15,
                            slidesPerGroup: 3,
                        },
                        768: {
                            slidesPerView: 4,
                            spaceBetween: 15,
                            slidesPerGroup: 4,
                        },
                        1024: {
                            slidesPerView: 5,
                            spaceBetween: 15,
                            slidesPerGroup: 5,
                        },
                        1200: {
                            slidesPerView: 6,
                            spaceBetween: 15,
                            slidesPerGroup: 6,
                        }

                    }}
                    modules={[Navigation]}
                    className={`homePageSwiper categorySizeSmall `} >
                    {item?.data?.map((value, index) => (
                        <SwiperSlide key={value?.id} className='theme-bg-color-secondary'>
                            <Link to={navigateTranslateChecker(`/music/${item?.source == "audios" ? "track" : "album"}/${value?.slug}`)} className="text-decoration-none  swiperHeading theme-text-color  categoryHover   d-block h-100 w-100" >
                                <LazyLoadImage key={value?.id} src={value?.image_url} alt={value?.title ? value?.title : value.albumname} title={value?.title ? value?.title : value.albumname} imageSize="loaderMain object-fit-cover" classNameOptional="rounded-3" />
                            </Link>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    )
}
const mapStateToProps = state => ({
    languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData,
})

export default connect(mapStateToProps)(AudioAlbumAll)