import { connect } from 'react-redux'
import MessageBox from '../../components/MessageBox'
import { Link } from 'react-router-dom'
import { useMyContext } from '../../components/Layout/CommonProvider'
import FollowButton from './CommonComponents/FollowButton'
import PriceLabel from '../../components/PriceLabel'
import { useEffect, useState } from 'react'
import { IconMyEarPhone, IconMyPause, IconMyPlay, IconMyWishList, IconMyWishListFill } from '../../assets/icons/MyIcons'
const MusicBannerPanelHorizontal = ({ songDetailsData, languageDetailsData, propsWishListAction, propsType, currentPlaying, currentAudio, otherAudios, playlistData }) => {
    const { handlePlay,navigateTranslateChecker } = useMyContext();
    const [totalTime, setTotalTime] = useState('');
    const [defaultState, setDefalutState] = useState({
        descriptionShowMore: false
    })

    const formatter = new Intl.NumberFormat('en', {
        useGrouping: true,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    });

    const calculateTotalTime = () => {
        let totalSeconds = 0;
        otherAudios?.forEach(item => {
            const [hours, minutes, seconds] = item?.duration?.split(':').map(Number);
            totalSeconds += hours * 3600 + minutes * 60 + seconds;
        });

        const totalHours = Math.floor(totalSeconds / 3600);
        const remainingSecondsAfterHours = totalSeconds % 3600;
        const totalMinutes = Math.floor(remainingSecondsAfterHours / 60);
        const remainingSeconds = remainingSecondsAfterHours % 60;

        let formattedTime = '';
        if (totalHours > 0) {
            formattedTime += `${totalHours} hr `;
        }
        if (totalMinutes > 0 || totalHours > 0) {
            formattedTime += `${totalMinutes} mins `;
        }
        formattedTime += `${remainingSeconds} sec `;

        setTotalTime(formattedTime.trim());
    };

    const handleShowMore = () => {
        setDefalutState((prev) => ({
            ...prev,
            descriptionShowMore: !prev.descriptionShowMore
        }))
    }
    useEffect(() => {
        calculateTotalTime()
    }, [otherAudios])

    return (
        <div className='mb-4 position-relative'>
            {(propsType !== "ALBUM" && propsType !== "PLAYLIST" && propsType !== "ARTIST" && propsType !== "STATION" && propsType !== "GENRE") && <PriceLabel item={songDetailsData} />}
            <div
                className='audioBanner py-5 px-4  d-flex align-items-center theme-border-secondary'
                style={{ backgroundImage: `url(${songDetailsData?.Player_thumbnail ? songDetailsData?.Player_thumbnail : songDetailsData?.Thumbnail ? songDetailsData?.Thumbnail : songDetailsData?.Player_image_url ? songDetailsData?.Player_image_url : songDetailsData?.image_url})`, backgroundSize: "cover", backgroundPosition: "right", backgroundOrigin: "border-box", backgroundAttachment: "fixed", backgroundRepeat: "no-repeat" }} >
                <div className='position-relative col-md-8 col-12 zIndexOne'>
                    <MessageBox classname='audioBannerHeading theme-text-color' text={songDetailsData?.title ? songDetailsData?.title : songDetailsData?.artist_name ? songDetailsData?.artist_name : songDetailsData?.albumname ? songDetailsData?.albumname : songDetailsData?.name} />
                    {propsType == "ARTIST" && <MessageBox text={`${songDetailsData?.upload_on}`} classname="theme-text-color d-block mini-heading fw-bold" />}
                    {(propsType == "PLAYLIST" || propsType == "STATION" || propsType == "ARTIST" || propsType == "ALBUM" || propsType == "GENRE") && <div className='mt-3'> <div className='d-flex align-items-center'><MessageBox text={`${otherAudios?.length} `} classname='playlistCount d-block' /><MessageBox text="Songs" classname='ms-1 playlistCount' /></div><p className='mini-heading mt-2 mb-0'>About {totalTime}</p></div>}
                    {propsType !== "PLAYLIST" && propsType !== "ALBUM" && propsType !== "STATION" && propsType !== "GENRE" && <> <MessageBox classname={`theme-text-color pt-4 audioBannerDescription ${defaultState?.descriptionShowMore ? 'more' : ''}`} text={songDetailsData?.description} />
                        <button className=' bg-transparent p-0 mb-0' onClick={() => handleShowMore()}><MessageBox text={defaultState?.descriptionShowMore ? 'Show Less' : 'Show More'} classname='theme-text-color mt-3 artistsCardTitle' /></button>

                        {propsType == "ARTIST" &&
                            <div className='my-3 d-flex align-items-center gap-4'>
                                <FollowButton artistDetails={songDetailsData} pageType="DETAILS" />
                                <div>
                                    <MessageBox text={songDetailsData?.artist_type} classname='theme-text-color d-block ' />
                                    <MessageBox text={`${formatter.format(songDetailsData?.artist_followers_count)} Followers`} classname='theme-text-color d-block mini-heading' />
                                </div>

                            </div>}
                        <div className='d-flex align-items-center flex-wrap'>
                            {songDetailsData?.artists?.map((name, index) => (<Link key={index} to={navigateTranslateChecker(`/artist/${name?.artist_slug}`)} className='d-inline-block me-2' ><MessageBox classname='theme-text-color pt-3 d-inline-block' text={name?.name ? name?.name : name?.artist_name} />{index < songDetailsData.artists.length - 1 ? "," : ""}</Link>))}
                        </div>
                        <div className='d-flex  mt-3'>
                            <IconMyEarPhone styled={{ width: "20px", height: "20px", className: "me-3" }} />
                            <span className='theme-text-color me-2'>{formatter.format(songDetailsData?.Monthly_istener ? songDetailsData?.Monthly_istener : 0)}</span>
                            <MessageBox classname='opacity-75' text="Monthly listeners" />
                        </div>
                    </>}
                    <div className='d-flex mt-4 align-items-center gap-3'>
                        {propsType !== "PLAYLIST" && propsType !== "ARTIST" && propsType !== "ALBUM" && propsType !== "STATION" && propsType !== "GENRE" ? <button className='audioBannerPlay   p-0 d-flex align-items-center justify-content-center theme-button-bg-color' onClick={() => handlePlay(propsType, songDetailsData?.slug, songDetailsData?.id)}>
                            {!currentPlaying || currentAudio?.id !== songDetailsData?.id ? <IconMyPlay styled={{ width: "20px", height: "20px", className: "" }} /> : <IconMyPause styled={{ width: "20px", height: "20px", className: "" }} />}
                        </button> :
                            <button className='audioBannerPlay  p-0 d-flex align-items-center justify-content-center theme-button-bg-color' onClick={() => handlePlay(propsType, songDetailsData?.slug ? songDetailsData?.slug : songDetailsData?.artist_slug, songDetailsData?.id)}>
                                {!currentPlaying || playlistData?.id !== songDetailsData?.id ? <IconMyPlay styled={{ width: "20px", height: "20px", className: "" }} /> : <IconMyPause styled={{ width: "20px", height: "20px", className: "" }} />}

                            </button>
                        }
                        {(propsType !== "PLAYLIST" && propsType !== "ARTIST" && propsType !== "ALBUM" && propsType !== "STATION" && propsType !== "GENRE") && <button type='button' onClick={() => propsWishListAction()} className='bg-transparent d-flex align-items-center gap-2'>
                            {songDetailsData?.wishlist !== null ? <IconMyWishListFill styled={{ width: "", height: "", className: "audioWishList  theme-text-color" }} /> : <IconMyWishList styled={{ width: "", height: "", className: "audioWishList  theme-text-color" }} />}
                            <MessageBox classname='theme-text-color pSmEnd audioText fw-bold' text={`${songDetailsData?.wishlist !== null ? "Remove To WishList" : "Add To WishList"}`} />
                        </button>}
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = state => ({
    songDetailsData: state.get_songDetails_Reducer.songDetailsData,
    languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData,
    currentPlaying: state.get_current_Reducers.currentPlaying,
    currentAudio: state.get_current_Reducers.currentAudio,
    playlistData: state.get_current_Reducers.playlistData,
    otherAudios: state.get_songDetails_Reducer.otherAudios,
})

export default connect(mapStateToProps)(MusicBannerPanelHorizontal)