import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import axios from 'axios';
// import TranslationEnFile from "../Locals/en.json"

const Translate = async (languageResponse) => {
    try {
        const [translations, response] = await new Promise((resolve, reject) => {
            let fetchedData = null;
            const fetchData = async () => {
                try {
                    const result = languageResponse;
                    if (result?.translationFilePath) {
                        const isValidURL = result?.translationFilePath.startsWith('http') || result?.translationFilePath.startsWith('https');
                        if (!isValidURL) {
                            throw new Error('Invalid translation file URL');
                        }
                        const data = await axios.post(result?.translationFilePath);
                        fetchedData = [data?.data, result];
                    } else {
                        fetchedData = [{ "demo": "demo" }, result];
                    }

                    resolve(fetchedData);
                } catch (error) {
                    reject(error);
                }
            };

            fetchData();
        });

        const resources = {
            [response?.Language_Code]: {
                translation: translations ? translations : {"demo":"demo"}
            }
        };
        await i18n
            .use(initReactI18next)
            .init({
                resources,
                lng: response?.Language_Code || 'en',
                fallbackLng: 'en',
                interpolation: {
                    escapeValue: false,
                },
            });
    } catch (error) {
        console.error("Error initializing i18n:", error);
    }
};

export const initializeI18n = Translate;
export default i18n;

