import React, { useRef, useState, useEffect } from "react";
import "../../Styles/Reels.css";

export default function ReelsVideoPage({ channel, song, url, likes, comment, shares }) {
  const [isVideoPlaying, setisVideoPlaying] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const vidRef = useRef();
  const onVideoClick = () => {
    if (isVideoPlaying) {
      vidRef.current.pause();
      setisVideoPlaying(false);
    } else {
      vidRef.current.play();
      setisVideoPlaying(true);
    }
  };
  useEffect(() => {
    const scroll = document.getElementById("video-container");
    if (scroll) {
      scroll.addEventListener("scroll", () => {
        vidRef.current.pause();
      });
    }
  }, []);

  return (
    <div className="video-cards" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <video onClick={onVideoClick} className="reelvideo-player" ref={vidRef} src={url} loop />
    </div>
  );
}