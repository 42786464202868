import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios';
import { connect } from 'react-redux';
import { getItem, getItemToken } from '../../../../Utils/localStorageUtils';
import { musicWishList } from '../../../../components/Api/MusicApi';
import PageLoader from '../../../../components/Loader/PageLoader';
import MusicSearchCard from '../../Layout/MusicSearchCard';
import MessageBox from '../../../../components/MessageBox';
import PaginationViewAll from '../../../../components/ViewAll/Source/PaginationViewAll';

const MusicWishListPage = ({ settings, playlistShow, languageDetailsData }) => {
    const [wishlistData, setWishlistData] = useState(null)
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true)
    const [containerWidth, setContainerWidth] = useState(820);
    const containerRef = useRef(null);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const fetchData = async () => {
            const response = await axios.post(`${musicWishList}`, {
                Login_user_type: parseInt(getItem('role'), 10) || 9,
                Login_device_type: "web",
                Login_user_id: parseInt(getItem('user_id'), 10) || null,
            }, { headers: getItemToken('access_token') || null })
            setWishlistData(response?.data?.Audios)
            setLoading(false)
    }


    const calculateSlidesPerView = () => {
        if (containerWidth < 450) { return 2; }
        else if (containerWidth < 650) { return 3; }
        else if (containerWidth < 992) { return 3; }
        else if (containerWidth < 1440) { return 2; }
        else if (containerWidth < 2000) { return 8; }
        else { return 11; }
    };


    useEffect(() => {
        const fetchDataAsync = async () => {
            await fetchData();
        };

        fetchDataAsync();
    }, [languageDetailsData?.Language_Code]);



    useEffect(() => {
        const handleResize = () => {
            if (containerRef?.current?.clientWidth) {
                setContainerWidth(containerRef?.current?.clientWidth);
            }
        };
        handleResize();
        calculateSlidesPerView()

    }, [playlistShow, containerRef?.current?.clientWidth]);


    const sourceTypeMap = {
        "Artists": "ARTIST",
        "Audios_Albums": "ALBUM",
        "Audios": "AUDIO",
        "Audios_Genre": "GENRE"
    };
    
    const pathTypeMap = {
        "Artists": "artist",
        "Audios_Albums": "album",
        "Audios": "track",
        "Audios_Genre": "audio-genre"
    };
    return (
        <div className="px-lg-3  px-2  w-100" ref={containerRef} >
            {loading ? <PageLoader /> : wishlistData && wishlistData.length > 0 ?
                (<div className="d-flex align-items-center flex-wrap pt-5 " >
                    {wishlistData?.slice((currentPage - 1) * settings?.videos_per_page, currentPage * settings?.videos_per_page)
                        .map((data, index) => (
                            <div className={`col-xl-${calculateSlidesPerView()} p-2 col-lg-4 col-3`}>
                                <MusicSearchCard store={data} type={sourceTypeMap[data?.source || data?.source_type]} path={pathTypeMap[data?.source || data?.source_type]} />
                            </div>))}
                </div>)
                :
                <MessageBox text="No Data found !!!" classname='text-center  d-block py-2 heading' />
            }
            <PaginationViewAll data={wishlistData} handlePage={handlePageChange} currentPage={currentPage} visibleLength={settings?.videos_per_page} />
        </div>
    )
}
const mapStateToProps = (state) => ({
    settings: state.get_setting_Reducer.settings,
    playlistShow: state.get_Playlist_Modal.playlistShow,
    languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData,
});
export default connect(mapStateToProps)(MusicWishListPage)