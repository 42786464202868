// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.moreLanguageBox {
    width: 150px;
    top: 10px;
    left: 52px;
}

.moreLanguageBox>li {
    font-size: 15px;
    line-height: 20px;
    font-weight: bold;
}

.listSub:hover + .moreLanguageBox {
    display: block !important;
}`, "",{"version":3,"sources":["webpack://./src/Styles/BannerOverlayThemeTwo.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,SAAS;IACT,UAAU;AACd;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".moreLanguageBox {\n    width: 150px;\n    top: 10px;\n    left: 52px;\n}\n\n.moreLanguageBox>li {\n    font-size: 15px;\n    line-height: 20px;\n    font-weight: bold;\n}\n\n.listSub:hover + .moreLanguageBox {\n    display: block !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
