import React from "react";
import { Link } from "react-router-dom";
import { MdOutlineCopyright } from "react-icons/md";
import MessageBox from "../../components/MessageBox"
const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="admin-footer theme-bg-color p-3 ">
      <div className="row mx-0">
        <div className="col-12 col-lg-6 d-flex align-items-center gap-3 flex-wrap">
          {/* <Link to="/terms-and-conditions" className=""><MessageBox text="Terms and Conditions" classname='theme-text-color' /> </Link>
          <Link to="/privacy-policy" className="mb-3 mb-md-0"><MessageBox text="Privacy Policy" classname='theme-text-color' /> </Link> */}
        </div>
        <div className="col-12 col-lg-6 d-flex align-items-center  justify-content-md-end flex-wrap">
          <MessageBox text={`Copyright ${currentYear}`} classname='theme-text-color ' />
          <MdOutlineCopyright fill="currentColor" className=" ms-1" style={{ width: "20px", height: "20px", marginRight: "5px" }} />
          {/* <Link to={`${process.env.REACT_APP_API_PATHss}`} target="_blank" className="mx-2 text-decoration-hover ">
          <MessageBox text="Webnexs" classname='text-primary' />
          </Link> */}
          <MessageBox text="All Rights Reserved." classname='theme-text-color mt-3 mt-md-0' />
        </div>
      </div>
    </div>
  );
};

export default Footer;