
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
// import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { connect } from 'react-redux';

const PaginationViewAll = ({ data, handlePage, currentPage, visibleLength, type, settings }) => {

    const { t } = useTranslation();

    const maxVisiblePages = 5;
    const totalPages = Math.ceil(data?.length / visibleLength) || 1;
    const [visiblePages, setVisiblePages] = useState(Array.from({ length: Math.min(totalPages, maxVisiblePages) }, (_, index) => index + 1));

    const updateVisiblePages = useCallback((currentPage) => {
        const newStartPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
        const newEndPage = Math.min(totalPages, newStartPage + maxVisiblePages - 1);
        setVisiblePages([...Array(newEndPage - newStartPage + 1)].map((_, index) => newStartPage + index));
    }, [maxVisiblePages, totalPages]);  // Dependencies for updateVisiblePages

    useEffect(() => {
        updateVisiblePages(currentPage);
    }, [currentPage, totalPages, updateVisiblePages]);  // Dependencies for useEffect


    return (
        // totalPages !== 1 &&
        <>
            <div className="viewAllPagination d-flex justify-content-end align-items-center flex-wrap ">
                <p className="pe-2 text-center mb-2 mb-sm-0 resultText">
                    {t('showing')} {(currentPage - 1) * settings?.videos_per_page + 1} {t('to')}{" "}
                    {Math.min(currentPage * settings?.videos_per_page, data?.length)} {t('of')}{" "}
                    {data?.length} {t('results')}
                </p>
                <div>
                    <button onClick={() => handlePage(currentPage - 1)} disabled={currentPage === 1} className="arrowButton "> {t("Prev")} {/*<FaChevronLeft />*/} </button>
                    {visiblePages?.map((pageNumber) => (<button key={pageNumber} onClick={() => handlePage(pageNumber)} className={`${currentPage === pageNumber ? "active  theme-border-text-color border border-2" : ""} mapButton `} > {pageNumber} </button>))}
                    <button onClick={() => handlePage(currentPage + 1)} disabled={currentPage === totalPages} className="arrowButton"> {t("Next")} {/*<FaChevronRight />*/} </button>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => ({
    settings: state.get_setting_Reducer.settings,
});
export default connect(mapStateToProps)(PaginationViewAll)