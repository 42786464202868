// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.category-card button {
  border-radius: 50px;
  font-size: 16px;
  transition: none;
  background-color: none !important;
}
.inactive-category {
  transition: none;
}
.inactive-category:hover {
  border: 2px solid #0056b3;
}



`, "",{"version":3,"sources":["webpack://./src/components/UserPreference/UserPreference.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,iCAAiC;AACnC;AACA;EACE,gBAAgB;AAClB;AACA;EACE,yBAAyB;AAC3B","sourcesContent":[".category-card button {\n  border-radius: 50px;\n  font-size: 16px;\n  transition: none;\n  background-color: none !important;\n}\n.inactive-category {\n  transition: none;\n}\n.inactive-category:hover {\n  border: 2px solid #0056b3;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
