import { connect } from "react-redux"
import LazyLoadImage from "../HomePage/HomeSwiperComponents/LazyLoadImage"
import MessageBox from "../../components/MessageBox"
import { Link } from "react-router-dom"
import PlayButton from "./CommonComponents/PlayButton"
import { useMyContext } from "../../components/Layout/CommonProvider"
const MusicCard = ({ data, PropsType, languageDetailsData, currentPlaying, currentAudio, playlistData }) => {
    const { navigateTranslateChecker } = useMyContext()
    return (
        <div className='listContainer position-relative rounded-3 theme-bg-color theme-border-color  w-100 p-2' key={data?.id} style={{ borderStyle: "solid", borderWidth: "1px" }}>
            <div className='position-relative overflow-hidden rounded-2 ' >
                <LazyLoadImage key={data?.title} src={data?.image_url ? data?.image_url : data?.Thumbnail} alt={data?.title} title={data?.title ? data?.title : data?.artist_name ? data?.artist_name : data?.albumname} imageSize={`rounded-2  overflow-hidden   musicDetailsImage object-fit-cover`} classNameOptional={`rounded-2   musicImageDetailsHeight`} />
            </div>
            <Link to={navigateTranslateChecker(`/music/${PropsType == "AUDIO" ? "track" : PropsType == "PLAYLIST" ? "playlist" : PropsType == "STATION" ? "station" : PropsType == "GENRE" ? "genre" : "artist"}/${data?.artist_slug ? data?.artist_slug : data?.slug}`)} ><MessageBox classname='artistsCardTitle theme-text-color mt-2 position-relative toolTipParent' text={data?.artist_name ? data?.artist_name : data?.albumname ? data?.albumname : data?.title ? data?.title : data?.name} /></Link>
            <MessageBox classname='d-block musicDescriptions theme-text-color text-wrap mt-1 opacity-75' text={data?.description ? data?.description : "Lorem In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available."} />
            <PlayButton type={PropsType} store={data} />
        </div>
    )
}
const mapStateToProps = state => ({
    languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData,
    currentAudio: state.get_current_Reducers.currentAudio,
    currentPlaying: state.get_current_Reducers.currentPlaying,
    playlistData: state.get_current_Reducers.playlistData,
})
export default connect(mapStateToProps)(MusicCard)