import React, { useEffect, useState } from 'react'
import { IconMyChat, IconMyPhoneRing, IconMySearch } from '../assets/icons/MyIcons'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PageLoader from '../components/Loader/PageLoader'
import { useMyContext } from '../components/Layout/CommonProvider'
const Contact = ({ languageDetailsData }) => {
    const [loading, setLoading] = useState(true)
    const { navigateTranslateChecker } = useMyContext()

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }, [])
    return (
        loading ? <PageLoader /> :
            <div className='pb-5 paddingTop col-lg-8 col-md-10 col-12 mx-auto themeTwoFaq px-2 px-lg-0'>
                <h1 className='small-lg-heading text-center py-5'>Contact Us</h1>
                <h1 className='small-lg-heading text-start pb-2'>How can we help?</h1>
                <div className='position-relative mb-2'>
                    <input type='text' className='theme-bg-color-secondary px-5 py-4 mb-5 rounded-2 w-100  d-block  border-0 outline-none' placeholder='Type your question or topic' />
                    <IconMySearch styled={{ width: "20px", height: "20px", className: "faqSearchThemeTwo position-absolute " }} />
                </div>
                <p className='small-md-heading mb-4 fw-bold'>Quick Links</p>
                <Link to={navigateTranslateChecker(`/`)} className='mb-3 d-block text-decoration-underline'>Content Grievances in India</Link>
                <Link to={navigateTranslateChecker(`/`)} className='mb-3 d-block text-decoration-underline'>Request TV shows or movies</Link>
                <Link to={navigateTranslateChecker(`/myprofile/info`)} className='mb-3 d-block text-decoration-underline'>Update email</Link>
                <Link to={navigateTranslateChecker(`/myprofile/billing`)} className='mb-3 d-block text-decoration-underline'> Update password</Link>
                <Link to={navigateTranslateChecker(`/myprofile/billing`)} className='mb-3 d-block text-decoration-underline'>Update payment method</Link>
                {/* <Link to={navigateTranslateChecker(`/myprofile/billing`)} className='mb-3 d-block text-decoration-underline'>Update payment method</Link> */}
                <p className='small-md-heading mb-4 mt-5 fw-bold'>Get in Touch</p>

                <div className='d-flex align-items-center gap-3'>
                    <button type="button" className='theme-text-color-button d-flex align-items-center gap-2 theme-bg-color-secondary ps-3 pe-5 py-3 rounded-2'>
                        <IconMyPhoneRing styled={{ width: "20px", height: "20px", className: "theme-text-color-button" }} />
                        <span>Call Us</span>
                    </button>
                    <button type="button" className='theme-text-color-button d-flex align-items-center gap-2 theme-bg-color-secondary ps-3 pe-5 py-3 rounded-2'>
                        <IconMyChat styled={{ width: "20px", height: "20px", className: "theme-text-color-button" }} />
                        <span>Chat with Us</span>
                    </button>
                </div>
            </div>
    )
}

const mapStateToProps = (state) => ({
    languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData,
});
export default connect(mapStateToProps)(Contact);
