import { combineReducers, applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { composeWithDevTools } from "redux-devtools-extension";
import { footerReducer, homeBannerReducer, settingsReducer, allHomePageReducer, videosDetailsReducer, seriesDetailsReducer, liveDetailsReducer, artistDetailsReducer, channelDetailsReducer, contentDetailsReducer, allThumbnailReducer, headerIndexReducer, profileDetailsReducer, CurrentReducers, playDetailsReducer, viewAllPageReducer, languagePageReducer, viewAllPageIdReducer, liveCatPageReducer, viewAllPageCatIdReducer, videoCatPageReducer, seriesCatPageReducer, audioCatPageReducer, episodeDetailsReducer, getwatchLaterListReducer, getWishListReducer, getMusicSettingsReducer, siteThemeReducer, displayTranslateLanguageReducer, getUserTranslateLanguageReducer, seasonDetailsReducer, filterReducer, movieHomePageReducer, showHomePageReducers, signUpSettingReducer, labelReducer } from "./Redux/Reducers/apiReducers";
import { MetaReducer } from "./Redux/Reducers/MetaReducer";
import { MusicViewAllPageReducer, PlaylistModalReducers, currentReducers, getMusicHomePageReducer, playlistReducers, songDetailsReducer } from "./Redux/Reducers/MusicReducer";
import { currencyReducers } from "./Redux/Reducers/CurrencyReducer";
const finalReducers = combineReducers({
  get_playlist_Reducer: playlistReducers,
  get_current_Reducers: currentReducers,
  get_songDetails_Reducer: songDetailsReducer,
  get_Footer_Reducer: footerReducer,
  get_HomeSlider_Reducer: homeBannerReducer,
  get_viewall_Reducer: viewAllPageReducer,
  get_setting_Reducer: settingsReducer,
  get_filter_Reducer: filterReducer,
  get_siteTheme_Reducer: siteThemeReducer,
  get_displayTranslateLanguage_Reducer: displayTranslateLanguageReducer,
  get_getUserTranslateLanguage_Reducer: getUserTranslateLanguageReducer,
  get_videoDetails_Reducer: videosDetailsReducer,
  get_seriesDetails_Reducer: seriesDetailsReducer,
  get_liveDetails_Reducer: liveDetailsReducer,
  get_artistDetails_Reducer: artistDetailsReducer,
  get_channelDetails_Reducer: channelDetailsReducer,
  get_contentDetails_Reducer: contentDetailsReducer,
  get_allHomePage_Reducer: allHomePageReducer,
  get_allThumbnail_Reducer: allThumbnailReducer,
  get_headerIndex_Reducer: headerIndexReducer,
  get_profileDetails_Reducer: profileDetailsReducer,
  get_playlistDetails_Reducer: playDetailsReducer,
  get_languagePageList_Reducer: languagePageReducer,
  get_liveCatPageList_Reducer: liveCatPageReducer,
  get_videoCatPageList_Reducer: videoCatPageReducer,
  get_seriesCatPageList_Reducer: seriesCatPageReducer,
  get_audioCatPageList_Reducer: audioCatPageReducer,
  get_viewAll_Id_Reducer: viewAllPageIdReducer,
  get_viewAll_CatId_Reducer: viewAllPageCatIdReducer,
  get_episodeDetails_Reducer: episodeDetailsReducer,
  get_seasonDetails_Reducer: seasonDetailsReducer,
  get_watchlaterList_Reducer: getwatchLaterListReducer,
  get_wishList_Reducer: getWishListReducer,
  get_Music_Settings: getMusicSettingsReducer,
  get_Meta_Data: MetaReducer,
  get_Music_Home_Page_Reducer: getMusicHomePageReducer,
  get_Playlist_Modal: PlaylistModalReducers,
  get_Music_ViewAll_Reducer: MusicViewAllPageReducer,
  get_Currency_Reducer: currencyReducers,
  movieHomePage: movieHomePageReducer,
  showHomePage: showHomePageReducers,
  signupSettings: signUpSettingReducer,
  labelSettings: labelReducer

});
const initialState = {};
const composeEnhancers = composeWithDevTools({});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["get_current_Reducers", "get_playlist_Reducer"],
};
const persistedReducer = persistReducer(persistConfig, finalReducers);
const store = createStore(
  persistedReducer,
  initialState,
  composeEnhancers(applyMiddleware(thunk))
);

let persistor = persistStore(store);
persistor.subscribe(() => {
  store.dispatch({ type: "GET_PLAYING_SUCCESS", isPlaying: false });
  const state = store.getState();
  if (state.get_current_Reducers.currentAudio?.id) {
    store.dispatch({ type: "OPEN_PLAYLIST", payload: true });
    store.dispatch({ type: "GET_CLOSE_SUCCESS", isClose: true });
  }
  else {
    store.dispatch({ type: "OPEN_PLAYLIST", payload: false });
    store.dispatch({ type: "GET_CLOSE_SUCCESS", isClose: false });
  }
});

export { persistor, store };