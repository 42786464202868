// import { useState, useEffect } from "react";
// import axios from "axios";

// const usePpvGlobalImageConfig = () => {
//     const access_token = localStorage.getItem("access_token");
//     const adminApiHeader = {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + access_token,
//         Accept: "application/json",
//         "Access-Control-Allow-Origin": "*",
//     };

//     const [currency, setCurrency] = useState({ code: '', country: '', symbol: '', enable_multi_currency: '' });
//     const [currencySymbol, setCurrencySymbol] = useState('');
//     const [locationResponselocationResponse, setLocationResponselocationResponse] = useState('');
//     const [userCurrency, setUserCurrency] = useState('');


//     const fetchCurrencyData = async () => {
//         try {
//             const currencyResponse = await axios.get(`${process.env.REACT_APP_Baseurl}/Front-End/Currency-Index`);
//             const currencyData = currencyResponse?.data;
//             setCurrency(currencyData?.CurrencySetting);
//         } catch (error) {
//             console.error('Error fetching currency data:', error);
//         }
//     };


//     const fetchLocationData = async () => {
//         try {
//             const locationResponse = await axios.get(`${process.env.REACT_APP_API_IP_Location}`);
//             const country = locationResponse?.data?.country;
//             const country_code = locationResponse?.data?.country_code;
//             const locationResponselocationResponse = locationResponse?.data;
//             setLocationResponselocationResponse(locationResponselocationResponse);

//             if (country) {
//                 const currencyResponse = await axios.get(`${process.env.REACT_APP_country_code}/${country_code}`);
//                 const symbol = currencyResponse?.data[0]?.currencies[Object.keys(currencyResponse?.data[0]?.currencies)[0]].symbol;
//                 setCurrencySymbol(symbol);
//                 setUserCurrency(Object.keys(currencyResponse?.data[0]?.currencies)[0]);
//             } else {
//                 console.error('Country code is undefined.');
//             }
//         } catch (error) {
//             console.error('Error fetching location data:', error);
//         } finally {
//             // setLoading(false);
//         }
//     };


//     useEffect(() => {
//         fetchLocationData();
//         fetchCurrencyData();
//     }, []);

//     return { currency, currencySymbol, userCurrency, locationResponselocationResponse };
// };

// export default usePpvGlobalImageConfig;



// import { useState, useEffect } from "react";
// import axios from "axios";

// const usePpvGlobalImageConfig = () => {
//     const access_token = localStorage.getItem("access_token");
//     const adminApiHeader = {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + access_token,
//         Accept: "application/json",
//         "Access-Control-Allow-Origin": "*",
//     };

//     const [currency, setCurrency] = useState({ code: '', country: '', symbol: '', enable_multi_currency: '' });
//     const [currencySymbol, setCurrencySymbol] = useState('');
//     const [locationResponselocationResponse, setLocationResponselocationResponse] = useState('');
//     const [userCurrency, setUserCurrency] = useState('');
//     const [loading, setLoading] = useState(true); // New loading state

//     const fetchCurrencyData = async () => {
//         try {
//             const currencyResponse = await axios.get(`${process.env.REACT_APP_Baseurl}/Front-End/Currency-Index`);
//             const currencyData = currencyResponse?.data;
//             setCurrency(currencyData?.CurrencySetting);
//         } catch (error) {
//             console.error('Error fetching currency data:', error);
//         }
//     };

//     if (currency)
//     const fetchLocationData = async () => {
//         try {
//             const locationResponse = await axios.get(`${process.env.REACT_APP_API_IP_Location}`);
//             const country = locationResponse?.data?.country;
//             const country_code = locationResponse?.data?.country_code;
//             const locationResponselocationResponse = locationResponse?.data;
//             setLocationResponselocationResponse(locationResponselocationResponse);

//             if (country) {
//                 const currencyResponse = await axios.get(`${process.env.REACT_APP_country_code}/${country_code}`);
//                 const symbol = currencyResponse?.data[0]?.currencies[Object.keys(currencyResponse?.data[0]?.currencies)[0]].symbol;
//                 setCurrencySymbol(symbol);
//                 setUserCurrency(Object.keys(currencyResponse?.data[0]?.currencies)[0]);
//             } else {
//                 console.error('Country code is undefined.');
//             }
//         } catch (error) {
//             console.error('Error fetching location data:', error);
//         }
//     };

//     // Combine both fetching functions
//     const fetchAllData = async () => {
//         await fetchLocationData();
//         await fetchCurrencyData();
//         setLoading(false); // Set loading to false after data is fetched
//     };

//     useEffect(() => {
//         fetchAllData(); // Call the combined function only once
//     }, []);

//     if (loading) {
//         return { loading }; // Optionally, return loading state
//     }

//     return { currency, currencySymbol, userCurrency, locationResponselocationResponse };
// };

// export default usePpvGlobalImageConfig;



import { useState, useEffect } from "react";
import axios from "axios";

const usePpvGlobalImageConfig = () => {
    const access_token = localStorage.getItem("access_token");
    const adminApiHeader = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
    };

    const [currency, setCurrency] = useState({ code: '', country: '', symbol: '', enable_multi_currency: '' });
    const [currencySymbol, setCurrencySymbol] = useState('');
    const [locationResponselocationResponse, setLocationResponselocationResponse] = useState('');
    const [userCurrency, setUserCurrency] = useState('');
    const [country, setCountry] = useState('');
    const [country_code, setCountry_code] = useState('');
    const [loading, setLoading] = useState(true);
    // Fetch currency data only once
    const fetchCurrencyData = async () => {
        try {
            const currencyResponse = await axios.get(`${process.env.REACT_APP_Baseurl}/Front-End/Currency-Index`);
            const currencyData = currencyResponse?.data;
            setCurrency(currencyData?.CurrencySetting);

            const locationResponse = await axios.get(`${process.env.REACT_APP_API_IP_Location}`);
            const country = locationResponse?.data?.country;
            const country_code = locationResponse?.data?.country_code;
            setLocationResponselocationResponse(locationResponse.data);
            setCountry(locationResponse?.data?.country);
            setCountry_code(locationResponse?.data?.country_code);

        } catch (error) {
            console.error('Error fetching currency data:', error);
        }
    };

    // Fetch location data if enable_multi_currency is 1
    const fetchLocationData = async () => {
        try {
            if (country) {
                const currencyResponse = await axios.get(`${process.env.REACT_APP_country_code}/${country_code}`);
                const currencies = currencyResponse?.data[0]?.currencies;

                // Extract the currency code and symbol
                const currencyCode = Object.keys(currencies)[0]; // Get the first currency code
                const symbol = currencies[currencyCode]?.symbol; // Get the symbol using the currency code

                // Set state with the extracted values
                setCurrencySymbol(symbol);
                setUserCurrency(currencyCode);
            } else {
                console.error('Country code is undefined.');
            }
        } catch (error) {
            console.error('Error fetching location data:', error);
        }
    };


    // This effect fetches currency data only once when the component mounts
    useEffect(() => {
        fetchCurrencyData(); // Fetch currency data on initial render
    }, []); // Empty dependency array to run only once

    // This effect fetches location data based on the currency's enable_multi_currency
    useEffect(() => {
        if (currency?.enable_multi_currency === 1) { // Check if enable_multi_currency is 1
            fetchLocationData(); // Fetch location data only if currency.enable_multi_currency is 1
        }
    }, [currency]); // Run when currency changes

    useEffect(() => {
        if (loading) {
            setLoading(false); // Set loading to false after fetching data
        }
    }, [locationResponselocationResponse, currency, userCurrency]); // Set loading to false once data is available

    return { loading, currency, currencySymbol, userCurrency, locationResponselocationResponse };
};

export default usePpvGlobalImageConfig;
