import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import JoditEditor from "jodit-react";
import StepContent from "@mui/material/StepContent";
import Typography from "@mui/material/Typography";
import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import "./Manage_Episode.css";
import Select from "react-select";
import Dropzone from "react-dropzone";
import { FaEllipsisVertical } from "react-icons/fa6";
import { toast } from "react-toastify";

import { TagsInput } from "react-tag-input-component";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import ResponseLoader from "../../../components/Loader/ResponseLoader";
import Wrapper from "../../CommonComponents/Wrapper";
import MessageBox from "../../../components/MessageBox";
import MessageLabel from "../../CommonComponents/MessageLabel";
import FormContainer from "../../CommonComponents/FormContainer";
import RightWrapper from "../../CommonComponents/RightWrapper";
import LeftWrapper from "../../CommonComponents/LeftWrapper";
import AdminCustomButton from "../../../components/adminCommonComponents/AdminCustomButton";
import { IoClose } from "react-icons/io5";
import AdminSaveHeader from "../../../components/adminCommonComponents/AdminSaveHeader";
import MessageSubHeading from "../../CommonComponents/MessageSubHeading";
import MessageHeading from "../../CommonComponents/MessageHeading";
import AdminDeleteModal from "../../../components/adminCommonComponents/AdminDeleteModal";
import AdminNavbar from "../../../components/adminCommonComponents/AdminNavbar";
import AdminHeader from "../../../components/adminCommonComponents/AdminHeader";
import NoRecordTable from "../../../components/adminCommonComponents/NoRecordTable";
import { CiShare1 } from "react-icons/ci";
import AdminSliderModal from "../../../components/adminCommonComponents/AdminSliderModal";

export default function Manage_Episode() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [modelClose, setModelClose] = useState(false);
  const [itemToDelete, setItemToDelete] = useState([]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const [series, setSeries] = useState([]);
  const [count, setCount] = useState();
  const [data, setData] = useState([]);
  const [videodata, setVideodata] = useState("");
  const [defaultImage, setDefaultimage] = useState();
  const [defaultImageurl, setDefaultimageurl] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [message, setMessage] = useState("");
  const [Subtitlesmap, setSubtitlesmap] = useState([]);
  const { id } = useParams();
  const { seriesid } = useParams();
  const [free_content_duration, setFree_content_duration] = useState("");
  const [episode_id, setEpisode_id] = useState("");

  const [type, setType] = useState("");
  const [access, setAccess] = useState("");
  const [ppv_status, setPpv_status] = useState("");
  const [description, setDescription] = useState("");
  const [mp4_url, setMp4_url] = useState("");
  const [url, setUrl] = useState("");
  const [path, setPath] = useState("");
  const [disk, setDisk] = useState("");
  const [stream_path, setStream_path] = useState("");
  const [processed_low, setProcessed_low] = useState("");
  const [converted_for_streaming_at, setConverted_for_streaming_at] =
    useState("");
  const [active, setActive] = useState("");
  const [skip_recap, setSkip_recap] = useState("");
  const [skip_intro, setSkip_intro] = useState("");
  const [recap_start_time, setRecap_start_time] = useState("");
  const [recap_end_time, setRecap_end_time] = useState("");
  const [intro_start_time, setIntro_start_time] = useState("");
  const [intro_end_time, setIntro_end_time] = useState("");
  const [featured, setFeatured] = useState("");

  const [banner, setBanner] = useState("");
  const [footer, setFooter] = useState("");
  const [uploaded_by, setUploaded_by] = useState("");
  const [views, setViews] = useState("");
  const [rating, setRating] = useState("");
  const [status, setStatus] = useState("");
  const [searchTags, setSearchTags] = useState([]);
  const [episode_ads, setEpisode_ads] = useState("");
  const [ads_position, setAds_position] = useState("");

  const [websitetitle, setWebsitetitle] = useState("");
  const [metadescription, setMetadescription] = useState("");
  const [websiteurl, setWebsiteurl] = useState("");

  const [free_duration_status, setFree_duration_status] = useState("");
  const [editUser, setEditUser] = useState({
    title: "",
    duration: "",
    free_duration_time: "",
  });

  const [adsPositions, setAdsPositions] = useState([]);
  const [selectedAdsPosition, setSelectedAdsPosition] = useState(null);
  const [secondSelectOptions, setSecondSelectOptions] = useState([]);

  const [user_access, setUser_access] = useState([]);
  const [inapppurchase, setInappPurchase] = useState("");
  const [age_restrictdata, setAge_restrictdata] = useState([]);
  const [age_restrict, setAge_restrict] = useState([]);
  const [publish_time, setPublish_time] = useState("");

  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValueAPI, setInputValueAPI] = useState("");

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
    if (!checkboxChecked) {
      setInputValue(inputValueAPI);
    } else {
      setInputValue("");
    }
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const [ios_ppv_price, setIos_ppv_price] = useState("");
  const handleAdsPositionChange = (selectedValue5) => {
    setSelectedAdsPosition(selectedValue5);
  };

  const navigate = useNavigate();

  const [videosubtitles, setVideoSubtitles] = useState({});

  const handleDropsubtitles = (language, acceptedFiles) => {
    // Handle dropped files here
    setVideoSubtitles((prevState) => ({
      ...prevState,
      [language]: acceptedFiles,
    }));
  };
  const access_token_channel = localStorage.getItem("access_token_channel");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token_channel,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const [autoFillValuetitle, setAutoFillValuetitle] = useState("");
  const handleInputKeyDowntitle = (event) => {
    if (event.key == "Enter" || event.key == "Tab") {
      generateSlug(editUser?.title);
    }
  };
  const handleBlurtitle = () => {
    generateSlug(editUser?.title);
  };
  const handleAutoFillChangetitle = (event) => {
    setValidationMessageslug("");
    setIsValidationHiddenslug(true);
    setAutoFillValuetitle(event.target.value);
  };

  const generateSlug = (title) => {
    title = title.trim();
    const slug = title.toLowerCase().replace(/\s+/g, "-");
    setAutoFillValuetitle(slug);
  };
  const [inputValueyear, setInputValueyear] = useState("");

  // const handleChangeyear = (event) => {
  //   const value = event.target.value;
  //   if (/^\d{0,4}$/.test(value)) {
  //     setInputValueyear(value);
  //   }
  // };

  const handleChangeyear = (event) => {
    const value = event.target.value;
    setEditUser({ ...editUser, year: value });
  };

  const [errors, setErrors] = useState({
    error_skip_start_time: "",
    error_skip_end_time: "",
    error_skip_start_session: "",
    error_recap_start_time: "",
    error_recap_end_time: "",
    error_recap_start_session: "",
  });

  const [userTime, setUserTime] = useState({
    skip_start_time: "",
    skip_end_time: "",
    skip_start_session: "",
    recap_start_time: "",
    recap_end_time: "",
    recap_start_session: "",
    url_linktym: "",
  });

  const handleInputintrotime = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    // Remove non-digit characters from input
    const formattedInput = inputValue.replace(/\D/g, "");

    // Add colons to maintain the format HH:MM:SS
    const formattedTime = formattedInput
      .replace(/^(\d{2})(\d{2})(\d{2})$/, "$1:$2:$3")
      .substr(0, 8);

    setUserTime({
      ...userTime,
      [inputName]: formattedTime,
    });

    validateInput(inputName, formattedTime);
  };

  const validateInput = (inputName, formattedTime) => {
    const errorsCopy = { ...errors };

    switch (inputName) {
      case "skip_start_time":
        if (formattedTime == "") {
          errorsCopy.error_skip_start_time = "";
        } else {
          errorsCopy.error_skip_start_time = "";
        }
        break;

      case "skip_end_time":
        if (formattedTime == "") {
          errorsCopy.error_skip_end_time = "";
        } else if (formattedTime <= userTime.skip_start_time) {
          errorsCopy.error_skip_end_time =
            "Skip End time must be greater than Skip Start time";
        } else {
          errorsCopy.error_skip_end_time = "";
        }
        break;

      case "skip_start_session":
        if (formattedTime == "") {
          errorsCopy.error_skip_start_session = "";
        } else if (formattedTime <= userTime.skip_end_time) {
          errorsCopy.error_skip_start_session =
            "Skip Start Session must be greater than Skip End time";
        } else {
          errorsCopy.error_skip_start_session = "";
        }
        break;

      case "recap_start_time":
        if (formattedTime == "") {
          errorsCopy.error_recap_start_time = "";
        } else {
          errorsCopy.error_recap_start_time = "";
        }
        break;

      case "recap_end_time":
        if (formattedTime == "") {
          errorsCopy.error_recap_end_time = "";
        } else if (formattedTime <= userTime.recap_start_time) {
          errorsCopy.error_recap_end_time =
            "Recap End time must be greater than Recap Start time";
        } else {
          errorsCopy.error_recap_end_time = "";
        }
        break;

      case "recap_start_session":
        if (formattedTime == "") {
          errorsCopy.error_recap_start_session = "";
        } else if (formattedTime <= userTime.recap_end_time) {
          errorsCopy.error_recap_start_session =
            "Recap Start Session must be greater than Recap End time";
        } else {
          errorsCopy.error_recap_start_session = "";
        }
        break;

      default:
        break;
    }

    setErrors(errorsCopy);
  };

  const insertColons = (time) => {
    if (time?.length >= 6) {
      const hours = time?.substr(0, 2);
      const minutes = time?.substr(2, 2);
      const seconds = time?.substr(4, 2);

      return `${hours}:${minutes}:${seconds}`;
    }
    return time;
  };



  const handleInputdata = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    // Remove non-digit characters from input
    const formattedInput = inputValue.replace(/\D/g, "");

    // Add colons to maintain the format HH:MM:SS
    const formattedTime = formattedInput
      .replace(/^(\d{2})(\d{2})(\d{2})$/, "$1:$2:$3")
      .substr(0, 8);

    setEditUser({
      ...editUser,
      [inputName]: formattedTime,
    });
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessageslug("");
    setIsValidationHiddenslug(true);
    setValidationMessagetitle("");
    setIsValidationHiddentitle(true);

    setEditUser({ ...editUser, title: e.target.value });
    setEditUser({ ...editUser, [e.target.name]: e.target.value });

    setActive({ [e.target.name]: e.target.value });
    setFeatured({ ...featured, [e.target.name]: e.target.value });
    setStatus({ ...status, [e.target.name]: e.target.value });
    setPpv_status({ ...ppv_status, [e.target.name]: e.target.value });
    setBanner({ ...banner, [e.target.name]: e.target.value });
    setActive({ ...active, [e.target.name]: e.target.value });
    setViews({ ...views, [e.target.name]: e.target.value });
    setFooter({ ...footer, [e.target.name]: e.target.value });
    setFree_duration_status({
      ...free_duration_status,
      [e.target.name]: e.target.value,
    });

    if (e.target.checked == true) {
      setPpv_status({ ...ppv_status, [e.target.name]: 1 });
      setFeatured({ ...featured, [e.target.name]: 1 });
      setStatus({ ...status, [e.target.name]: 1 });
      setBanner({ ...banner, [e.target.name]: 1 });
      setActive({ ...active, [e.target.name]: 1 });
      setViews({ ...views, [e.target.name]: 1 });
      setFooter({ ...footer, [e.target.name]: 1 });
      setFree_duration_status({
        ...free_duration_status,
        [e.target.name]: 1,
      });
    } else {
      setPpv_status({ ...ppv_status, [e.target.name]: 0 });
      setFeatured({ ...featured, [e.target.name]: 0 });
      setBanner({ ...banner, [e.target.name]: 0 });
      setActive({ ...active, [e.target.name]: 0 });
      setViews({ ...views, [e.target.name]: 0 });
      setFooter({ ...footer, [e.target.name]: 0 });
      setFree_duration_status({
        ...free_duration_status,
        [e.target.name]: 0,
      });
    }
  };

  const contentFieldChanaged = (data) => {
    setDescription(data);
  };

  const handleTagsChange = (newTags) => {
    // Convert tags to lowercase, remove duplicates, and filter out empty tags
    const cleanedTags = newTags
      .map((tag) => tag.trim().toLowerCase())
      .filter((tag) => tag !== "")
      .filter((value, index, self) => self.indexOf(value) == index);
    setSearchTags(cleanedTags);
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const [uploadPercentage, setUploadPercentage] = useState(0);
  const cancelFileUpload = useRef(null);

  const cancelUpload = () => {
    if (cancelFileUpload.current)
      cancelFileUpload.current("User has canceled the file upload.");
  };

  const [uploadPercentagefile, setUploadPercentagefile] = useState(0);
  const cancelFileUploadfile = useRef(null);

  const cancelUploadfile = () => {
    if (cancelFileUploadfile.current)
      cancelFileUploadfile.current("User has canceled the file upload.");
  };

  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const handleUpload = async (files) => {
    const uploadUrl = `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-Episode/upload`;

    const uploaders = files.map((file) => {
      const formData = new FormData();
      formData.append("id", id);
      formData.append("file", file);
      formData.append("seriesid", seriesid);

      return axios.post(uploadUrl, formData, {
        headers: headers,
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
      });
    });

    try {
      await Promise.all(uploaders);
      const responses = await Promise.all(uploaders);
      if (responses[0]?.data?.status == true) {
        responses?.forEach((response, index) => {
          setEditUser(response?.data?.Episode);
          setEpisode_id(response?.data?.Episode_id);
        });
        const uploadedDetails = files.map((file, index) => ({
          name: file.name,
          size: (file.size / (1024 * 1024)).toFixed(2), // Convert bytes to MB with 2 decimal places
        }));
        setUploadedFiles(uploadedDetails);
        setUploadCompleted(true);
        setUploadProgress(0);
        toast.success(responses[0]?.data?.message);
      } else {
        toast.error(responses[0]?.data?.message);
        setUploadProgress(0);
      }
    } catch (error) {
      alert("Error uploading videos. Please try again.");
      setUploadProgress(0);
    }
  };

  const resetUploadState = () => {
    setUploadProgress(0);
    setUploadCompleted(false);
    setUploadedFiles([]);
  };

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);

  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = (
    setSelectedFile1,
    setSelectedFile2,
    setSelectedFile3
  ) => {
    setSelectedFile1(null);
    if (fileInputRef1.current) {
      fileInputRef1.current.value = null;
    }
    setSelectedFile2(null);
    if (fileInputRef2.current) {
      fileInputRef2.current.value = null;
    }
    setSelectedFile3(null);
    if (fileInputRef3.current) {
      fileInputRef3.current.value = null;
    }
  };

  // Multiselect
  const [block_country, setBlock_Country] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [available_country, setAvailable_country] = useState([]);
  const [
    selectedOptionsAvailable_country,
    setSelectedOptionsAvailable_country,
  ] = useState([]);

  const [related_videos, setRelated_videos] = useState([]);
  const [selectedOptionsRelated_videos, setSelectedOptionsRelated_videos] =
    useState([]);

  const [language, setLanguage] = useState([]);
  const [selectedOptionslanguage, setSelectedOptionslanguage] = useState([]);

  const [artists, setArtists] = useState([]);
  const [selectedOptionsartists, setSelectedOptionsartists] = useState([]);

  const [category, setCategory] = useState([]);
  const [selectedOptionscategory, setSelectedOptionscategory] = useState([]);

  useEffect(() => {
    fetchOptions();
  }, []);

  const fetchOptions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-Episode/create`,
        { headers: headers }
      );
      setBlock_Country(response?.data?.Block_Country);
      setAvailable_country(response?.data?.Available_country);
      setRelated_videos(response?.data?.Related_videos);
      setLanguage(response?.data?.video_language);
      setArtists(response?.data?.video_artist);
      setCategory(response?.data?.video_category);
      setInputValueAPI(response?.data?.ppv_gobal_price);
      var age_restrictdata = response?.data?.Age_Restrict;
      setAge_restrictdata(age_restrictdata);
      const subtitlesmap = response?.data?.Subtitle;
      setSubtitlesmap(subtitlesmap);
      var result = response?.data?.user_access;
      var InappPurchase = response?.data?.InappPurchase;
      setUser_access(result);
      setInappPurchase(InappPurchase);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
  };

  const handleSelectChangeRelated_videos = (selectedValues) => {
    setSelectedOptionsRelated_videos(selectedValues);
  };

  const handleSelectChangeAvailable_country = (selectedValues) => {
    setSelectedOptionsAvailable_country(selectedValues);
  };

  const handleSelectChangelanguage = (selectedValues) => {
    setSelectedOptionslanguage(selectedValues);
  };

  const handleSelectChangeartists = (selectedValues) => {
    setSelectedOptionsartists(selectedValues);
  };

  const handleSelectChangecategory = (selectedValues) => {
    setSelectedOptionscategory(selectedValues);
  };

  // Multiselect

  useEffect(async () => {
    await axios
      .get(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-Episode/list`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        var result = response?.data?.Episodes;
        setData(result);
      })
      .catch((error) => console.log(error));
  }, []);

  const [validationMessagetitle, setValidationMessagetitle] = useState("");
  const [isValidationHiddentitle, setIsValidationHiddentitle] = useState(true);

  const [validationMessageslug, setValidationMessageslug] = useState("");
  const [isValidationHiddenslug, setIsValidationHiddenslug] = useState(true);

  const [validationMessagecategory, setValidationMessagecategory] =
    useState("");
  const [isValidationHiddencategory, setIsValidationHiddencategory] =
    useState(true);

  const [validationMessagelanguage, setValidationMessagelanguage] =
    useState("");
  const [isValidationHiddenlanguage, setIsValidationHiddenlanguage] =
    useState(true);

  const titleInputRef = useRef(null);
  const categoryInputRef = useRef(null);
  const languageInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  async function Manage_Episode() {
    let focusInputRef = null;

    if (editUser?.title == "") {
      setValidationMessagetitle("Title cannot be empty*.");
      setIsValidationHiddentitle(false);
      focusInputRef = titleInputRef;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    const editInputfeatured = featured?.featured;
    var datafeatured = JSON.stringify(editInputfeatured);
    var featureddata = datafeatured;

    const editInputbanner = banner?.banner;
    var databanner = JSON.stringify(editInputbanner);
    var bannerdata = databanner;

    const editInputvalue = active?.active;
    var data = JSON.stringify(editInputvalue);
    var aactivedata = data;

    const editInputvaluefooter = footer?.footer;
    var data = JSON.stringify(editInputvaluefooter);
    var footerdata = data;

    const editInputfree_duration_statusdata =
      free_duration_status?.free_duration_status;
    var data = JSON.stringify(editInputfree_duration_statusdata);
    var free_duration_statusdata = data;

    const formData = new FormData();

    formData.append("series_id", seriesid);
    formData.append("season_id", id);
    formData.append("id", episode_id);
    formData.append("title", editUser?.title);
    formData.append("slug", autoFillValuetitle);
    // formData.append("episode_description", episode_description);
    formData.append("episode_description", description);
    formData.append("type", type);
    formData.append("access", access);

    const enableppv_status = Number.isNaN(parseInt(inputValue, 10)) ? 0 : parseInt(inputValue, 10);
    formData.append("ppv_status", enableppv_status);
    // formData.append("ppv_status", inputValue);
    formData.append("ios_ppv_price", ios_ppv_price?.value);
    formData.append("ppv_price", inputValue);
    formData.append("mp4_url", mp4_url);
    formData.append("url", url);
    formData.append("path", path);
    formData.append("disk", disk);
    formData.append("stream_path", stream_path);
    formData.append("processed_low", processed_low);
    formData.append("converted_for_streaming_at", converted_for_streaming_at);
    // formData.append("active", aactivedata2 ? parseInt(aactivedata2, 10) : 0);

    const aactivedata2 = Number.isNaN(parseInt(aactivedata, 10)) ? 0 : parseInt(aactivedata, 10);
    formData.append("active", aactivedata2 ? parseInt(aactivedata2, 10) : 0);

    formData.append("year", inputValueyear);

    const enablefree_duration_statusdata = Number.isNaN(parseInt(free_duration_statusdata, 10)) ? 0 : parseInt(free_duration_statusdata, 10);
    formData.append("free_duration_status", enablefree_duration_statusdata);
    formData.append("free_duration_time", editUser?.free_duration_time);

    formData.append(
      "artist_id",
      JSON.stringify(selectedOptionsartists?.map((option) => option?.value))
    );
    formData.append(
      "block_country_id",
      JSON.stringify(selectedOptions?.map((option) => option?.value))
    );
    formData.append(
      "available_countries_id",
      JSON.stringify(
        selectedOptionsAvailable_country?.map((option) => option?.value)
      )
    );
    formData.append(
      "category_id",
      JSON.stringify(selectedOptionscategory?.map((option) => option?.value))
    );
    formData.append(
      "languages_id",
      JSON.stringify(selectedOptionslanguage?.map((option) => option?.value))
    );
    formData.append("age_restrict", age_restrict);
    formData.append(
      "related_videos",
      JSON.stringify(
        selectedOptionsRelated_videos?.map((option) => option?.value)
      )
    );

    // formData.append("skip_recap", skip_recap);
    formData.append("url_linktym", insertColons(userTime?.url_linktym));

    formData.append("skip_start_time", userTime?.skip_start_time);
    formData.append("skip_end_time", userTime?.skip_end_time);
    formData.append("skip_start_session", userTime?.skip_start_session);
    formData.append("recap_start_time", userTime?.recap_start_time);
    formData.append("recap_end_time", userTime?.recap_end_time);
    formData.append("recap_start_session", userTime?.recap_start_session);

    // formData.append("recap_start_time", recap_start_time);
    // formData.append("recap_end_time", recap_end_time);
    // formData.append("intro_start_time", intro_start_time);
    // formData.append("intro_end_time", intro_end_time);

    const enablefeatureddata = Number.isNaN(parseInt(featureddata, 10)) ? 0 : parseInt(featureddata, 10);
    formData.append("featured", enablefeatureddata);
    const enablebannerdata = Number.isNaN(parseInt(bannerdata, 10)) ? 0 : parseInt(bannerdata, 10);
    formData.append("banner", enablebannerdata);
    const enablefooterdata = Number.isNaN(parseInt(footerdata, 10)) ? 0 : parseInt(footerdata, 10);
    formData.append("footer", enablefooterdata);
    formData.append("duration", editUser?.duration);
    formData.append("age_restrict", age_restrict);

    const enableviews = Number.isNaN(parseInt(views, 10)) ? 0 : parseInt(views, 10);
    formData.append("views", enableviews ? parseInt(enableviews, 10) : 0);
    const enablerating = Number.isNaN(parseInt(rating, 10)) ? 0 : parseInt(rating, 10);
    formData.append("rating", enablerating);
    const enablestatus = Number.isNaN(parseInt(status, 10)) ? 0 : parseInt(status, 10);
    formData.append("status", enablestatus);
    formData.append("free_content_duration", free_content_duration);
    formData.append("search_tags", searchTags);
    formData.append("uploaded_by", uploaded_by);
    formData.append("ads_position", ads_position);
    formData.append("episode_ads", episode_ads);

    let firstApiStatus = false;
    let secondApiStatus = false;
    let seventhApiStatus = false;
    let fourthApiStatus = false;
    let resultapi = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-Episode/store`,
        formData,
        { headers: headers }
      );

      if (response.data.status == true) {
        firstApiStatus = true;
        resultapi = response.data;
        const imageFormData = new FormData();
        imageFormData.append("episode_id", episode_id);
        if (selectedFile1) {
          imageFormData.append("image", selectedFile1);
        }
        if (selectedFile2) {
          imageFormData.append("player_image", selectedFile2);
        }
        if (selectedFile3) {
          imageFormData.append("tv_image", selectedFile3);
        }
        const imageResponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-Episode/Image-upload`,
          imageFormData,
          { headers: headers }
        );

        const formDataseo = new FormData();
        formDataseo.append("episode_id", episode_id);
        formDataseo.append("website_page_title", websitetitle);
        formDataseo.append("website_URL", websiteurl);
        formDataseo.append("Meta_description", metadescription);

        const formDataseoresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-Episode/SEO`,
          formDataseo,
          { headers: headers }
        );

        const formDatasubtitles = new FormData();
        formDatasubtitles.append("episode_id", episode_id);

        Object.keys(videosubtitles).forEach((language, index) => {
          formDatasubtitles.append(
            `short_code[${index}]`,
            Subtitlesmap[index].short_code
          );
          formDatasubtitles.append(
            `language[${index}]`,
            Subtitlesmap[index].language
          );
          videosubtitles[language].forEach((file) => {
            formDatasubtitles.append(`subtitles[${index}]`, file);
          });
        });

        const formDatasubtitlesresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-Episode/Subtitles`,
          formDatasubtitles,
          { headers: headers }
        );

        if (imageResponse.data.status == true) {
          secondApiStatus = true;
        }
        if (formDataseoresponse.data.status == true) {
          seventhApiStatus = true;
        }
        if (formDatasubtitlesresponse.data.status == true) {
          fourthApiStatus = true;
        }
      }
    } catch (error) { }

    if (
      firstApiStatus &&
      secondApiStatus &&
      seventhApiStatus &&
      fourthApiStatus
    ) {
      setValidationMessagetitle("");
      setValidationMessageslug("");
      toast.success(resultapi?.message);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      if (resultapi == false) {
        var resultError = resultapi;
        toast.error(resultError?.message);
        setIsValidationHiddentitle(false);
        setValidationMessageslug(false);
        setProcessing(false);
        setShowOverlay(false);
      }
    }
  }

  useEffect(() => {
    if (selectedAdsPosition !== "") {
      const formData = new FormData();
      formData.append("position", selectedAdsPosition?.value);
      axios
        .post(
          `${process.env.REACT_APP_Baseurl}/Channel/advertisement-tag-url`,
          formData,
          { headers: headers }
        )

        .then((response) => {
          const data = response?.data;
          const adsNames = data?.Advertisement?.map((position) => ({
            value: position?.id,
            label: position?.ads_name,
          }));
          setSecondSelectOptions(adsNames);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [selectedAdsPosition]);

  const [Episodesource, setEpisodesource] = useState("");
  function Episode_stream_source() {
    switch (Episodesource) {
      case "guest":
        return <div></div>;
      case "subscriber":
        return <div></div>;
      case "registered":
        return <div></div>;
      case "ppv":
        return (
          <div className="row mt-3 text-start ">
            <div className="col-sm-6">
              <label className="m-0 theme-text-color">PPV Price</label>
              <div className="col-lg-12 row">
                <div className="col-8 col-lg-8">
                  <p className="p1 theme-text-color">
                    Apply PPV Price from Global Settings?{" "}
                  </p>
                </div>
                <div className="col-4 col-lg-4">
                  <label className="switch">
                    <input
                      name="ppv_price"
                      defaultChecked={checkboxChecked}
                      onChange={handleCheckboxChange}
                      type="checkbox"
                    />
                    <span
                      className="slider round"
                      name="ppv_price"
                      checked={checkboxChecked}
                      onChange={handleCheckboxChange}
                    ></span>
                  </label>
                </div>
              </div>

              <div className="panel-body">
                <input
                  type="number"
                  className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"
                  placeholder="PPV Price"
                  name="ppv_price"
                  id="price"
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="col-sm-6">
              <label className="m-0 theme-text-color"> IOS PPV Price</label>
              <p className="p1 theme-text-color">
                Apply IOS PPV Price from Global Settings?
              </p>
              <div className="panel-body ppv_price_ios">
                <select
                  onChange={(e) => setIos_ppv_price(e.target.value)}
                  className="form-select  theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"
                  id="ios_ppv_price"
                  name="ios_ppv_price"
                >
                  <option value="0">Choose a PPV Price</option>
                  {inapppurchase?.map((item, key) => (
                    <option value={item.product_id}>{item.plan_price}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  }
  const [enable_restreampublic, setEnable_restreampublic] = useState("");
  const [enablestreamvisiblepublic, setEnablestreamvisiblepublic] =
    useState("");

  function proceednextpublic() {
    switch (enable_restreampublic) {
      case "publish_now":
        return <div></div>;
      case "publish_later":
        return (
          <div className="mb-3">
            <label className="m-0 theme-text-color">Publish Time</label>
            <input
              type="datetime-local"
              className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"
              id="publish_time"
              name="publish_time"
              onChange={(e) => setPublish_time(e.target.value)}
            />
          </div>
        );
      default:
        return null;
    }
  }

  const [allEpisodeuseraccess, setallEpisodeuseraccess] = useState("");
  const [storeEpisodeuseraccess, setStoreEpisodeuseraccess] = useState("");

  function allEpisode_streamuseraccess() {
    switch (allEpisodeuseraccess) {
      case "guest":
        return <div></div>;
      case "subscriber":
        return <div></div>;
      case "registered":
        return <div></div>;
      case "ppv":
        return (
          <div className=" ">
            <div className="row m-0">
              <MessageLabel text="PPV Price" className="ps-0" />
              <div className="col-8 ps-0">
                <MessageSubHeading text="Apply PPV Price from Global Settings?" />
              </div>
              <div className="col-4 text-end">
                <label className="switch">
                  <input
                    name="ppv_price"
                    defaultChecked={checkboxChecked}
                    onChange={handleCheckboxChange}
                    type="checkbox"
                    className="rs-input"
                  />
                  <span
                    className="slider round"
                    name="ppv_price"
                    checked={checkboxChecked}
                    onChange={handleCheckboxChange}
                  ></span>
                </label>
              </div>
            </div>
            <input
              type="text"
              className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"
              placeholder="PPV Price"
              name="ppv_price"
              id="price"
              value={inputValue}
              onChange={handleInputChange}
            />
            <MessageLabel text="IOS PPV Price" />
            <MessageSubHeading text="Apply IOS PPV Price from Global Settings?" />
            <div className="panel-body ppv_price_ios  mt-2">
              <select
                onChange={(e) => setIos_ppv_price(e.target.value)}
                className="form-select  theme-bg-color-secondary custom-placeholder  theme-text-color border-0"
                id="ios_ppv_price"
                name="ios_ppv_price"
              >
                <option value="0" className="theme-text-color theme-bg-color">
                  Choose a PPV Price
                </option>
                {inapppurchase?.map((item, key) => (
                  <option
                    value={item?.id}
                    className="theme-text-color theme-bg-color"
                  >
                    {item?.plan_price}
                  </option>
                ))}
              </select>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  useEffect(() => {
    fetchData();
    fetchdefaultimage();
    fetchdefaultimageurl();
    fetchDatanextpre();
  }, [page]);

  const fetchDatanextpre = () => {
    axios
      .get(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-Episode/list?page=${page}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        const videosData = res?.data?.episodes;
        setVideodata(videosData);
        if (videosData?.length > 0) {
          setData(videosData);
          setLoading(false);
        } else {
          setData([]);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-Episode/list`,
        { headers: headers }
      );
      setCount(response?.data?.Total_count);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const fetchdefaultimage = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/Channel/Vertical-Default-Image`,
        { headers: headers }
      );
      setDefaultimage(response?.data?.Vertical_Default_Image);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchdefaultimageurl = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/Channel/Default-image-url`,
        { headers: headers }
      );
      setDefaultimageurl(response?.data?.Default_Image_Url);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [showModal, setShowModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const handleToggle = async (item) => {
    setCurrentItem(item);
    setShowModal(true);
  };

  const confirmToggle = async () => {
    try {
      setShowModal(false);
      const newStatus = currentItem.banner == 1 ? 0 : 1;
      const response = await fetch(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-Episode/slider-update`,
        {
          method: "POST",
          body: JSON.stringify({
            episode_id: currentItem.id,
            banner_status: newStatus,
          }),
          headers: headers,
        }
      );

      const responseData = await response.json();
      if (response.ok) {
        const updatedItems = series.map((currItem) => {
          if (currItem.id == currentItem.id) {
            return { ...currItem, banner: newStatus };
          }
          return currItem;
        });
        setSeries(updatedItems);
        setMessage(responseData?.message);
        toast.success(responseData?.message);
        setTimeout(() => {
          window.location.reload()
        }, 2000);
      } else {
        toast.error(responseData?.message);
      }
    } catch (error) { }
  };

  const closeModalslider = () => {
    setShowModal(false);
    setCurrentItem(null);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);

  const handlePreClick = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNxtClick = () => {
    setPage(page + 1);
  };

  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isSuccessMessageOpen, setIsSuccessMessageOpen] = useState(false);

  const handleCancelDelete = () => {
    setIsConfirmationOpen(false);
  };
  const [successMessage, setSuccessMessage] = useState("");
  // all List of episode
  const [getEpisodeData, setEpisodeData] = useState([]);
  const [filteredEpisodeData, setFilteredEpisodeData] = useState([]);
  useEffect(() => {
    fetchDatas();
  }, []);

  const fetchDatas = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-Episode/list`,
        { headers: headers }
      );
      const EpisodeData = response?.data?.Episodes;
      console.log(EpisodeData);
      const filterHelp = EpisodeData?.map((item) => {
        const UploadStatus =
          item?.uploaded_by == "1"
            ? "Admin"
            : item?.uploaded_by == "5"
              ? "Content Partner"
              : item?.uploaded_by == "4"
                ? "Channel Partner"
                : "";
        const statusText =
          item?.active == 1 &&
            item?.publish_type == "publish_now" &&
            item?.status == 1
            ? // &&
            // item.draft == 1
            "PUBLISHED"
            : item?.active == null &&
              item?.publish_type == "publish_later" &&
              item?.status == null
              ? //  &&
              // item.draft == null
              "SCHEDULED"
              : "DRAFT";

        return { ...item, uploaded_by: UploadStatus, active: statusText };
      });
      setEpisodeData(filterHelp);
      setFilteredEpisodeData(filterHelp);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    nextPageText: "Next",
    prePageText: "Prev",
    firstPageText: <MdKeyboardDoubleArrowLeft />,
    lastPageText: <MdKeyboardDoubleArrowRight />,
    showTotal: true,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "All", value: filteredEpisodeData?.length },
    ],
  });

  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (row) {
        if (isSelect) {
          setItemToDelete([...itemToDelete, row?.id]);
        } else {
          setItemToDelete(itemToDelete?.filter((id) => id !== row?.id));
        }
      }
    },
    selected: itemToDelete,
    selectionHeaderRenderer: ({ mode, checked, indeterminate }) => (
      <div>
        <input
          type="checkbox"
          checked={checked}
          indeterminate={indeterminate}
          onChange={handleAll}
        />
      </div>
    ),
  };

  const videoFormatter = (data, row) => {
    return (
      <div className="d-flex justify-content-between align-items-center ">
        <img src={row?.Thumbnail} className="profile" alt="image_all" />
        <div className="rightVideoTitle">
          <Link
            className="theme-text-color table-heading d-flex  align-items-center"
            target="_blank"
            to={`/videos/${row?.slug}`}
          >
            {" "}
            <span title={`${row.slug}`}>{`${data?.slice(0, 15)}...`} </span>
            <CiShare1
              style={{
                width: "15px",
                height: "15px",
                strokeWidth: "1px",
                marginLeft: "6px",
              }}
            />{" "}
          </Link>
          <span className="me-1 text-dark dateText theme-text-color table-subheading">
            {row?.duration}
          </span>
          <span className="dateText table-subheading theme-text-color">
            {" "}
            {row?.upload_on}
          </span>
        </div>
      </div>
    );
  };

  const statusFormatter = (data, row) => {
    return (
      <div
        className={`d-inline-block px-3 py-1 text-center ${data === "PUBLISHED"
          ? "PUBLISHED_active"
          : data === "SCHEDULED"
            ? " DISABLED_active"
            : "UNPUBLISHED_active"
          }`}
      >
        {" "}
        {data}{" "}
      </div>
    );
  };

  const actionFormatter = (data, row) => {
    return (
      <div className="editdropdown">
        <span className="editdropdown-button">
          <span>
            <FaEllipsisVertical className=" theme-text-color" />
          </span>
        </span>
        <div className="editdropdown-menu theme-bg-color">
          <Link
            to={`/channel/edit-episode/manage/${row?.id}`}
            className="theme-text-color theme-bg-color"
          >
            <span>
              <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <g>
                  <path
                     className="svgIcon"
                    d="M10.3,18.5l-4.74.64A.49.49,0,0,1,5,18.59l.64-4.74a.42.42,0,0,1,.14-.28L17.31,2a.59.59,0,0,1,.85,0L22.11,6a.6.6,0,0,1,0,.84L10.58,18.36A.53.53,0,0,1,10.3,18.5Z"
                  />
                  <line
                     className="svgIcon"
                    x1="1.01"
                    y1="21.58"
                    x2="18.89"
                    y2="21.58"
                  />
                </g>
              </svg>
              <MessageBox
                text="Edit"
                classname="ms-2 theme-text-color"
              ></MessageBox>
            </span>
          </Link>

          <div
            onClick={() => singleDelete(row?.id)}
            className="commonActionPadding theme-bg-color"
          >
            <svg
              id="Layer_1"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <g>
                <rect
                   className="svgIcon"
                  x="4.38"
                  y="5.54"
                  width="14.49"
                  height="17.71"
                />
                <line
                   className="svgIcon"
                  x1="1.16"
                  y1="5.54"
                  x2="22.09"
                  y2="5.54"
                />
                <g>
                  <line
                     className="svgIcon"
                    x1="9.21"
                    y1="9.56"
                    x2="9.21"
                    y2="19.06"
                  />
                  <line
                     className="svgIcon"
                    x1="14.04"
                    y1="9.56"
                    x2="14.04"
                    y2="19.06"
                  />
                </g>
                <path
                   className="svgIcon"
                  d="M7.45,5.22a2.72,2.72,0,0,1,0-.28,4.19,4.19,0,1,1,8.37,0c0,.09,0,.19,0,.29"
                />
              </g>
            </svg>
            <MessageBox
              text="Delete"
              classname="ms-2 theme-text-color"
            ></MessageBox>
          </div>
        </div>
      </div>
    );
  };

  const sliderFormatter = (data, row) => {
    return (
      <div>
        <label className="switch">
          <input
            name="banner"
            id="banner"
            className={data == 0 ? "inactive" : null}
            checked={data == 1}
            onChange={() => handleToggle(row)}
            onClick={() => handleToggle(row)}
            type="checkbox"
          />
          <span className="slider round"></span>
        </label>
      </div>
    );
  };

  const columns = [
    {
      dataField: "title",
      text: "Episode",
      sort: true,
      formatter: videoFormatter,
    },
    {
      dataField: "uploaded_by",
      text: "Uploaded By",
      sort: true,
    },
    {
      dataField: "active",
      text: "Status",
      formatter: statusFormatter,
    },
    {
      dataField: "banner",
      text: "Slider",
      formatter: sliderFormatter,
    },
    {
      dataField: "ACTIONS",
      text: "Action",
      formatter: actionFormatter,
    },
  ];

  const handleFilterChangeEpisode = (data) => {
    setFilteredEpisodeData(data);
  };

  const handleAll = () => {
    setSelectAll(!selectAll);
    setItemToDelete(
      selectAll ? [] : filteredEpisodeData?.map((item) => item?.id)
    );
  };

  const handleConfirmDelete = async () => {
    setProcessing(true);
    setShowOverlay(true);
    const formData = new FormData();
    formData.append("id", "[" + itemToDelete + "]");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-Episode/multi-delete`,
        formData,
        {
          headers: headers,
        }
      );
      const result = response.data;
      if (result.status == true) {
        toast.success(result?.message);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err.response.data.message;
      toast.error({ status: false, message: errorMessage });
      setShowOverlay(false);
    }
  };

  const singleDelete = (id) => {
    setModelClose(true);
    setItemToDelete([id]);
  };
  const closeModal = () => {
    setModelClose(!closeModal);
    setShowModal(false);
  };
  const handleDeletedata = () => {
    handleConfirmDelete();
    closeModal();
  };
  const openModal = () => {
    setModelClose(true);
  };

  const steps = [
    {
      label: "Select campaign settings",
      description: (
        <>
          {showOverlay && <ResponseLoader />}
          <Wrapper>
            <MessageBox
              text="Upload Episode"
              classname="d-block admin-title mb-4"
            ></MessageBox>
            <Dropzone
              accept="video/*"
              onDrop={(acceptedFiles) => handleUpload(acceptedFiles)}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <MessageBox
                    text="Drag and drop video files here, or click to select files"
                    classname="draganddrop theme-border-color"
                  />
                </div>
              )}
            </Dropzone>
            <div className="col-10 mx-auto py-4 ">
              {uploadProgress > 0 && (
                <div>
                  <MessageLabel text={`Uploading... ${uploadProgress} %`} />
                  <progress value={uploadProgress} max="100" />
                </div>
              )}
              {uploadCompleted && (
                <div className="d-flex align-items-center  justify-content-center">
                  {uploadedFiles.length > 0 && (
                    <div className="col-6 text-start">
                      <MessageLabel text="Uploaded Files:" />
                      <ul className="p-0 m-0">
                        {uploadedFiles.map((file, index) => (
                          <li
                            key={index}
                            className="theme-text-color admin-input-description opacity-75 my-2"
                          >
                            {file.name} - {file.size} MB
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  <div className="col-6  ">
                    <Button
                      variant="contained"
                      onClick={() => {
                        handleNext();
                      }}
                      className="btn button-color button-bg-color admin-button-text"
                    >
                      <MessageBox
                        text="Proceed to Next"
                        classname="d-block "
                      ></MessageBox>
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </Wrapper>
          {showModal && (
            <AdminSliderModal
              modelClose={showModal}
              sliderClose={closeModalslider}
              sliderAction={confirmToggle}
              buttonText={currentItem?.banner}
            />
          )}
          {modelClose && (
            <AdminDeleteModal
              modelClose={modelClose}
              deleteClose={closeModal}
              deleteAction={handleDeletedata}
              buttonText={itemToDelete?.length}
            />
          )}
          <AdminNavbar
            heading="All Episode"
            totalDataLength={getEpisodeData?.length}
            deleteCount={itemToDelete?.length}
            navigation=""
            deleteMethod={openModal}
            navText=""
          />
          <div className="theme-bg-color p-3 rounded-2 bootstrapTable  multi-delete-width">
            <AdminHeader
              searchMethod={handleFilterChangeEpisode}
              reduceData={filteredEpisodeData}
              originalData={getEpisodeData}
              fileName="EPISODE-FILTERED-LIST"
            />
            <BootstrapTable
              keyField="id"
              columns={columns}
              data={filteredEpisodeData}
              pagination={pagination}
              className="no-border-table theme-text-color"
              noDataIndication={
                <NoRecordTable
                  result={filteredEpisodeData?.length}
                  loader={loading}
                />
              }
              hover
              headerClasses="videoWidth"
              selectRow={selectRow}
              selectAll={selectAll}
            />
          </div>
        </>
      ),
    },
    {
      label: "Create an ad group",
      description: (
        <div className="">
          <AdminSaveHeader
            heading="Add New Episode"
            saveText="Save Episode"
            saveMethod={Manage_Episode}
          />
          {showOverlay && <ResponseLoader />}
          <FormContainer>
            <LeftWrapper>
              <Wrapper>
                <MessageHeading text="Create Episode" />
                <MessageLabel text="Title" className="mandatorySimple" />
                <MessageSubHeading text="Add the episodes title in the textbox below" />
                <input
                  type="text"
                  id="title"
                  name="title"
                  className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0 mb-3"
                  onChange={handleInput}
                  value={editUser?.title}
                  onKeyDown={handleInputKeyDowntitle}
                  placeholder="Title"
                  onBlur={handleBlurtitle}
                  ref={titleInputRef}
                />
                {!isValidationHiddentitle && (
                  <MessageBox
                    text={`${validationMessagetitle}`}
                    classname="errorred mb-2 d-block"
                  />
                )}
                <MessageLabel text="Slug" />
                <MessageSubHeading text="Add the episodes slug in the textbox below" />
                <input
                  type="text"
                  id="slug"
                  name="slug"
                  className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0 mb-3"
                  value={autoFillValuetitle}
                  onChange={handleAutoFillChangetitle}
                  placeholder="Episode Slug"
                />
                <MessageLabel text="Episode Description" />
                <JoditEditor
                  value={description}
                  onChange={(newContent) => contentFieldChanaged(newContent)}
                />
                <MessageLabel text="Duration" />
                <MessageSubHeading text="Enter the episode duration in the following format(Hours : Minutes : Seconds)" />
                <input
                  type="text"
                  name="duration"
                  maxLength="8"
                  className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"
                  onChange={handleInputdata}
                  value={editUser?.duration}
                  placeholder="duration"
                />
                <MessageLabel text="Publish Year" />
                <MessageSubHeading text="Enter The Episode Year" />
                <input
                  type="date"
                  name="date"
                  id="date"
                  className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"
                  onChange={handleChangeyear}
                  value={editUser?.year}
                  placeholder="year"
                />
              </Wrapper>
              <Wrapper>
                <MessageHeading text="Organize" className="" />
                <MessageLabel text="Episode Ratings" />
                <MessageSubHeading text="IMDb Ratings 10 out of 10" />
                <select
                  className="form-select theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"
                  id="rating"
                  name="rating"
                  size="lg"
                  onChange={(e) => setRating(e.target.value)}
                >
                  <option value="0" className="theme-bg-color theme-text-color">
                    0
                  </option>
                  <option value="1" className="theme-bg-color theme-text-color">
                    1
                  </option>
                  <option value="2" className="theme-bg-color theme-text-color">
                    2
                  </option>
                  <option value="3" className="theme-bg-color theme-text-color">
                    3
                  </option>
                  <option value="4" className="theme-bg-color theme-text-color">
                    4
                  </option>
                  <option value="5" className="theme-bg-color theme-text-color">
                    5
                  </option>
                  <option value="6" className="theme-bg-color theme-text-color">
                    6
                  </option>
                  <option value="7" className="theme-bg-color theme-text-color">
                    7
                  </option>
                  <option value="8" className="theme-bg-color theme-text-color">
                    8
                  </option>
                  <option value="9" className="theme-bg-color theme-text-color">
                    9
                  </option>
                  <option
                    value="10"
                    className="theme-bg-color theme-text-color"
                  >
                    10
                  </option>
                </select>
                <MessageLabel text="Age Restrict" />
                <MessageSubHeading text="Select A episode Age Below" />

                <select
                  className="form-select theme-bg-color-secondary custom-placeholder mb-3 theme-text-color"
                  id="age_restrict"
                  name="age_restrict"
                  size="lg"
                  onChange={(e) => setAge_restrict(e.target.value)}
                >
                  <option value="0">Choose an Age</option>
                  {age_restrictdata?.map((item) => (
                    <option selected value={item?.id}>
                      {item?.slug}
                    </option>
                  ))}
                </select>

                <MessageLabel text="Cast and Crew" />
                <MessageSubHeading text="Add Trainer for the Video below" />
                <Select
                  options={artists?.map((option) => ({
                    value: option?.id,
                    label: option?.artist_name,
                  }))}
                  className="theme-bg-color-secondary custom-placeholder mb-3 theme-text-color"
                  isMulti
                  value={selectedOptionsartists}
                  onChange={handleSelectChangeartists}
                />

                <MessageLabel text="Category" className="mandatorySimple" />
                <MessageSubHeading text="Select A episode Category Below" />
                <Select
                  options={category?.map((option) => ({
                    value: option?.id,
                    label: option?.name,
                  }))}
                  className="theme-bg-color-secondary custom-placeholder mb-3 theme-text-color"
                  isMulti
                  value={selectedOptionscategory}
                  onChange={handleSelectChangecategory}
                  ref={categoryInputRef}
                />
                {!isValidationHiddencategory && (
                  <MessageBox
                    text={`${validationMessagecategory}`}
                    classname="errorred mb-2 d-block"
                  />
                )}

                <MessageLabel text="Equipment" className="mandatorySimple" />
                <MessageSubHeading text="Select A episode Equipment Below" />
                <Select
                  options={language?.map((option) => ({
                    value: option?.id,
                    label: option?.name,
                  }))}
                  className="theme-bg-color-secondary custom-placeholder mb-3 theme-text-color"
                  isMulti
                  value={selectedOptionslanguage}
                  onChange={handleSelectChangelanguage}
                  ref={languageInputRef}
                />
                {!isValidationHiddenlanguage && (
                  <MessageBox
                    text={`${validationMessagelanguage}`}
                    classname="errorred mb-2 d-block"
                  />
                )}

                <MessageLabel text="Block Country" />
                <MessageSubHeading text="Select A episode country Below" />
                <Select
                  options={block_country?.map((option) => ({
                    value: option?.id,
                    label: option?.country_name,
                  }))}
                  className="theme-bg-color-secondary custom-placeholder mb-3 theme-text-color"
                  isMulti
                  value={selectedOptions}
                  onChange={handleSelectChange}
                />
                <MessageLabel text="Available Country" />
                <MessageSubHeading text="Block the Audio for Selected Country" />
                <Select
                  options={available_country?.map((option) => ({
                    value: option?.id,
                    label: option?.country_name,
                  }))}
                  className="theme-bg-color-secondary custom-placeholder theme-text-color"
                  isMulti
                  value={selectedOptionsAvailable_country}
                  onChange={handleSelectChangeAvailable_country}
                />
              </Wrapper>
              <Wrapper>
                <MessageHeading text="Search Tags" className="" />
                <MessageLabel text="You don't have any search keywords." />
                <TagsInput
                  value={searchTags}
                  className="rs-input"
                  onChange={handleTagsChange}
                  name="search_tags"
                />
              </Wrapper>
              <Wrapper>
                <MessageHeading text="Intro Time (Optional)" className="" />
                <div className="row mx-0 ">
                  <div className="col-lg-6 ps-0">
                    <MessageLabel text="Skip Start Time" />
                    <MessageSubHeading text="(Please Give In Seconds)" />
                    <input
                      type="text"
                      value={userTime?.skip_start_time}
                      onChange={handleInputintrotime}
                      placeholder="HH:MM:SS"
                      maxLength="8"
                      className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"
                      name="skip_start_time"
                      id="skip_start_time"
                    />
                    {errors?.error_skip_start_time && (
                      <MessageBox
                        text={`${errors?.error_skip_start_time}`}
                        classname="errorred mb-2 d-block"
                      />
                    )}
                  </div>
                  <div className="col-6 pe-0">
                    <MessageLabel text="Recap Start Time" />
                    <MessageSubHeading text="(Please Give In Seconds)" />
                    <input
                      type="text"
                      value={userTime?.recap_start_time}
                      onChange={handleInputintrotime}
                      placeholder="HH:MM:SS"
                      maxLength="8"
                      className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"
                      name="recap_start_time"
                      id="recap_start_time"
                    />
                    {errors?.error_recap_start_time && (
                      <MessageBox
                        text={`${errors?.error_recap_start_time}`}
                        classname="errorred mb-2 d-block"
                      />
                    )}
                  </div>
                  <div className="col-6 ps-0">
                    <MessageLabel text="Skip End Time" />
                    <MessageSubHeading text="(Please Give In Seconds)" />
                    <input
                      type="text"
                      value={userTime?.skip_end_time}
                      onChange={handleInputintrotime}
                      placeholder="HH:MM:SS"
                      maxLength="8"
                      className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"
                      name="skip_end_time"
                      id="skip_end_time"
                    />
                    {errors?.error_skip_end_time && (
                      <MessageBox
                        text={`${errors?.error_skip_end_time}`}
                        classname="errorred mb-2 d-block"
                      />
                    )}
                  </div>
                  <div className="col-6 pe-0">
                    <MessageLabel text="Recap End Time" />
                    <MessageSubHeading text="(Please Give In Seconds)" />
                    <input
                      type="text"
                      maxLength="8"
                      value={userTime?.recap_end_time}
                      onChange={handleInputintrotime}
                      placeholder="HH:MM:SS"
                      className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"
                      name="recap_end_time"
                      id="recap_end_time"
                    />
                    {errors?.error_recap_end_time && (
                      <MessageBox
                        text={`${errors?.error_recap_end_time}`}
                        classname="errorred mb-2 d-block"
                      />
                    )}
                  </div>
                  <div className="col-6 ps-0">
                    <MessageLabel text="Skip Start Session" />
                    <MessageSubHeading text="(Please Give In Seconds)" />
                    <input
                      type="text"
                      maxLength="8"
                      value={userTime?.skip_start_session}
                      onChange={handleInputintrotime}
                      placeholder="HH:MM:SS"
                      className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"
                      name="skip_start_session"
                      id="skip_start_session"
                    />
                    {errors?.error_skip_start_session && (
                      <MessageBox
                        text={`${errors?.error_skip_start_session}`}
                        classname="errorred mb-2 d-block"
                      />
                    )}
                  </div>
                  <div className="col-6 pe-0">
                    <MessageLabel text=" Recap Start Session" />
                    <MessageSubHeading text="(Please Give In Seconds)" />
                    <input
                      type="text"
                      maxLength="8"
                      value={userTime?.recap_start_session}
                      onChange={handleInputintrotime}
                      placeholder="HH:MM:SS"
                      className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"
                      name="recap_start_session"
                      id="recap_start_session"
                    />
                    {errors?.error_recap_start_session && (
                      <MessageBox
                        text={`${errors?.error_recap_start_session}`}
                        classname="errorred mb-2 d-block"
                      />
                    )}
                  </div>
                </div>
              </Wrapper>
              <Wrapper>
                <MessageHeading text="SEO" className="" />
                <MessageLabel text="Website Page Title" />
                <input
                  type="text"
                  className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"
                  onChange={(e) => setWebsitetitle(e.target.value)}
                  placeholder=""
                />
                <MessageLabel text="Website URL" />
                <input
                  type="text"
                  className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"
                  onChange={(e) => setWebsiteurl(e.target.value)}
                  placeholder=""
                />
                <MessageLabel text=" Meta Description" />
                <textarea
                  type="text"
                  className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder theme-text-color border-0"
                  onChange={(e) => setMetadescription(e.target.value)}
                  placeholder=""
                ></textarea>
              </Wrapper>
            </LeftWrapper>
            <RightWrapper>
              <Wrapper>
                <MessageHeading text="Thumbnails" className="" />
                <MessageLabel text="Episode Image Cover" />
                <MessageSubHeading text="Select the episodes image (1080 X 1920px or 9:16 ratio)" />
                <div className="row mx-0 my-3">
                  <div className="col-6 ps-0">
                    <div
                      className="imagedrop theme-border-color"
                      onClick={() => fileInputRef1.current.click()}
                    >
                      <input
                        type="file"
                        ref={fileInputRef1}
                        onChange={(event) =>
                          handleFileChange(
                            event,
                            fileInputRef1,
                            setSelectedFile1
                          )
                        }
                        accept="image/*"
                        style={{ display: "none" }}
                      />
                      <label className=" theme-text-color">
                        <CameraRetroIcon className=" theme-text-color" />
                      </label>
                    </div>
                  </div>
                  <div className="col-6 pe-0">
                    <div>
                      {selectedFile1 && (
                        <div className="imagedropcopy theme-border-color text-end">
                          <img
                            src={URL.createObjectURL(selectedFile1)}
                            alt="Uploaded"
                            width="150"
                            height="100"
                          />
                          <button
                            onClick={() => handleDelete(setSelectedFile1)}
                            type="button"
                            className="bg-transparent theme-text-color"
                          >
                            <IoClose className="custom-close-btn" />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <MessageLabel text="Episode Player Image" />
                <MessageSubHeading text="Select the player image ( 1280 X 720px or 16:9 Ratio )" />
                <div className="row mx-0 my-3">
                  <div className="col-6 ps-0">
                    <div
                      className="imagedrop theme-border-color"
                      onClick={() => fileInputRef2.current.click()}
                    >
                      <input
                        type="file"
                        ref={fileInputRef2}
                        onChange={(event) =>
                          handleFileChange(
                            event,
                            fileInputRef2,
                            setSelectedFile2
                          )
                        }
                        accept="image/*"
                        style={{ display: "none" }}
                      />
                      <label className=" theme-text-color">
                        <CameraRetroIcon className=" theme-text-color" />
                      </label>
                    </div>
                  </div>
                  <div className="col-6 pe-0">
                    <div>
                      {selectedFile2 && (
                        <div className="imagedropcopy theme-border-color text-end">
                          <img
                            src={URL.createObjectURL(selectedFile2)}
                            alt="Uploaded"
                            width="150"
                            height="100"
                          />
                          <button
                            onClick={() => handleDelete(setSelectedFile2)}
                            type="button"
                            className="bg-transparent theme-text-color"
                          >
                            <IoClose className="custom-close-btn" />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <MessageLabel text="Episode TV Image" />
                <MessageSubHeading text="Select the player image ( 16:9 Ratio or 1920 X 1080 px)" />
                <div className="row mx-0 my-3">
                  <div className="col-6 ps-0">
                    <div
                      className="imagedrop theme-border-color"
                      onClick={() => fileInputRef3.current.click()}
                    >
                      <input
                        type="file"
                        ref={fileInputRef3}
                        onChange={(event) =>
                          handleFileChange(
                            event,
                            fileInputRef3,
                            setSelectedFile3
                          )
                        }
                        accept="image/*"
                        style={{ display: "none" }}
                      />
                      <label className=" theme-text-color">
                        <CameraRetroIcon className=" theme-text-color" />
                      </label>
                    </div>
                  </div>
                  <div className="col-6 pe-0">
                    <div>
                      {selectedFile3 && (
                        <div className="imagedropcopy text-end theme-border-color">
                          <img
                            src={URL.createObjectURL(selectedFile3)}
                            alt="Uploaded"
                            width="150"
                            height="100"
                          />
                          <button
                            onClick={() => handleDelete(setSelectedFile3)}
                            type="button"
                            className="bg-transparent theme-text-color"
                          >
                            <IoClose className="custom-close-btn" />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Wrapper>
              <Wrapper>
                <MessageHeading text="Access" className="" />
                <MessageLabel text="User Access" />
                <MessageSubHeading text="Who Is Allowed To View This episode ?" />
                <select
                  onChange={(e) => setStoreEpisodeuseraccess(e.target.value)}
                  className="form-select  theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"
                  id="access"
                  name="access"
                  onClick={(event) => {
                    setallEpisodeuseraccess(event.target.value);
                  }}
                >
                  {user_access?.map((item, key) => (
                    <option
                      value={item?.role}
                      className="theme-bg-color theme-text-color"
                    >
                      {item?.name}
                    </option>
                  ))}
                </select>
                {allEpisode_streamuseraccess()}
              </Wrapper>
              <Wrapper>
                <MessageHeading text="Free Duration" className="" />
                <div className="row mx-0">
                  <div className="col-9 ps-0">
                    <MessageLabel text="Enable Free Duration Status" />
                  </div>
                  <div className="col-3 pe-0">
                    <label className="switch">
                      <input
                        name="free_duration_status"
                        id="free_duration_status"
                        onChange={handleInput}
                        defaultChecked={
                          free_duration_status?.free_duration_status == 1
                            ? true
                            : false
                        }
                        checked={
                          free_duration_status?.free_duration_status == 1
                            ? true
                            : false
                        }
                        type="checkbox"
                      />
                      <span
                        className="slider round"
                        id="free_duration_status"
                        name="free_duration_status"
                        onChange={handleInput}
                        value={
                          free_duration_status?.free_duration_status == 1
                            ? "1"
                            : "0"
                        }
                      ></span>
                    </label>
                  </div>
                </div>
                <MessageLabel text="Free Duration Time" />
                <input
                  type="text"
                  name="free_duration_time"
                  className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder  theme-text-color border-0"
                  maxLength="8"
                  placeholder="HH:MM:SS"
                  value={editUser?.free_duration_time}
                  onChange={handleInputdata}
                />
              </Wrapper>
              <Wrapper>
                <MessageHeading text="Status Settings" className="" />
                <div className="mx-0 row">
                  <div className="col-9 ps-0">
                    <MessageLabel text="Is this episode Featured" />
                  </div>
                  <div className="col-3 pe-0 text-end">
                    <label className="switch">
                      <input
                        name="featured"
                        onChange={handleInput}
                        className="rs-input"
                        defaultChecked={featured?.featured == 1 ? true : false}
                        checked={featured?.featured == 1 ? true : false}
                        type="checkbox"
                      />
                      <span
                        className="slider round"
                        name="featured"
                        onChange={handleInput}
                        value={featured?.featured == 1 ? "1" : "0"}
                      ></span>
                    </label>
                  </div>
                </div>
                <div className="mx-0 row ">
                  <div className="col-9 ps-0">
                    <MessageLabel text="Is this episode Active" />
                  </div>
                  <div className="col-3 pe-0 text-end">
                    <label className="switch">
                      <input
                        name="active"
                        onChange={handleInput}
                        defaultChecked={active?.active == 1 ? true : false}
                        checked={active?.active == 1 ? true : false}
                        className="rs-input"
                        type="checkbox"
                      />
                      <span
                        className="slider round"
                        name="active"
                        onChange={handleInput}
                        value={active?.active == 1 ? "1" : "0"}
                      ></span>
                    </label>
                  </div>
                </div>
                <div className="mx-0 row ">
                  <div className="col-9 ps-0">
                    <MessageLabel text="Is this episode display in Banner" />
                  </div>
                  <div className="col-3 pe-0 text-end">
                    <label className="switch">
                      <input
                        name="banner"
                        onChange={handleInput}
                        className="r-input"
                        defaultChecked={banner?.banner == 1 ? true : false}
                        checked={banner?.banner == 1 ? true : false}
                        type="checkbox"
                      />
                      <span
                        className="slider round"
                        name="banner"
                        onChange={handleInput}
                        value={banner?.banner == 1 ? "1" : "0"}
                      ></span>
                    </label>
                  </div>
                </div>
              </Wrapper>
              <Wrapper>
                <MessageHeading text="Subtitles (srt)" className="" />
                {Subtitlesmap?.map((item, index) => (
                  <div className="mt-2" key={index}>
                    <MessageLabel text={item.language} />
                    <div className="file-draganddrop theme-border-color mt-2">
                      <Dropzone
                        onDrop={(acceptedFiles) =>
                          handleDropsubtitles(item.short_code, acceptedFiles)
                        }
                        accept="video/*"
                        multiple={false}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()} className="dropzone">
                            <input {...getInputProps()} />
                            <MessageSubHeading text="Drag and drop subtitles videos here" />
                            {videosubtitles[item.short_code]?.length > 0 && (
                              <MessageLabel
                                text={`${videosubtitles[item?.short_code].length
                                  } ${videosubtitles[item?.short_code].length == 1
                                    ? "file"
                                    : "files"
                                  } selected`}
                              ></MessageLabel>
                            )}
                          </div>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                ))}
              </Wrapper>
            </RightWrapper>
            <AdminCustomButton
              saveMethod={Manage_Episode}
              saveText="Save Episode"
              className="ms-auto"
            />
          </FormContainer>
        </div>
      ),
    },
  ];

  return (
    <div className="stepLineNone">
      <Stepper activeStep={activeStep} className="p-0" nonLinear>
        {steps?.map((step, index) => (
          <Step key={step?.label} className="p-0">
            <StepContent className="p-0 m-0 border-0">
              <Typography>{step?.description}</Typography>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
