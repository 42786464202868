// External Libraries and Packages
import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import HomePageNav from '../CommonComponents/HomePageNav';
import MusicArtistCard from '../CommonComponents/MusicArtistCard';
import { connect } from 'react-redux';
const MusicArtist = ({ item, swiperOptions, languageDetailsData }) => {
    return (
        <div className='mb-4'>
            <HomePageNav link={item?.source} headerName={item?.header_name} store={item?.data} seg={`music/`} />
            <Swiper {...swiperOptions} className='musicHomePageSwiper'>
                {item?.data?.map((value, index) => (
                    <SwiperSlide key={value?.id} >
                        <MusicArtistCard store={value} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}
const mapStateToProps = state => ({
    languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData
})

export default connect(mapStateToProps)(MusicArtist)