import React from 'react';
import '../../../Styles/Details.css'; // Optional: Create your own custom styling for the modal.

const RentModal = ({ show, handleCloseModal, children }) => {
    if (!show) {
        return null; // Don't render anything if the modal is not supposed to be shown.
    }
 return (
        <div className="modal-backdrop-rent">
            <div className="modal-content-rent">
                {children}
            </div>
        </div>
    );
};

export default RentModal;
