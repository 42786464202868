// External Libraries and Packages
import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import HomePageNav from '../CommonComponents/HomePageNav'
import MusicBasedCategoryCard from '../CommonComponents/MusicBasedCategoryCard';
import { connect } from 'react-redux';

const MusicBasedCategory = ({ item, swiperOptions, languageDetailsData }) => {
    return (
        item?.category__audios.length > 0 ? <div className='mb-4 basedcategory'>
            <HomePageNav link={item?.slug} headerName={item?.name} store={item?.category__audios} seg={`music/audio-genre/`} />
            <Swiper {...swiperOptions} className='musicHomePageSwiper' >
                {item?.category__audios?.map((value, index) => (
                    <SwiperSlide key={value?.id} >
                        <MusicBasedCategoryCard store={value} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div> : null
    )
}
const mapStateToProps = state => ({
    languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData
})
export default connect(mapStateToProps)(MusicBasedCategory)