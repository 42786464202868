import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import * as XLSX from "xlsx";
import { CSVLink } from "react-csv";
import { jsPDF } from "jspdf";
import {
  MdDelete,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import { toast } from "react-toastify";
 
import { MdOutlineClose } from "react-icons/md";
import { BsThreeDotsVertical } from "react-icons/bs";
import deleteitem from "../../../assets/icons/deleteitem.svg";
import edititem from "../../../assets/icons/edititem.svg";
import loadings from "../../../assets/gif/loading.gif";
import defaultImage from "../../../assets/images/default_vertical_image.webp";
import ExportIcon from "../../../assets/icons/export-icon.webp";
import ResponseLoader from "../../../components/Loader/ResponseLoader";

function Contentregionanalytics() {
  const [getregionanalytics, setregionanalytics] = useState([]);
  const [filteredregionanalyticsData, setFilteredregionanalyticsData] =
    useState([]);
  const [itemToDeleteregionanalytics, setItemToDeleteregionanalytics] =
    useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [modalCloseregionanalytics, setModalCloseregionanalytics] =
    useState(false);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  // let access_token, access_token_cpp;

  // if (localStorage.getItem("username")) {
  //   access_token = localStorage.getItem("access_token");
  // } else {
  //   access_token_cpp = localStorage.getItem("access_token_cpp");
  // }

  // const headers = {
  //   "Content-Type": "application/json",
  //   // Authorization: "Bearer " + access_token_cpp,
  //   Accept: "application/json",
  //   "Access-Control-Allow-Origin": "*",
  // };

  // if (access_token) {
  //   headers.Authorization = "Bearer " + access_token;
  // } else if (access_token_cpp) {
  //   headers.Authorization = "Bearer " + access_token_cpp;
  // }

  const access_token_cpp = localStorage.getItem("access_token_cpp");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token_cpp,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/regionanalytics/index`,
        { headers: headers }
      );
      const regionanalyticsData = response?.data?.Allregionanalytics;
      setregionanalytics(regionanalyticsData);
      setFilteredregionanalyticsData(regionanalyticsData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(async () => {
    fetchData();
  }, []);
  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    nextPageText: "Next",
    prePageText: "Prev",
    firstPageText: <MdKeyboardDoubleArrowLeft />,
    lastPageText: <MdKeyboardDoubleArrowRight />,
    showTotal: true,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "All", value: filteredregionanalyticsData?.length },
    ],
  });
  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (row) {
        if (isSelect) {
          setItemToDeleteregionanalytics([
            ...itemToDeleteregionanalytics,
            row?.id,
          ]);
        } else {
          setItemToDeleteregionanalytics(
            itemToDeleteregionanalytics.filter((id) => id !== row?.id)
          );
        }
      }
    },
    selected: itemToDeleteregionanalytics,
    selectionHeaderRenderer: ({ mode, checked, indeterminate }) => (
      <div>
        <input
          type="checkbox"
          checked={checked}
          indeterminate={indeterminate}
          onChange={handleAll}
        />
      </div>
    ),
  };
  const imageFormatter = (data, row) => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <img
          src={row.image_url ? row.image_url : defaultImage}
          className="profile"
        />

        <div className="rightVideoTitle">
          <p className="theme-text-color">{data}</p>
          <span className="me-1 text-dark dateText theme-text-color">
            {" "}
            {row?.duration}
          </span>
          <span className="dateText">{row?.upload_on}</span>
        </div>
      </div>
    );
  };
  const sliderFormatter = (data, row) => {
    return (
      <div>
        <label className="switch">
          <input
            name="banner"
            id="banner"
            className={data == 0 ? "inactive" : null}
            checked={data == 1}
            type="checkbox"
          />
          <span className="slider round"></span>
        </label>
      </div>
    );
  };
  const actionFormatter = (data, row) => {
    return (
      <div className="editdropdown">
        <span className="editdropdown-button">
          <span>
            {" "}
            <BsThreeDotsVertical className=" theme-text-color" />
          </span>
        </span>
        <div className="editdropdown-menu  theme-bg-color">
          <Link
            to={`/edit-regionanalytics/${row?.id}`}
            className="theme-text-color  theme-bg-color"
          >
            <span>
              <img src={edititem} alt="flogo" width={20} height={20} />
              <span className="ms-2 theme-text-color "> Edit region </span>
            </span>
          </Link>

          <div
            onClick={() => openModalSingleDeleteregionanalytics(row?.id)}
            className="commonActionPadding theme-bg-color"
          >
            <img src={deleteitem} alt="flogo" width={20} height={20} />
            <span className="ms-2 theme-text-color ">Delete region</span>
          </div>
        </div>
      </div>
    );
  };
  const statusFormatter = (data, row) => {
    return (
      <div
        className={`font-weight-bolder  d-flex justify-content-center ${
          data == "PUBLISHED"
            ? "PUBLISHED_active"
            : data == "DRAFT"
            ? " DISABLED_active"
            : "UNPUBLISHED_active"
        }`}
      >
        {data}
      </div>
    );
  };
  const columns = [
    {
      dataField: "VIDEO NAME",
      text: "VIDEO NAME",
      formatter: imageFormatter,
      sort: true,
    },
    {
      dataField: "COUNTRY NAME",
      text: "COUNTRY NAME",
      formatter: statusFormatter,
    },
    {
      dataField: "IP NUMBER",
      text: "IP NUMBER",
      formatter: sliderFormatter,
    },
    {
      dataField: "VIEWS",
      text: "VIEWS",
      formatter: actionFormatter,
    },
  ];
  const exportHeadersregionanalytics = [
    { label: "ID", key: "id" },
    { label: "regionanalyticsS", key: "regionanalytics_name" },
    { label: "regionanalyticsS TYPE ", key: "regionanalytics_type" },
    { label: "UPLOADED BY", key: "uploaded_by" },
    { label: "UPLOAD-ON", key: "upload_on" },
  ];

  const conditionalGeneratePDFregionanalytics = (get) => {
    const doc = new jsPDF();
    doc.text("regionanalyticsS LIST ", 10, 10);
    const columns = [
      "INDEX",
      "ID",
      "regionanalyticsS",
      "regionanalyticsS TYPE ",
      "UPLOAD-ON",
    ];
    const allDataPDF = getregionanalytics?.map((item, index) => [
      index + 1,
      item?.id,
      item?.regionanalytics_name,
      item?.regionanalytics_type,
      item?.upload_on,
    ]);
    const filterPDF = filteredregionanalyticsData?.map((item, index) => [
      index + 1,
      item?.id,
      item?.regionanalytics_name,
      item?.regionanalytics_type,
      item?.upload_on,
    ]);

    doc.autoTable({
      startY: 20,
      head: [columns],
      body: get == 1 ? allDataPDF : filterPDF,
    });
    doc.save("regionanalyticsS-LIST.pdf");
  };
  const generateXLSXregionanalytics = (get) => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(
        get === 1 ? getregionanalytics : filteredregionanalyticsData
      );
    XLSX.utils.book_append_sheet(wb, ws, "MySheet");
    XLSX.writeFile(wb, "regionanalyticsS-LIST.xlsx");
  };

  const handleFilterChangeregionanalytics = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setFilter(searchTerm);
    const filteredResults = getregionanalytics.filter((item) =>
      [
        "regionanalytics_name",
        "regionanalytics_type",
        "uploaded_by",
        "upload_on",
      ].some(
        (property) =>
          typeof item[property] === "string" &&
          item[property].toLowerCase().includes(searchTerm)
      )
    );
    setFilteredregionanalyticsData(filteredResults);
  };

  const handleClear = () => {
    fetchData();
    setFilter("");
  };
  const handleAll = () => {
    setSelectAll(!selectAll);
    setItemToDeleteregionanalytics(
      selectAll ? [] : filteredregionanalyticsData?.map((item) => item?.id)
    );
  };

  const closeregionanalyticsModal = () => {
    setModalCloseregionanalytics(!modalCloseregionanalytics);
  };
  const openModalSingleDeleteregionanalytics = (id) => {
    setItemToDeleteregionanalytics([id]);
    setModalCloseregionanalytics(true);
  };
  const openModalMultiDeleteregionanalytics = () => {
    setModalCloseregionanalytics(true);
  };
  const handleDeleteregionanalyticsData = () => {
    closeregionanalyticsModal();
    regionanalyticsDeleteOperation();
  };
  const regionanalyticsDeleteOperation = async () => {
    setProcessing(true);
    setShowOverlay(true);
    const formData = new FormData();
    formData.append("id", "[" + itemToDeleteregionanalytics + "]");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/regionanalytics/multi-delete`,
        formData,
        {
          headers: headers,
        }
      );
      const result = response?.data;
      if (result?.status === true) {
        // props?.setApiresponsealert(result);
        // props?.setShowalert(true);
        toast.success(result?.message);
        setProcessing(false);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err?.response?.data?.message;
      // props?.setApiresponsealert({ status: false, message: errorMessage });
      // props?.setShowalert(true);
      toast.error({ status: false, message: errorMessage });
      setProcessing(false);
      setShowOverlay(false);
    }
  };
  return (
    <>
      <div
        className={`modal  packageModel ${
          modalCloseregionanalytics ? "show" : ""
        }`}
        tabIndex="-1"
        style={{ display: modalCloseregionanalytics ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content  theme-bg-color shadow">
            <div className="modal-header">
              <h5 className="modal-title theme-text-color">Confirm Deletion</h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeregionanalyticsModal}
              ></button>
            </div>
            <div className="modal-body">
              {itemToDeleteregionanalytics?.length === 1 ? (
                <p className="fs-6  theme-text-color">
                  {" "}
                  Are you sure you want to delete this regionanalytics ?
                </p>
              ) : (
                <p className="fs-6 theme-text-color">
                  Are you sure you want to delete the selected regionanalytics ?
                </p>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={closeregionanalyticsModal}
              >
                Close
              </button>

              <button
                type="button"
                className="btn btn-danger"
                onClick={handleDeleteregionanalyticsData}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>{showOverlay && <ResponseLoader />}</div>

      <section className="section container-fluid  theme-bg-color-secondary  paddingbottomm pt-2 all-audio-stream">
        <div id="content-page" className="content-page">
          <div className="row pb-2 admin-section-title">
            <div className="col-md-6">
              <h4 className=" theme-text-color">
                View by the Region{" "}
                <span className="fs-6">
                  (Display all {getregionanalytics?.length} region)
                </span>
              </h4>
            </div>
            <div className="col-md-6 col-12 mt-3 mt-md-0" align="right">
              {itemToDeleteregionanalytics?.length > 0 && (
                <button
                  className="btn btn-danger d-inline-flex  fw-bolder "
                  onClick={openModalMultiDeleteregionanalytics}
                >
                  <MdDelete className="exportIcon ms-2" />
                  Delete
                </button>
              )}
              {/* <Link
                to="/new-regionanalytics"
                className="btn btn-primary fw-bolder"
              >
                <i className="fa fa-plus-circle"></i> Add regionanalytics
              </Link> */}
            </div>
          </div>
          <div className="iq-card p-3 mt-3 theme-bg-color">
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 gap-3">
              <div className="filterContainer">
                <input
                  type="text"
                  placeholder="Filter all.."
                  className="filter form-control "
                  value={filter}
                  onChange={handleFilterChangeregionanalytics}
                />
                {filter?.length > 0 && (
                  <button
                    type="button"
                    className="closeBtn"
                    onClick={handleClear}
                  >
                    <MdOutlineClose />
                  </button>
                )}
              </div>
              <div className="btn-group">
                <div className="dropdown d-block " key={"2"}>
                  <button
                    className="btn btn-success dropdown-toggle d-flex"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="fw-bolder">Export </span>
                    <img src={ExportIcon} className="ms-2 exportIcon" />
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li className="dropdown-item">
                      <CSVLink
                        headers={exportHeadersregionanalytics}
                        data={filteredregionanalyticsData}
                        className="text-dark d-block"
                        filename="regionanalyticsS-LIST.csv"
                      >
                        CSV
                      </CSVLink>
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        conditionalGeneratePDFregionanalytics(0);
                      }}
                    >
                      PDF
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        generateXLSXregionanalytics(0);
                      }}
                    >
                      XLSX
                    </li>
                  </ul>
                </div>

                <div className="dropdown d-block ms-2">
                  <button
                    className="btn btn-success dropdown-toggle d-flex"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="fw-bolder">Export All</span>
                    <img src={ExportIcon} className="ms-2 exportIcon" />
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li className="dropdown-item">
                      <CSVLink
                        headers={exportHeadersregionanalytics}
                        data={getregionanalytics}
                        className="text-dark d-block"
                        filename="regionanalyticsS-LIST.csv"
                      >
                        CSV
                      </CSVLink>
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        conditionalGeneratePDFregionanalytics(1);
                      }}
                    >
                      PDF
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => {
                        generateXLSXregionanalytics(1);
                      }}
                    >
                      XLSX
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="bootstrapTable theme-bg-color">
              <BootstrapTable
                keyField="id"
                columns={columns}
                data={filteredregionanalyticsData}
                pagination={pagination}
                className="no-border-table theme-text-color"
                noDataIndication={
                  <div className="noRecord">
                    {loading ? (
                      <label>
                        {" "}
                        <img
                          src={loadings}
                          width={30}
                          height={30}
                          alt="Animated GIF"
                        />
                        Loading...
                      </label>
                    ) : (
                      filteredregionanalyticsData?.length <= 0 && (
                        <p className=" theme-text-color">
                          No Region Available !!!
                        </p>
                      )
                    )}
                  </div>
                }
                hover
                headerClasses="AlbumWidth"
                selectRow={selectRow}
                selectAll={selectAll}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Contentregionanalytics;
