import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import * as XLSX from "xlsx";
import { CSVLink } from "react-csv";
import { jsPDF } from "jspdf";
import {
  MdDelete,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";

import "./Content_AllPages.css";
import { FaCirclePlus } from "react-icons/fa6";
import { MdOutlineClose } from "react-icons/md";
import { BsThreeDotsVertical } from "react-icons/bs";
import { toast } from "react-toastify";
 
import deleteitem from "../../../assets/icons/deleteitem.svg";
import edititem from "../../../assets/icons/edititem.svg";
import loadings from "../../../assets/gif/loading.gif";
import defaultImage from "../../../assets/images/default_vertical_image.webp";
import ExportIcon from "../../../assets/icons/export-icon.webp";
import ResponseLoader from "../../../components/Loader/ResponseLoader";

function Content_AllPages() {
  const [datapages, setDatapages] = useState([]);
  const [filteredPagesData, setFilteredPagesData] = useState([]);
  const [itemToDeletePages, setItemToDeletePages] = useState([]);
  const [modalClosePages, setModalClosePages] = useState(false);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [selectAll, setSelectAll] = useState(false);


  // let access_token, access_token_cpp;

  // if (localStorage.getItem("username")) {
  //   access_token = localStorage.getItem("access_token");
  // } else {
  //   access_token_cpp = localStorage.getItem("access_token_cpp");
  // }

  // const headers = {
  //   "Content-Type": "application/json",
  //   // Authorization: "Bearer " + access_token_cpp,
  //   Accept: "application/json",
  //   "Access-Control-Allow-Origin": "*",
  // };

  // if (access_token) {
  //   headers.Authorization = "Bearer " + access_token;
  // } else if (access_token_cpp) {
  //   headers.Authorization = "Bearer " + access_token_cpp;
  // }

  const access_token_cpp = localStorage.getItem("access_token_cpp");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token_cpp,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };


  const fetchDatapages = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/admin/page/index`,
        { headers: headers }
      );
      const pagesData = response?.data?.AllPage;
      const filterHelp = pagesData.map((item) => {
        const statusText =
          item?.active == 1
            ? "PUBLISHED"
            : item?.active == 0 || item?.active == null
              ? "UNPUBLISHED"
              : "DRAFT";

        return { ...item, active: statusText };
      });
      setDatapages(filterHelp);
      setFilteredPagesData(filterHelp);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  useEffect(async () => {
    fetchDatapages();
  }, []);
  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    nextPageText: "Next",
    prePageText: "Prev",
    firstPageText: <MdKeyboardDoubleArrowLeft />,
    lastPageText: <MdKeyboardDoubleArrowRight />,
    showTotal: true,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "All", value: filteredPagesData?.length },
    ],
  });

  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect) => {
      if (row) {
        if (isSelect) {
          setItemToDeletePages([...itemToDeletePages, row.id]);
        } else {
          setItemToDeletePages(
            itemToDeletePages.filter((id) => id !== row?.id)
          );
        }
      }
    },
    selected: itemToDeletePages,
    selectionHeaderRenderer: ({ mode, checked, indeterminate }) => (
      <div>
        <input
          type="checkbox"
          checked={checked}
          indeterminate={indeterminate}
          onChange={handleAll}
        />
      </div>
    ),
  };
  const pagesFormatter = (data, row) => {
    return (
      <div className="d-flex align-items-center">
        <img
          src={row.image_url ? row.image_url : defaultImage}
          className="profile"
        />

        <div className="rightVideoTitle">
          <p className="theme-text-color">{data}</p>
          <span className="me-1 text-dark dateText theme-text-color">
            {" "}
            {row?.duration}
          </span>
          <span className="dateText">{row?.upload_on}</span>
        </div>
      </div>
    );
  };

  const actionFormatter = (data, row) => {
    return (
      <div className="editdropdown">
        <span className="editdropdown-button">
          <span>
            {" "}
            <BsThreeDotsVertical className=" theme-text-color" />
          </span>
        </span>
        <div className="editdropdown-menu  theme-bg-color">
          <Link to={`/editpages/${row.id}`} className="theme-text-color  theme-bg-color">
            <span>
              <img src={edititem} alt="flogo" width={20} height={20} />
              <span className="ms-2 theme-text-color "> Edit Page</span>
            </span>
          </Link>

          <div
            onClick={() => openModalSingleDeletePages(row.id)}
            className="commonActionPadding theme-bg-color"
          >
            <img src={deleteitem} alt="flogo" width={20} height={20} />
            <span className="ms-2 theme-text-color ">Delete Page</span>
          </div>
        </div>
      </div>
    );
  };

  const statusFormatter = (data, row) => {
    return (
      <div
        className={`  font-weight-bolder  d-flex justify-content-center ${data === "PUBLISHED"
          ? "PUBLISHED_active"
          : data === "DRAFT"
            ? " DISABLED_active"
            : "UNPUBLISHED_active"
          }`}
      >
        {data}
      </div>
    );
  };

  const columns = [
    {
      dataField: "title",
      text: "Pages",
      formatter: pagesFormatter,
      sort: true,
    },
    {
      dataField: "slug",
      text: "Url",
      sort: true,
    },
    {
      dataField: "active",
      text: "Status",
      formatter: statusFormatter,
      sort: true,
    },
    {
      dataField: "ACTIONS",
      text: "Actions",
      formatter: actionFormatter,
    },
  ];

  const exportHeadersPages = [
    { label: "ID", key: "id" },
    { label: "PAGES", key: "title" },
    { label: "URL", key: "slug" },
    { label: "STATUS", key: "active" },
    { label: "UPLOAD ON", key: "upload_on" },
  ];
  const conditionalGeneratePDFPages = (get) => {
    const doc = new jsPDF();
    doc.text("ALL PAGES LIST ", 10, 10);
    const columns = ["INDEX", "ID", "PAGES", "URL", "STATUS", "UPLOAD-ON"];
    const allDataPDF = datapages.map((item, index) => [
      index + 1,
      item?.id,
      item?.title,
      item?.slug,
      item?.active,
      item?.upload_on,
    ]);
    const filterPDF = filteredPagesData.map((item, index) => [
      index + 1,
      item?.id,
      item?.title,
      item?.slug,
      item?.active,
      item?.upload_on,
    ]);

    doc.autoTable({
      startY: 20,
      head: [columns],
      body: get == 1 ? allDataPDF : filterPDF,
    });
    doc.save("ALL-PAGES-LIST.pdf");
  };

  const generateXLSXPAGES = (get) => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(get === 1 ? datapages : filteredPagesData);
    XLSX.utils.book_append_sheet(wb, ws, "MySheet");
    XLSX.writeFile(wb, "ALL-PAGES-LIST.xlsx");
  };

  const handleFilterChangePages = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setFilter(searchTerm);
    const filteredResults = datapages.filter((item) =>
      ["id", "title", "slug", "active", "upload_on"].some(
        (property) =>
          typeof item[property] === "string" &&
          item[property].toLowerCase().includes(searchTerm)
      )
    );
    setFilteredPagesData(filteredResults);
  };

  const handleClear = () => {
    fetchDatapages();
    setFilter("");
  };

  const handleAll = () => {
    setSelectAll(!selectAll);
    setItemToDeletePages(
      selectAll ? [] : filteredPagesData.map((item) => item?.id)
    );
  };

  const closePagesModal = () => {
    setModalClosePages(!modalClosePages);
  };

  const openModalSingleDeletePages = (id) => {
    setItemToDeletePages([id]);
    setModalClosePages(true);
  };

  const openModalMultiDeletePages = () => {
    setModalClosePages(true);
  };

  const handleDeletePagesData = () => {
    closePagesModal();
    pagesDeleteOperation();
  };

  const pagesDeleteOperation = async () => {
    setProcessing(true);
    setShowOverlay(true);
    const formData = new FormData();
    formData.append("id", "[" + itemToDeletePages + "]");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/admin/page/multi-delete`,
        formData,
        {
          headers: headers,
        }
      );
      const result = response.data;
      if (result.status === true) {
        // props.setApiresponsealert(result);
        // props.setShowalert(true);
        toast.success(result?.message);
        setProcessing(false);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err.response.data.message;
      // props.setApiresponsealert({ status: false, message: errorMessage });
      // props.setShowalert(true);
      toast.error({ status: false, message: errorMessage });
      setProcessing(false);
      setShowOverlay(false);
    }
  };

  return (
    <>
      <div className="allaudioscpp">
        <div
          className={`modal  packageModel ${modalClosePages ? "show" : ""}`}
          tabIndex="-1"
          style={{
            display: modalClosePages ? "block" : "none",
          }}
        >
          <div className="modal-dialog">
            <div className="modal-content theme-bg-color shadow">
              <div className="modal-header">
                <h5 className="modal-title">Confirm Deletion</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closePagesModal}
                ></button>
              </div>
              <div className="modal-body">
                {itemToDeletePages?.length === 1 ? (
                  <p className="fs-6 theme-text-color">
                    {" "}
                    Are you sure you want to delete this page ?
                  </p>
                ) : (
                  <p className="fs-6 theme-text-color">
                    Are you sure you want to delete the selected pages ?
                  </p>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={closePagesModal}
                >
                  Close
                </button>{" "}
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleDeletePagesData}
                >
                  Yes
                </button>{" "}
              </div>
            </div>
          </div>
        </div>

        <div>
          {showOverlay && (<ResponseLoader />)}
        </div>

        <section className="section container-fluid  theme-bg-color-secondary  paddingbottomm pt-2 all-audio-stream">
          <div id="content-page" className="content-page">
            <div className="row pb-3 admin-section-title">
              <div className="col-md-6">
                <h4 className=" theme-text-color">
                  All Page{" "}
                  <span className="fs-6">
                    (Display all {datapages?.length} Page)
                  </span>
                </h4>
              </div>
              <div className="col-md-6 col-12 mt-3 mt-md-0" align="right">
                {itemToDeletePages?.length > 0 && (
                  <button
                    className="btn btn-danger d-inline-flex  fw-bolder "
                    onClick={openModalMultiDeletePages}
                  >
                    <MdDelete className="exportIcon " />
                    Delete
                  </button>
                )}
                <Link to="/cpp/add-pages" className="btn btn-primary fw-bolder">
                  <FaCirclePlus /> Add Page
                </Link>
              </div>
            </div>
            <div className="iq-card mt-3 p-3 theme-bg-color">
              <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 gap-3">
                <div className="filterContainer">
                  <input
                    type="text"
                    placeholder="Filter all.."
                    className="filter form-control "
                    value={filter}
                    onChange={handleFilterChangePages}
                  />
                  {filter?.length > 0 && (
                    <button
                      type="button"
                      className="closeBtn"
                      onClick={handleClear}
                    >
                      <MdOutlineClose />
                    </button>
                  )}
                </div>
                <div className="btn-group">
                  <div className="dropdown d-block " key={"2"}>
                    <button
                      className="btn btn-success dropdown-toggle d-flex"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="fw-bolder">Export </span>
                      <img src={ExportIcon} className="ms-2 exportIcon" />
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li className="dropdown-item">
                        <CSVLink
                          headers={exportHeadersPages}
                          data={filteredPagesData}
                          className="text-dark d-block"
                          filename="PAGES-FILTERED-LIST.csv"
                        >
                          CSV
                        </CSVLink>
                      </li>
                      <li
                        className="dropdown-item"
                        onClick={() => {
                          conditionalGeneratePDFPages(0);
                        }}
                      >
                        PDF
                      </li>
                      <li
                        className="dropdown-item"
                        onClick={() => {
                          generateXLSXPAGES(0);
                        }}
                      >
                        XLSX
                      </li>
                    </ul>
                  </div>

                  <div className="dropdown d-block ms-2">
                    <button
                      className="btn btn-success dropdown-toggle d-flex"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="fw-bolder">Export All</span>
                      <img src={ExportIcon} className="ms-2 exportIcon" />
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li className="dropdown-item">
                        <CSVLink
                          headers={exportHeadersPages}
                          data={datapages}
                          className="text-dark d-block"
                          filename="ALL-PAGES-LIST.csv"
                        >
                          CSV
                        </CSVLink>
                      </li>
                      <li
                        className="dropdown-item"
                        onClick={() => {
                          conditionalGeneratePDFPages(1);
                        }}
                      >
                        PDF
                      </li>
                      <li
                        className="dropdown-item"
                        onClick={() => {
                          generateXLSXPAGES(1);
                        }}
                      >
                        XLSX
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="bootstrapTable theme-bg-color">
                <BootstrapTable
                  keyField="id"
                  columns={columns}
                  data={filteredPagesData}
                  pagination={pagination}
                  className="no-border-table theme-text-color"
                  noDataIndication={
                    <div className="noRecord">
                      {loading ? (
                        <label>
                          {" "}
                          <img
                            src={loadings}
                            width={30}
                            height={30}
                            alt="Animated GIF"
                          />
                          Loading...
                        </label>
                      ) : (
                        filteredPagesData?.length <= 0 && (
                          <p className=" theme-text-color">No Pages Available. !!!</p>
                        )
                      )}
                    </div>
                  }
                  hover
                  headerClasses="videoWidth"
                  selectRow={selectRow}
                  selectAll={selectAll}
                />
              </div>
            </div>
          </div>
        </section>
      </div></>
  );
}

export default Content_AllPages;
