import React from 'react';
import { useMyContext } from './Layout/CommonProvider';
import { useTranslation } from 'react-i18next';
const OnlineStatusAlert = ({ reload }) => {
    const { isOnline } = useMyContext();
    const { t } = useTranslation();

    return (
        <div className={`onlineStatus d-flex align-items-center    ${isOnline ? 'button-btn justify-content-center shadow' : 'text-dark shadow bg-white justify-content-between'}`} >
            <p className='text-center'>{t(isOnline ? 'Back online' : 'You are offline')}</p>
            {!isOnline && <button type='button' className='bg-transparent text-primary' onClick={reload}>Retry</button>}
        </div >
    );
};

export default OnlineStatusAlert;
