import React from "react";
import "../../Styles/signin.css";
import { Link } from "react-router-dom";
import { VscEyeClosed } from "react-icons/vsc";
import { RxEyeOpen } from "react-icons/rx";
import google from "../../assets/icons/google-icon.webp";
import fb from "../../assets/icons/facebook-icon.webp";
import { connect } from "react-redux";
import LazyLoadImage from "../HomePage/HomeSwiperComponents/LazyLoadImage.js";
import { useTranslation } from "react-i18next";
import ButtonWithLoader from "../Music/CommonComponents/ButtonWithLoader.jsx";
import { MdClose } from "react-icons/md";
import { useMyContext } from "../../components/Layout/CommonProvider.js";


const Signin = ({ themeMode, authAction, error, onchange, field, show, data, passAction, modalClose, signInModal, signUpModal }) => {
  const { t } = useTranslation()
  const { navigateTranslateChecker } = useMyContext()
  return (
    <div >
      <div className={`w-100 min-vh-100 ${data?.pop} row mx-0 align-items-center  `}>
        <div  className="col-11 col-md-8 col-lg-4 mx-auto ">
          <div className="signin-popup-container theme-bg-color p-3 rounded-3 position-relative" >
            {data?.headerDisable && <button className='bg-transparent p-0 theme-text-color position-absolute closeBtnModal' type='button' onClick={() => modalClose()}><MdClose className='playlistIcon' /></button>}
            <div  className="modal-content theme-bg-color ">
              <div  className="modal-header flex-column border-0 py-3">
                <LazyLoadImage src={themeMode?.logoUrl} alt="SignInLogo" className="d-block mx-auto signin-logo-images w-50 object-fit-contain" />
              </div>
              <div  className="modal-body">
                <div className={`${data?.headerDisable ? "popup-active login" : ""} signInMenu  position-relative`}>
                  <form onSubmit={authAction}>
                    <div className="position-relative pb-4">
                      <div className="border-bottom">
                        <input id="signinemail" name="email" type="text" autoComplete="off" className="p-2 signin-input border-0 theme-text-color w-100" value={field?.username} onChange={onchange} placeholder={`${t('Email')} *`} />
                        {error?.emailerr !== "" && (
                          <span className="d-flex text-danger signin-error py-2 position-absolute">
                            {error?.emailerr}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="position-relative pb-4">
                      <div className="position-relative">
                        <input id="signinpassword" name="password" value={field?.password} type={show ? 'password' : 'text'} autoComplete="off" className="p-2 signin-input border-0 theme-text-color border-bottom" onChange={onchange} placeholder={`${t('Password')} *`} />
                        <button onClick={passAction} type="button" className="p-2 border-0 eyeButton">{show ? <VscEyeClosed className="theme-text-color eyeIcons" style={{ width: "25px", height: "25px" }} /> : <RxEyeOpen className="theme-text-color eyeIcons" style={{ width: "25px", height: "25px" }} />}</button>
                      </div>
                      <Link to={`/${data?.lang?.Language_Code}/verify/forget`} className="float-end theme-text-color py-2">{t("Forgot Password?")}</Link>
                      {error?.passworderr !== "" && (
                        <span className="d-flex text-danger signin-error py-2 position-absolute">
                          {error?.passworderr}
                        </span>
                      )}
                    </div>
                    <h4 className="signinLabelText py-3 mb-0 theme-text-color">{t("If your email is not registered with us, we will verify your email and sign you up")}</h4>
                    <ButtonWithLoader type="submit" action={authAction} text="Sign In" loading={data?.load} className='signinbutton text-center  mb-0 mx-auto d-block text-uppercase px-5 py-1 rounded-2 theme-text-color w-50' />
                    <h4 className="signinLabelText py-3 theme-text-color">{t("Or Continue With")}</h4>
                    <div className="d-flex justify-content-center gap-3">
                      <img src={google} alt="google" style={{ width: "39px", height: "40px" }} />
                      <img src={fb} alt="facebook" style={{ width: "43px", height: "43px" }} />
                    </div>
                    <p className="signinLabelText mb-0 pt-2 theme-text-color">{t("Don't have an account")}? {data?.headerDisable ? <button type="button" className="border-0 bg-transparent theme-text-color" onClick={() => signUpModal()}>{t("Sign Up")}</button> : <button type="button" className="border-0 bg-transparent theme-text-color" > <Link to={navigateTranslateChecker(`/signup`)}>{t("Sign Up")}</Link></button>}</p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </div >
  );
};

const mapStateToProps = (state) => ({
  themeMode: state.get_headerIndex_Reducer.themeMode,
});

export default connect(mapStateToProps)(Signin);