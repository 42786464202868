// External Libraries and Packages
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { useTranslation } from 'react-i18next';
// Internal Styles
import "swiper/css";
import "swiper/css/navigation";
// Internal Components'
import { connect } from 'react-redux';
import CardWithHover from '../../HomePage/HomeSwiperComponents/Theme/CardWithHover';
import CardWithoutHover from '../../HomePage/HomeSwiperComponents/Theme/CardWithoutHover';
import { URLGenerater } from '../../CommonMethods/CommonMethods';
import { useMyContext } from '../../../components/Layout/CommonProvider';
const ShowAll = ({ item, ThumbnailTitle, themeMode }) => {
    const { navigateTranslateChecker } = useMyContext()
    const { t } = useTranslation();
    const swiperConfig = {
        slidesPerView: 2,
        spaceBetween: 15,
        navigation: true,
        lazy: true,
        slidesPerGroup: 2,
        breakpoints: {
            376: { slidesPerView: 2, spaceBetween: 15, slidesPerGroup: 2 },
            576: { slidesPerView: 3, spaceBetween: 15, slidesPerGroup: 3 },
            768: { slidesPerView: 4, spaceBetween: 15, slidesPerGroup: 4 },
            1024: { slidesPerView: 5, spaceBetween: 15, slidesPerGroup: 5 },
            1200: { slidesPerView: 6, spaceBetween: 15, slidesPerGroup: 6 },
            1440: { slidesPerView: 6, spaceBetween: 15, slidesPerGroup: 6 },
        },
        modules: [Navigation],
        className: 'homePageSwiper'
    }

    return (
        <div className=' pt-1 zIndex'>
            {
                item?.data.length > 0 && (<>
                    <Link to={navigateTranslateChecker(`/${URLGenerater(item?.source)}`)} className="blocklinetitle text-decoration-none  d-inline-block swiperHeading theme-text-color" aria-label={`View all ${item?.header_name}`}>{t(item?.header_name)} </Link>
                    <Link to={navigateTranslateChecker(`/${URLGenerater(item?.source)}`)} className="blocklinetitle text-decoration-none  float-end  swiperHeading theme-text-color viewall-homepage" aria-label={`View all ${item?.header_name}`}>{t('View All')}</Link>
                </>)
            }
            <Swiper {...swiperConfig} >
                {item?.data.map((value, index) => (
                    <SwiperSlide key={value?.id} className='theme-bg-color-secondary' >
                        {themeMode?.hoverCard ? <CardWithHover value={value} segMent="shows" hoverClass="homeSwiperContainer" /> : <CardWithoutHover value={value} segMent="shows" />}
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}
const mapStateToProps = (state) => ({
    themeMode: state.get_headerIndex_Reducer.themeMode,
});
export default connect(mapStateToProps)(ShowAll);
