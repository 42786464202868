import React from 'react'
import LazyLoadImage from '../../HomePage/HomeSwiperComponents/LazyLoadImage'
import { connect } from 'react-redux';
import MessageBox from '../../../components/MessageBox';
import { Link } from 'react-router-dom';
import PlayButton from './PlayButton';
import { useMyContext } from '../../../components/Layout/CommonProvider';

const MusicBasedLanguageCard = ({ store, currentAudio, currentPlaying, languageDetailsData }) => {
    const { navigateTranslateChecker } = useMyContext()
    return (
        <div className='p-2 rounded-2 w-100 h-100 theme-border-secondary position-relative '>
            <LazyLoadImage key={store?.title} src={store?.image_url} alt={store?.title} title={store?.title} imageSize="rounded-2  overflow-hidden musicImage object-fit-cover" classNameOptional="rounded-2 musicImageHeight" />
            <Link to={navigateTranslateChecker(`/music/track/${store?.slug}`)}> <MessageBox classname='playCardTitle py-2 theme-text-color' text={store?.title} /></Link>
            <MessageBox classname='playCardDescription theme-text-color opacity-75' text={store?.description} />
            <PlayButton type="AUDIO" store={store} />
        </div>
    )
}
const mapStateToProps = state => ({
    currentAudio: state.get_current_Reducers.currentAudio,
    currentPlaying: state.get_current_Reducers.currentPlaying,
    languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData
})
export default connect(mapStateToProps)(MusicBasedLanguageCard)