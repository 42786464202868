// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transcation_list_bg {
  /* background-color: #fff; */
}

.closeBtn {
  top: 89.5%;
  position: absolute;
  left: 22%;
  background: transparent;

  color: black;
}

/* #pageDropDown {
  display: none;
} */

.searchPageIconFaSearchtranssearchPageIconFaSearchtrans {
  position: absolute;
  top: 74px;
  left: 142px;
  width: 25px;
  height: 33px;
  z-index: 1;
}

/* Add this to your CSS file or in a style tag */
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  /* Enables horizontal scroll */
  -webkit-overflow-scrolling: touch;
  /* Enables smooth scrolling on iOS */
}

.table-responsive Table {
  width: 100%;
  /* Ensures table width is 100% */
  min-width: 600px;
  /* Optional: Add a minimum width if needed */
}`, "",{"version":3,"sources":["webpack://./src/Styles/Transcation_List_Register_Payments.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;AAC9B;;AAEA;EACE,UAAU;EACV,kBAAkB;EAClB,SAAS;EACT,uBAAuB;;EAEvB,YAAY;AACd;;AAEA;;GAEG;;AAEH;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,WAAW;EACX,YAAY;EACZ,UAAU;AACZ;;AAEA,gDAAgD;AAChD;EACE,cAAc;EACd,WAAW;EACX,gBAAgB;EAChB,8BAA8B;EAC9B,iCAAiC;EACjC,oCAAoC;AACtC;;AAEA;EACE,WAAW;EACX,gCAAgC;EAChC,gBAAgB;EAChB,4CAA4C;AAC9C","sourcesContent":[".transcation_list_bg {\n  /* background-color: #fff; */\n}\n\n.closeBtn {\n  top: 89.5%;\n  position: absolute;\n  left: 22%;\n  background: transparent;\n\n  color: black;\n}\n\n/* #pageDropDown {\n  display: none;\n} */\n\n.searchPageIconFaSearchtranssearchPageIconFaSearchtrans {\n  position: absolute;\n  top: 74px;\n  left: 142px;\n  width: 25px;\n  height: 33px;\n  z-index: 1;\n}\n\n/* Add this to your CSS file or in a style tag */\n.table-responsive {\n  display: block;\n  width: 100%;\n  overflow-x: auto;\n  /* Enables horizontal scroll */\n  -webkit-overflow-scrolling: touch;\n  /* Enables smooth scrolling on iOS */\n}\n\n.table-responsive Table {\n  width: 100%;\n  /* Ensures table width is 100% */\n  min-width: 600px;\n  /* Optional: Add a minimum width if needed */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
