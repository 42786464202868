import React from 'react';
import '../Signin/Header.css'
import logo from '../../assets/images/site-dark-logo.webp'
function Header() {
  return (

    <section className="header1">
      <div className="container text-center p-3">
        <a href="/">
          <img src={logo} className="p-1" alt='flogo' />
        </a>
      </div>
    </section>
  );
}

export default Header;