import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "./Contentvideocategory.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MdDelete } from "react-icons/md";
import { FaEllipsisVertical } from "react-icons/fa6";
import { toast } from "react-toastify";
import loadings from "../../../assets/gif/loading.gif";
import deleteitem from "../../../assets/icons/deleteitem.svg";
import edititem from "../../../assets/icons/edititem.svg";
import ResponseLoader from "../../../components/Loader/ResponseLoader";
import { useTranslation } from "react-i18next";

const Contentvideocategory = () => {
  const { t } = useTranslation();
  const [getVideoCategory, setVideoCategory] = useState([]);
  const [itemToDeleteVideoCategory, setItemToDeleteVideoCategory] = useState([]);
  const [selectAllVideoCategory, setSelectAllVideoCategory] = useState(false);
  const [modalCloseVideoCategory, setModalCloseVideoCategory] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [loading, setLoading] = useState(true);

  // let access_token, access_token_cpp;
  // if (localStorage.getItem("username")) {
  //   access_token = localStorage.getItem("access_token");
  // } else {
  //   access_token_cpp = localStorage.getItem("access_token_cpp");
  // }

  // const headers = {
  //   "Content-Type": "application/json",
  //   // Authorization: "Bearer " + access_token_cpp,
  //   Accept: "application/json",
  //   "Access-Control-Allow-Origin": "*",
  // };

  // if (access_token) {
  //   headers.Authorization = "Bearer " + access_token;
  // } else if (access_token_cpp) {
  //   headers.Authorization = "Bearer " + access_token_cpp;
  // }

  const access_token_cpp = localStorage.getItem("access_token_cpp");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token_cpp,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    fetchDatavideo();
  }, []);

  const fetchDatavideo = async () => {
    try {
      const formData = new FormData();

      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/videos-category/List`,
        formData,
        { headers: headers }
      );

      if (response.status === 200) {
        setVideoCategory(response?.data?.VideoCategory?.data);
      } else {
        console.error("Error fetching data. Status:", response.status);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedItems = Array.from(getVideoCategory);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);
    setVideoCategory(reorderedItems);
    saveShuffleOrder(reorderedItems);
  };

  const saveShuffleOrder = async (updatedItems) => {
    setProcessing(true);
    setShowOverlay(true);

    if (!updatedItems || updatedItems?.length == 0) {
      console.error("Menu items are empty");
      return;
    }

    const positions = updatedItems.map((menuItem) => menuItem.id);
    const ids = updatedItems.map((_, index) => index + 1);

    const requestData = {
      id: JSON.stringify(positions),
      position: JSON.stringify(ids),
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/videos-category/delete`,
        requestData,
        { headers: headers }
      );

      const result = response.data;
      if (result.status == true) {
        // props.setApiresponsealert(result);
        // props.setShowalert(true);
        toast.success(t(result?.message));
        setProcessing(false);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err.response.data.message;
      // props.setApiresponsealert({ status: false, message: errorMessage });
      // props.setShowalert(true);
      toast.error({ status: false, message: t(errorMessage) });
      setProcessing(false);
      setShowOverlay(false);
    }
  };
  const handleSelectAllVideofile = () => {
    setSelectAllVideoCategory(!selectAllVideoCategory);
    setItemToDeleteVideoCategory(
      selectAllVideoCategory ? [] : getVideoCategory?.map((item) => item.id)
    );
  };

  const handleMultiSelectVideoFile = (itemId) => {
    if (itemToDeleteVideoCategory.includes(itemId)) {
      setItemToDeleteVideoCategory(
        itemToDeleteVideoCategory.filter((id) => id !== itemId)
      );
    } else {
      setItemToDeleteVideoCategory([...itemToDeleteVideoCategory, itemId]);
    }
  };

  const singleDeleteVideoCategory = (id) => {
    setItemToDeleteVideoCategory([id]);
    setModalCloseVideoCategory(true);
    setSelectAllVideoCategory(false);
  };

  const closeVideoCategoryModal = () => {
    setModalCloseVideoCategory(!modalCloseVideoCategory);
  };

  const openModalMultiDeleteVideoCategory = () => {
    setModalCloseVideoCategory(true);
  };

  const handleDeleteVideoCategoryData = () => {
    closeVideoCategoryModal();
    videoCategoryDeleteOperation();
  };

  const videoCategoryDeleteOperation = async () => {
    setProcessing(true);
    setShowOverlay(true);
    const formData = new FormData();
    formData.append("id", "[" + itemToDeleteVideoCategory + "]");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/CPP/Backend/videos-category/multi-delete`,
        formData,
        {
          headers: headers,
        }
      );
      const result = response.data;
      if (result.status == true) {
        // props.setApiresponsealert(result);
        // props.setShowalert(true);
        toast.success(t(result?.message));
        setProcessing(false);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err.response.data.message;
      // props.setApiresponsealert({ status: false, message: errorMessage });
      // props.setShowalert(true);
      toast.error({ status: false, message: t(errorMessage) });
      setProcessing(false);
      setShowOverlay(false);
    }
  };

  return (
    <div className="channel_video_category">
      <div
        className={`modal  packageModel ${modalCloseVideoCategory ? "show" : ""
          }`}
        tabIndex="-1"
        style={{ display: modalCloseVideoCategory ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content theme-bg-color global-shadow">
            <div className="modal-header">
              <h5 className="modal-title theme-text-color">Confirm Deletion</h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeVideoCategoryModal}
              ></button>
            </div>
            <div className="modal-body">
              {itemToDeleteVideoCategory?.length == 1 ? (
                <p className="fs-6 theme-text-color">
                  Are you sure you want to delete this video category?
                </p>
              ) : (
                <p className="fs-6 theme-text-color">
                  Are you sure you want to delete the selected video category ?
                </p>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={closeVideoCategoryModal}
              >
                Close
              </button>

              <button
                type="button"
                className="btn btn-danger"
                onClick={handleDeleteVideoCategoryData}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>{showOverlay && <ResponseLoader />}</div>

      <section className="section container-fluid pt-3  theme-bg-color-secondary  paddingbottomm">
        <div id="content-page" className="content-page">
          <div className="row pb-3 admin-section-title">
            <div className="col-md-6">
              <h4 className="theme-text-color">
                Video Categories{" "}
                <span className="fs-6">
                  (Display all {getVideoCategory?.length} Category)
                </span>
              </h4>
            </div>

            <div className="col-md-6 col-12 mt-3 mt-md-0" align="right">
              {itemToDeleteVideoCategory?.length > 0 && (
                <button
                  type="button"
                  className="btn btn-danger d-inline-flex  fw-bolder "
                  onClick={openModalMultiDeleteVideoCategory}
                >
                  <MdDelete className="exportIcon " />
                  Delete
                </button>
              )}

              <Link to="/cpp/add-video-category" className="ms-2">
                <button type="button" className="btn btn-primary fw-bolder">
                  <i className="fa fa-plus-circle"></i> Add Video Categories
                </button>
              </Link>
            </div>
          </div>
          <div className="rounded-3 border-0 mt-4 theme-bg-color ">
            <div className="bootstrapTable marginbbb p-2">
              <div className=" col-lg-12 col-sm-12 col-md-12 col-12 p-2">
                <div className="row m-0 p-0">
                  <div className="col-lg-2 col-md-2 col-sm-2 col-2 data">
                    {" "}
                    <label>
                      <input
                        type="checkbox"
                        checked={selectAllVideoCategory}
                        onChange={handleSelectAllVideofile}
                      />
                    </label>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-4 data">
                    {" "}
                    <label className="theme-text-color fw-bold fs-6">
                      Category
                    </label>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-3 col-3 data">
                    {" "}
                    <label className="theme-text-color fw-bold fs-6">
                      Uploaded by
                    </label>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-3 col-3 data">
                    {" "}
                    <label className="theme-text-color fw-bold fs-6">
                      Actions
                    </label>
                  </div>
                </div>
                <hr className=" theme-text-color"></hr>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable
                    droppableId="menu-items"
                    className="theme-bg-color"
                  >
                    {(provided) => (
                      <ul
                        {...provided.droppableProps}
                        className="p-0 theme-bg-color"
                        ref={provided.innerRef}
                      >
                        {loading ? (
                          <div className="noRecord">
                            {" "}
                            <img
                              src={loadings}
                              width={30}
                              height={30}
                              alt="Animated GIF"
                            />
                            Loading...
                          </div>
                        ) : getVideoCategory?.length <= 0 ? (
                          <div className="noRecord">
                            {" "}
                            <p className="theme-text-color">
                              No Video Category Available !!!{" "}
                            </p>
                          </div>
                        ) : (
                          getVideoCategory?.map((item, index) => (
                            <Draggable
                              key={item?.id.toString()}
                              draggableId={item?.id.toString()}
                              index={index}
                            >
                              {(provided) => (
                                <li
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className={`theme-bg-color orderborder ${item.order
                                    } index ${index + 1}w-100`}
                                >
                                  <div className="row m-0 p-0 align-items-center">
                                    <div className="col-lg-2 col-md-2 col-sm-2 col-2  data_th">
                                      <input
                                        type="checkbox"
                                        checked={itemToDeleteVideoCategory.includes(
                                          item.id
                                        )}
                                        onChange={() =>
                                          handleMultiSelectVideoFile(item.id)
                                        }
                                      />
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-4  data_th">
                                      <div className="d-flex justify-content-between align-items-center">
                                        <img
                                          src={item.image_url}
                                          className="profile"
                                        />
                                        <div className="rightVideoTitle">
                                          <p className=" theme-text-color">
                                            {item.name}
                                          </p>
                                          <span className="dateText  theme-text-color">
                                            {item?.upload_on}
                                          </span>
                                        </div>
                                      </div>
                                    </div>{" "}
                                    <div className="col-lg-3 col-md-3 col-sm-3 col-3  data_th">
                                      <div className=" theme-text-color">
                                        {" "}
                                        {item?.uploaded_by == "1"
                                          ? "Admin"
                                          : item?.uploaded_by == "5"
                                            ? "Content Partner"
                                            : item?.uploaded_by == "4"
                                              ? "Channel Partner"
                                              : ""}
                                      </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-3 col-3  data_th">
                                      <div className="editdropdown ">
                                        <span className="editdropdown-button">
                                          <span className="theme-text-color">
                                            <FaEllipsisVertical className="theme-text-color" />
                                          </span>
                                        </span>
                                        <div className="editdropdown-menu  theme-bg-color ">
                                          <Link
                                            to={
                                              "/cpp/edit-category/" + item?.id
                                            }
                                            className=" theme-bg-color"
                                          >
                                            <span>
                                              <img
                                                src={edititem}
                                                alt="flogo"
                                                width={20}
                                                height={20}
                                              />
                                              <span className="ms-2 theme-text-color">
                                                Edit Categories
                                              </span>
                                            </span>
                                          </Link>
                                          <div
                                            onClick={() =>
                                              singleDeleteVideoCategory(
                                                item?.id
                                              )
                                            }
                                            className="commonActionPadding  theme-bg-color"
                                          >
                                            <img
                                              src={deleteitem}
                                              alt="flogo"
                                              width={20}
                                              height={20}
                                            />
                                            <span className="ms-2 theme-text-color">
                                              Delete Categories
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <hr className=" theme-text-color p-0 m-0"></hr>
                                </li>
                              )}
                            </Draggable>
                          ))
                        )}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contentvideocategory;
