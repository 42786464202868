// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Card */
.card {
  margin-bottom: 30px;
  border: none;
  border-radius: 5px;
}

.card-header,
.card-footer {
  border-color: #ebeef4;
  background-color: #fff;
  padding: 15px;
}

.user-webnexs .card-title {
  padding: 20px 0 15px 0;
  font-size: 18px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.card-title span {
  font-size: 14px;
  font-weight: 400;
}

.card-body {
  padding: 0 20px 20px 20px;
}

.card-img-overlay {
  background-color: rgba(255, 255, 255, 0.6);
}

.dashboard .nav-link {
  border-radius: 5px;
  font-weight: 500;
  background-color: #e1e1e1;
}

.dashboard .nav-link.active {
  background: rgb(255 255 255 / 20%);
  font-size: 16px;
  font-weight: 500;
}

.upload-video {
  background-color: #fafafa;
  padding: 10px;
  border-radius: 10px;
}

.iq-card {
  background: #fff;
  border-radius: 5px;
  margin-bottom: 30px;
  border: none;
  box-shadow: 0px 4px 20px rgb(0 0 0 / 5%);
  border-radius: 5px;
}
.rent_policsh {
  font-size: 12px;
}
@media (min-width: 576px) {
  .rent_payment_setting .modal-dialog {
    max-width: 600px;
    margin: 1.25rem auto;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Contentportal/Content_Dashboard/ContentDashboard.css"],"names":[],"mappings":"AAAA,SAAS;AACT;EACE,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;;EAEE,qBAAqB;EACrB,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,sBAAsB;EACtB,eAAe;EACf,gBAAgB;EAChB,kCAAkC;AACpC;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,0CAA0C;AAC5C;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,kCAAkC;EAClC,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAGhB,kBAAkB;EAClB,mBAAmB;EACnB,YAAY;EACZ,wCAAwC;EACxC,kBAAkB;AACpB;AACA;EACE,eAAe;AACjB;AACA;EACE;IACE,gBAAgB;IAChB,oBAAoB;EACtB;AACF","sourcesContent":["/* Card */\n.card {\n  margin-bottom: 30px;\n  border: none;\n  border-radius: 5px;\n}\n\n.card-header,\n.card-footer {\n  border-color: #ebeef4;\n  background-color: #fff;\n  padding: 15px;\n}\n\n.user-webnexs .card-title {\n  padding: 20px 0 15px 0;\n  font-size: 18px;\n  font-weight: 500;\n  font-family: \"Poppins\", sans-serif;\n}\n\n.card-title span {\n  font-size: 14px;\n  font-weight: 400;\n}\n\n.card-body {\n  padding: 0 20px 20px 20px;\n}\n\n.card-img-overlay {\n  background-color: rgba(255, 255, 255, 0.6);\n}\n\n.dashboard .nav-link {\n  border-radius: 5px;\n  font-weight: 500;\n  background-color: #e1e1e1;\n}\n\n.dashboard .nav-link.active {\n  background: rgb(255 255 255 / 20%);\n  font-size: 16px;\n  font-weight: 500;\n}\n\n.upload-video {\n  background-color: #fafafa;\n  padding: 10px;\n  border-radius: 10px;\n}\n\n.iq-card {\n  background: #fff;\n  -webkit-border-radius: 5px;\n  -moz-border-radius: 5px;\n  border-radius: 5px;\n  margin-bottom: 30px;\n  border: none;\n  box-shadow: 0px 4px 20px rgb(0 0 0 / 5%);\n  border-radius: 5px;\n}\n.rent_policsh {\n  font-size: 12px;\n}\n@media (min-width: 576px) {\n  .rent_payment_setting .modal-dialog {\n    max-width: 600px;\n    margin: 1.25rem auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
