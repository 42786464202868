import React, { useState, useEffect } from "react";
import "./Contentsignin.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Navbar from "./ContentHeader";
import { toast } from "react-toastify";
// import Navbar from "./Header";
import { FaFacebookF, FaGoogle } from "react-icons/fa";
import { VscEyeClosed } from "react-icons/vsc";
import { RxEyeOpen } from "react-icons/rx";
import google from "../../assets/icons/google-icon.webp";
import fb from "../../assets/icons/facebook-icon.webp";
import { IoClose } from "react-icons/io5";
import { connect } from "react-redux";
// import ForgotPassword from "../Others/ForgotPassword";
import LazyLoadImage from "../../Pages/HomePage/HomeSwiperComponents/LazyLoadImage.js";
import { useTranslation } from "react-i18next";

const ContentSignin = ({ themeMode, footerlink, sociallink, companyname, isLoading, activeyear, error, props }) => {
  // const navigate = useNavigate();

  // const [loading, setLoading] = useState(false);
  // const [inputField, setInputField] = useState({
  //   email: "",
  //   password: "",
  // });
  // const [errField, setErrField] = useState({
  //   emailerr: "",
  //   passworderr: "",
  // });
  // function clicked(e) {
  //   e.preventDefault();
  // }
  // function handleChange(e) {
  //   setInputField({ ...inputField, [e.target.name]: e.target.value });
  // }

  // useEffect(async () => {
  //   if (localStorage.getItem("access_token_cpp") !== null) {
  //    await navigate("/cpp/home");
  //   }
  // }, []);

  // const headers = {
  //   "Content-Type": "application/json",
  //   Accept: "application/json",
  //   "Access-Control-Allow-Origin": "*",
  // };

  // async function login() {
  //   // console.log(inputField);
  //   setLoading(true);
  //   axios
  //     .post(
  //       `${process.env.REACT_APP_Baseurl}/CPP-auth/sign-in`,
  //       { email: inputField?.email, password: inputField?.password },
  //       { cors: "no-cors" },
  //       { credentials: "include" },
  //       { credentials: "same-origin" },
  //       { headers }
  //     )
  //     .then((res) => {
  //       // console.log(res)
  //       let access_token = res.data.access_token;
  //       let expires_in = res.data.expires_in;
  //       let username = res.data.CPP_user.username;
  //       let user_id = res.data.CPP_user.id;
  //       // let role = res.data.cpp_user.role;
  //       let cpp_image = res.data.CPP_user.cpp_image;
  //       localStorage.setItem("access_token_cpp", access_token);
  //       localStorage.setItem("cpp_Partner_id", user_id);
  //       // localStorage.setItem("role", role);
  //       localStorage.setItem("cpp_name", username);
  //       localStorage.setItem("cpp_image", cpp_image);
  //       localStorage.setItem("cpp_expires_in", expires_in);
  //       // localStorage.setItem("user", inputField)
  //       setLoading(false);
  //       setTimeout(() => {
  //         window.location.href = "/cpp/home";
  //       }, 1000);
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       alert("Please Enter Correct Details");
  //       console.log(err);
  //     });
  // }

  // const validForm = (e) => {
  //   e.preventDefault();

  //   const validateEmail = (email) => {
  //     return String(email)
  //       .toLowerCase()
  //       .match(
  //         /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  //       );
  //   };

  //   let formIsValid = true;
  //   setErrField({
  //     emailerr: "",
  //     passworderr: "",
  //   });
  //   if (inputField?.email == "") {
  //     formIsValid = false;
  //     setErrField((prevState) => ({
  //       ...prevState,
  //       emailerr: "Please Enter Email*",
  //     }));
  //   } else if (!validateEmail(inputField?.email)) {
  //     formIsValid = false;
  //     setErrField((prevState) => ({
  //       ...prevState,
  //       emailerr: "Please Enter Valid Email ID",
  //     }));
  //   }

  //   if (inputField?.password == "") {
  //     formIsValid = false;
  //     setErrField((prevState) => ({
  //       ...prevState,
  //       passworderr: "Please Enter Password*",
  //     }));
  //   }

  //   if (inputField?.email !== "" && inputField?.password !== "") {
  //     login();
  //   }
  //   return formIsValid;
  // };

  // const [passwordType, setPasswordType] = useState("password");
  // const [passwordInput, setPasswordInput] = useState("");
  // const handlePasswordChange = (evnt) => {
  //   setPasswordInput(evnt.target.value);
  // };
  // const togglePassword = () => {
  //   if (passwordType == "password") {
  //     setPasswordType("text");
  //     return;
  //   }
  //   setPasswordType("password");
  // };



  const { t } = useTranslation();
  const navigate = useNavigate();
  const [inputField, setInputField] = useState({ email: "", password: "" });
  const [errField, setErrField] = useState({ emailerr: "", passworderr: "" });
  const [loginError, setLoginError] = useState(null)
  const [passwordType, setPasswordType] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoginError(null)
    }, [5000])
  }, [loginError])
  function handleChange(e) {
    setInputField({ ...inputField, [e.target.name]: e.target.value });
  }

  // const headers = {
  //   "Access-Control-Allow-Origin": "*",
  //   "Access-Control-Allow-Headers":
  //     "Access-Control-Allow-Headers, Content-Type, Authorization",
  //   "Access-Control-Allow-Methods": "*",
  //   "Content-Type": "application/json",
  // };

  useEffect(async () => {
    if (localStorage.getItem("access_token_cpp") !== null) {
      await navigate("/cpp/home");
    }
  }, []);

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  async function login() {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_Baseurl}/CPP-auth/sign-in`,
        { email: inputField?.email, password: inputField?.password },
        headers
      );

      const result = res.data;
      const loginStatus = result?.status;
      if (loginStatus === true) {
        const access_token = result?.access_token;
        const expires_in = result?.expires_in;
        const username = result?.CPP_user?.username;
        const user_id = result?.CPP_user?.id;
        const cpp_image = result?.CPP_user?.cpp_image;
        toast.success(t(result?.message));
        localStorage.setItem("access_token_cpp", access_token);
        localStorage.setItem("cpp_Partner_id", user_id);
        // localStorage.setItem("role", role);
        localStorage.setItem("cpp_name", username);
        localStorage.setItem("cpp_image", cpp_image);
        localStorage.setItem("cpp_expires_in", new Date().getTime() + expires_in * 1000);
        // localStorage.setItem("cpp_expires_in", new Date().getTime() + expires_in * 1000);
        setTimeout(() => {
          window.location.href = "/cpp";
        }, 1000);
      } else {
        toast.error(t(result?.message));
      }
    } catch (error) {
      setLoginError("These credentials do not match our records");
      console.error(error);
      toast.error(t(error?.message));
    }
  }

  const validateForm = () => {
    let formIsValid = true;
    if (!inputField.email) {
      setErrField((prevState) => ({ ...prevState, emailerr: "Email cannot be empty", }));
      formIsValid = false;
    } else if (!/\S+@\S+\.\S+/.test(inputField.email)) {
      setErrField((prevState) => ({ ...prevState, emailerr: "Invalid email address", }));
      formIsValid = false;
    } else {
      setErrField((prevState) => ({ ...prevState, emailerr: "", }));
    }
    if (!inputField.password) {
      setErrField((prevState) => ({ ...prevState, passworderr: "Password cannot be empty", }));
      formIsValid = false;
    } else {
      setErrField((prevState) => ({ ...prevState, passworderr: "", }));
    }
    return formIsValid;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      login()
    } else {
      console.log('Form validation failed');
    }
  };
  const togglePassword = () => {
    setPasswordType(!passwordType)
  };


  return (
    <div>
      {/* <Navbar /> */}

      <div  className="signin-bg-data">
        <div  className="col-md-6 offset-md-3">
          <div className=" signin-popup-container theme-bg-color" >
            <div className="p-3 ">
              <div  className="modal-content theme-bg-color p-4">
                <div  className="modal-header flex-column border-0 py-3">
                  {loginError !== null && <span className="text-center d-block bg-warning p-2 mx-auto theme-text-color rounded-3 mb-4 login-error">{loginError}</span>}
                  {/* <LazyLoadImage src={themeMode?.logoUrl} alt="header-logo" className="d-block mx-auto signin-logo-image mt-2 w-auto"  /> */}
                  <LazyLoadImage src={themeMode?.logoUrl} alt="SignInLogo" className="d-block mx-auto signin-logo-images w-50 object-fit-contain" />
                  {/* <h4 className="signinText py-2 theme-text-color mb-0">FREE Non-Stop Entertainment</h4> */}
                </div>
                <div  className="modal-body">
                  <div className="signInMenu position-relative" >
                    <form autoComplete="off" onSubmit={handleSubmit}>
                      <div className="position-relative pb-4">
                        <div className="border-bottom">
                          <input
                            id="signinemail"
                            name="email"
                            type="text"
                            autoComplete="off"
                            className=" p-2 signin-input border-0 theme-text-color"
                            value={inputField?.username}
                            onChange={handleChange}
                            placeholder="Email *"
                          />
                        </div>
                        {errField?.emailerr !== "" && (
                          <span className="d-flex text-danger signin-error py-2 position-absolute">
                            {errField?.emailerr}
                          </span>

                        )}
                      </div>
                      <div className="position-relative pb-4">
                        <div className="position-relative">
                          <input
                            id="signinpassword"
                            name="password"
                            value={inputField?.password}
                            type={passwordType ? 'password' : 'text'}
                            autoComplete="off"
                            className="p-2 signin-input border-0 theme-text-color border-bottom"
                            onChange={handleChange}
                            placeholder="Password *"
                          />
                          <button onClick={togglePassword} type="button" className="p-2 border-0 eyeButton">{passwordType ? <VscEyeClosed className="theme-text-color eyeIcons" style={{ width: "25px", height: "25px" }} /> : <RxEyeOpen className="theme-text-color eyeIcons" style={{ width: "25px", height: "25px" }} />}</button>
                        </div>
                        <span className="float-end theme-text-color py-2" data-bs-toggle="modal" data-bs-target="#exampleModalToggle3" data-bs-dismiss="modal">Forgot Password ?</span>
                        {errField?.passworderr !== "" && (
                          <span className="d-flex text-danger signin-error py-2 position-absolute">
                            {errField?.passworderr}
                          </span>
                        )}
                      </div>
                      <h4 className="signinLabelText py-3 mb-0 theme-text-color">If your email is not registered with us, we will verify your email and sign you up</h4>
                      <button className="signinbutton mb-0 mx-auto d-block text-uppercase px-5 py-1 rounded-2 theme-text-color w-50" type="submit" >Sign In</button>
                      <h4 className="signinLabelText py-3 theme-text-color">Or Continue With</h4>
                      <div className="d-flex justify-content-center gap-3">
                        <img src={google} alt="google" style={{ width: "39px", height: "40px" }} />
                        <img src={fb} alt="facebook" style={{ width: "43px", height: "43px" }} />
                      </div>
                      <p className="signinLabelText mb-0 pt-2 theme-text-color" > To Content Partner Portal <Link to="/cpp/signup"><button type="button" className="border-0 bg-transparent theme-text-color">Sign up</button></Link> Here! </p>
                    </form>
                  </div>
                </div>


              </div>

            </div>
          </div></div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  settings: state.get_setting_Reducer.settings,
  isLoading: state.get_setting_Reducer.isLoading,
  props: ownProps,
  themeMode: state.get_headerIndex_Reducer.themeMode,
  footerlink: state.get_Footer_Reducer.footerlink,
  sociallink: state.get_Footer_Reducer.sociallink,
  companyname: state.get_Footer_Reducer.companyname,
  activeyear: state.get_Footer_Reducer.activeyear,
  error: state.get_Footer_Reducer.error,
});
export default connect(mapStateToProps)(ContentSignin);
