import React, { useEffect, useRef, useState } from 'react'
import axios from "axios";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import { toast } from "react-toastify"
import { useNavigate, useParams } from 'react-router-dom';
import ResponseLoader from '../../../components/Loader/ResponseLoader';
import { IoClose } from 'react-icons/io5';
import AdminSaveHeader from '../../../components/adminCommonComponents/AdminSaveHeader';
import FormContainer from '../../CommonComponents/FormContainer';
import LeftWrapper from '../../CommonComponents/LeftWrapper';
import Wrapper from '../../CommonComponents/Wrapper';
import MessageHeading from '../../CommonComponents/MessageHeading';
import MessageLabel from '../../CommonComponents/MessageLabel';
import MessageBox from '../../../components/MessageBox';
import RightWrapper from '../../CommonComponents/RightWrapper';
import { useTranslation } from 'react-i18next';

const ChannelUpdateArtists = () => {
    const { t } = useTranslation();
    const { artistid } = useParams()
    let defaultValue = '';
    const access_token_channel = localStorage.getItem("access_token_channel");
    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token_channel,
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
    };

    const [showOverlay, setShowOverlay] = useState(false);
    const [editUser, setEditUser] = useState({
        artist_name: '',
        artist_slug: '',
        artist_email: '',
        artist_type: ''
    })
    const [artistsType, setArtistsType] = useState([])
    const [editUserError, setEditUserError] = useState({});
    const [coverImageFile, setCoverImageFile] = useState(null)

    //input ref 
    const titleInputRef = useRef(null);
    const slugInputRef = useRef(null)
    const emailInputRef = useRef(null)
    const artistInputRef = useRef(null)
    const coverImageRef = useRef(null);

    //handle input field
    const handleInput = (e, data) => {
        const { name, value } = e.target;
        setEditUser((prevEditUser) => ({
            ...prevEditUser,
            [name]: value,
        }));
        setEditUserError((prevEditUser) => ({
            ...prevEditUser,
            [name]: '',
        }));
        setEditUserError((prevEditUser) => ({
            ...prevEditUser,
            artist_slug: '',
        }));

    }

    // Handle Auto slug generate function *start*
    const handleBlurtitle = () => {
        generateSlug(editUser?.artist_name);
    };
    const handleBlurTitleEdit = () => {
        generateSlug(editUser?.artist_slug);
    }
    const generateSlug = (title) => {
        title = title?.trim();
        const slug = title?.toLowerCase().replace(/\s+/g, "-");
        setEditUser((prevEditUser) => ({
            ...prevEditUser,
            artist_slug: slug,
        }));
    };
    const handleAutoFillChangetitle = (event) => {
        setEditUser((prevEditUser) => ({
            ...prevEditUser,
            artist_slug: event.target.value,
        }));
        setEditUserError((prevEditUser) => ({
            ...prevEditUser,
            [event.target.name]: '',
        }));
    };
    const handleInputKeyDowntitle = (event) => {
        if (event.key == "Enter" || event.key == "Tab") {
            generateSlug(editUser?.artist_name);
        }
    };
    //Handle Image upload
    const handleCoverImageChange = (event) => {
        const selectedFile = event.target.files;
        if (selectedFile.length > 0) {
            const newFile = selectedFile[0];
            const reader = new FileReader();
            reader.onload = () => {
                setCoverImageFile(newFile);
            };
            reader.readAsDataURL(newFile);
        } else {
        }
    };

    const fetchData = () => {
        axios.get(`${process.env.REACT_APP_Baseurl}/Channel/Backend/artist/create`, { headers })
            .then((response) => {
                var result = response?.data?.artist_type;
                setArtistsType(result);
            })
            .catch((error) => console.log(error));
    }
    const getUser = async () => {
        const response = await axios.get(`${process.env.REACT_APP_Baseurl}/Channel/Backend/artist/edit/${artistid}`, { headers });
        var res = response?.data?.artist;
        setEditUser(res);
    };

    var pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const formValidation = () => {
        let formIsValid = true;
        if (!editUser?.artist_type) {
            setEditUserError((prevEditUser) => ({ ...prevEditUser, artist_type: 'Type is required.' }));
            artistInputRef.current.focus();
            formIsValid = false;
        }
        if (!editUser?.artist_email) {
            setEditUserError((prevEditUser) => ({ ...prevEditUser, artist_email: 'Email is required.' }));
            emailInputRef.current.focus();
            formIsValid = false;
        }
        else if (!pattern.test(editUser?.artist_email)) {
            setEditUserError((prevEditUser) => ({ ...prevEditUser, artist_email: 'Invaild Email Address.' }));
            emailInputRef.current.focus();
            formIsValid = false;
        }
        if (!editUser?.artist_name) {
            setEditUserError((prevEditUser) => ({ ...prevEditUser, artist_name: 'Title cannot be empty.' }));
            titleInputRef.current.focus();
            formIsValid = false;
        }
        else if (!editUser?.artist_slug) {
            setEditUserError((prevEditUser) => ({ ...prevEditUser, artist_slug: 'Slug cannot be empty.' }));
            slugInputRef.current.focus();
            formIsValid = false;
        }
        return formIsValid;
    }
    const handleSubmit = async () => {
        if (formValidation()) {
            console.log("data true go to api")
            handleUpdate()
        }
        else {
            console.log('Form validation failed ');
        }
    }

    const handleUpdate = async () => {
        try {
            setShowOverlay(true);
            const formDatas = new FormData();
            const imageFormData = new FormData();
            formDatas.append("artist_name", editUser?.artist_name);
            formDatas.append("artist_slug", editUser?.artist_slug);
            formDatas.append("artist_email", editUser?.artist_email);
            formDatas.append("artist_type", editUser?.artist_type);
            formDatas.append("description", editUser?.description);

            const response = await axios.post(`${process.env.REACT_APP_Baseurl}/Channel/Backend/artist/update/${artistid}`, formDatas, { headers });
            let result = response?.data;
            imageFormData.append("artist_id", artistid);
            imageFormData.append("image", coverImageFile);
            let imageResponse;
            if (result?.status) {
                imageResponse = await axios.post(`${process.env.REACT_APP_Baseurl}/Channel/Backend/Image-upload-artist`, imageFormData, { headers });
                if (imageResponse) {
                    setShowOverlay(false)
                    toast.success(t(result?.message))
                    setTimeout(async () => {
                        window.location.reload()
                    }, 3000);
                }
                else {
                    setShowOverlay(false)
                    toast.error(t('image upload error'))
                }
            }
            else {
                setShowOverlay(false)
                toast.error(t('api upload error'))
            }
        } catch (err) {
            console.error(err);
            var errorMessage = err?.response?.data?.message;
            setShowOverlay(false);
            toast.error(t(errorMessage))
        }
    }
    useEffect(() => {
        fetchData()
        getUser()
    }, []);




    return (
        <>
            {showOverlay && (<ResponseLoader />)}
            <AdminSaveHeader heading="Edit artists" saveText="Update" saveMethod={handleSubmit} />
            <FormContainer>
                <LeftWrapper>
                    <Wrapper>
                        <MessageHeading text="Create Artist" />
                        <MessageLabel text="Artist Name" className='mandatorySimple' />
                        <input
                            type="text"
                            id="title"
                            name="artist_name"
                            className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder theme-text-color border-0 mb-3"
                            onChange={handleInput}
                            value={editUser?.artist_name}
                            onBlur={handleBlurtitle}
                            onKeyDown={handleInputKeyDowntitle}
                            placeholder="Please enter artists name"
                            ref={titleInputRef}
                        />
                        {editUserError?.artist_name && (<MessageBox text={`${editUserError?.artist_name}`} classname='errorred mb-2 d-block' />)}
                        <MessageLabel text="Artist Slug" />
                        <input
                            type="text"
                            id="artist_slug"
                            name="artist_slug"
                            className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"
                            value={editUser?.artist_slug}
                            onChange={handleAutoFillChangetitle}
                            placeholder="Please enter artist slug"
                            ref={slugInputRef}
                            onBlur={handleBlurTitleEdit}
                        />
                        {editUserError?.artist_slug && (<MessageBox text={`${editUserError?.artist_slug}`} classname='errorred mb-2 d-block' />)}
                        <MessageLabel text="Artist Email" className='mandatorySimple' />
                        <input
                            type="text"
                            id="artist_email"
                            name="artist_email"
                            className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"
                            value={editUser?.artist_email}
                            onChange={handleInput}
                            placeholder="Please enter artist email"
                            ref={emailInputRef}
                        />
                        {editUserError?.artist_email && (<MessageBox text={`${editUserError?.artist_email}`} classname='errorred mb-2 d-block' />)}
                        <MessageLabel text="Artist Type" className='mandatorySimple' />
                        <select
                            className="form-select theme-bg-color-secondary custom-placeholder theme-text-color border-0 "
                            name="artist_type"
                            id="artist_type"
                            value={editUser?.artist_type}
                            ref={artistInputRef}
                            onChange={handleInput}
                        >
                            {artistsType?.map((item) => {
                                defaultValue = editUser?.artist_type === item.value;
                                return (
                                    <option key={item?.value} value={item?.value} className="theme-bg-color theme-text-color" selected={defaultValue}>
                                        {item?.name}
                                    </option>
                                );
                            })}
                        </select>
                        {editUserError?.artist_type && (<MessageBox text={`${editUserError?.artist_type}`} classname='errorred mb-2 d-block' />)}
                    </Wrapper>
                </LeftWrapper>
                <RightWrapper>
                    <Wrapper>
                        <MessageHeading text="Thumbnails" />
                        <MessageLabel text="Cover Image" />
                        <img src={editUser?.Thumbnail} alt='artist image' style={{ width: "100px", height: '100px', objectFit: "cover" }} />
                        <div className="mx-0 my-2 row">
                            <div className="col-6 ps-0">
                                <div className="imagedrop theme-border-color"
                                >
                                    <input
                                        type="file"
                                        ref={coverImageRef}
                                        onChange={(event) => handleCoverImageChange(event)}
                                        accept="image/*"
                                        className="opacity-0"
                                        key={coverImageFile ? coverImageFile : "inputKey"} />
                                    <button type="button" className="bg-transparent position-absolute">
                                        <CameraRetroIcon />
                                    </button>
                                </div>
                            </div>
                            <div className="col-6 pe-0">
                                {coverImageFile && (
                                    <div className="imagedropcopy theme-border-color text-end">
                                        <div className="d-flex align-items-center">
                                            <img
                                                src={URL.createObjectURL(coverImageFile)}
                                                alt="Uploaded"
                                                className="thumbnails-upload-images"
                                            />
                                            <button onClick={() => setCoverImageFile(null)} type="button" className="bg-transparent theme-text-color" > <IoClose className="custom-close-btn" /> </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Wrapper>
                    <Wrapper>
                        <MessageHeading text="Descriptions" />
                        <MessageLabel text="Artist Description" />
                        <textarea
                            type="text"
                            name="description"
                            value={editUser?.description}
                            placeholder="Please enter artist description"
                            className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder theme-text-color border-0 "
                            onChange={handleInput}
                        ></textarea>
                    </Wrapper>
                </RightWrapper >
            </FormContainer >
        </>
    )
}

export default ChannelUpdateArtists