// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bootstrapTable .react-bs-table-sizePerPage-dropdown {
    position: relative;
  }
  
  .bootstrapTable .react-bs-table-sizePerPage-dropdown .dropdown-menu {
    bottom: 32px;
  }
  
  .bootstrapTable .react-bs-table-sizePerPage-dropdown .dropdown-item>a {
    display: block;
  }
  .bootstrapTable .react-bootstrap-table-pagination {
    margin: 0 !important;
  }`, "",{"version":3,"sources":["webpack://./src/Styles/BoostrapTable.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;EACpB;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,cAAc;EAChB;EACA;IACE,oBAAoB;EACtB","sourcesContent":[".bootstrapTable .react-bs-table-sizePerPage-dropdown {\n    position: relative;\n  }\n  \n  .bootstrapTable .react-bs-table-sizePerPage-dropdown .dropdown-menu {\n    bottom: 32px;\n  }\n  \n  .bootstrapTable .react-bs-table-sizePerPage-dropdown .dropdown-item>a {\n    display: block;\n  }\n  .bootstrapTable .react-bootstrap-table-pagination {\n    margin: 0 !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
